import axios from 'apiclient';

const doiConfirm = (formData) => axios.post('/api/newsletter/doiconfirm', {
  mandant: process.env.CLIENTNAME,
  ...formData,
})
  .then((result) => result.data)
  .catch((result) => Promise.reject(result.errors));

export default doiConfirm;
