import axios from 'apiclient';

export default function loadPoi(poiId) {
  return axios.get('/api/poi/doc', {
    params: {
      dummy: 1,
      mandant: process.env.CLIENTNAME,
      poiId,
    },
  }, {
    cache: {
      ignoreCache: false,
      maxAge: 15 * 60 * 10000,
    },
  }).then((res) => res.data);
}

const loadMultiplePois = (queryParameters) => axios.post('/api/poi/pois', {
  dummy: 0,
  mandant: process.env.CLIENTNAME,
  queryParameters,
}).then((res) => res.data);

export {
  loadPoi,
  loadMultiplePois,
};
