/* eslint-disable no-underscore-dangle */
import { addGuards } from 'router/guards/navigationGuards';
import scrollBehavior from 'router/scrollBehavior';
import VueRouter from 'vue-router';
import getRoutes from 'router/routes/index';
// eslint-disable-next-line import/no-unresolved
import Sentry from 'plugins/sentry.TARGET';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const handleRouterError = (onAbort) => (e) => {
  if (
    !isNavigationFailure(e, NavigationFailureType.redirected)
    && !isNavigationFailure(e, NavigationFailureType.cancelled)
  ) {
    // forward e to original onAbort
    // otherwise throw error
    if (onAbort) onAbort(e);
    else {
      // eslint-disable-next-line no-console
      console.log('throwing', e);
      throw e;
    }
  }
};

const handlePromiseRouterError = (e) => {
  if (
    !isNavigationFailure(e, NavigationFailureType.redirected)
    && !isNavigationFailure(e, NavigationFailureType.cancelled)
  ) {
    return Promise.reject(e);
  }
  return Promise.resolve();
};

const createRouter = (store) => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: getRoutes(store),
    ...(store.state.app.isNode ? {} : { scrollBehavior }),
  });

  router.onError((e) => {
    Sentry.captureException(e, {
      extra: {
        url: router.currentRoute.fullPath,
      },
    });
  });

  // replace original push with one that automatically handles navigation failures so we don't have to 'catch' all router.push calls
  const originalPush = router.push.bind(router);
  router.push = (location, onComplete, onAbort) => {
    // push can return promise or use callbacks
    // need to follow this logic here as well

    if (!onComplete && !onAbort) return originalPush(location, onComplete, onAbort).catch(handlePromiseRouterError);

    return originalPush(location, onComplete, handleRouterError(onAbort));
  };

  addGuards(router);

  return router;
};

export default createRouter;
