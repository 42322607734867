import Vue from 'vue';

export default {
  TOGGLE_HIGHLIGHT: (state, { objectNumber }) => {
    Vue.set(state, 'highlightObject', (objectNumber || null));
  },
  SET_SEARCH_CLIENTSEARCH: (state, status) => {
    state.clientSearch = status;
  },
  SET_SEARCH_LASTCLICKEDOBJECTNUMBER: (state, objectNumber) => {
    state.lastClickedObjectNumber = objectNumber;
  },
};
