import config from 'router/guards/guardConfig';
import { routeBuilder } from 'utils/locale/routeHelpers';

const redirectTo = (base, mixin = {}) => {
  const {
    path, name, meta, params, query, hash,
  } = base;
  // new path / name not provided by mixin
  // this should be avoided, but will still work
  if (!mixin.name && !mixin.path) {
    return {
      name,
      path,
      meta,
      params,
      query,
      hash,
      ...mixin,
    };
  }

  return {
    meta,
    params,
    query,
    hash,
    ...mixin,
  };
};

// taken from https://github.com/vuejs/vue-router/issues/721
let routeArgs = {};

const executeGuards = ({
  guards, router, store, to, from, next, i18n,
}, i) => {
  const guardNext = (nextArg) => {
    switch (typeof (nextArg)) {
      case 'object':
        routeArgs = routeBuilder(nextArg, router, store);
        // if (store.state.app.isNode) {
        //   router.push({ ...nextArg });
        // } else {
        next({
          ...routeArgs,
          // logic required to make history api work with anyComponent
          // it's a mess, better solution for dynamic routing needed!!!
          replace: store.state.app.router.popstate || from.name === null,
        });
        // }
        break;
      case 'boolean':
        router.push(nextArg).catch(() => {});
        // next(nextArg);
        break;
      case 'string':
        // if (store.state.app.isNode) {
        //   router.push(nextArg);
        // } else {
        next({
          path: nextArg,
          // logic required to make history api work with anyComponent
          // it's a mess, better solution for dynamic routing needed!!!
          replace: store.state.app.router.popstate || from.name === null,
        });
        // }
        break;
      default:
        if (guards.length === i + 1) {
          next();
        } else {
          executeGuards({
            guards, router, store, to, from, next, i18n,
          }, i + 1);
        }
    }
  };

  const guard = guards[i];
  guard({
    router, store, i18n, to, from, next: guardNext,
  });
};

const addGuards = (router) => {
  router.beforeEach((to, from, next) => {
    const store = router.app.$store;
    const i18n = router.app.$i18n;

    const guards = config.filter((c) => c.test(to)).reduce((t, c) => t.concat(c.guards), []);
    if (guards.length === 0) {
      next();
    } else {
      executeGuards({
        guards, router, store, to, from, next, i18n,
      }, 0);
    }
  });
  router.afterEach(() => {
    router.app.$store.state.routerPageview = true;
    if (!router.app.$store.state.app.isNode) router.app.$store.state.app.router.isEntryRoute = false;


    if (!router.app.$store.state.app.router.navigationFromPopstate && router.app.$el) {
      router.app.$store.state.app.router.lastAppHeight = router.app.$el.scrollHeight;
    }

    const removeAppHeight = () => {
      router.app.$store.state.app.router.lastAppHeight = 0;
      window.removeEventListener('scroll', removeAppHeight);
    };

    router.app.$nextTick(() => {
      if (router.app.$store.state.app.router.navigationFromPopstate) {
        window.addEventListener('scroll', removeAppHeight);
      }
    });
  });
};

export { redirectTo, addGuards };
