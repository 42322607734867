// search module
import searchState from './state';
import searchActions from './actions';
import searchMutations from './mutations';
import searchGetters from './getters';

// module in extra file so we can maybe lazyload it later
// currently it's just implemented on store creation
export default {
  state: searchState,
  actions: searchActions,
  getters: searchGetters,
  mutations: searchMutations,
};
