import axios from 'apiclient';

const tiptapContentLockToggle = ({
  uid, jwt, contentID, language, page, toggleState,
}) => axios.post('/api/cms/content/locktoggle', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  contentID,
  language,
  page,
  toggleState,
}).then((res) => res.data);

export default tiptapContentLockToggle;

