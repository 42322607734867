const IntersectionObserverHelper = {
  methods: {
    editAppIntersectClass(isVisible, entry) {
      this.$store.dispatch('EDIT_APP_INTERSECT_CLASS', {
        elemId: entry.target.id,
        visbilityStatus: (isVisible ? 'visible' : 'invisible'),
      });
    },
  },
};

export default IntersectionObserverHelper;
