import {
  getClearedFilter, getQuerySearchFilters, getOtherQueryParams, getCompareJSON,
} from 'utils/SearchFilterHelper';
import { redirectTo } from 'router/guards/navigationGuards';
import { searchLocationFilterNames } from 'router/guards/queryConfig';
import rewriteHomeUrlGuard from 'router/guards/rewriteHomeUrls';

// just importing and exporting it doesn't work in jest
// so we have to re-assign it once and then export it
const rewriteHomeUrls = rewriteHomeUrlGuard;

const getClearedSearchFilter = (store, filterIgnoreList = [], filterExclusiveList = []) => {
  // certain filters have to reset other filters for the correct location logic
  const mapFilterResets = {
    fulltext: searchLocationFilterNames.filter((el) => !['fulltext'].includes(el)),
    BBoxString: searchLocationFilterNames.filter((el) => !['fulltext', 'BBoxString'].includes(el)),
  // q: searchLocationFilterNames.filter((el) => !['fulltext', 'BBoxString', 'q'].includes(el)),
  };
  // get filters that get replaced
  const locationFilterResets = Object.keys(store.getters.getMergedTerms)
    .filter((el) => Object.keys(mapFilterResets).includes(el))
    .reduce((aggregated, current) => {
      aggregated.push(...mapFilterResets[current]);
      return aggregated;
    }, []);
  // filter getMergedTerms
  const storeFilters = Object.keys(store.getters.getMergedTerms)
    .filter((el) => !locationFilterResets.includes(el))
    .reduce((aggregated, current) => {
      aggregated[current] = store.getters.getMergedTerms[current];
      return aggregated;
    }, {});
  const clearedFilter = getClearedFilter(storeFilters, filterIgnoreList, filterExclusiveList);
  return clearedFilter;
};

// set correct search URL with query params so user can refresh page with f5 and get same results
const rewriteUrls = ({
  store, to, next,
}, searchFilterIgnoreList = [], searchFilterExclusiveList = []) => {
  const clearedSearchFilters = getClearedSearchFilter(store, searchFilterIgnoreList, searchFilterExclusiveList);
  const searchFiltersDiffer = getCompareJSON(clearedSearchFilters) !== getCompareJSON(getQuerySearchFilters(to.query));

  // store filters different => redirect
  // merge cleared search filters into other ones
  if (searchFiltersDiffer) {
    // redirect on changes to query
    next({
      ...redirectTo(to, {
        name: to.name,
        query: { ...getOtherQueryParams(to.query), ...clearedSearchFilters },
      }),
    });
  } else next();
};

const rewriteSearchUrls = ({
  store, to, next,
}) => rewriteUrls({
  store, to, next,
}, ['objectsPerPage'].concat(store.state.app.router.seoSearchFilter));
const rewriteOtherUrls = ({
  store, to, next,
}) => rewriteUrls({
  store, to, next,
}, [], ['startDate', 'endDate', 'country', 'region', 'place', 'persons']);
const rewriteObjectUrls = ({
  store, to, next,
}) => rewriteUrls({
  store, to, next,
}, [], ['startDate', 'endDate', 'persons']);

const redirectToOfferSeo = ({
  store, to, next,
}) => {
  // EDVUE-1155
  // redirect to seo url

  // we do not need to redirect non seo pages
  // technically this guard is only invoked on seo routes, but checking can't hurt
  // this obviously means seo check guard has to run before this one
  if (!store.state.isSeoPage) {
    next();
    return;
  }

  // check for offer ID in params
  // technically offer param should exist in routes that invoke this guard, but checking can't hurt
  const offerID = to.params?.offer;
  if (!offerID) {
    next();
    return;
  }
  const offerKey = `${parseInt(offerID, 10)}_${store.state.app.currentLanguage}`;
  // offer should be in the store, but let's check regardless
  if (!store.state.app.router.offer.list.includes(offerKey)) {
    next();
    return;
  }

  // redirect if path and seoUrl does not match
  // TODO: On server we do not have to push because express sends a 301 anyway, but the performance gain is marginal
  const offerData = store.state.app.router.offer.data[offerKey];
  if (offerData.seoUrl && to.path !== offerData.seoUrl) {
    store.commit('SET_APP_HTTPREWIRTEURL', offerData.seoUrl);
    store.commit('SET_APP_HTTPSTATUS', 301);
    next({
      path: offerData.seoUrl,
    });
  } else {
    next();
  }
};

export {
  rewriteSearchUrls, rewriteOtherUrls, rewriteObjectUrls, rewriteHomeUrls, redirectToOfferSeo,
};
