/**
 * this config defines possible parameters for tracking
 * additional information for tracking parameters can be added here
 */

import referrerPIDs from 'config/referrerPIDs';

const trackingParamsList = {
  pid: {
    isPid: true,
  },
  partner: {
    // this get param has to be stored/used as PID
    isPid: true,
  },
  ebk: {},
  ptc: {},
  gclid: {},
  utm_source: {},
  utm_medium: {},
  utm_campaign: {},
  utm_term: {},
  utm_content: {},
  utm_referrer: {},
  msclkid: {},
  dclid: {},
  awc: {},
  at_gd: {},
};

const pidList = {
  116729000000: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  117643000000: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  117644000000: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  117723000000: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  117145000000: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  116729000001: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  116729000004: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  11672900001: {
    description: 'HomeToGo Traffic',
    encouragementAlert: true,
  },
  114320000000: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
  },
  114321000000: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
  },
  114810000000: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
  },
  115061000000: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
  },
  115508000000: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
  },
  115508000001: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
  },
  116165000000: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
    watchlistShare: true,
  },
  117920000000: {
    description: 'Google AdWords (SEA)',
    phoneNumber: {
      interact: '+496974305466',
      display: '+49 (0)69 74 305 466',
    },
  },
  114810000001: {
    description: 'Google AdWords Brand (SEA)',
    phoneNumber: {
      interact: '+496974305469',
      display: '+49 (0)69 74 305 469',
    },
  },
  117749000000: {
    description: 'Google AdWords Brand (SEA)',
    phoneNumber: {
      interact: '+496974305469',
      display: '+49 (0)69 74 305 469',
    },
    watchlistShare: true,
  },
  114531000000: {
    description: 'Bing Ads (SEA)',
    phoneNumber: {
      interact: '+496974305481',
      display: '+49 (0)69 74 305 481',
    },
  },
  117750000000: {
    description: 'Bing Ads (SEA)',
    phoneNumber: {
      interact: '+496974305481',
      display: '+49 (0)69 74 305 481',
    },
    watchlistShare: true,
  },
  117996000000: {
    description: 'Bing Ads (SEA)',
    phoneNumber: {
      interact: '+496974305481',
      display: '+49 (0)69 74 305 481',
    },
  },
  114531000001: {
    description: 'Bing Ads Brand (SEA)',
    phoneNumber: {
      interact: '+496974305482',
      display: '+49 (0)69 74 305 482',
    },
  },
  117751000000: {
    description: 'Bing Ads Brand (SEA)',
    phoneNumber: {
      interact: '+496974305482',
      display: '+49 (0)69 74 305 482',
    },
    watchlistShare: true,
  },
  116057000000: {
    description: 'Criteo',
    phoneNumber: {
      interact: '+496974305476',
      display: '+49 (0)69 74 305 476',
    },
  },
  117752000000: {
    description: 'Criteo',
    phoneNumber: {
      interact: '+496974305476',
      display: '+49 (0)69 74 305 476',
    },
    watchlistShare: true,
  },
  115696000000: {
    description: 'Social Media (Facebook)',
    phoneNumber: {
      interact: '+496974305435',
      display: '+49 (0)69 74305 435',
    },
    watchlistShare: true,
  },
  117160000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
    watchlistShare: true,
  },
  111116000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  116374000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  117528000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  117661000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  117662000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  115109000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  117529000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  117660000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  118023000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  118024000000: {
    description: 'Newsletter',
    phoneNumber: {
      interact: '+496974305475',
      display: '+49 (0)69 74305 475',
    },
  },
  // Bellevue-Ferienhaus DE
  115979000000: {
    description: 'Google Ads',
    phoneNumber: {
      interact: '+4925138480783',
      display: '+49 (0)251 38 480 783',
    },
  },
  115979000002: {
    description: 'Google Ads Brand',
    phoneNumber: {
      interact: '+4925138480784',
      display: '+49 (0)251 38 480 784',
    },
  },
  117766000000: {
    description: 'Google Ads Display',
    phoneNumber: {
      interact: '+4925138480783',
      display: '+49 (0)251 38 480 783',
    },
  },
  115979000003: {
    description: 'Google Ads Dynamic Remarketing',
    phoneNumber: {
      interact: '+4925138480783',
      display: '+49 (0)251 38 480 783',
    },
  },
  115979000004: {
    description: 'Bellevue-Ferienhaus DE Vermieter',
    phoneNumber: {
      interact: '+4925138480783',
      display: '+49 (0)251 38 480 783',
    },
  },
  116444000000: {
    description: 'Criteo',
    phoneNumber: {
      interact: '+4925138480787',
      display: '+49 (0)251 38 480 787',
    },
  },
  116459000000: {
    description: 'Bing Ads',
    phoneNumber: {
      interact: '+4925138480785',
      display: '+49 (0)251 38 480 785',
    },
  },
  116459000001: {
    description: 'Bing Ads Brand',
    phoneNumber: {
      interact: '+4925138480786',
      display: '+49 (0)251 38 480 786',
    },
  },
  117720000000: {
    description: 'Google Ads Anruf',
    phoneNumber: {
      interact: '+4925138480783',
      display: '+49 (0)251 38 480 783',
    },
  },
  117753000000: {
    description: 'Google Ads Brand Anruf',
    phoneNumber: {
      interact: '+4925138480784',
      display: '+49 (0)251 38 480 784',
    },
  },
  117754000000: {
    description: 'Bing Ads Anruf',
    phoneNumber: {
      interact: '+4925138480785',
      display: '+49 (0)251 38 480 785',
    },
  },
  117755000000: {
    description: 'Bing Ads Brand Anruf',
    phoneNumber: {
      interact: '+4925138480786',
      display: '+49 (0)251 38 480 786',
    },
  },
  116049000000: {
    description: 'GNL',
    watchlistShare: true,
  },
  118406000000: {
    description: 'SCNL',
  },
  118537000000: {
    description: 'Trigger Geburtstag',
  },
  118628000000: {
    description: 'Trigger Ratings',
  },
  118407000000: {
    description: 'Welcomemailing',
  },
  ...referrerPIDs,
};

// predefined strings for Google Tag Manager Events
const gtmFilterMapping = {
  pets: {
    one: 'haustier_eins',
    more: 'haustier_mehrere',
    none: 'haustier_ohne',
  },
  objectType: {
    alltypes: 'unterkunft_beliebig',
    house: 'unterkunft_haus',
    apartment: 'unterkunft_wohnung',
  },
  price: {
    min: 'eins',
    max: 'mehrere',
  },
  distances: {
    distSea: 'entfernung_meer_',
    distLake: 'entfernung_see_',
    distSki: 'entfernung_ski_',
    distSkiloipe: 'entfernung_loipe_',
  },
  distanceString: { // FilterDistance.vue
    0: 'beliebig',
    1: 'Fußnähe',
    5: 'bis_5km',
    10: 'bis_10km',
  },
  boolfilter: { // Filterbool.vue
    sauna: 'ausstattung_sauna',
    swimmingpool: 'ausstattung_pool',
    internet: 'ausstattung_internet',
    terrace: 'ausstattung_terrasse',
    parking: 'ausstattung_parkplatz',
    tv: 'ausstattung_tv',
    dishwasher: 'ausstattung_spülmaschine',
    washingmachine: 'ausstattung_waschmaschine',
    nonsmoking: 'ausstattung_nichtraucher',
    grill: 'ausstattung_grill',
    airconditioning: 'ausstattung_klimaanlage',
    whirlpool: 'ausstattung_whirlpool',
    fireplace: 'ausstattung_kamin',
    fishing: 'ausstattung_angeln',
    boatrentalservice: 'ausstattung_boot',
    seaview: 'ausstattung_seemeerblick',
  },
  sleepingRooms: 'schlafzimmer',
  bathRooms: 'badezimmer',
  rating: 'bewertung',
  persons: 'personen',
  freeCancel: {
    Y: 'stornierung_kostenlos',
    N: 'stornierung_beliebig',
  },
  tolerance: {
    '': 'flex_anreise_genauer_reisetermin',
    1: 'flex_anreise_1_tag',
    2: 'flex_anreise_2_tage',
    3: 'flex_anreise_3_tage',
  },
};

const gtmClient = {
  'e-domizil.de': {
    GTM_CONTAINER_ID: 'GTM-WWV5TFK',
  },
  'e-domizil.ch': {
    GTM_CONTAINER_ID: 'GTM-WWV5TFK',
  },
  'atraveo.de': {
    GTM_CONTAINER_ID: 'GTM-TJZ6X2C',
  },
  wlv: {
    GTM_CONTAINER_ID: 'GTM-WWV5TFK',
  },
  'bellevue-ferienhaus.de': {
    GTM_CONTAINER_ID: 'GTM-KJ4K7MB',
  },
};
export {
  trackingParamsList,
  pidList,
  gtmFilterMapping,
  gtmClient,
};
