import Vue from 'vue';

Vue.filter('toUpper', (value) => value.toUpperCase());
//  example: {{ average | toFixed(1) }}
Vue.filter('toFixed', (price, limit) => { if (price) { return price.toFixed(limit); } return ''; });

// slice to make a copy of array, then reverse the copy
Vue.filter('objectReverse', (unordered) => {
  const srcKeys = Object.keys(unordered);
  const reversed = [];
  Object.keys(srcKeys).slice().reverse().forEach((key) => {
    reversed.push({ [srcKeys[key]]: unordered[srcKeys[key]] });
  });

  return reversed;
});

// check Object values, remove empty properties
Vue.filter('object_filter', (srcObject) => {
  const result = {};
  Object.keys(srcObject).forEach((key) => {
    if (srcObject[key]) {
      result[key] = srcObject[key];
    }
  });
  return result;
});

Vue.filter('replaceHeart', (string) => {
  const heartSvg = '<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class=""><path data-v-5ce0fe79="" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg></i>';
  return string.replace(/❤/, heartSvg);
});
