import Vue from 'vue';

export default {
  TIPTAP_CONTENT_STORE: (state, {
    language, page, containerKey, containerData,
  }) => {
    if (containerData) {
      // do not overwrite content already stored in store
      const currentContent = { ...state.pageContent } || {};
      if (!currentContent[language]) {
        currentContent[language] = {};
      }
      if (!currentContent[language][page]) {
        currentContent[language][page] = {};
      }
      if (!currentContent[language][page][containerKey]) {
        currentContent[language][page][containerKey] = {};
      }
      currentContent[language][page][containerKey] = containerData;
      Vue.set(state, 'pageContent', currentContent);
    }
  },
};
