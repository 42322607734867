import axios from 'apiclient';

const partnerLogin = (uid, partner, c) => axios.post('/api/user/partnerlogin', {
  mandant: process.env.CLIENTNAME,
  uid,
  partner,
  c,
}, {
  timeout: 20000,
}).then((result) => Promise.resolve(result.data));

export default partnerLogin;
