import axios from 'apiclient';

const userLogin = (uid, jwt, user, loginPersistent) => axios.post('/api/user/login', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  user,
  loginPersistent,
}, {
  timeout: 20000,
}).then((result) => Promise.resolve(result.data));

export default userLogin;
