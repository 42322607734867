export default {
  getWatchedObjectById: (state) => (objectNumber) => {
    let watchedObjectReturn = {};
    if (state.watchedObjectsSearch) {
      state.watchedObjectsSearch.forEach((watchedObject) => {
        if (watchedObject.objectNumber === objectNumber) {
          watchedObjectReturn = watchedObject;
        }
      });
    }
    return watchedObjectReturn;
  },
  getUid: (state) => state.uid,
  getSharedWatchListUserUID: (state) => state.sharedWatchListUserUID,
  getEbk: (state) => state.ebk,
  getWatchListUID: (state) => state.watchListUID,
  getStoreSubscriptions: (state) => state.storeSubscriptions,
  getLastSeenObjects: (state) => state.lastSeenObjects,
  getWatchedObjects: (state) => state.watchedObjects,
  getBookingList: (state) => state.bookingList,
  getBookingDetails: (state) => state.bookingDetails,
  getWatchedObjectsShared: (state) => state.watchedObjectsShared,
  getWatchedObjectsSearch: (state) => state.watchedObjectsSearch,
  // "active" means, that this objects are not deleted from watchlist
  getActiveWatchedObjectsSearch: (state) => {
    const watchedObjectsReturn = [];
    if (state.watchedObjectsSearch && state.watchedObjects) {
      state.watchedObjectsSearch.forEach((watchedObjectSearch) => {
        state.watchedObjects.forEach((watchedObject) => {
          if (watchedObjectSearch.objectNumber === watchedObject.objectNumber) {
            if (!watchedObject.deleteDate) {
              watchedObjectsReturn.push(watchedObjectSearch);
            }
          }
        });
      });
    }
    return watchedObjectsReturn;
  },
  getWatchedObjectsAggregations: (state) => state.watchedObjectsAggregations,
  getWatchedObjectsSearchBounds: (state) => state.watchedObjectsAggregations.searchBounds,
  getPidLatest: (state) => state.pidLatest,
  getPidHistory: (state) => state.pidHistory,
  getPtcLatest: (state) => state.ptcLatest,
  getGclidLatest: (state) => state.gclidLatest,
  getMsclkidLatest: (state) => state.msclkidLatest,
  getDclidLatest: (state) => state.dclidLatest,
  getEncouragementFlag: (state) => state.encouragementFlag,
  getExperiments: (state) => state.experiments,
  getExperimentById: (state) => (experimentid) => state.experiments[experimentid] || { variant: 0 },
  getJwt: (state) => state.jwt,
  getAccountData: (state) => state.accountData,
  getBookingConfirmationData: (state) => state.bookingConfirmationData,
};
