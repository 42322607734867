import axios from 'apiclient';

export default function updateOfficeOverwrites(uid, jwt, updateData) {
  return axios.post('/api/office/overwrites/set', {
    mandant: process.env.CLIENTNAME,
    uid,
    jwt,
    updateData,
  }).then((res) => res);
}
