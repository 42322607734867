import axios from 'axios';
import axiosConfig from 'axiosconfig';
// eslint-disable-next-line import/no-unresolved
// import Sentry from 'plugins/sentry.TARGET';

const verifyResponse = (data) => typeof data.data === 'object'
  && typeof data.errorLength !== 'undefined' && parseInt(data.errorLength, 10) > -1
  && typeof data.status !== 'undefined' && [200, 201, 404, 410, 400, 500].includes(data.status)
  && typeof data.statusText === 'object'
  && typeof data.statusText.msg !== 'undefined' && typeof data.statusText.isKey !== 'undefined'
  && Array.isArray(data.errors)
  && ([200, 201].includes(data.status) || data.errors.length > 0) // unsuccessful requests must contain error desc
  && (
    data.errors.length === 0
    || !data.errors.map((error) => typeof error.text === 'string'
        && typeof error.isKey !== 'undefined'
        && typeof error.type === 'string'
        && typeof error.data === 'object'
        && typeof error.appInform !== 'undefined').includes(false)
  );

// this should have the same structure as responses from the API => check App/Helper/ResponseHelper.php
const defaultResponseData = () => ({
  // response data structure
  data: {},
  errorLength: 0,
  errors: [],
  status: 200,
  statusText: {
    msg: '',
    isKey: false,
  },
});

// this should have the same structure as responses from the API => check App/Helper/ResponseHelper.php
const defaultError = () => ({
  text: '',
  isKey: false,
  type: '',
  data: {},
  appInform: true,
});

const malformedResponseError = () => {
  const data = defaultResponseData();
  data.status = 500;
  const err = defaultError();
  err.type = 'malformed';
  err.text = 'api returned a malformed response';

  data.errors.push(err);
  data.errorLength = 1;

  return data;
};

const responseInterceptor = (res) => {
  // console.log(res.config.url, end, start, end - start);
  // we can get valid responses that contain no data
  // add malformed response with given status code
  if (!verifyResponse(res.data)) {
    res.data = malformedResponseError();
    res.data.status = res.status;
  }
  return res.data;
};

const errorInterceptor = (error) => {
  let isCancel = false;
  if (Object.getPrototypeOf(error).name !== 'Error') {
    isCancel = axios.isCancel(error);
    // eslint-disable-next-line no-param-reassign
    error = new Error(error.message);
  }

  if (typeof error.response === 'undefined' || typeof error.response.data === 'string') {
    // no server answer, timeout, or axios error etc.
    if (typeof error.response === 'undefined') error.response = {};
    error.response.data = defaultResponseData();
    error.response.data.status = 500;
    error.response.data.statusText.msg = error.message;

    // create error
    const err = defaultError();
    err.text = error.message;
    err.appInform = true;
    // set error type
    if (error.code === 'ECONNABORTED') {
      err.type = 'timeout';
      error.name = `TimeoutError_${error.config.url.replace(/\//g, '')}`;
    } else if (err.isAxiosError) err.type = 'axios';
    else err.type = 'unknown';

    // add error and reject
    error.response.data.errors.push(err);
    error.response.data.errorLength = 1;

    // add cancel token to fake out axios and let it properly identify request as cancelled later
    // eslint-disable-next-line no-underscore-dangle
    if (isCancel) error.response.data.__CANCEL__ = true;
  }

  if (!verifyResponse(error?.response?.data ?? {})) {
    error.response.data = malformedResponseError();
  }

  // push api errors with context to sentry
  // if (process.env.NODE_ENV === 'production' && !isCancel && error.response.data.status >= 500) {
  //   const url = error?.config?.url ?? 'unknown';
  //   Sentry.setExtra('url', url);
  //   Sentry.setExtra('params', error?.config?.params ?? null);
  //   Sentry.setExtra('errorResponse', error.response.data);
  //   Sentry.setExtra('errorData', error.response.data.errors);
  //   Sentry.setExtra('errorRequest', error.request);
  //   error.message = `${error.message}: ${url}`;
  //   Sentry.captureException(error);
  // }

  if (error.response.data.errors.length && error.response.data.errors.map((err) => err.appInform).includes(false)) {
    error.response.data.errors = error.response.data.errors.filter((err) => err.appInform);
    error.response.data.errorLength = error.response.data.errors.length;
  }

  // add generic error if none provided
  if (error.response.data.errorLength === 0) {
    error.response.data.errors.push(defaultError());
    error.response.data.errors[0].type = 'unknown';
    error.response.data.errors[0].text = 'no error data provided';
    error.response.data.errors[0].appInform = true;
    error.response.data.errorLength = error.response.data.errors.length;
  }

  return Promise.reject(error.response.data);
};

// Add a request interceptor
const reqInterceptor = (config) => {
  config.params = config.params || {};
  // config.params.testparam = 'en';
  return config;
};

export {
  responseInterceptor, errorInterceptor, defaultResponseData, defaultError, verifyResponse, reqInterceptor,
};

const client = axios.create(axiosConfig);
client.interceptors.request.use(reqInterceptor);
client.interceptors.response.use(responseInterceptor, errorInterceptor);

export default client;
