/*

@@author        j0Shi
@@date          2019.08.08
@@desc          Helps with device specific environment vars
@@usedby        LightboxStandard -> Object

*/

// save last known breakpoint because breakpoint can be undefined and then we'll simply use the last known one to not run into undefined trouble
let lastKnownBreakpoint = 'error';

const DeviceHelper = {
  data() {
    return {
      dh_mediaQueryStrings: {
        xs: '(max-width: 576px), (max-width: 575.98px) and (max-height: 575.98px) and (orientation: landscape)',
        sm: '(min-width: 576px) and (max-width: 767.98px), (max-width: 767.98px) and (min-height: 576px) and (max-height: 767.98px) and (orientation: landscape)',
        smIOS: '(min-width: 576px) and (max-width: 895.98px) and (-webkit-device-pixel-ratio: 3), (min-height: 576px) and (max-height: 895.98px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)',
        md: '(min-width: 768px) and (max-width: 991.98px)',
        lg: '(min-width: 992px) and (max-width: 1199.98px)',
        xl: '(min-width: 1200px)',
        landscape: '(orientation: landscape)',
        portrait: '(orientation: portrait)',
        pointerMouse: '(pointer: fine)',
        pointerTouch: '(pointer: coarse)',
      },
      dh_mediaQueryObjects: {},
      dh_mediaMatches: {},
    };
  },
  computed: {
    dh_breakpoint() {
      const idents = ['xs', 'sm', 'md', 'lg', 'xl'];
      const match = Object.keys(this.dh_mediaMatches).find(
        (el) => idents.includes(el) && this.dh_mediaMatches[el],
      );
      if (match) {
        lastKnownBreakpoint = match;
        return match;
      }
      return lastKnownBreakpoint;
    },
  },
  mounted() {
    // cycle through strings and add media query objects
    Object.keys(this.dh_mediaQueryStrings).forEach((ident) => {
      this.dh_setup(ident);
    });
  },
  methods: {
    dh_setup(ident) {
      const query = this.dh_mediaQueryStrings[ident];
      this.$set(this.dh_mediaQueryObjects, ident, window.matchMedia(query));
      // save media string back to mediaQueryStrings because matchMedia might change it
      this.dh_mediaQueryStrings[ident] = this.dh_mediaQueryObjects[ident].media;
      this.dh_mediaQueryObjects[ident].addListener(this.dh_listener);
      this.dh_listener(this.dh_mediaQueryObjects[ident]);
    },
    dh_addQuery(ident, queryString) {
      if (typeof this.dh_mediaQueryStrings[ident] !== 'undefined') {
        this.dh_modifyQuery(ident, queryString);
      } else {
        this.$set(this.dh_mediaQueryStrings, ident, queryString);
        this.dh_setup(ident);
      }
    },
    dh_modifyQuery(ident, queryString) {
      this.dh_mediaQueryStrings[ident] = queryString;
      this.dh_mediaQueryObjects[ident].removeListener(this.dh_listener);
      this.dh_setup(ident);
    },
    dh_listener(e) {
      // e.media = used query string
      const ident = this.dh_getIdent(e.media);
      if (typeof this.dh_mediaMatches[ident] === 'undefined') {
        this.$set(this.dh_mediaMatches, ident, e.matches);
      } else {
        this.dh_mediaMatches[ident] = e.matches;
      }
    },
    dh_getIdent(query) {
      const filter = Object.keys(this.dh_mediaQueryStrings).find(
        (el) => this.dh_mediaQueryStrings[el] === query,
      );
      if (typeof filter !== 'undefined') return filter;
      return false;
    },
    dh_isMatching(ident) {
      if (typeof this.dh_mediaMatches[ident] === 'undefined') return false;
      return this.dh_mediaMatches[ident];
    },
  },
  destroyed() {
    // cycle through strings and unregister media query listeners
    Object.keys(this.dh_mediaQueryObjects).forEach((ident) => {
      this.dh_mediaQueryObjects[ident].removeListener(this.dh_listener);
    });
  },
};
export default DeviceHelper;
