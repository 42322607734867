export default {
  ratingsSummary: {
    average: 0,
    total: 0,
    votes: {
      cleanliness: false,
      externalImpression: false,
      furniture: false,
      location: false,
      priceToService: false,
    },
    votesAverage: 0,
  },
  ratings: {
    1: {
      ratingid: 1,
      votes: {
        furniture: 5,
        location: 5,
        cleanliness: 5,
        externalImpression: 5,
        priceToService: 5,
        votesAverage: 5,
      },
      travelData: {
        travellerName: 'Max Mustermann',
        travelStart: '2019-02-15T00:00:00',
        travelEnd: '2019-02-17T00:00:00',
        ratingTyp: 1,
        ratingDate: '2019-02-19T16:01:00',
        travellerVerified: 1,
        travelType: 'family',
      },
      average: 5,
      timestamp: 1550188800,
      documents: {
        objectpositive: 'objectpositive',
      },
    },
  },
};
