// eslint-disable-next-line import/no-unresolved
import config from 'config/appConfig';

const pathMatches = `${config.locales.helper.supportedLanguageIdents().join('|')}|db`;

const stripLocaleFromPath = (path) => {
  const reHome = new RegExp(`^/(${pathMatches})${config.router.trailingSlash ? '/' : ''}$`);
  const re = new RegExp(`^/(${pathMatches})/(.+)`);
  const returnPath = path.replace(re, '/$2').replace(reHome, '');
  return returnPath;
};

const stripLocaleFromRoute = (to) => {
  config.locales.languageRouteMethods.forEach(({ type, name, listen }) => {
    if (typeof to[type] === 'undefined') to[type] = {};
    if (listen && to[type][name] !== 'undefined') {
      to[type][name] = '';
      delete to[type][name];
    }
  });

  const path = stripLocaleFromPath(to.path);
  const name = to.name === 'home-locale' ? 'home' : to.name;

  return {
    ...to,
    path,
    name,
  };
};

const getLocaleFromRoute = (to) => {
  let locale = config.locales.standardLanguage;

  const langParam = config.locales.languageRouteMethods
    .filter(({ type, name, listen }) => listen && typeof to[type][name] !== 'undefined')
    .map(({ type, name }) => to[type][name])
    .reduce((aggr, cur) => (aggr === null ? cur : aggr), null);

  // check param locale first
  if (langParam !== null && config.locales.helper.supportedLanguageIdents().includes(langParam)) {
    locale = langParam;
  } else {
    const pathToCheck = stripLocaleFromRoute(to).path;
    const localeOfUrl = to.path.replace(pathToCheck, '').substr(1, 2);
    if (config.locales.helper.supportedLanguageIdents().includes(localeOfUrl)) {
      locale = localeOfUrl;
    }
  }
  return locale;
};

const languageLink = (languageCode, to, router) => {
  let resolvedTo = router.resolve(to);

  // EDVUE-2130 check if it is an language specific link available
  if (to.name
    && resolvedTo.resolved.name
    && resolvedTo.resolved.meta?.language
  ) {
    const langTo = { ...to };
    langTo.name = langTo.name.replace(`-${resolvedTo.resolved.meta.language}`, '');
    if (languageCode !== config.locales.standardLanguage) {
      langTo.name += `-${languageCode}`;
    }
    const resolvedLangTo = router.resolve(langTo);
    if (resolvedLangTo.resolved) {
      resolvedTo = resolvedLangTo;
    }
  }

  let { path } = resolvedTo.resolved;
  // let routeName = resolvedTo.resolved.name;

  if (path !== '') {
    const newPath = stripLocaleFromPath(path);
    if (newPath !== path) {
      path = newPath;
      // routeName = null;
    }
  }

  // if language differs add check for trailing slash
  if (!to.name || (path === '' && config.locales.standardLanguage !== languageCode)) {
    // manage training slahes
    if (config.router.trailingSlash && path.match(/\/$/) === null && path.match(/\.[a-z]+$/i) === null) {
      path += '/';
    }
    if (!config.router.trailingSlash && (path.match(/\/$/) !== null || path.match(/\.[a-z]+$/i) !== null)) {
      path = path.replace(/\/$/, '');
    }
  }

  const localizedPath = `/${languageCode}${path}`;
  config.locales.languageRouteMethods
    .filter((el) => el.write)
    .forEach((method) => {
      const { type, name } = method;
      // if (languageCode !== resolvedTo.resolved[type][name]) {
      // lang missing in url, add!
      if (config.locales.standardLanguage !== languageCode) {
        resolvedTo.resolved[type][name] = languageCode;
        path = localizedPath;
        // eslint-disable-next-line no-debugger
        // routeName = null;
      } else {
        // standard language, remove lang param
        resolvedTo.resolved[type][name] = undefined;
      }
      // }
    });

  // adding param only works with names routes, but route might not have been added yet
  // rewrite url in that case
  return {
    query: resolvedTo.resolved.query,
    hash: resolvedTo.resolved.hash,
    path,
    name: to.path === path ? resolvedTo.resolved.name : undefined,
    params: resolvedTo.resolved.params,
    meta: resolvedTo.resolved.meta,
  };
};

const routeBuilder = (to, router, store) => languageLink(store.state.app.currentLanguage, to, router);

export {
  routeBuilder, getLocaleFromRoute, stripLocaleFromRoute, pathMatches, languageLink,
};
