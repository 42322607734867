import cdn from 'config/cdn';

export default {
  agency: 0,
  active: 0,
  availability: {
    count: 0,
    days: {},
    maxDate: new Date(),
  },
  additionalCosts: {
    additionalCosts: '',
    additionalCostsTest: '',
  },
  breadcrumb: [],
  objectGroupKeyCount: 0,
  countRooms: 0,
  countRoomsBath: 0,
  countRoomsSleeping: 0,
  created: new Date(),
  descriptionLanguages: [],
  descriptions: {},
  descriptionsAexea: {
    description: '',
    teaser: '',
  },
  detailPetAllowed: 0,
  detailPetSize: 'big',
  images: [{
    src: `https://${cdn}/photos/dummy/noimage_725x450.webp`,
    cdn: '725x450_webp, 250x155_webp',
    no: 1
  }],
  locations: {
    country: '',
    countryCode: '',
    region: '',
    state: '',
    regionNumber: 0,
  },
  minPrice: 0,
  objectDTV: 0,
  objectFeatureDescription: '',
  objectNumber: 0,
  objectRatingSummary: {
    cleanliness: 5,
    externalImpression: 5,
    furniture: 5,
    location: 5,
    priceToService: 5,
  },
  objectSTV: 0,
  objectType: null,
  personsMax: 0,
  adultsMax: 0,
  position: {
    lat: 0,
    lon: 0,
  },
  prices: [],
  providerType: 'wholesaler',
  ratingAverage: 5,
  ratingCount: null,
  searchFilter: [],
  searchOffers: [],
  squaremeterComplete: 0,
  squaremeterLiving: 0,
  SEOLinks: {
    country: '',
    object: '',
    region: '',
    state: '',
  },
  title: '',
  index: 'Y',
  agencyName: '',
  distance: 0,
  landlord: {},
  privateObject: 0,
};
