/**
 * ONLY EDIT THIS FILE WITHIN CLIENT FOLDERS TO OVERRIDE MAIN CONFIG
 */
export default {
  office: {
    default: {
      header: {
        contactImage: false,
        top: {
          display: {
            source: 'partnerConfig',
          },
        },
        middle: {
          display: {
            source: 'partnerConfig',
          },
          interact: {
            source: 'partnerConfig',
          },
        },
        bottom: {
          display: {
            source: 'partnerConfig',
          },
          interact: {
            source: 'partnerConfig',
            prefix: 'office:header.prefixMail',
          },
        },
      },
    },
  },
};
