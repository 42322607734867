// regex for curretn domain replacement in seo content
import offerDefault from 'store/defaults/offer';

const DomainRegex = new RegExp(`https://product.${process.env.CLIENTNAME}`, 'g');

export default {
  getSearchObjectById: (state) => (objectNumber) => {
    let searchObjectReturn = {};
    // eslint-disable-next-line no-unused-vars
    Object.entries(state.searchObjects).forEach(([fooKey, searchObject]) => {
      if (searchObject.objectNumber === objectNumber) {
        searchObjectReturn = searchObject;
      }
    });
    return searchObjectReturn;
  },
  getMapStatus: (state) => state.searchMapFullscreen,
  getMapZoom: (state) => state.decreaseZoom,
  getSearchMapModalStatus: (state) => state.searchMapModalStatus,
  getSearchObjects: (state) => state.searchObjects || [],
  getSearchObjectsNumbers: (state) => {
    const searchObjectsNumbers = [];
    if (state.searchObjects && Object.keys(state.searchObjects).length) {
      state.searchObjects.forEach((searchObject) => {
        searchObjectsNumbers.push(searchObject.objectNumber);
      });
    }
    return searchObjectsNumbers;
  },
  getObjectInternal: (state) => (objectNumber) => state.objectInternalData[objectNumber],
  // check if internal object information is saved in vuex
  getObjectInternalSaved: (state) => (objectNumbers) => {
    let objectInternalSaved = true;
    objectNumbers.forEach((objectNumber) => {
      if (!state.objectInternalData[objectNumber]) {
        objectInternalSaved = false;
      }
    });
    return objectInternalSaved;
  },
  getActiveFilterCount: (state) => {
    const ignoreProps = {
      BBoxString: null,
      page: null,
      country: null,
      priceMax: null,
      duration: null,
      persons: null,
      endDate: null,
      objectsPerPage: null,
      sort: null,
    };
    const res = { ...state.searchFilter, ...ignoreProps };
    const count = Object.values(res).filter((n) => n).length;
    return count;
  },
  getSearchFilter: (state) => (name) => state.searchFilter[name] || undefined,
  getSearchFilters: (state) => state.searchFilter || [], // get all filters
  getSearchLocationFilter: (state) => (name) => state.searchLocationFilter[name] || '',
  getMergedTerms: (state) => ({
    ...state.searchFilter, ...state.searchLocationFilter,
  }),
  getSearchFilterForced: (state) => state.searchFilterForced || {}, // get all forced filters
  getDirectusContent: (state) => (pageAlias) => {
    const content = state.directusContent[pageAlias];
    if (content) {
      return content;
    }
    return false;
  },
  getDirectusList: (state) => (pageAlias) => {
    const content = state.directusContent[pageAlias] || {};
    return content;
  },
  getSearchFilterCount: (state) => (name) => ((
    state.searchAggregations.searchCriteria && state.searchAggregations.searchCriteria[name])
    ? state.searchAggregations.searchCriteria[name] : 0),
  getPriceAggregation: (state) => ({
    all: [0, 0], cheap: [0, 0], mid: [0, 0], premium: [0, 0], ...state.searchAggregations.prices_stats,
  }),
  getOfficeContact: (state) => state.officeContact,
  getOfficeInfoFull: (state) => state.officeInfoFull,
  getOfficeOverwrites: (state) => state.officeOverwrites,
  getAggregationTypeCount: (state) => (name, value) => ((
    state.searchAggregations
    && state.searchAggregations[name]
    && state.searchAggregations[name][value]
  )
    ? state.searchAggregations[name][value] : null
  ),
  getAppIntersectClass: (state) => state.appIntersectClass,
  getAggregation: (state) => (name) => ((state.searchAggregations
    && state.searchAggregations[name])
    ? ({ ...state.searchAggregations[name] }) : {}
  ),
  getRatingsTotal: (state) => ((state.searchAggregations
    && state.searchAggregations.totalRatings)
    ? state.searchAggregations.totalRatings : 0
  ),
  getRatingsAVG: (state) => ((state.searchAggregations
    && state.searchAggregations.ratingAVG)
    ? state.searchAggregations.ratingAVG : 0
  ),
  // get poi object or empty
  getPoiDocument: (state) => (poiId) => state.documents.pois[poiId] || {},
  // get multiple pois for many map marker or pois on objectpage
  getPois: (state) => (name) => state.pois[name] || [],
  // return poi object length as render check
  hasPoi: (state, getters) => (poiId) => Object.keys(getters.getPoiDocument(poiId)).length,
  getSearchOvergrey: (state) => state.searchOvergrey,

  // router => whoami
  getWhoamiData: (state) => (path) => {
    if (state.app.router.whoami.list.includes(path)) return state.app.router.whoami.data[path];
    return false;
  },
  // router => offer
  getOfferData: (state) => (id) => {
    const languageID = `${id}_${state.app.currentLanguage}`;
    if (state.app.router.offer.list.includes(languageID)) return state.app.router.offer.data[languageID];
    return false;
  },
  // api http states
  getApiHttpStatus: (state) => (type) => {
    if (typeof state.app.apiHttpStatus[type] !== 'undefined') return state.app.apiHttpStatus[type];
    return 200;
  },
  getErrorTextObject: (state) => state.app.apiErrorText || {},
  getSsrHttpStatus: (state) => state.app.ssrHttpStatus || 200,
  getLocationSuccessfull: (state) => state.locationSuccessfull || false,
  getLocationName: (state) => state.locationInformations.locationName,
  getLocationType: (state) => state.locationInformations.locationType || 0,
  checkCoordinatesSearch: (state) => state.coordinatesSearch || false,
  getLocationPosition: (state) => state.locationInformations.locationPosition || {},
  getLocationNamePreposition: (state) => state.locationInformations.locationNamePreposition,
  getLocationNameWithPreposition: (state) => state.locationInformations.locationNameWithPreposition,
  getLocationBreadcrumbs: (state) => state.locationInformations.locationBreadcrumbs || [],
  getLocationTitle: (state) => state.locationInformations.title,
  getLocationSeoContent: (state) => ({
    title: state.locationInformations.title,
    text1: state.locationInformations.text1.replace(DomainRegex, ''),
    text2: state.locationInformations.text2.replace(DomainRegex, ''),
    metaTitle: state.locationInformations.metaTitle,
    metaDescription: state.locationInformations.metaDescription,
    metaKeywords: state.locationInformations.keywords,
    seoText: state.locationInformations.seoText.replace(DomainRegex, ''),
    seoUrl: state.locationInformations.seoUrl,
    noIndex: state.locationInformations.noIndex,
    languageLinks: state.locationInformations.languageLinks,
    topLinks: state.locationInformations.topLinks,
    offers: state.locationInformations.offers,
  }),
  getOfferContent: (state, getters) => (id) => {
    let offerData = getters.getOfferData(parseInt(id, 10));
    if (offerData === false) offerData = offerDefault;
    return {
      id: offerData.id,
      name: offerData.name,
      nameIncluded: offerData.nameIncluded,
      title: offerData.title,
      text1: offerData.text1.replace(DomainRegex, ''),
      text2: offerData.text2.replace(DomainRegex, ''),
      metaTitle: offerData.metaTitle,
      metaDescription: offerData.metaDescription,
      metaKeywords: offerData.keywords,
      seoText: offerData.seoText.replace(DomainRegex, ''),
      seoUrl: offerData.seoUrl,
      noIndex: offerData.noIndex,
      languageLinks: offerData.languageLinks,
      query: offerData.query,
      topLinks: [],
    };
  },
  getOfferList: (state) => state.offerInformations || [],
  getOfferBreadcrumbs: (state, getters) => (id) => getters.getOfferData(parseInt(id, 10)).breadcrumbs || [],
  getLocationBounds: (state) => state.locationInformations.bounds,
  gtmPush: (state) => state.gtmPush,
  userDataStatus: (state) => state.userSaved,
  getSearchTimeES: (state) => state.searchTimeES,
  getAwinClickChecksum: (state) => state.awinClickChecksum,
  getCurrentLanguage: (state) => state.app.currentLanguage,
  getGaProperty: (state) => state.gaProperty || '',
  getTrackingWebVitals: (state) => state.tracking.performance.webVitals.done || 0,
  getCovidStateStatus: (state) => state.covidStateStatus || [],
  getCookiePermissions: (state) => state.cookieLaw,
  getUsePageCache: (state) => state.usePageCache || false,
  getSearchTrackVars: (state) => state.searchTrackVars || {},
  getPartnerConfigValue: (state) => (name) => ((
    state.partnerConfig
    && state.partnerConfig[name]
  )
    ? state.partnerConfig[name] : ''
  ),
  getRetailerResponse: (state) => (ratingNumber) => state.retailerResponse[ratingNumber] || {},
  getSearchObjectsTeaser: (state) => (id) => state.searchObjectsTeaser[id] || false,
  getSearchObjectLastSeen: (state) => (objectNumber) => state.searchObjectsLastSeen[objectNumber] || false,
  getCurrentRoutePath: (state) => state.route.path,
  getAffiliateRedirectLink: (state) => state.affiliateRedirectLink || '',
  getIsOfferLandingPage: (state) => state.isOfferLandingPage || false,
  getRecommendations: (state) => state.recommendations || [],
};
