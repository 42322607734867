import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
/* eslint-disable-next-line */
import { Backend, i18nOptions, pluginOptions } from 'plugins/i18next.TARGET';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import config from 'config/appConfig';

Vue.use(VueI18Next);

export default (store) => {
  intervalPlural.setOptions({
    intervalRegex: /^\((\S*)\){#(.*)#}$/,
  });

  const i18nextInstance = i18next.createInstance();

  i18nextInstance.use(intervalPlural);
  i18nextInstance.use(Backend).init({
    debug: false,
    lng: store.state.app.currentLanguage,
    fallbackLng: config.locales.fallbackLanguage,
    defaultNS: 'common',
    ns: 'common',
    ...i18nOptions,
  });

  return new VueI18Next(i18nextInstance, pluginOptions);
};
