export default {
  agencies: [
    9118, // Belvilla
    8610, // Dancenter
    36691, // Dertour
    85828, // i.d. Riva
    93168, // interchalet
    8695, // interhome
    8757, // novasol
    433050, // wolters
  ],
};
