import redirectConfig from 'config/redirects';

export default ({
  to, next, store,
}) => {
  const match = redirectConfig.filter((redirect) => to.path.match(redirect.from));
  if (match.length > 0) {
    const r = match[0];
    const newQuery = {};
    if (r.keepQuery !== false && Array.isArray(r.keepQuery)) {
      r.keepQuery.forEach((key) => {
        if (Object.keys(to.query).includes(key)) newQuery[key] = to.query[key];
      });
    }
    const originalPath = r.attachPath ? to.path : '';
    const queryString = Object.keys(newQuery).length > 0 ? `?${Object.keys(newQuery).map((key) => `${key}=${newQuery[key]}`).join('&')}` : '';
    store.commit('SET_APP_HTTPREWIRTEURL', r.to + originalPath + queryString);
    store.commit('SET_APP_HTTPSTATUS', 301);

    if (!store.state.app.isNode) {
      window.location.replace(r.to + originalPath + queryString);
    }

    next({ name: 'Error301' });
    return;
  }

  next();
};
