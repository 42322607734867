import changeLanguage from 'utils/locale/changeLanguage';
import config from 'config/appConfig';
import { getLocaleFromRoute, stripLocaleFromRoute } from 'utils/locale/routeHelpers';
import { redirectTo } from 'router/guards/navigationGuards';

export default ({
  to, from, next, i18n, store, router,
}) => {
  // only set language once at startup
  // this now runs once on the server and once on the client, but since we're passing the correct language from the server to the client it's not much of an issue other than running the logic one more time than necessary
  if (from.name !== null || from.matched.length > 0) {
    next();
    return;
  }

  const locale = getLocaleFromRoute(to);

  // set locale
  if (i18n.i18next.language !== locale) {
    changeLanguage({
      lng: locale, reRoute: false, store, i18n, router,
    });
  }

  if (config.locales.standardLanguage === store.state.app.currentLanguage && to.path !== stripLocaleFromRoute(to).path) {
    // switch from home-locale to home
    next(redirectTo(stripLocaleFromRoute(to)));
    return;
  }

  next();
};
