/**
 * with this helper you can set, read, edit or delete cookies
 * vue-cookies plugin doesn't match our requirements anymore (e.g. no samesite)
 */

import Sentry from 'plugins/sentry.TARGET';

/**
  * set a cookie with different options
  * the options are
  *   name: String required
  *   value: String required
  *   path: String default '/'
  *   expires: String required (y,m,d,h,i,s e.g.: '30d' or '10m' or '1y' etc...)
  *   secure: String default 'secure'
  *   sameSite: String default 'lax'
  *
  * @param {} cookieOptions
  */
const setCookie = (cookieOptionsParam) => {
  // check required params
  ['name', 'value', 'expires'].forEach((val) => {
    if (typeof cookieOptionsParam[val] === 'undefined') {
      // eslint-disable-next-line no-console
      console.error(`Error in CookieApi.setCookie(): required param '${val}' missing`);
    }
  });

  // set expires datetime str
  const cookieExpiresOption = /([0-9]+)(\w)/.exec(cookieOptionsParam.expires);
  if (cookieExpiresOption) {
    const cookieExpires = new Date();
    switch (cookieExpiresOption[2]) {
      case 'y':
        cookieExpires.setFullYear(cookieExpires.getFullYear() + parseInt(cookieExpiresOption[1], 10));
        break;
      case 'm':
        cookieExpires.setMonth(cookieExpires.getMonth() + parseInt(cookieExpiresOption[1], 10));
        break;
      case 'd':
        cookieExpires.setDate(cookieExpires.getDate() + parseInt(cookieExpiresOption[1], 10));
        break;
      case 'h':
        cookieExpires.setHours(cookieExpires.getHours() + parseInt(cookieExpiresOption[1], 10));
        break;
      case 'i':
        cookieExpires.setMinutes(cookieExpires.getMinutes() + parseInt(cookieExpiresOption[1], 10));
        break;
      case 's':
        cookieExpires.setSeconds(cookieExpires.getSeconds() + parseInt(cookieExpiresOption[1], 10));
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Error setCookie(): required param expires wrong');
        break;
    }
    cookieOptionsParam.expires = cookieExpires.toUTCString();
  } else {
    // set expire to session as default
    cookieOptionsParam.expires = 0;
  }

  // merge given options with defaults
  const defaultOptions = {
    path: '/',
    secure: 'secure',
    sameSite: 'Lax',
  };
  const cookieOptions = Object.assign(defaultOptions, cookieOptionsParam);

  // prepare cookie data as string
  let cookieString = `${encodeURIComponent(cookieOptions.name)}=${encodeURIComponent(cookieOptions.value)};`;
  cookieString += `path=${cookieOptions.path};`;
  cookieString += `expires=${cookieOptions.expires};`;
  cookieString += `SameSite=${cookieOptions.sameSite};`;
  if (typeof document !== 'undefined' && typeof document.location !== 'undefined' && document.location.protocol.match(/^https/) !== null) {
    cookieString += `${cookieOptions.secure}`;
  }

  if (typeof document !== 'undefined') {
    document.cookie = cookieString;
  }
};

/**
 * get the value of a cookie
 *
 * @param String cookieName
 * @param Boolean parseJson
 *  optional: if cookie value is json string, return parsed json
 */
const getCookieVal = (cookieName, parseJson = false) => {
  let returnValue = '';

  if (typeof document !== 'undefined') {
    const allCookies = document.cookie.split(';');
    allCookies.forEach((val) => {
      const valTrim = val.trim();
      if (valTrim.indexOf(`${cookieName}`) === 0) {
        returnValue = decodeURIComponent(valTrim.substring(`${cookieName}=`.length));
      }
    });
  }

  // parse cookie val if it is valid json
  if (parseJson) {
    if (!returnValue) {
      return returnValue;
    }
    let cookieValJson = '';
    try {
      cookieValJson = JSON.parse(returnValue);
    } catch (error) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(error);
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    returnValue = cookieValJson;
  }

  return returnValue;
};

/**
 * remove a cookie from client
 *
 * @param String cookieName
 */
const removeCookie = (cookieName) => {
  if (typeof document !== 'undefined') {
    const allCookies = document.cookie.split(';');
    allCookies.forEach((val) => {
      const valTrim = val.trim();
      if (valTrim.indexOf(`${cookieName}`) >= 0) {
        // remove via expires date
        document.cookie = `${encodeURIComponent(cookieName)}=; path=/; expires=0;`;
      }
    });
  }
};

export {
  setCookie,
  getCookieVal,
  removeCookie,
};
