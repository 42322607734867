import axios from 'apiclient';

const tiptapContentSet = ({
  uid, jwt, contentID, language, page, containerKey, contentStatus, contentAction, containerContent,
}) => axios.post('/api/cms/content/set', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  contentID,
  language,
  page,
  containerKey,
  contentStatus,
  contentAction,
  containerContent,
}).then((res) => res.data);

export default tiptapContentSet;
