<template>
  <div>
    <component
      :is="template"
      :contact-header="contactHeader"
      :contact-card="contactCard"
      :object-number="objectNumber"
      :start-date="startDate"
      :end-date="endDate"
      :mail-to="showEmail"
      :headline="customHeadline"
    />
  </div>
</template>

<script>
import { pidList } from 'config/trackingParams';
import config from 'config/appConfig';
import defaultsDeep from 'lodash.defaultsdeep';
import OfficeContactHeader from 'components/OfficeContactHeader.vue';
import OfficeContactCard from 'components/OfficeContactCard.vue';
import { format, differenceInCalendarDays } from 'date-fns';
import { fromAPIDateTimeString } from 'utils/DateHelper';
import AnchorRouterPush from 'mixins/AnchorRouterPush';
import UserApi from 'mixins/UserApi';

export default {
  name: 'OfficeContact',
  components: {
    OfficeContactHeader,
    OfficeContactCard,
  },
  mixins: [
    AnchorRouterPush,
    UserApi,
  ],
  i18nOptions: { namespaces: ['office'] },
  props: {
    template: {
      type: String,
      required: true,
    },
    objectNumber: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    startDate: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    endDate: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    multiCards: {
      type: Boolean,
      required: false,
      default: false,
    },
    showEmail: {
      type: Boolean,
      default: false
    },
    customHeadline: {
      type: String,
      default: 'office:card.commercial.headline'
    }
  },
  computed: {
    templateLoading() {
      return `${this.template}Loading`;
    },
    // current route decides, which config has to be used
    configRouteKey() {
      let routeKey = 'global'; // global is default
      switch (true) {
        case this.$route.meta.name.match(/ObjectPage/i) !== null:
        case this.$route.meta.name.indexOf('whoami_Object') !== -1:
          routeKey = 'objectPage';
          break;
        case this.$route.meta.name === 'Retailer':
          routeKey = 'retailer';
          break;
        case this.$route.meta.name === 'Agency':
          routeKey = 'agency';
          break;
        case this.$route.meta.name === 'TravelAgency':
          routeKey = 'travelAgency';
          break;
        case this.$route.meta.name === 'Distribution':
          routeKey = 'partner';
          break;
        case this.$route.meta.name === 'Affiliates':
          routeKey = 'affiliates';
          break;
        case this.$route.meta.name === 'Destination':
          routeKey = 'destination';
          break;
        default:
          routeKey = 'global';
          break;
      }

      return routeKey;
    },
    // merge default config with overwrites, depending on route
    officeConfig() {
      const mainConfig = config.office;

      let routeConfig = config.office[this.configRouteKey] || false;
      if (this.multiCards) {
        routeConfig = this.officeSecondCard[this.configRouteKey];
      }
      return defaultsDeep(routeConfig, mainConfig.default);
    },
    officeSecondCard() {
      return config.secondCard ?? false;
    },
    officeContact() {
      // main data from api
      return this.$store.getters.getOfficeContact;
    },
    employeeData() {
      // random office employee from API response
      const randomEmployee = this.officeContact.officeRandom;
      return {
        name: this.officeContact?.employeeData?.staff[randomEmployee]?.staffNameShorted || 'office:fallbacks.contactName',
        images: this.officeContact?.employeeData?.staff[randomEmployee]?.images || {
          '45x45': 'office:fallbacks.images.45x45',
          '120x120': 'office:fallbacks.images.120x120',
          '45x45_webp': 'office:fallbacks.images.45x45_webp',
          '120x120_webp': 'office:fallbacks.images.120x120_webp',
        },
      };
    },
    // depends on officeConfig, what should be displayed
    // set config options if src is "officeStream", "partnerConfig" for wlv, route params etc to
    // the values that the template OfficeContactHeader.vue needs
    contactHeader() {
      // prevent var references
      const officeConfigHeader = JSON.parse(JSON.stringify(this.officeConfig.header));

      // dynamic data from scheduler
      if (officeConfigHeader.contactImage.source === 'officeStream') {
        officeConfigHeader.contactImage.images = this.employeeData.images;
      }
      if (officeConfigHeader.top.display.source === 'officeStream') {
        officeConfigHeader.top.display.string = this.employeeData.name;
      }
      if (officeConfigHeader.bottom.display.source === 'officeStream') {
        officeConfigHeader.bottom.display.string = this.officeTimesDynamic.name;
        officeConfigHeader.bottom.display.stringData = this.officeTimesDynamic.data;
      }

      // data from (wlv-) partner configs
      // if wlv service user is logged in, display e-dom contact data
      if (officeConfigHeader.top.display.source === 'partnerConfig') {
        officeConfigHeader.top.display.string = this.partnerLoggedIn ?
          'office:header.customerService' :
          this.partnerName;
      }
      if (officeConfigHeader.middle.display.source === 'partnerConfig') {
        officeConfigHeader.middle.display.string = this.partnerLoggedIn ?
          'office:openingsStatic.global.contactPhoneDisplay' :
          this.partnerPhone;
      }
      if (officeConfigHeader.middle.interact.source === 'partnerConfig') {
        officeConfigHeader.middle.interact.string = this.partnerLoggedIn ?
          'office:openingsStatic.global.contactPhoneInteract' :
          'office:header.telLink';
        officeConfigHeader.middle.interact.stringData = this.partnerLoggedIn ?
          false :
          { phone: this.partnerPhone };
      }
      if (officeConfigHeader.bottom.display.source === 'partnerConfig') {
        officeConfigHeader.bottom.display.string = this.partnerLoggedIn ?
          this.officeTimesDynamic.name :
          this.partnerEmail;
        officeConfigHeader.bottom.display.stringData = this.partnerLoggedIn ?
          this.officeTimesDynamic.data :
          false;
      }
      if (officeConfigHeader.bottom.interact.source === 'partnerConfig') {
        officeConfigHeader.bottom.interact.string = this.partnerLoggedIn ?
          false :
          'office:header.mailtoLink';
        officeConfigHeader.bottom.interact.stringData = this.partnerLoggedIn ?
          false :
          { email: this.partnerEmail };
        if (this.partnerLoggedIn) {
          officeConfigHeader.bottom.interact.prefix = false;
          officeConfigHeader.bottom.interact = false;
        }
      }

      // check if pid phone-number has to be shown
      // pid phone-number overwrites "global" and "objectPage" phone number
      if (['global', 'objectPage'].includes(this.configRouteKey) && this.pidLatest && pidList[this.pidLatest]?.phoneNumber) {
        // replace office header number
        officeConfigHeader.middle.display.string = pidList[this.pidLatest].phoneNumber.display;
        officeConfigHeader.middle.interact.string = 'office:header.telLink';
        officeConfigHeader.middle.interact.stringData = { phone: pidList[this.pidLatest].phoneNumber.interact };
      }

      return officeConfigHeader;
    },
    // depends on officeConfig, what should be displayed
    // overwrite the config options ("officeStream", route params etc) with
    // the values that the template OfficeContactCard.vue needs
    contactCard() {
      // prevent var references
      const officeConfigCard = JSON.parse(JSON.stringify(this.officeConfig.card));
      // dynamic data from scheduler
      if (officeConfigCard.contactImage.source === 'officeStream') {
        officeConfigCard.contactImage.images = this.employeeData.images;
      }
      if (officeConfigCard.overview.top.display.source === 'officeStream') {
        officeConfigCard.overview.top.display.string = this.employeeData.name;
      }
      if (officeConfigCard.overview.middle.display.source === 'officeStream') {
        officeConfigCard.overview.middle.display.string = this.officeTimesDynamic.name;
        officeConfigCard.overview.middle.display.stringData = this.officeTimesDynamic.data;
      }

      // check if pid phone-number has to be shown
      // pid phone-number overwrites "global" and "objectPage" phone number
      if (['global', 'objectPage'].includes(this.configRouteKey) && this.pidLatest && pidList[this.pidLatest]?.phoneNumber) {
        // replace office card number
        officeConfigCard.interact.bottom.display.string = pidList[this.pidLatest].phoneNumber.display;
        officeConfigCard.interact.bottom.interact.string = 'office:header.telLink';
        officeConfigCard.interact.bottom.interact.stringData = { phone: pidList[this.pidLatest].phoneNumber.interact };
      }

      return officeConfigCard;
    },
    officeOverwriteString() {
      let translationKey = '';
      if (this.officeContact?.employeeData?.overwriteTranslation) {
        Object.values(this.officeContact.employeeData.overwriteTranslation).forEach((element) => {
          if (element.pagetype === this.officeContactRoute) {
            translationKey = element.translation;
          }
        });
      }
      return translationKey;
    },
    // (wlv-) partner is logged in
    partnerLoggedIn() {
      return this.UserApi_loggedIn || false;
    },
    partnerName() {
      return this.$store.getters.getPartnerConfigValue('partnerName') || '';
    },
    partnerPhone() {
      return this.$store.getters.getPartnerConfigValue('partnerPhone') || '';
    },
    partnerEmail() {
      return this.$store.getters.getPartnerConfigValue('partnerEmail') || '';
    },
    officeTimesType() {
      // officeOverwriteString is always static
      if (this.officeOverwriteString) {
        return 'static';
      }
      // office times string can be static or dynamic (from scheduler)
      if (['global', 'agency'].includes(this.officeContactRoute)) {
        return 'dynamic';
      }
      return 'static';
    },
    officeContactEmployee() {
      // random office employee
      const randomEmployee = this.officeContact.officeRandom;
      const returnContact = {
        name: this.officeContact?.employeeData?.staff[randomEmployee]?.staffNameShorted || 'office:fallbacks.contactName',
        images: this.officeContact?.employeeData?.staff[randomEmployee]?.images || {
          '45x45': 'office:fallbacks.images.45x45',
          '120x120': 'office:fallbacks.images.120x120',
          '45x45_webp': 'office:fallbacks.images.45x45_webp',
          '120x120_webp': 'office:fallbacks.images.120x120_webp',
        },
      };

      // some pages display only static data
      if (this.officeTimesType === 'static') {
        returnContact.name = `office:openingsStatic.${this.officeContactRoute}.contactName`;
        returnContact.images = false;
      }

      return returnContact;
    },
    officeTimesDynamic() {
      const returnObj = {
        name: 'office:fallbacks.contactTimes',
      };
      if (this.officeContact?.employeeData?.staffTimes?.from) {
        const dateObj = fromAPIDateTimeString(this.officeContact.employeeData.staffTimes.from);
        const fromObj = fromAPIDateTimeString(this.officeContact.employeeData.staffTimes.from);
        const toObj = fromAPIDateTimeString(this.officeContact.employeeData.staffTimes.to);
        returnObj.name = `office:openingsDynamic.${this.officeTimesDynamicType}`;
        returnObj.data = {
          date: format(dateObj, 'dd.MM.yyyy'),
          from: format(fromObj, 'HH:mm'),
          to: format(toObj, 'HH:mm'),
        };
      }
      return returnObj;
    },
    officeTimesDynamicType() {
      let returnStr = 'today';
      if (this.officeTimesType === 'dynamic' && this.officeContact?.employeeData?.staffTimes?.from) {
        const now = new Date();
        const fromObj = fromAPIDateTimeString(this.officeContact.employeeData.staffTimes.from);
        const dayCountTillOpen = differenceInCalendarDays(fromObj, now);
        if (dayCountTillOpen >= 2) {
          returnStr = 'afterTomorrow';
        } else if (dayCountTillOpen === 1) {
          returnStr = 'tomorrow';
        } else {
          returnStr = 'today';
        }
      }
      return returnStr;
    },
    officeContactRoute() {
      // office stream returns static data for different routes. get the matching office stream key, depending on route
      let staticKey = 'global'; // global is default
      switch (true) {
        case this.$route.meta.name === 'Retailer':
          staticKey = 'retailer';
          break;
        case this.$route.meta.name === 'Agency':
          staticKey = 'agency';
          break;
        case this.$route.meta.name === 'TravelAgency':
          staticKey = 'travelAgency';
          break;
        case this.$route.meta.name === 'Distribution':
          staticKey = 'partner';
          break;
        case this.$route.meta.name === 'Affiliates':
          staticKey = 'affiliates';
          break;
        case this.$route.meta.name === 'Destination':
          staticKey = 'destination';
          break;
        default:
          staticKey = 'global';
          break;
      }

      return staticKey;
    },
    pidLatest() {
      return this.$store.getters.getPidLatest || false;
    },
  },
  mounted() {
    this.getOfficeContactData();
  },
  methods: {
    getOfficeContactData() {
      if (![200, 'pending'].includes(this.$store.getters.getApiHttpStatus('officeContact'))) {
        this.$store.commit('SET_APP_APIHTTPSTATUS', { type: 'officeContact', status: 'pending' });
        return this.$store.dispatch('GET_OFFICE_CONTACT')
          .then(() => this.$store.commit('SET_APP_APIHTTPSTATUS', { type: 'officeContact', status: 200 }))
          .catch((err) => {
            this.$store.commit('SET_APP_APIHTTPSTATUS', { type: 'officeContact', status: err.status });
          });
      }
      return Promise.resolve();
    },
  },
};
</script>
