import axios from 'apiclient';

const bookingDetailsGet = (uid, jwt, bookingId) => axios.post('/api/user/getBookings', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  type: 'booking',
  bookingid: bookingId
}).then((result) => Promise.resolve(result.data));

export default bookingDetailsGet;
