import axios from 'apiclient';

const loadResponse = (ratingNumber) => axios.post('/api/landlord/rating/response/get', {
  mandant: process.env.CLIENTNAME,
  ratingNumber,
}).then((res) => res);

const setResponse = (ratingNumber, ratingAnswer) => axios.post('/api/landlord/rating/response/set', {
  mandant: process.env.CLIENTNAME,
  ratingNumber,
  ratingAnswer,
}).then((res) => res);

export {
  loadResponse,
  setResponse,
};
