export default {
  newsletter: {
    // wlv doesn't have a newsletter
    newsletterAvailableCheckFnc: () => false,
    showTextAboveSubmitBtn: false,
    classes: {
      bullets: 'col-12',
      stoerer: 'd-none',
    },
  }
};
