const searchBoolFilterNames = [
  'swimmingpool', 'internet', 'terrace', 'parking', 'tv', 'dishwasher', 'washingmachine',
  'nonsmoking', 'sauna', 'grill', 'airconditioning', 'whirlpool', 'fireplace', 'fishing',
  'boatrentalservice', 'seaview',
];
const searchOtherFilterNames = [
  'startDate', 'endDate', 'page', 'persons',
  'personsMax', 'rating', 'objectType', 'sleepingRooms',
  'bathRooms', 'priceMax', 'priceMin', 'pets',
  'distSea', 'distLake', 'distSkilift', 'distSkiloipe',
  'sort', 'squaremeterLiving', 'objectsPerPage', 'offer', 'fulltext', 'onr', 'agency', 'objects', 'q', 'lastminuteonly', 'precision',
  'providerType', 'tolerance', 'ignoreFilter', 'freeCancel', 'insObject', 'partnerTags', 'duration', 'group', 't',
];
const searchFilterNames = searchBoolFilterNames.concat(searchOtherFilterNames);
const searchLocationFilterNames = ['latitude', 'longitude', 'latitude', 'distance', 'BBoxString', 'country', 'region', 'place', 'q', 'fulltext', 'offerFilter', 'precision'];
const searchAllFilterNames = searchFilterNames.concat(searchLocationFilterNames);

export {
  searchBoolFilterNames, searchAllFilterNames, searchFilterNames, searchLocationFilterNames,
};
