import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=56f5c76f&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Header.vue?vue&type=style&index=0&id=56f5c76f&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "56f5c76f",
  "24c67293"
  
)

export default component.exports