import { fromAPIDateTimeString, toAPIDateString } from 'utils/DateHelper';

const caseInsensitiveQuerySearch = (pattern, query) => {
  const r = new RegExp(pattern, 'i');
  const matches = Object.keys(query).filter((el) => r.exec(el) !== null);
  if (matches.length) return matches[0];
  return false;
};

const rewriteLegacyQueryParams = (query) => {
  const removedFilter = ['event', 'fuseaction', 'searchtyp'];
  const rewrites = {
    partner: { match: /[0-9]+/, filter: 'PID', valueCallback: (v) => v },
    ort: { match: /[0-9]+/, filter: 'place', valueCallback: (v) => v },
    land: { match: /[A-Za-z]{2}/, filter: 'country', valueCallback: (v) => v.toLowerCase() },
    preisvon: { match: /[0-9]{3,}/, filter: 'priceMin', valueCallback: (v) => v },
    preisbis: { match: /[0-9]{3,}/, filter: 'priceMax', valueCallback: (v) => v },
    personen: { match: /[0-9]+/, filter: 'persons', valueCallback: (v) => v },
    schlafzimmer: { match: /[0-9]+/, filter: 'sleepingRooms', valueCallback: (v) => v },
    haustyp: { match: /[12]/, filter: 'objectType', valueCallback: (v) => ((v === '1') ? 'house' : 'apartment') },
    IDObjektliste: { match: /[0-9,]+/, filter: 'objects', valueCallback: (v) => v },
    meer: {
      match: /[123]/,
      filter: 'distSea',
      valueCallback: (v) => {
        if (v === '1') return 1;
        if (v === '2') return 5;
        if (v === '3') return 10;
        return 1;
      },
    },
    see: {
      match: /[123]/,
      filter: 'distLake',
      valueCallback: (v) => {
        if (v === '1') return 1;
        if (v === '2') return 5;
        if (v === '3') return 10;
        return 1;
      },
    },
    skilift: {
      match: /[123]/,
      filter: 'distSkilift',
      valueCallback: (v) => {
        if (v === '1') return 1;
        if (v === '2') return 5;
        if (v === '3') return 10;
        return 1;
      },
    },
    loipe: {
      match: /[123]/,
      filter: 'distSkiloipe',
      valueCallback: (v) => {
        if (v === '1') return 1;
        if (v === '2') return 5;
        if (v === '3') return 10;
        return 1;
      },
    },
    bootsverleih: { match: /1/, filter: 'boatrentalservice', valueCallback: () => 'Y' },
    angeln: { match: /1/, filter: 'fishing', valueCallback: () => 'Y' },
    grill: { match: /1/, filter: 'grill', valueCallback: () => 'Y' },
    internet: { match: /1/, filter: 'internet', valueCallback: () => 'Y' },
    sauna: { match: /1/, filter: 'sauna', valueCallback: () => 'Y' },
    tv: { match: /1/, filter: 'tv', valueCallback: () => 'Y' },
    geschirr: { match: /1/, filter: 'dishwasher', valueCallback: () => 'Y' },
    kamin: { match: /1/, filter: 'fireplace', valueCallback: () => 'Y' },
    klima: { match: /1/, filter: 'airconditioning', valueCallback: () => 'Y' },
    nichtraucherhaus: { match: /1/, filter: 'nonsmoking', valueCallback: () => 'Y' },
    parkplatz: { match: /1/, filter: 'parking', valueCallback: () => 'Y' },
    terrasse: { match: /1/, filter: 'terrace', valueCallback: () => 'Y' },
    tiere: { match: /1/, filter: 'pets', valueCallback: () => 'one' },
    pool: { match: /1/, filter: 'swimmingpool', valueCallback: () => 'Y' },
    waschmasch: { match: /1/, filter: 'washingmachine', valueCallback: () => 'Y' },
    datvon: { match: /./, filter: 'startDate', valueCallback: (v) => toAPIDateString(fromAPIDateTimeString(v, 'dd.MM.yyyy')) },
    datbis: { match: /./, filter: 'endDate', valueCallback: (v) => toAPIDateString(fromAPIDateTimeString(v, 'dd.MM.yyyy')) },
    reisedauer: {
      match: /./,
      filter: 'endDate',
      valueCallback: (v) => {
        const datvonMatches = caseInsensitiveQuerySearch('datvon', query);
        if (!datvonMatches) return '';
        const tempDate = fromAPIDateTimeString(query[datvonMatches], 'dd.MM.yyyy');
        if (tempDate === false) return '';
        removedFilter.push(caseInsensitiveQuerySearch('datbis', query));
        return toAPIDateString(new Date(tempDate.setDate(tempDate.getDate() + parseInt(v, 10))));
      },
    },
    searchtype: {
      match: /^volltextsuche$/,
      filter: 'fulltext',
      valueCallback: () => {
        const patternMatches = caseInsensitiveQuerySearch('pattern', query);
        if (!patternMatches) return '';
        removedFilter.push(patternMatches);
        return query[patternMatches];
      },
    },
    col: {
      match: /objoai|objrating|preis/,
      filter: 'sort',
      valueCallback: (v) => {
        const { sort } = query;
        if (v === 'objoai') return 'recommendation';
        if (v === 'objrating') return 'rating';
        if (v === 'preis' && sort === 'asc') return 'priceAsc';
        if (v === 'preis' && sort === 'desc') return 'priceDesc';
        return '';
      },
    },
    anbieter: { match: /[0-9,]+/, filter: 'agency', valueCallback: (v) => v },
    idangebot: { match: /[0-9,]+/, filter: 'offer', valueCallback: (v) => v },
  };

  // params are case-insensitive so we have to search for all variations
  Object.keys(query).forEach((paramName) => {
    const paramValue = query[paramName];
    Object.keys(rewrites).forEach((rewriteName) => {
      const pattern = new RegExp(`^${rewriteName}$`, 'i');
      const rewriteObj = rewrites[rewriteName];
      if (pattern.exec(paramName) !== null) {
        // make sure old paramName doesn't match new one
        // otherwise the new filter gets removed
        if (paramName !== rewriteObj.filter) removedFilter.push(paramName);
        if (Array.isArray(paramValue)) {
          query[rewriteObj.filter] = [];
          paramValue.forEach((value) => {
            if (value.match(rewriteObj.match)) query[rewriteObj.filter].push(rewriteObj.valueCallback(value));
          });
        } else if (paramValue.toString().match(rewriteObj.match)) query[rewriteObj.filter] = rewriteObj.valueCallback(paramValue);
      }
    });
  });

  // clean old params
  const newQuery = Object.keys(query).filter((name) => removedFilter.indexOf(name) === -1).reduce((aggr, current) => {
    aggr[current] = query[current];
    return aggr;
  }, {});
  return newQuery;
};
export { rewriteLegacyQueryParams };

const rewriteHomeUrls = ({ to, next }) => {
  const eventQueryParams = ['event', 'fuseaction'];
  let eventQueryContent = '';
  try {
    eventQueryContent = Object.keys(to.query).filter((el) => eventQueryParams.includes(el)).map((el) => to.query[el]).values()
      .next().value;
  } catch {
    eventQueryContent = '';
  }
  if (eventQueryContent && eventQueryContent.match(/search\./)) {
    const newQuery = rewriteLegacyQueryParams(to.query);
    next({ name: 'Search', query: newQuery }); // needs to be 'search'
  } else if (eventQueryContent && (eventQueryContent.match(/^buchung\.inseratanfrage_step1$/) || eventQueryContent.match(/^buchung\.step1$/))) {
    const newQuery = rewriteLegacyQueryParams(to.query);
    next({
      name: 'Booking', query: newQuery, params: { objectNumber: to.query.onr },
    }); // needs to be 'request'
  } else if (eventQueryContent && eventQueryContent.match(/^newsletter.main$/)) {
    const newQuery = rewriteLegacyQueryParams(to.query);
    next({ name: 'Newsletter', query: newQuery });
  } else if (eventQueryContent && eventQueryContent.match(/objekt\.showObject/) && to.query.onr && to.query.onr.toString().match(/[0-9]+/)) {
    const newQuery = rewriteLegacyQueryParams(to.query);
    delete newQuery.onr;
    next({ name: 'ObjectPage', params: { objectNumber: to.query.onr }, query: newQuery });
  // } else if (to.path.match(/\/[1-9][0-9]+/)) { // rewrite for /{{ objectNumber }} urls i.e. /2334243
  //   const newQuery = rewriteLegacyQueryParams(to.query);
  //   next({ path: `/object${to.path}`, query: newQuery });
  } else if (eventQueryContent && eventQueryContent.match(/vermieter\.buchungsbestaetigung/)) { // rewrite for
    next({ name: 'LandlordBookingConfirmation', query: to.query });
  } else if (eventQueryContent && eventQueryContent.match(/rating.rating.ratingvmst/)) {
    next({ name: 'RetailerRatingAnswer', params: { ratingNumber: to.query.ratingnr } });
  } else {
    next();
  }
};

export default rewriteHomeUrls;
