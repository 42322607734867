import config from 'config/images';

const { CDN } = config;

export default {

  imageCloud: [
    [
      {
        link: `${CDN}/assets/portals/logo-e-domizil-60.png`,
        alt: 'e-domizil',
      },
      {
        link: `${CDN}/assets/portals/logo-e-domizil-schweiz-60.png`,
        alt: 'e-domizil Schweiz',
      },

      {
        link: `${CDN}/assets/portals/logo-bellevue-ferienhaus-60.png`,
        alt: 'Bellevue Ferienhaus',
      },

      {
        link: `${CDN}/assets/portals/logo-premium-selection-by-e-domizil-60.png`,
        alt: 'Premium Selection by e-domizil',
      },
      {
        link: `${CDN}/assets/portals/logo-tourist-online-60.png`,
        alt: 'tourist-online.de',
      },

      {
        link: `${CDN}/assets/portals/logo-atraveo-60.png`,
        alt: 'atraveo',
      },
    ],
    [
      {
        link: `${CDN}/assets/portals/logo-ab-in-den-urlaub-60.png`,
        alt: 'ab-in-den-urlaub',
      },
      {
        link: `${CDN}/assets/portals/logo-bestfewo-60.png`,
        alt: 'BestFewo.de',
      },
      {
        link: `${CDN}/assets/portals/logo-cabaneo-60.png`,
        alt: 'cabaneo',
      },
      {
        link: `${CDN}/assets/portals/logo-casamundo-60.png`,
        alt: 'casamundo',
      },
      {
        link: `${CDN}/assets/portals/logo-fewo-direkt-60.png`,
        alt: 'Fewo-direkt',
      },
      {
        link: `${CDN}/assets/portals/logo-holidaycheck-60.png`,
        alt: 'HolidayCheck',
      },
      {
        link: `${CDN}/assets/portals/logo-holidu-60.png`,
        alt: 'holidu',
      },
      {
        link: `${CDN}/assets/portals/logo-homelidays-60.png`,
        alt: 'Homelidays',
      },
      {
        link: `${CDN}/assets/portals/logo-hometogo-60.png`,
        alt: 'hometogo',
      },
      {
        link: `${CDN}/assets/portals/logo-hrs-holidays-60.png`,
        alt: 'HRS-Holidays',
      },
      {
        link: `${CDN}/assets/portals/logo-idealo-60.png`,
        alt: 'idealo',
      },
      {
        link: `${CDN}/assets/portals/logo-ostseeklar-60.png`,
        alt: 'Ostseeklar.de',
      },
      {
        link: `${CDN}/assets/portals/logo-sonnenklar-tv-60.png`,
        alt: 'sonnenklar.tv',
      },
      {
        link: `${CDN}/assets/portals/logo-tchibo-60.png`,
        alt: 'Tchibo',
      },
      {
        link: `${CDN}/assets/cms/atraveo-de/logo-tui-60.png`,
        alt: 'TUI',
      },
      {
        link: `${CDN}/assets/portals/logo-vrbo-60.png`,
        alt: 'vrbo',
      },
    ],
  ],

};
