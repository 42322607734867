// object module
import objectState from './state';
import objectActions from './actions';
import objectMutations from './mutations';
import objectGetters from './getters';

// module in extra file so we can maybe lazyload it later
// currently it's just implemented on store creation
export default {
  state: objectState,
  actions: objectActions,
  getters: objectGetters,
  mutations: objectMutations,
};
