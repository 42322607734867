import axios from 'apiclient';
import imageConfig from 'config/images';

const { CDN } = imageConfig;
export default function loadOfficeContact() {
  return axios.post('/api/office/contact', {
    client_cdn: CDN || '//cdn.e-domizil.de',
    client_mandant: process.env.CLIENTNAME,
    mandant: process.env.CLIENTNAME,
  }, {
    cache: {
      ignoreCache: false,
      maxAge: 15 * 60 * 10000,
    },
  }).then((res) => res.data)
    .catch((result) => Promise.reject(result.errors));
}
