import { render, staticRenderFns } from "./RouterLinkSimple.vue?vue&type=template&id=724a71a0"
import script from "./RouterLinkSimple.vue?vue&type=script&lang=js"
export * from "./RouterLinkSimple.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "852b7b60"
  
)

export default component.exports