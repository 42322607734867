import Vue from 'vue';
import objectDefault from 'store/defaults/object';
// import objectRatingsDefault from 'store/defaults/objectRatings';
import objectPriceDefault from 'store/defaults/objectPrice';
import defaultsDeep from 'lodash.defaultsdeep';
import { objectPriceKey } from 'store/keys/objectPrice';
import { fromAPIDateString } from 'utils/DateHelper';
import { differenceInCalendarDays } from 'date-fns';


export default {
  // mutation to store prices from a price request
  SET_PRICE: (state, {
    priceData, ...properties
  }) => {
    const key = objectPriceKey(properties);
    const priceDataAdditions = {
      priceObject: {
        ts: new Date().valueOf(),
        ...properties,
      },
    };
    Vue.set(state.pricesData, key, {});
    state.pricesData[key] = {
      ...defaultsDeep(state.pricesData[key], priceData, priceDataAdditions, objectPriceDefault),
    };
    state.pricesList.push(key);
  },
  // store any object in state
  SET_OBJECT: (state, { object, language }) => {
    // console.log('set object', object, 'language', language);
    if (object) {
      // set state activeObjectNumber
      // don't set activeObjectNumber here because this is invoked by lastseenobject as well
      // state.activeObjectNumber = object.objectNumber;

      // push object to state with property objectNumber
      if (object.objectNumber) {
        Vue.set(state.data, object.objectNumber, {});
        const objectDefaultLang = Object.assign(objectDefault, { descriptions: { natives: { [language]: { languageCode: language, text: 'na' } } } });
        state.data[object.objectNumber] = {
          ...defaultsDeep(state.data[object.objectNumber], object, objectDefaultLang),
        };
        state.list.push(parseInt(object.objectNumber, 10));
      }
    }
  },
  // set object as active
  SET_ACTIVE_OBJECT: (state, { objectNumber }) => {
    state.activeObjectNumber = parseInt(objectNumber, 10);
  },
  SET_OBJECT_DESCRIPTION_LANGUAGE: (state, lang) => {
    Vue.set(state, 'currentDescriptionLanguage', lang);
  },
  // object description
  SET_OBJECT_DESCRIPTION: (state, { objectNumber, result, lang }) => {
    Vue.set(state, 'currentDescriptionLanguage', lang);
    const { descriptions } = result;
    if (descriptions) {
      Object.keys(descriptions).forEach((sourceType) => {
        if (descriptions[sourceType]) {
          Object.keys(descriptions[sourceType]).forEach((languageCode) => {
            const storeDesc = state.data[objectNumber].descriptions;
            if (!storeDesc[sourceType]) {
              storeDesc[sourceType] = {};
            }
            // console.log('set sourceType', sourceType, ' languageCode ', languageCode);
            storeDesc[sourceType][languageCode] = descriptions[sourceType][languageCode] || 'na';
            // console.log('text = ', storeDesc[sourceType][languageCode]);
          });
        }
      });
      // console.log('state', rootState);
    }
    const { objectFeatureDescription } = result;
    if (objectFeatureDescription) {
      Vue.set(state.data[objectNumber].objectFeatureDescription, lang, objectFeatureDescription[lang]);
    }
  },
  // ratings
  SET_OBJECT_RATINGS: (state, objectRatings) => {
    const or = objectRatings;
    Object.keys(or).forEach((objectNumber) => {
      if (objectNumber.length) {
        const hasData = (state.ratingsList.indexOf(parseInt(objectNumber, 10)) > -1);
        const { pages } = or[objectNumber].ratings;
        /*
          ratings:
            pages:
              nr: "1"
              ratings: {1: {…}, 2: {…}, 3: {…}
      */
        const { pageNr } = pages;
        const { ratings } = pages;

        if (!state.ratingsData[objectNumber]) {
          Vue.set(state.ratingsData, objectNumber, {});
          Vue.set(state.ratingsData[objectNumber], 'ratings', {});
        }
        Vue.set(state.ratingsData[objectNumber].ratings, pageNr, ratings);
        Vue.set(state.ratingsData[objectNumber], 'resultData', or[objectNumber].resultData);
        // Vue.set(state.ratingsData, objectNumber, Object.assign(
        //   {}, defaultsDeep(
        //     existingRatings,
        //     or[objectNumber].ratings,
        //   ),
        // ));
        if (!hasData) { state.ratingsList.push(parseInt(objectNumber, 10)); }
      }
    });
  },
  SET_OBJECT_RATINGS_TRANSLATION: (state, { ratingId, objectRatings }) => {
    // get ratingObject from State
    function getRatingsFromState(stateRatings) {
      const translatedRatingId = ratingId;
      // found matching rating to ratingid
      let targetRating;
      Object.keys(stateRatings).forEach((page) => {
        const ratingsOnPage = stateRatings[page];
        if (!targetRating) { targetRating = Object.values(ratingsOnPage).find((item) => item.ratingid === translatedRatingId); }
      });


      return targetRating;
    }
    // get translation from Result Object
    function getTranslationFromResult(resultRating, translatedRatingId) {
      // found matching rating to ratingid
      let translations;
      const { pages } = resultRating;
      const { ratings } = pages;
      Object.values(ratings).forEach((rating) => {
        if (rating.ratingid === translatedRatingId) {
          translations = rating.translations;
        }
      });
      return translations;
    }

    Object.keys(objectRatings).forEach((objectNumber) => {
      const stateObject = state.ratingsData[objectNumber];
      let targetRating;
      if (stateObject && stateObject.ratings) {
        if (Object.values(stateObject.ratings).length) {
          targetRating = getRatingsFromState(stateObject.ratings); // get ratingObject from store
          const newTranslation = getTranslationFromResult(objectRatings[objectNumber].ratings, ratingId);// get ratingObject from request;
          // console.log('newTranslation', newTranslation);
          const newMergedTranslations = { ...targetRating.translations, ...newTranslation }; // merge new and existing translations

          if (state.ratingsData[objectNumber] && !state.ratingsData[objectNumber].translations) { // create translation object, if not exists
            Vue.set(state.ratingsData[objectNumber], 'translations', {});
          }
          // console.log('state object ratings', state.ratingsData[objectNumber].translations, 'ratingId', ratingId, 'newMergedTranslations', newMergedTranslations);
          Vue.set(state.ratingsData[objectNumber].translations, ratingId, newMergedTranslations); // save translation to this object with key ratingid (no page no)
        }
      }
    });
  },
  SET_OBJECT_PRICE_REQUEST_DATA: (state, {
    startDate = null, endDate = null, persons = 2, language = 'de',
  }) => {
    state.priceRequestData.startDate = startDate;
    state.priceRequestData.endDate = endDate;
    state.priceRequestData.persons = parseInt(persons, 10);
    state.priceRequestData.language = language;
  },
  SET_OBJECT_PRICE_IDENTIFIER_KEY: (state, { partner = '' }) => {
    // duration
    const duration = differenceInCalendarDays(fromAPIDateString(state.priceRequestData.endDate), fromAPIDateString(state.priceRequestData.startDate));

    const { activeObjectNumber: objectNumber } = state;
    state.priceRequestData.identifierKey = objectPriceKey({
      ...state.priceRequestData,
      duration,
      objectNumber,
      partner,
    });
  },
  SET_OBJECT_PICKER_VIEWED_DATE: (state, date) => {
    if (date instanceof Date) {
      state.priceRequestData.viewedDate = date;
    } else {
      state.priceRequestData.viewedDate = new Date();
    }
  },

  // marker to check if pricerequest is running
  SET_IS_FETCHING_PRICE: (state, isFetchingPrice) => {
    Vue.set(state, 'isFetchingPrice', isFetchingPrice);
  },

  // marker to check if pricerequest failed
  SET_FETCHING_PRICE_ERROR: (state, { fetchingPriceError }) => {
    Vue.set(state, 'fetchingPriceError', fetchingPriceError);
  },

  // reset all priceRequests
  RESET_PRICECACHE: (state) => {
    Vue.set(state, 'pricesData', {});
    Vue.set(state, 'pricesList', []);
  },
  SET_CANCELABEL: (state, { objectNumber, cancelable }) => {
    // console.log('mutation');
    const cancelAbleObjects = state.cancelableObjects;
    if (cancelable && cancelAbleObjects.indexOf(objectNumber) === -1) { // add to cancelable objects
      cancelAbleObjects.push(objectNumber);
    }
    if (!cancelable) { // remove objectnumber from array
      state.cancelableObjects = state.cancelableObjects.filter((onr) => onr !== objectNumber);
      // console.log('state.cancelableObjects', state.cancelableObjects);
    }
  },
  SET_RKIDATA: (state, { landkreisID, data }) => {
    Vue.set(state.rkiData, landkreisID, data);
  },
  SET_PRINT_COLLAPSIBLES: (state, { action, value }) => {
    if (action === 'add') {
      state.printData.openCollapsibles.push(value);
    }
    if (action === 'rm') {
      state.printData.openCollapsibles.splice(value, 1);
    }
    if (action === 'addToClose') {
      state.printData.closeCollapsibles.push(value);
    }
  },
  SET_PRINT_STATUS_AND_ERROR: (state, { action, bool }) => {
    if (action === 'printing') {
      state.printData.printing = bool;
    }
    if (action === 'error') {
      state.printData.printingError = bool;
    }
  },

};
