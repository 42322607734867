<template>
  <div class="object-contact">
    <h2 class="h3">
      {{ $t(headline) }}
    </h2>
    <div class="row">
      <div class="col-sm-7">
        <div class="row row-tight">
          <div
            v-if="mountedReady && contactCard.contactImage && contactCard.contactImage.images"
            class="col-3"
          >
            <div class="img img-profile">
              <picture>
                <source
                  :srcset="$t(contactCard.contactImage.images['120x120_webp'])"
                  type="image/webp"
                >
                <source
                  :srcset="$t(contactCard.contactImage.images['120x120'])"
                  type="image/jpeg"
                >
                <img
                  :src="$t(contactCard.contactImage.images['120x120'])"
                  :alt="$t('office:openingsStatic.global.contactName')"
                >
              </picture>
            </div>
          </div>
          <div class="col-9">
            <div class="name">
              {{ $t(contactCard.overview.top.display.string, contactCard.overview.top.display.stringData) }}
            </div>
            <small>
              {{ $t(contactCard.overview.middle.display.string, contactCard.overview.middle.display.stringData) }}
            </small>
            <div
              v-if="contactCard.overview.bottom && contactCard.overview.bottom.display.string"
              class="object-id"
            >
              {{ $t(contactCard.overview.bottom.display.string, contactCard.overview.bottom.display.stringData) }}
              <template v-if="objectNumber">
                {{ objectNumber }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-5"
      >
        <div class="options">
          <div v-if="!mailTo">
            <router-link-simple
              v-if="contactCard.interact.top.interact"
              class="btn btn-line btn-block"
              :to="arp_linkBuilder({
                name: $t(contactCard.interact.top.interact.name),
                params: {
                  objectNumber,
                },
                query: requestLinkQuery,
              })"
            >
              {{ $t(contactCard.interact.top.display.string, contactCard.interact.top.display.stringData) }}
            </router-link-simple>
          </div>
          <div v-else>
            <router-link-simple
              class="btn btn-line btn-block"
              :to="arp_linkBuilder(`mailto:${$t(contactCard.interact.top.interact.name)}`)"
              @click.prevent="arp_linkClicker(contactCard.interact.top.interact.name)"
            >
              {{ $t(contactCard.interact.top.display.string, contactCard.interact.top.display.stringData) }}
            </router-link-simple>
          </div>
          <router-link-simple
            v-if="contactCard.interact.bottom.interact"
            class="btn btn-line btn-block mb-0"
            :data-ident="`contactInteract`"
            :to="arp_linkBuilder($t(contactCard.interact.bottom.interact.string, contactCard.interact.bottom.interact.stringData))"
            @click.prevent="arp_linkClicker($t(contactCard.interact.bottom.interact.string, contactCard.interact.bottom.interact.stringData))"
          >
            {{ $t(contactCard.interact.bottom.display.string, contactCard.interact.bottom.display.stringData) }}
          </router-link-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';
import AnchorRouterPush from 'mixins/AnchorRouterPush';

export default {
  name: 'OfficeContactCard',
  components: {
    RouterLinkSimple,
  },
  mixins: [
    AnchorRouterPush,
  ],
  i18nOptions: {
    namespaces: ['office']
  },
  props: {
    contactHeader: {
      type: Object,
      required: true,
    },
    contactCard: {
      type: Object,
      required: true,
    },
    objectNumber: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    startDate: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    endDate: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    mailTo: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      // workaround to keep reactivity after SSR
      mountedReady: false,
    };
  },
  computed: {
    // need for objectPage with date params
    requestLinkQuery() {
      const returnVal = {};
      if (this.startDate) {
        returnVal.startDate = this.startDate;
      }
      if (this.endDate) {
        returnVal.endDate = this.endDate;
      }
      return returnVal;
    },
  },
  mounted() {
    this.mountedReady = true;
  },
};
</script>

<style lang="scss" scoped>
  h2 {
    margin: -.25em 0 .75em;
  }
</style>
