import clientroutes from 'router/routes/client';

const Search = () => import(/* webpackChunkName: "view-search" */ 'views/Search.vue');
const ObjectPage = () => import(/* webpackChunkName: "view-object" */ 'views/Object.vue');
const BookingStalker = () => import(/* webpackChunkName: "view-stalker" */ 'views/BookingStalker.vue');
const Home = () => import(/* webpackChunkName: "view-home" */ 'views/Home.vue');
const Error301 = () => import(/* webpackChunkName: "view-404" */ 'views/errors/301.vue');
const Error404 = () => import(/* webpackChunkName: "view-404" */ 'views/errors/404.vue');
const Error500 = () => import(/* webpackChunkName: "view-500" */ 'views/errors/500.vue');
const PoiPage = () => import(/* webpackChunkName: "view-poi" */ 'views/Poi.vue');
const Contact = () => import(/* webpackChunkName: "view-contact" */ 'views/Contact.vue');
const Newsletter = () => import(/* webpackChunkName: "view-404" */ 'views/errors/404.vue');
const WatchList = () => import(/* webpackChunkName: "view-watch-list" */ 'views/WatchList.vue');
const Lastminute = () => import(/* webpackChunkName: "view-lastminute" */ 'views/Lastminute.vue');
const Holidayparks = () => import(/* webpackChunkName: "view-holidayparks" */ 'views/Holidayparks.vue');
const DogBeach = () => import(/* webpackChunkName: "view-dogbeach" */ 'views/DogBeach.vue');
const Privacy = () => import(/* webpackChunkName: "view-privacy" */ 'views/Privacy.vue');
const Disclaimer = () => import(/* webpackChunkName: "view-disclaimer" */ 'views/Disclaimer.vue');
const Faq = () => import(/* webpackChunkName: "view-faq" */ 'views/Faq.vue');
const Tips = () => import(/* webpackChunkName: "view-tips" */ 'views/Tips.vue');
const Agency = () => import(/* webpackChunkName: "view-agency" */ 'views/Agency.vue');
const TravelConditions = () => import(/* webpackChunkName: "view-travelconditions" */ 'views/TravelConditions.vue');
const UserHome = () => import(/* webpackChunkName: "view-user-home" */ 'views/user/UserHome.vue');
const TravelVoucher = () => import(/* webpackChunkName: "travel-voucher" */'views/TravelVoucher.vue');
const PaymentProviderConcardis = () => import(/* webpackChunkName: "payment-provider-concardis" */'views/PaymentProviderConcardis.vue');
const CovidTravelerNotice = () => import(/* webpackChunkName: "covid-traveler-notice" */ 'views/CovidTravelerNotice.vue');
const LocaleEditor = () => import(/* webpackChunkName: "covid-traveler-notice" */ 'views/LocaleEditor.vue');
const ClusterDemo = () => import(/* webpackChunkName: "cluster-demo" */'views/ClusterDemo.vue');
const s3upload = () => import(/* webpackChunkName: "s3-upload" */'views/administration/s3Upload.vue');
const s3UploadOffice = () => import(/* webpackChunkName: "s3-upload-office" */ 'views/administration/s3UploadOffice.vue');
const ScheduleEdit = () => import(/* webpackChunkName: "schedule-edit" */'views/administration/ScheduleEdit.vue');
const ContentSnippets = () => import(/* webpackChunkName: "content-snippets" */'views/ContentSnippets.vue');
const ContentSnippetsTeaser = () => import(/* webpackChunkName: "content-snippets-teaser" */'views/administration/ContentSnippetsTeaser.vue');
const CmsPlayground = () => import(/* webpackChunkName: "cms-poc" */'views/administration/CmsPlayground.vue');
const Distribution = () => import(/* webpackChunkName: "view-distribution" */ 'views/Distribution.vue');
// const Register = () => import(/* webpackChunkName: "view-register" */ 'views/Register.vue');
const RedeemTravelVoucher = () => import(/* webpackChunkName: "redeem-travel-voucher" */'views/RedeemTravelVoucher.vue');
const LandlordBookingConfirmation = () => import(/* webpackChunkName: "landlord-booking-confirmation" */'views/LandlordBookingConfirmation.vue');
const OutdatedBrowser = () => import(/* webpackChunkName: "outdated-browser" */'views/UpdateBrowser.vue');
const RetailerRatingAnswer = () => import(/* webpackChunkName: "retailer-rating-answer" */'views/RetailerRatingAnswer.vue');
const EmptyDummy = () => import(/* webpackChunkName: "empty-dummy" */'components/loading/Empty.vue');
const DestinationLinkList = () => import(/* webpackChunkName: "destination-link-list" */ 'views/DestinationLinkList.vue');
const FaqDemo = () => import(/* webpackChunkName: "faq-demo" */'views/FaqDemo.vue');
const AffiliateRedirect = () => import(/* webpackChunkName: "affiliate-redirect" */ 'views/AffiliateRedirect.vue');
const BookingOverView = () => import(/* webpackChunkName: "booking-over-view" */ 'views/user/BookingOverView.vue');
const BookingDetails = () => import(/* webpackChunkName: "booking-over-view" */ 'views/user/BookingDetails.vue');

// export needed for manual preloading
export {
  /* ObjectPage, */Search,
};

export default () => [
  // add clientroutes in the beginning to take precedence over default routes
  ...clientroutes,
  {
    path: '/index.cfm',
    name: 'HomeLegacy',
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/object/:objectNumber(\\d+)',
    name: 'ObjectPage',
    props(route) {
      const props = { ...route.params };
      props.objectNumber = parseInt(props.objectNumber, 10);
      return props;
    },
    component: ObjectPage,
  },
  {
    path: '/:objectNumber(\\d+)',
    name: 'ObjectPageOld',
    props(route) {
      const props = { ...route.params };
      props.objectNumber = parseInt(props.objectNumber, 10);
      return props;
    },
    component: ObjectPage,
  },
  {
    path: '/(skiurlaub)/',
    name: 'SearchSki',
    meta: {
      searchFilter: {
        offer: 122,
      },
    },
    component: Search,
  },
  {
    path: '/skiurlaub-deutschland/',
    name: 'SearchSkiDE',
    meta: {
      searchFilter: {
        offer: 124,
      },
    },
    component: Search,
  },
  {
    path: '/skiurlaub-frankreich/',
    name: 'SearchSkiFR',
    meta: {
      searchFilter: {
        offer: 125,
      },
    },
    component: Search,
  },
  {
    path: '/skiurlaub-italien/',
    name: 'SearchSkiIT',
    meta: {
      searchFilter: {
        offer: 127,
      },
    },
    component: Search,
  },
  {
    path: '/skiurlaub-oesterreich/',
    name: 'SearchSkiAT',
    meta: {
      searchFilter: {
        offer: 123,
      },
    },
    component: Search,
  },
  {
    path: '/skiurlaub-schweiz/',
    name: 'SearchSkiCH',
    meta: {
      searchFilter: {
        offer: 128,
      },
    },
    component: Search,
  },
  {
    path: '/interhome/',
    name: 'SearchAgencyInterhome',
    meta: {
      searchFilter: {
        offer: 63,
      },
    },
    component: Search,
  },
  {
    path: '/interchalet/',
    name: 'SearchAgencyInterchalet',
    meta: {
      searchFilter: {
        offer: 149,
      },
    },
    component: Search,
  },
  {
    path: '/belvilla/',
    name: 'SearchAgencyBelvilla',
    meta: {
      searchFilter: {
        offer: 148,
      },
    },
    component: Search,
  },
  {
    path: '/dancenter/',
    name: 'SearchAgencyDancenter',
    meta: {
      searchFilter: {
        offer: 112,
      },
    },
    component: Search,
  },
  {
    path: '/belvilla-by-oyo/',
    name: 'SearchAgencyTui',
    meta: {
      searchFilter: {
        offer: 153,
      },
    },
    component: Search,
  },
  {
    path: '/%languagePath%/',
    name: 'DogBeachCroatia',
    component: DogBeach,
    langConfig: {
      de: 'hundestrand-kroatien',
      en: 'dog-beach-croatia',
      fr: 'plage-pour-chiens-croatie',
      it: 'spiaggia-per-cane-croazia',
    },
  },
  {
    path: '/%languagePath%/',
    name: 'DogBeachNorthsea',
    component: DogBeach,
    langConfig: {
      de: 'hundestrand-nordsee',
      en: 'dog-beach-north-sea',
      fr: 'plage-pour-chiens-mer-du-nord',
      it: 'spiaggia-per-cane-mare-del-nord',
    },
  },
  {
    path: '/%languagePath%/',
    name: 'DogBeachEastsea',
    component: DogBeach,
    langConfig: {
      de: 'hundestrand-ostsee',
      en: 'dog-beach-baltic-sea',
      fr: 'plage-pour-chiens-mer-baltique',
      it: 'spiaggia-per-cane-mare-baltico',
    },
  },
  {
    path: '/huetten-oesterreich/',
    name: 'SearchHutAT',
    meta: {
      searchFilter: {
        offer: 486,
      },
    },
    component: Search,
  },
  {
    path: '/novasol/',
    name: 'SearchAgencyNovasol',
    meta: {
      searchFilter: {
        offer: 998,
      },
    },
    component: Search,
  },
  {
    path: '/steinbock-lodges/',
    name: 'SearchAgencySteinbockLodges',
    meta: {
      searchFilter: {
        offer: 1124,
      },
    },
    component: Search,
  },
  {
    path: '/huetten-deutschland/',
    name: 'SearchHutDE',
    meta: {
      searchFilter: {
        offer: 487,
      },
    },
    component: Search,
  },
  { // open defined content pages
    path: '/%languagePath%/',
    name: 'TravelVoucher',
    component: TravelVoucher,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'reisegutscheine',
      en: 'travel-vouchers',
      fr: 'bons-de-voyage',
      it: 'buoni-viaggio',
    },
  },
  { // open distribution content-pages
    path: '/%languagePath%/',
    name: 'Distribution',
    component: Distribution,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'partnerprogramm',
      en: 'affiliate-program',
      it: 'programma-del-partner',
      fr: 'programme-du-partenaire',
    },
  },
  {
    path: '/%languagePath%/',
    name: 'Agency',
    component: Agency,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'agentur',
      en: 'propertymanager',
      fr: 'proprietaireprofessionelle',
      it: 'agenzia',
    },
  },
  { // open defined content pages
    path: '/%languagePath%/',
    name: 'TravelConditions',
    component: TravelConditions,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'reisebedingungen',
      en: 'general-terms-and-conditions',
      fr: 'termes-et-conditions-generales',
      it: 'termini-e-condizioni-generali',
    },
  },
  { // open faq content-page
    path: '/%languagePath%/',
    name: 'Faq',
    props(route) {
      const props = { ...route.params };
      return props;
    },
    component: Faq,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'haeufige-fragen/:part(faq1|faq2)?',
      en: 'frequently-asked-questions/:part(faq1|faq2)?',
      fr: 'questions-frequentes/:part(faq1|faq2)?',
      it: 'domande-frequenti/:part(faq1|faq2)?',
    },
  },
  { // open privacy content-page
    path: '/%languagePath%/',
    name: 'Privacy',
    component: Privacy,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'datenschutz',
      en: 'data-protection',
      fr: 'protection-des-donnees',
      it: 'protezione-dei-dati',
    },
  },
  { // open disclaimer content-page
    path: '/%languagePath%/',
    name: 'Disclaimer',
    component: Disclaimer,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'impressum',
      en: 'imprint',
      fr: 'mentions-legales',
      it: 'imprenta',
    },
  },
  { // open holidayparks content-page
    path: '/%languagePath%/',
    name: 'HolidayParks',
    component: Holidayparks,
    meta: {
      contentGroup: 5,
    },
    langConfig: {
      de: 'ferienparks',
      en: 'ferienparks',
      fr: 'parcs-de-vacances',
      it: 'ferienparks',
    },
  },
  { // open tips content-page
    path: '/%languagePath%/',
    name: 'Tips',
    meta: {
      contentGroup: 5,
    },
    component: Tips,
    langConfig: {
      de: 'reisetipps',
      en: 'travel-tips',
      fr: 'conseils-de-voyage',
      it: 'consiglio-di-viaggio',
    },
  },
  { // open tips-health content-page
    path: '/%languagePath%/',
    name: 'TipsHealth',
    meta: {
      contentGroup: 5,
    },
    component: Tips,
    langConfig: {
      de: 'reisetipps/reisegesundheit',
      en: 'travel-tips/travel-health',
      fr: 'conseils-de-voyage/sante-pendant-le-voyage',
      it: 'consiglio-di-viaggio/salute-durante-il-viaggio',
    },
  },
  { // open pets content-page
    path: '/%languagePath%/',
    name: 'TipsControl',
    meta: {
      contentGroup: 5,
    },
    component: Tips,
    langConfig: {
      de: 'reisetipps/checklisten',
      en: 'travel-tips/checklists',
      fr: 'conseils-de-voyage/lista-de-control',
      it: 'consiglio-di-viaggio/liste-di-controllo',
    },
  },
  { // open lastminute content-page
    path: '/lastminute/',
    name: 'Lastminute',
    component: Lastminute,
    meta: {
      contentGroup: 5,
    },
  },
  { // possibility to directly open object view without anycomponent
    path: '/(Reisebericht|Sehenswuerdigkeit)/:url?/:poiId(\\d+)/',
    name: 'Poi',
    props: true,
    component: PoiPage,
    meta: {
      contentGroup: 5,
    },
  },
  {
    path: '/bookingStalker/',
    name: 'BookingStalker',
    component: BookingStalker,
  },
  {
    path: '/search/',
    name: 'Search',
    component: Search,
  },
  {
    path: '/error/301/',
    name: 'Error301',
    component: Error301,
  },
  {
    path: '/error/404/',
    name: 'Error404',
    component: Error404,
  },
  {
    path: '/error/500/',
    name: 'Error500',
    component: Error500,
  },
  {
    path: '/%languagePath%/',
    name: 'Contact',
    props(route) {
      const props = { ...route.params };
      return props;
    },
    component: Contact,
    langConfig: {
      de: 'hilfe-kontakt',
      en: 'help-contact',
      fr: 'aide-contact',
      it: 'aiuto-contatto',
    },
  },
  {
    path: '/%languagePath%/',
    name: 'ContactLandlord',
    props(route) {
      const props = { ...route.params };
      return props;
    },
    component: Contact,
    langConfig: {
      de: 'hilfe-kontakt/formular-vermieter',
      en: 'help-contact/form-landlords',
      fr: 'aide-contact/formulaire-proprietaires',
      it: 'aiuto-contatto/formulario-locatori',
    },
  },
  {
    path: '/%languagePath%/',
    name: 'ContactPartner',
    props(route) {
      const props = { ...route.params };
      return props;
    },
    component: Contact,
    langConfig: {
      de: 'hilfe-kontakt/formular-partner',
      en: 'help-contact/form-partner',
      fr: 'aide-contact/formulaire-partenaires',
      it: 'aiuto-contatto/formulario-partner',
    },
  },
  {
    path: '/%languagePath%/',
    name: 'ContactAgency',
    props(route) {
      const props = { ...route.params };
      return props;
    },
    component: Contact,
    langConfig: {
      de: 'hilfe-kontakt/formular-agentur',
      en: 'help-contact/form-agency',
      fr: 'aide-contact/formulaire-agence',
      it: 'aiuto-contatto/formulario-agenzia',
    },
  },
  {
    path: '/newsletter/',
    name: 'Newsletter',
    component: Newsletter,
  },

  {
    path: '/nlnew/',
    name: 'NlNew',
    component: Newsletter,
  },
  {
    path: '/%languagePath%/',
    name: 'WatchList',
    component: WatchList,
    langConfig: {
      de: 'watchlist',
      en: 'favorite-property',
      fr: 'ma-selection',
      it: 'prememoria',
    },
  },
  {
    path: '/user/',
    name: 'UserHome',
    component: UserHome,
    props: () => ({
      userForm: 'UserDashboard',
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/user/login/',
    name: 'UserLogin',
    component: UserHome,
    props: () => ({
      userForm: 'UserLogin',
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/user/registration/',
    name: 'UserRegistration',
    component: UserHome,
    props: () => ({
      userForm: 'UserRegistration',
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/user/registration/confirm/',
    name: 'UserRegistrationConfirm',
    component: UserHome,
    props: () => ({
      userForm: 'UserRegistrationConfirm',
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/user/password/request/',
    name: 'UserPasswordRequest',
    component: UserHome,
    props: () => ({
      userForm: 'UserPasswordRequest',
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/user/password/reset/',
    name: 'UserPasswordReset',
    component: UserHome,
    props: () => ({
      userForm: 'UserPasswordReset',
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/user/debug/',
    name: 'UserDebug',
    component: UserHome,
    props: () => ({
      userForm: 'UserDebug',
    }),
  },
  {
    path: '/admin/schedule/edit',
    name: 'ScheduleEdit',
    component: ScheduleEdit,
    props: () => ({
      userForm: 'ScheduleEdit',
    }),
  },
  {
    path: '/payment/:language(de|en)/',
    name: 'PaymentProviderConcardis',
    component: PaymentProviderConcardis,
  },
  {
    path: '/%languagePath%/:ratingNumber/',
    name: 'RetailerRatingAnswer',
    component: RetailerRatingAnswer,
    props(route) {
      const props = { ...route.params };
      return props;
    },
    langConfig: {
      de: 'bewertung-vermieterkommentar',
      en: 'rating-landlord-comment',
      fr: 'evaluation-commentaire-proprietaire',
      it: 'valutazione-commento-locatore',
    },
  },
  { // markercluster demo
    path: '/demo/cluster/',
    name: 'ClusterDemo',
    component: ClusterDemo,
  },
  {
    path: '/corona-info/',
    name: 'CovidTravelerNotice',
    component: CovidTravelerNotice,
  },
  {
    path: '/localeEditor/',
    name: 'LocaleEditor',
    component: LocaleEditor,
  },
  {
    path: '/admin/s3upload/',
    name: 'S3Upload',
    component: s3upload,
  },
  {
    path: '/admin/s3uploadOffice/',
    name: 'S3UploadOffice',
    component: s3UploadOffice,
  },
  {
    path: '/user/booking-overview/',
    name: 'BookingOverView',
    meta: {
      footerComponent: 'BookingFooter',
    },
    component: BookingOverView,
  },
  {
    path: '/user/booking-details/:bookingDisplayNumber',
    name: 'BookingDetails',
    props(route) {
      const props = { ...route.params };
      props.bookingDisplayNumber = parseInt(props.bookingDisplayNumber, 10);
      return props;
    },
    component: BookingDetails,
  },
  {
    path: '/admin/snippets/',
    name: 'ContentSnippets',
    component: ContentSnippets,
  },
  {
    path: '/admin/teaser/',
    name: 'ContentSnippetsTeaser',
    component: ContentSnippetsTeaser,
  },
  {
    path: '/admin/cmsplayground/',
    name: 'CmsPlayground',
    component: CmsPlayground,
  },
  {
    path: '/demo/faq',
    name: 'FaqDemo',
    component: FaqDemo,
  },
  {
    path: '/ihr-reisegutschein/:bookingID(\\d+)/:customerNumber(\\d+)/',
    name: 'RedeemTravelVoucher',
    props(route) {
      const props = { ...route.params };
      return props;
    },
    component: RedeemTravelVoucher,
  },
  { // LandlordBookingConfirmation
    path: '/landlord/booking/confirmation/',
    name: 'LandlordBookingConfirmation',
    component: LandlordBookingConfirmation,
  },
  {
    path: '/outdated/',
    name: 'OutdatedBrowser',
    component: OutdatedBrowser,
  },
  {
    path: '/empty/',
    name: 'EmptyDummy',
    component: EmptyDummy,
  },
  {
    path: 'reiseziele',
    name: 'DestinationLinkList',
    component: DestinationLinkList,
  },
  {
    path: '/affiliate-redirect',
    name: 'AffiliateRedirect',
    component: AffiliateRedirect,
  },
];
