export default {
  locationName: '',
  locationNamePreposition: '',
  locationNameWithPreposition: '',
  locationBreadcrumbs: [],
  title: '',
  text1: '',
  text2: '',
  metaTitle: '',
  metaDescription: '',
  keywords: '',
  seoText: '',
  seoUrl: '',
  noIndex: 0,
  bounds: {},
  languageLinks: [],
  topLinks: [],
};
