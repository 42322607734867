export default {
  activeObjectNumber: 0,
  currentDescriptionLanguage: '',
  data: {}, // ObjectDatas for objectpage
  list: [], // object numbers that have data
  ratingsData: {}, // data for object ratings
  ratingsList: [], // object numbers that have ratings
  pricesData: {}, // price request data for current object
  rkiData: {},
  pricesList: [],
  priceRequestData: {
    startDate: null,
    endDate: null,
    persons: 2,
    identifierKey: null,
    viewedDate: new Date(),
  },
  languageLinks: {}, // list of all links for other domains
  isFetchingPrice: false,
  fetchingPriceError: false,
  cancelableObjects: [],
  printData: {
    printing: false,
    openCollapsibles: [],
    printingError: false,
    closeCollapsibles: [],
    ignoredCollapsibles: ['collapse-photos'],
    firstScrollMultiplicator: 1,
    secondScrollMultiplicator: 2,
    thirdScrollMultiplicator: 3,
  },
};
