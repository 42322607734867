import axios from 'apiclient';

const loadLocation = (country, location, bbox, languageCode) => {
  if (country || location || bbox) {
    return axios.get('/api/location', {
      params: {
        country,
        location,
        bbox,
        languageCode,
        mandant: process.env.CLIENTNAME,
      },
    }, {
      cache: {
        ignoreCache: false,
        maxAge: 15 * 60 * 10000,
      },
    }).then((res) => res.data)
      .catch((result) => Promise.reject(result.errors));
  }
  return Promise.resolve();
};

export default loadLocation;
