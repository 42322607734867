import axios from 'apiclient';

const loadRecommendations = () => {
  const params = { mandant: process.env.CLIENTNAME };
  return axios.get('/api/getHomeRecommendations', {
    params,
  })
    .then((res) => res.data)
    .catch((result) => Promise.reject(result.errors));
};

export default loadRecommendations;
