// import { stripLocaleFromRoute } from 'utils/locale/routeHelpers';
import config from 'config/appConfig';

const getWhoamiData = (to, store) => store.getters.getWhoamiData(to.path);

const Search = () => import(/* webpackChunkName: "view-search" */ 'views/Search.vue');
const Error404 = () => import(/* webpackChunkName: "view-404" */ 'views/errors/404.vue');
const Error410 = () => import(/* webpackChunkName: "view-404" */ 'views/errors/410.vue');
const Error500 = () => import(/* webpackChunkName: "view-500" */ 'views/errors/500.vue');
const ObjectPage = () => import(/* webpackChunkName: "view-object" */ 'views/Object.vue');

export { getWhoamiData };

export default (path, whoamiData, store) => {
  let routeComponent = Error404;
  switch (whoamiData.compname) {
    case 'Search': routeComponent = Search; break;
    case 'Object': routeComponent = ObjectPage; break;
    case 'Error410': routeComponent = Error410; break;
    case 'Error500': routeComponent = Error500; break;
    default: routeComponent = Error404;
  }

  if (whoamiData.datas.location) {
    const langPrefix = store.state.app.currentLanguage !== config.locales.standardLanguage ? `/${store.state.app.currentLanguage}` : '';
    return {
      path: `${langPrefix}${whoamiData.datas.location}`,
      name: `whoami_${whoamiData.compname}_${langPrefix}${whoamiData.datas.location}`,
      meta: {
        name: `whoami_${whoamiData.compname}_${langPrefix}${whoamiData.datas.location}`,
      },
      component: routeComponent,
      props(route) {
        const props = { ...route.params };
        if (whoamiData.compname === 'Object') {
          props.objectNumber = parseInt(whoamiData.datas.objectNumber, 10);
        }
        return props;
      },
      pathToRegexpOptions: {
        strict: true,
      },
    };
  }

  // add offer-searchfilter to meta
  const metaData = {
    name: `whoami_${whoamiData.compname}_${path}`,
  };

  if (whoamiData.compname === 'Search' && whoamiData?.datas?.offer) {
    metaData.searchFilter = { offer: parseInt(whoamiData.datas.offer, 10) };
  }

  return {
    path,
    name: `whoami_${whoamiData.compname}_${path}`,
    meta: metaData,
    component: routeComponent,
    props(route) {
      const props = { ...route.params };
      if (whoamiData.compname === 'Object') {
        props.objectNumber = parseInt(whoamiData.datas.objectNumber, 10);
      }
      return props;
    },
    pathToRegexpOptions: {
      strict: true,
    },
  };
};
