const GtmTools = {
  data() {
    return {
      iswaiting: false,
      dataLayerReady: false,
      contentGroup: null,
      debounce: false,
      clientSide: false,
    };
  },
  mounted() {
    this.clientSide = true;
  },
  computed: {

    routerPageview() {
      return this.$store.state.routerPageview;
    },
    waiting: {
      get() {
        return this.iswaiting;
      },
      set(status) {
        this.iswaiting = status;
      },
    },
    userDataReady() {
      return this.$store.state.userSaved;
    },
    analytics_storage() {
      if (this.$store.state.cookieLaw.statistic) {
        return 'granted';
      }
      return 'denied';
    },
    ad_storage() {
      if (this.$store.state.cookieLaw.marketing) {
        return 'granted';
      }
      return 'denied';
    },
    consentDefaultSet() {
      return this.$store.state.tracking.consent.default.done;
    },
  },
  methods: {
    dataLayerPush(object = {}, delayed = 0) {
      // push given object to gtm dataLayer
      this.$nextTick(() => {
        if (typeof window !== 'undefined') {
          // eslint-disable-next-line no-multi-assign
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          setTimeout(() => {
            dataLayer.push(object);
          }, delayed);
        }
      });
    },
    gtag(...args) { // EGMT-2029
      // console.log('gtag', args);// https://www.demirjasarevic.com/google-consent-mode/#1
      if (this.clientSide && typeof window !== 'undefined') {
        // eslint-disable-next-line no-multi-assign
        const dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push(args);
      }
    },

    trackErrors(observerErrors) {
      const errorElements = [];
      Object.entries(observerErrors).forEach((element) => {
        const hasError = Object.values(element)[1].find((item) => item.length >= 1);
        const compIdenty = element[0];
        if (hasError) {
          errorElements.push(compIdenty);
        }
      });
      const formErrorFields = errorElements.join('_');
      if (formErrorFields) {
        this.dataLayerPush(
          {
            event: 'formErrorPush',
            formErrorField: formErrorFields,
          },
        );
      }
    },
    setProperty(object = {}) {
      // push given object to gtm dataLayer
      return new Promise((resolve, reject) => {
        if (typeof window !== 'undefined') {
          // eslint-disable-next-line no-multi-assign
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          dataLayer.push(object);
          resolve();
        }
        reject(new Error('Window Object nicht vorhanden'));
      });
    },
    fn_userDataReady(value) {
      if (value === 'userSaved') {
        if (this.logPerformance) { this.logPerformance(); }
        if (this.gtmContentGroupPush && this.waiting === 'userdata') { // wenn ready schon durch ist und auf userdata gewartet wird
          this.pushContentGroup();
          this.dataLayerReady = false;// reset to default
        }
      }
    },
    fn_dataLayerReady(ready) {
      if (ready) {
        if (this.gtmContentGroupPush && this.userDataReady === 'userSaved') { // wenn userDataReady schon durch ist und auf ready gewartet wird
          this.pushContentGroup();
          this.dataLayerReady = null; // reset to default
        } else {
          this.waiting = 'userdata';
        }
      }
    },
    /**
     * call ContentGroupPush, set consent default and enable tagmanager
     */
    pushContentGroup() {
      if (this.clientSide && this.consentDefaultSet === 0) { // just one time
        this.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 500,
        });
        // this.dataLayerPush({ event: 'consent_default' });
        this.$gtm.enable(true); //
        this.$store.commit('SET_CONSENT_DEFAULT_DONE', 1);
      }
      if (this.gtmContentGroupPush) {
        this.gtmContentGroupPush();
      }
    },
  },
  watch: {
    userDataReady: {
      immediate: false,
      handler: 'fn_userDataReady',
    },
    dataLayerReady: {
      immediate: true,
      handler: 'fn_dataLayerReady',
    },
  },
};

export default GtmTools;
