import getWhoamiRoute from 'router/routes/whoami';
import getAllRoutes from 'router/routes/all';
import { pathMatches } from 'utils/locale/routeHelpers';
import config from 'config/appConfig';

const Home = () => import(/* webpackChunkName: "view-home" */ 'views/Home.vue');
// import setLocale from 'router/guards/setLocale';

// add a parent locale route and append all other routes as children
// this means /en/* and /* will point to the same route
// export default (i18n) => ([{
//   path: `/:locale(${pathMatches})?`,
//   component: {
//     beforeRouteEnter: (to, from, next) => setLocale(to, from, next, i18n),
//     beforeRouteUpdate: (to, from, next) => setLocale(to, from, next, i18n),
//     render(h) { return h('router-view'); },
//   },
//   children: allRoutes.map((route) => {
//     route.path = route.path.replace(/^\//, '');
//     return route;
//   }),
// }]);

// need some extra routes thanks to trailingSlashes...
// home always has trailing slash and locale homes also need to be handled separately
const specialHomeRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      name: 'Home',
    },
  },
  {
    path: `/:locale(${pathMatches})?${config.router.trailingSlash ? '/' : ''}`,
    name: 'HomeLocale',
    component: Home,
    pathToRegexpOptions: {
      strict: true,
    },
    meta: {
      name: 'HomeLocale',
    },
  },
];

// EDVUE-2130 add language specific routes by attribute langConfig in routes
function getLanguageRoutes(store) {
  const languageRoutes = [];

  const supported = {
    ...config.locales.supportedLanguages,
    db: {
      currency: 'EUR',
      locale: 'db'
    }
  };
  getAllRoutes(store).forEach((route) => {
    if (typeof route.langConfig === 'undefined') {
      route.langConfig = {
        ...Object.keys(supported).reduce((prev, cur) => {
          prev[cur] = route.path.replace(/^\//, '').replace(/\/$/, '');
          return prev;
        }, {}),
      };
      route.path = '/%languagePath%';
    }
    Object.entries(route.langConfig).forEach((entry) => {
      const newRoute = { ...route };
      const [lang, langPath] = entry;
      // replace path for each language
      newRoute.path = newRoute.path.replace('%languagePath%', langPath);

      // manage training slahes
      if (newRoute.name.match(/HomeLegacy/) === null) {
        if (config.router.trailingSlash && newRoute.path.match(/\/$/) === null) {
          newRoute.path += '/';
        }
        if (!config.router.trailingSlash && newRoute.path.match(/\/$/) !== null) {
          newRoute.path = newRoute.path.replace(/\/$/, '');
        }
      }

      // route has to match perfect (slash at ending)
      newRoute.pathToRegexpOptions = {
        strict: true,
      };

      // information for language switches by name
      if (!newRoute.meta) newRoute.meta = {};
      newRoute.meta = Object.assign(newRoute.meta, { language: lang, name: route.name });
      if (lang.toUpperCase() !== process.env.DEFAULT_LANGUAGE.toUpperCase()) {
        newRoute.path = `/${lang}${newRoute.path}`;
        newRoute.name = `${newRoute.name}-${lang}`;
      }
      languageRoutes.push(newRoute);
    });
  });
  return languageRoutes;
}

export default (store) => [
  ...specialHomeRoutes,
  ...Object.keys(store.state.app.router.whoami.data).map((url) => getWhoamiRoute(url, store.state.app.router.whoami.data[url], store)),
  ...getLanguageRoutes(store),
];
