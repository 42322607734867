import axios from 'apiclient';

const loadOffer = (languageCode, offer) => {
  const params = { mandant: process.env.CLIENTNAME, languageCode };
  if (offer) {
    params.offerId = offer;
  } else params.offerList = 1;

  return axios.get('/api/offer', {
    params,
  }).then((res) => res.data);
};

export default loadOffer;
