import axios from 'apiclient';

const loadBookingData = ({ bookingId, bookingHash }) => axios.post('/api/landlord/booking/confirm/get', {
  bookingId,
  bookingHash,
})
  .then((res) => res.data)
  .catch((error) => Promise.reject(error));

const sendConfirm = (confirmation) => axios.post('/api/landlord/booking/confirm/set', confirmation)
  .then((res) => res.data);

export default loadBookingData;
export { sendConfirm };
