import axios from 'apiclient';

const loadObject = (objectNumber, jwt, languageCode = process.env.DEFAULT_LANGUAGE) => axios.post('/api/object', {
  languageCode,
  mandant: process.env.CLIENTNAME,
  objectNumber,
  jwt,
}).then((res) => res.data);

export function loadObjectInternal(objectNumbers, uid, jwt, languageCode = process.env.DEFAULT_LANGUAGE) {
  return axios.post('/api/objectInternal', {
    mandant: process.env.CLIENTNAME,
    languageCode,
    objectNumbers,
    uid,
    jwt,
  }).then((res) => res.data);
}

export function loadObjectDescription(objectNumber, languageCode, translationSource) {
  return axios.post('/api/object', {
    objectNumber,
    descriptionLanguageCode: languageCode,
    translationSource,
    mandant: process.env.CLIENTNAME,
  }).then((res) => res.data);
}
export function loadObjectRatings({
  objectNumber, languageCode, ratingId, pageNr, perPage,
}) {
  return axios.get(`/api/ratings/object/${objectNumber}`, {
    params: {
      dummy: 0,
      mandant: process.env.CLIENTNAME,
      languageCode,
      translationRatingId: ratingId,
      pageNr,
      perPage,
    },
  }, {
    cache: {
      ignoreCache: false,
      maxAge: 15 * 60 * 10000,
    },
  }).then((res) => res.data);
}

export default loadObject;
