import axios from 'apiclient';

const lastSeenObjectsSet = (uid, objectData) => axios.post('/api/user/lastseenobjects/set', {
  mandant: process.env.CLIENTNAME,
  uid,
  objectData,
})
  .then((result) => Promise.resolve(result.data));

export default lastSeenObjectsSet;
