import axios from 'apiclient';

const watchedObjectSet = (uid, objectData, action, watchListUID) => axios.post('/api/user/watchedobjects/set', {
  mandant: process.env.CLIENTNAME,
  uid,
  objectNumber: objectData.objectNumber,
  price: objectData.priceAtBooking,
  priceOriginal: objectData.original,
  pricePercentReduction: objectData.pricePercentReduction,
  currency: objectData.currency,
  startDate: objectData.startDate,
  endDate: objectData.endDate,
  persons: objectData.persons,
  action,
  watchListUID,
}).then((result) => Promise.resolve(result.data));

export default watchedObjectSet;
