<template>
  <div
    class="loading-placeholder"
    :style="placeholderStyle"
  >
    <div
      class="loading-spinner"
    >
      <div /><div /><div /><div />
    </div>
    <span>{{ loadingText }}</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    // when not using custom loading slot, you can define the placeholder CSS here
    placeholderStyle: {
      type: Object,
      required: false,
      default: () => ({ height: '100px' }),
    },
    loadingText: {
      type: String,
      required: false,
      default: 'Wird geladen...',
    },
  },
};
</script>

<style lang="scss" scoped>
  $color1: #e1e1e1;
  $color2: #909090;

  .loading-placeholder {
    height: 100px;
    width: 100%;
    border-radius: 10px;
    background-color: $color1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* https://loading.io/css/ */
  .loading-spinner {
    display: inline-block;
    position: relative;
    height: 80px;
    width: 80px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      margin: 10px;
      border: 10px solid #000;
      border-radius: 50%;
      animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #000 transparent transparent transparent;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
