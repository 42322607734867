import Vue from 'vue';
import { testParam } from 'utils/SearchFilterHelper';
import { searchFilterNames, searchLocationFilterNames } from 'router/guards/queryConfig';
import locationDefault from 'store/defaults/location';
import defaultsDeep from 'lodash.defaultsdeep';
import offerDefault from 'store/defaults/offer';

export default {
  SET_GLOBAL_LOADING: (state, isLoading) => {
    state.pageLoading = isLoading;
  },
  SET_OBJECTS: (state, { searchObjects, requestId }) => {
    state.searchObjects = [];
    let objectIndex = 0;
    if (searchObjects) {
      if (searchObjects.hits) {
        searchObjects.hits.forEach((object) => {
          if (object) {
            Vue.set(state.searchObjects, objectIndex, object);
            objectIndex += 1;
          }
        });
        if (requestId) {
          Vue.set(state, 'mapData', { id: requestId });
        }
      }
      if (searchObjects.coordinatesSearch) {
        Vue.set(state, 'coordinatesSearch', searchObjects.coordinatesSearch);
      } else {
        Vue.set(state, 'coordinatesSearch', false);
      }

      if (searchObjects.trackVars) {
        Vue.set(state, 'searchTrackVars', searchObjects.trackVars);
      } else {
        Vue.set(state, 'searchTrackVars', {});
      }
    }
  },
  SET_OBJECT_LAST_SEEN: (state, { objectData }) => {
    Vue.set(state.searchObjectsLastSeen, objectData.objectNumber, objectData);
  },
  SET_AGGREGATIONS: (state, { searchObjects, requestId }) => {
    if (searchObjects && searchObjects.aggs) {
      const aggregationKeys = Object.keys(searchObjects.aggs);
      aggregationKeys.forEach((aggregationKey) => {
        Vue.set(state.searchAggregations, aggregationKey, searchObjects.aggs[aggregationKey]);
      });
      if (requestId) {
      //   const { id } = state.mapData || false; // get last saved id from state
      //   if (id !== requestId) { // if no data available for this request, write new mapData
      //     const aggBounds = state.searchAggregations.searchBounds;
      //     aggBounds.push({ lat: aggBounds.top_left.lat, lng: aggBounds.top_left.lon });
      //     aggBounds.push({ lat: aggBounds.bottom_right.lat, lng: aggBounds.bottom_right.lon });
      //     Vue.set(state, 'mapData', { id: requestId, bounds: aggBounds, source: 'searchBoundsAggregation' });
      //   }
      }
    }
  },
  SET_SEARCH_COUNTER: (state, { searchObjects }) => {
    let hits = 0;
    if (searchObjects && searchObjects.hitsTotal) {
      hits = searchObjects.hitsTotal;
    }
    Vue.set(state, 'searchTotalObjects', hits);

    let hitsCollapse = 0;
    if (searchObjects && searchObjects.aggs?.totalObjectsCollapse) {
      hitsCollapse = searchObjects.aggs.totalObjectsCollapse;
    }
    Vue.set(state, 'searchTotalObjectsCollapse', hitsCollapse);

    let criteriaMatch = 0;
    if (searchObjects && searchObjects.aggs?.criteriaMatch) {
      criteriaMatch = searchObjects.aggs.criteriaMatch;
    }
    Vue.set(state, 'searchTotalObjectsCriteriaMatch', criteriaMatch);

    let criteriaMatchSearch = false;
    if (searchObjects && searchObjects?.criteriaMatchSearch && searchObjects.criteriaMatchSearch === 'Y') {
      criteriaMatchSearch = true;
    }
    Vue.set(state, 'criteriaMatchSearch', criteriaMatchSearch);

    let time = 0;
    if (searchObjects && searchObjects.timeES) {
      time = searchObjects.timeES;
    }
    Vue.set(state, 'searchTimeES', time);
  },
  SET_WHOAMI_DATA: (state, { path, data }) => {
    // basic checks
    if (typeof data.compname === 'undefined') data.compname = null;
    if (typeof data.datas !== 'object') data.datas = null;

    // convert object number to number
    if (data.datas.objectNumber) data.datas.objectNumber = parseInt(data.datas.objectNumber, 10);

    Vue.set(state.app.router.whoami.data, path, data);
    state.app.router.whoami.list.push(path);

    // if path has trailing / then also add the path without the trailing /
    const checkTrailingSlash = path.match(/(.*)\/$/);
    if (checkTrailingSlash !== null) {
      Vue.set(state.app.router.whoami.data, checkTrailingSlash[1], data);
      state.app.router.whoami.list.push(checkTrailingSlash[1]);
    }
  },
  SET_SEO_SEARCHFILTER: (state, filterList) => {
    state.app.router.seoSearchFilter = filterList;
  },
  SET_CANONICAL: (state, addCanonical) => {
    state.app.addCanonical = addCanonical;
  },
  SET_OFFER_DATA: (state, { id, data }) => {
    Vue.set(state.app.router.offer.data, id, defaultsDeep(data, offerDefault));
    state.app.router.offer.list.push(id);
  },
  SET_APP_CURRENT_LANGUAGE: (state, currentLanguage) => {
    Vue.set(state.app, 'currentLanguage', currentLanguage);
  },
  SET_APP_CURRENT_CURRENCY: (state, currentCurrency) => {
    Vue.set(state.app, 'currentCurrency', currentCurrency);
  },
  SET_APP_CURRENT_LOCALE: (state, currentLocale) => {
    Vue.set(state.app, 'currentLocale', currentLocale);
  },
  SET_APP_APIHTTPSTATUS: (state, { type, status }) => {
    Vue.set(state.app.apiHttpStatus, type, status);
  },
  SET_API_ERROR_TEXT: (state, errorTextObject) => {
    Vue.set(state.app, 'apiErrorText', errorTextObject);
  },
  SET_APP_ISNODE: (state, status) => {
    state.app.isNode = status;
  },
  SET_APP_ISBOT: (state, status) => {
    state.app.isBot = status;
  },
  SET_APP_ISMOBILE: (state, status) => {
    state.app.isMobile = status;
  },
  SET_APP_UA: (state, data) => {
    state.app.ua = data;
  },
  SET_AGENCYDATA: (state, agencyData) => {
    // basic checks
    if (typeof agencyData.agencyName === 'undefined') agencyData.agencyName = '';
    if (typeof agencyData.agencyLinkIntern === 'undefined') agencyData.agencyLinkIntern = '';
    if (typeof agencyData.agencyLogoLink === 'undefined') agencyData.agencyLogoLink = '';

    // set data
    state.object.agency.agencyName = agencyData.agencyName;
    state.object.agency.agencyLinkIntern = agencyData.agencyLinkIntern;
    state.object.agency.agencyLogoLink = agencyData.agencyLogoLink;
  },
  SET_SEARCHFILTER: (state, filterList) => {
    const filterNames = Object.keys(filterList);
    if (filterNames.length > 0) {
      filterNames.forEach((name) => {
        const filterValue = filterList[name];
        if (searchFilterNames.includes(name)) {
          if (testParam(name, filterValue)) Vue.set(state.searchFilter, name, filterValue);
          // purge if necessary
          else Vue.delete(state.searchFilter, name);
        } else if (searchLocationFilterNames.includes(name)) {
          if (testParam(name, filterValue)) Vue.set(state.searchLocationFilter, name, filterValue);
          // purge if necessary
          else Vue.delete(state.searchLocationFilter, name);
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Unknown searchFilter '${name}', please check filter!`);
        }
      });
    }
  },
  SET_SEARCHFILTER_FORCED: (state, filterList) => {
    const filterNames = Object.keys(filterList);
    if (filterNames.length > 0) {
      filterNames.forEach((name) => {
        const filterValue = filterList[name];
        if (searchFilterNames.includes(name)) {
          if (testParam(name, filterValue)) Vue.set(state.searchFilterForced, name, filterValue);
          // purge if necessary
          else Vue.delete(state.searchFilterForced, name);
        }
      });
    }
  },
  SET_LASTSEARCHFILTER: (state, filterList) => {
    state.lastSearchFilters = { ...filterList };
  },
  /**
    {
      latitude:12.9999
      ,longitude:7.8888
      ,distance:50
    }
    OR
    { country:'de'}
    OR
    {
      BBoxString: '1.0327148437500002,41.68932225997044,25.42236328125,49.36806633482156'
    }
    */
  SET_DIRECTUS_CONTENT(state, { pageAlias, directusContent }) {
    Vue.set(state.directusContent, pageAlias, directusContent);
  },
  SET_OFFICE_CONTACT(state, officeContact) {
    // set random employee index here to prevent flickering
    const officeStaffCount = Object.values(officeContact.employeeData.staff).length;
    const rndInd = Math.floor(Math.random() * officeStaffCount);
    officeContact.officeRandom = rndInd;
    Vue.set(state, 'officeContact', officeContact);
  },
  // send data of all eymployees to store
  SET_OFFICE_INFO_FULL(state, officeInfoFull) {
    Vue.set(state, 'officeInfoFull', officeInfoFull);
  },
  SET_OFFICE_OVERWRITES(state, officeOverwrites) {
    Vue.set(state, 'officeOverwrites', officeOverwrites);
  },
  // send pois of objects and landing-pages to store
  SET_POIS(state, { name, poiList }) {
    Vue.set(state.pois, name, poiList.pois);
  },
  SET_APP_INTERSECT_CLASS(state, { elemId, visbilityStatus }) {
    Vue.set(state.appIntersectClass, elemId, visbilityStatus);
  },
  SET_LANG_RESOURCE: (state, { lng, ns, data }) => {
    if (typeof state.i18next[lng] === 'undefined') {
      Vue.set(state.i18next, lng, {});
    }
    state.i18next[lng][ns] = data;
  },
  SET_CAPACITY: (state, percentage) => {
    Vue.set(state, 'searchCapacity', percentage);
  },
  SET_DOCUMENT_POI: (state, poi) => {
    Vue.set(state.documents.pois, poi.id, poi);
  },
  SET_MODAL_STATUS(state, { status }) {
    // toggle modal show/close by status true/false
    Vue.set(state, 'showModalStatus', status);
  },
  SET_MAP_STATUS(state, { fullscreen }) {
    // toggle modal show/close by status true/false
    Vue.set(state, 'searchMapFullscreen', fullscreen);
  },
  SET_MAP_ZOOM(state, { mapZoom }) {
    // toggle modal show/close by status true/false
    Vue.set(state, 'decreaseZoom', mapZoom);
  },
  SET_SEARCH_OVERGREY(state, { status }) {
    // set overgrey class on serach object list
    Vue.set(state, 'searchOvergrey', status);
  },
  SET_LOCATION(state, locationInfo) {
    Vue.set(state, 'locationInformations', defaultsDeep(locationInfo, locationDefault));
  },

  // called from router.js which checks if a route is a seo/sea page
  SET_ISSEOPAGE(state, isSeo) {
    Vue.set(state, 'isSeoPage', isSeo);
  },
  SET_ISSEAPAGE(state, isSea) {
    Vue.set(state, 'isSeaPage', isSea);
  },
  SET_USEPAGECACHE(state, usePageCache) {
    Vue.set(state, 'usePageCache', usePageCache);
  },
  LOCATION_REQUEST_INPROGRESS(state, status) {
    Vue.set(state, 'locationRequestInprogress', status);
  },
  SET_OFFERS(state, offerInfo) {
    Vue.set(state, 'offerInformations', offerInfo);
  },
  SET_APP_HTTPSTATUS(state, code) {
    const allowedCodes = [200, 404, 410, 301, 500];
    if (allowedCodes.includes(code)) {
      state.app.ssrHttpStatus = code;
    } else {
      state.app.ssrHttpStatus = 500;
    }
  },
  SET_APP_HTTPREWIRTEURL(state, url) {
    state.app.httpRewriteTo = url;
  },
  SET_GA_PROPERTY(state, gaProperty) {
    state.gaProperty = gaProperty;
  },
  SET_WEBVITALS_DONE(state, done) {
    Vue.set(state.tracking.performance.webVitals, 'done', done);
  },
  SET_CONSENT_DEFAULT_DONE(state, done) {
    Vue.set(state.tracking.consent.default, 'done', done);
  },
  STORE_COOKIE_AWIN(state, cookieAwc) {
    Vue.set(state, 'awinClickChecksum', cookieAwc);
  },
  SET_OBJECT_INTERNAL: (state, { objectNumber, objectInternal }) => {
    Vue.set(state.objectInternalData, objectNumber, objectInternal);
  },
  SAVE_COVID_STATE_STATUS: (state, { stateData }) => {
    Vue.set(state, 'covidStateStatus', stateData);
  },
  STORE_COOKIE_PERMISSIONS: (state, permissions) => {
    Object.keys(permissions).forEach((permission) => {
      Vue.set(state.cookieLaw, permission, permissions[permission]);
    });
  },
  SHOW_COOKIE_DISCLAIMER: (state, toggle) => {
    Vue.set(state.cookieLaw, 'showDisclaimer', toggle);
  },
  SET_COOKIE_DISCLAIMER: (state) => {
    Vue.set(state.cookieLaw, 'confirmed', true);
  },
  SET_S3_RESULT: (state, data) => {
    Vue.set(state, 's3', data);
  },
  SET_USERTYPE: (state, type) => {
    Vue.set(state, 'usertype', type);
  },
  SET_PARTNER_CONFIG: (state, partnerConfig) => {
    Object.entries(partnerConfig).forEach((entry) => {
      const [key, value] = entry;
      if (typeof state.partnerConfig[key] !== 'undefined') {
        Vue.set(state.partnerConfig, key, value);
      }
    });
  },
  // saving api-response of retailer for ratings of their object
  SET_RETAILER_RESPONSE: (state, { ratingNumber, response }) => {
    Vue.set(state.retailerResponse, ratingNumber, response);
  },
  SET_FLATPICKR_OPENED: (state) => {
    Vue.set(state, 'flatpickrOpened', true);
  },
  SET_SEARCH_TEASER: (state, { content, key }) => {
    Vue.set(state.searchObjectsTeaser, key, content);
  },
  SET_OFFER_LANDINGPAGE: (state, offer) => {
    const { isoffer } = offer;
    Vue.set(state, 'isOfferLandingPage', isoffer);
  },
  SET_RECOMMENDATIONS: (state, response) => {
    Vue.set(state, 'recommendations', response);
  },
};
