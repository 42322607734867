import routeWhoami from 'requests/routeWhoami';
import { routeBuilder, stripLocaleFromRoute } from 'utils/locale/routeHelpers';
import getWhoamiRoute, { getWhoamiData } from 'router/routes/whoami';
import { pathToRegexp } from 'path-to-regexp';
import { redirectTo } from 'router/guards/navigationGuards';
import config from 'config/appConfig';
import Sentry from 'plugins/sentry.TARGET';
// import changeLanguage from 'utils/locale/changeLanguage';

// eslint-disable-next-line import/no-unresolved

const pathToRegExpSavePath = (path) => path.replace('(', '\\(')
  .replace(')', '\\)')
  .replace(':', '\\:')
  .replace('?', '\\?')
  .replace(/\+/g, '\\+');

const addRoute = (router, to, store) => {
  const whoamiData = getWhoamiData(to, store) || {
    compname: null,
    datas: null,
  };

  const whoamiRoute = getWhoamiRoute(to.path, whoamiData, store);

  router.addRoute({
    ...whoamiRoute,
    path: pathToRegExpSavePath(whoamiRoute.path),
    caseSensitive: true,
    pathToRegexpOptions: {
      strict: true,
    },
  });
};

export default ({
  router, store, to, next,
// eslint-disable-next-line consistent-return
}) => {
  // sanatize the whoami url since everything not matching a current route will be thrown against the whoami API and added to the router
  // this covers cases in which parameters in the url are turned into regexp and then won't match the original url any longer, leading to infinite loops
  try {
    if (pathToRegexp(pathToRegExpSavePath(stripLocaleFromRoute(to).path)).exec(stripLocaleFromRoute(to).path) === null) {
      next({ name: 'Error404' });
      return;
    }
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        url: to.path,
        pathToRegexp: pathToRegExpSavePath(stripLocaleFromRoute(to).path),
      },
    });
    next({ name: 'Error500' });
    return;
  }

  const whoamiData = getWhoamiData(to, store);
  const hasData = whoamiData !== false;

  // guard gets called on unmatched routes or whoami routes
  if (to.matched.length > 0) {
    if (hasData) {
      // search whoami
      if (whoamiData.datas.location && whoamiData.datas.location !== to.path) {
        const resolved = router.resolve(redirectTo(to, {
          path: routeBuilder({ path: whoamiData.datas.location }, router, store).path,
        }));

        store.commit('SET_APP_HTTPREWIRTEURL', resolved.href);
        store.commit('SET_APP_HTTPSTATUS', 301);
        // filter location data
        // we could rerun a new whoami request with the location as param, but it would be the same return without location
        // so we might as well just filter the location manually and avoid the extra request
        const newData = Object.keys(whoamiData.datas).filter((el) => el !== 'location').reduce((aggr, cur) => { aggr[cur] = whoamiData.datas[cur]; return aggr; }, {});
        let { location } = whoamiData.datas;
        if (store.state.app.currentLanguage !== config.locales.standardLanguage) {
          location = `/${store.state.app.currentLanguage}${location}`;
        }
        store.commit('SET_WHOAMI_DATA', {
          path: location,
          data: { compname: whoamiData.compname, datas: newData },
        });
        next(redirectTo(to, {
          path: routeBuilder({ path: location }, router, store).path,
        }));
        return;
      }

      // reset location filter because not all three might be present in datas
      store.commit('SET_SEARCHFILTER', {
        country: whoamiData.datas.country || '',
        region: whoamiData.datas.region || '',
        place: whoamiData.datas.place || '',
        offer: whoamiData.datas.offer || '',
      });

      next();
    } else {
      // other whoami routes
      // the only other relevant one seems to be object, which is now handled with a direct route that catches old urls
      next();
    }
  } else {
    // whoami route

    if (!hasData) {
      // data not there yet, fetch it
      // get locale of url
      const pathToCheck = stripLocaleFromRoute(to).path;
      const localeOfUrl = store.state.app.currentLanguage;
      // let localeOfUrl = to.path.replace(pathToCheck, '').substr(1, 2);
      // if (localeOfUrl === '') localeOfUrl = store.state.app.currentLanguage;
      // else {
      //  changeLanguage(localeOfUrl, false, store, router.app.$i18n, router);
      // }

      routeWhoami(pathToCheck, localeOfUrl).then((component) => {
        store.commit('SET_WHOAMI_DATA', {
          path: getWhoamiRoute(to.path, component, store).path,
          data: { compname: component.compname, datas: component.datas },
        });

        const whoamiPath = getWhoamiRoute(to.path, component, store).path;

        addRoute(router, {
          ...to,
          path: whoamiPath,
        }, store, next);

        if (component.datas.location && component.datas.location !== to.path) {
          const resolved = router.resolve(redirectTo(to, {
            path: routeBuilder({ path: component.datas.location }, router, store).path,
          }));

          store.commit('SET_APP_HTTPREWIRTEURL', resolved.href);
          store.commit('SET_APP_HTTPSTATUS', 301);

          // render a dummy component in case of 301 to speed up the rendering process
          // this is a workaround because we can't abort the navigation process due to https://github.com/vuejs/vue-router/issues/2873
          if (store.state.app.isNode) {
            // next(false);
            next({
              name: 'EmptyDummy',
            });
            return;
          }
        }

        next(redirectTo(to, {
          path: whoamiPath,
        }));
      }).catch((err) => {
        store.commit('SET_APP_APIHTTPSTATUS', { type: 'whoami', status: err.status });
        // we have to set whoami data on errors as well
        // otherwise the server will render an error page and the client does not
        store.commit('SET_WHOAMI_DATA', {
          path: to.path,
          data: { compname: `Error${err.status}`, datas: {} },
        });
        addRoute(router, to, store, next);
        next(to);
      });
    }

    // client check whether server has already fetched the data
    // we still need to add it to the router though
    if (hasData) addRoute(router, to, store, next);
  }
};
