import axios from 'apiclient';

const routeWhoami = (path, languageCode = process.env.DEFAULT_LANGUAGE) => axios.get('/api/whoami', { params: { mandant: process.env.CLIENTNAME, routepath: path, languageCode } }, {
  cache: {
    ignoreCache: false,
    maxAge: 15 * 60 * 10000,
  },
})
  .then((result) => result.data)
  .catch((error) => Promise.reject(error));

export default routeWhoami;
