import axios from 'apiclient';

const userWatchlistSendCustomer = (uid, jwt, inputs) => axios.post('/api/user/watchedobjects/sendcustomer', {
  mandant: process.env.CLIENTNAME,
  jwt,
  uid,
  inputs: {
    ...inputs,
  },
}, {
  timeout: 20000,
}).then((result) => Promise.resolve(result.data));

export default userWatchlistSendCustomer;
