export default {
  type: 'application/ld+json',
  json: {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `https://product.${process.env.CLIENTNAME}/`,
    potentialAction: {
      '@type': 'SearchAction',
      target: `https://product.${process.env.CLIENTNAME}/search/?fulltext={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  },
  vmid: 'structuredDataSearchboxJson',
};
