import cdn from 'config/cdn';

export default {
  heroImages: {
    home: [
      `//${cdn}/assets/locations/width/deutschland-sylt-2092.jpg`,
    ],
    error404: `//${cdn}/assets/categories/error-404-atraveo.jpg`,
  },
};
