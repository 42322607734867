import config from 'config/images';

const { CDN } = config;
// const CDN = process.env.CLIENT_CDN || 'cdn.tourist-online.de';


export default {
  locales: {
    supportedLanguages: {
      de: {
        currency: 'EUR',
        locale: 'de-DE',
      },
    },
    standardLanguage: 'de',
    fallbackLanguage: 'de',
    // language route methods is a sorted list
    // if app finds language idents in multiple given methods, it'll take the first on the list
    languageRouteMethods: [
      {
        type: 'params', // type = params|query
        listen: true,
        write: true,
        name: 'locale',
      },
      {
        type: 'query',
        listen: false,
        write: false,
        name: 'lang',
      },
    ],
  },
  router: {
    trailingSlash: true,
    to: {
      domain: null,
      whitelist: []
    },
    ota: {
      domain: null
    }
  },
  defaults: {
    country: 'de',
    insurance: 0,
    searchTolerance: 3,
    searchPriceMax: 6000,
    searchPriceInterval: 100,
  },
  rules: {
    zipcode: 'digits:5',
  },
  booking: {
    showCompleteTravelPriceInSummary: true,
    sendNewsletterSeparated: false,
    disableBonus: false,
  },
  cookieLaw: {
    categoriesConfig: [
      {
        name: 'required',
        usage: true,
        description: 'cookieLaw:required.description',
        label: 'cookieLaw:required.label',
        btn: {
          className: 'disabled',
        },
        disabled: true,
      },
      {
        name: 'statistic',
        usage: false,
        description: 'cookieLaw:statistic.description',
        label: 'cookieLaw:statistic.label',
        disabled: false,
      },
      {
        name: 'marketing',
        usage: false,
        description: 'cookieLaw:marketing.description',
        label: 'cookieLaw:marketing.label',
        disabled: false,
      },
    ],
  },
  heroImages: {
    backgroundImages: [
      // array for random location images,
      // images taken from EDVUE-262
      `${CDN}/assets/locations/width/bungalow-am-meer.jpg`,
      `${CDN}/assets/locations/width/familie-am-strand.jpg`,
      `${CDN}/assets/locations/width/familie-im-feld-herbst.jpg`,
      `${CDN}/assets/locations/width/ferienhaus-am-see.jpg`,
      `${CDN}/assets/locations/width/ferienhaus-mit-pool.jpg`,
      `${CDN}/assets/locations/width/finca-landhaus.jpg`,
      `${CDN}/assets/locations/width/haus-am-see.jpg`,
      `${CDN}/assets/locations/width/paar-am-sandstrand.jpg`,
      `${CDN}/assets/locations/width/paar-am-strand.jpg`,
      `${CDN}/assets/locations/width/reisegruppe-am-strand.jpg`,
      `${CDN}/assets/locations/width/reisegruppe-skiurlaub.jpg`,
      `${CDN}/assets/locations/width/reisegruppe-zu-tisch.jpg`,
      `${CDN}/assets/locations/width/wandergruppe-auf-berg.jpg`,
    ],
    home: [],
    landlord: '',
    /** Check Image ALT Texts (altTexts.json) if you change Country/Region Images  */
    backgroundImagesCountry: {
      be: `${CDN}/assets/locations/width/belgien-bruessel.jpg`,
      bg: `${CDN}/assets/locations/width/bulgarien-sieben-rila-seen.jpg`,
      dk: `${CDN}/assets/locations/width/daenemark-haus-am-strand.jpg`,
      de: `${CDN}/assets/locations/width/deutschland-usedom-ahlbeck.jpg`,
      fk: `${CDN}/assets/locations/width/falklandinseln.jpg`,
      fi: `${CDN}/assets/locations/width/finnland-helsinki.jpg`,
      fr: `${CDN}/assets/locations/width/frankreich-cote-d-azur-nizza.jpg`,
      gr: `${CDN}/assets/locations/width/griechenland-santorini.jpg`,
      gb: `${CDN}/assets/locations/width/grossbritannien-cornwall.jpg`,
      id: `${CDN}/assets/locations/width/indonesien-bali-sanur.jpg`,
      ie: `${CDN}/assets/locations/width/irland-cliffs-of-moher.jpg`,
      is: `${CDN}/assets/locations/width/island-gullfoss-wasserfall.jpg`,
      it: `${CDN}/assets/locations/width/italien-kampanien-amalfi.jpg`,
      // hr: '//'+cdn+'/assets/locations/width/kroatien-dalmatien-makarska-rivera-brela.jpg',
      hr: `${CDN}/assets/locations/width/kroatien-pucisca-insel-brac.jpg`, // EDVUE-1073
      lu: `${CDN}/assets/locations/width/luxemburg.jpg`,
      // mt: '//'+cdn+'/assets/locations/width/malta-valletta.jpg',
      // mt: '//'+cdn+'/assets/locations/width/malta.jpg',
      mt: `${CDN}/assets/locations/width/822-malta-hauptinsel.jpg`,
      ma: `${CDN}/assets/locations/width/marokko-atlas-gebirge-ait-ben-haddou.jpg`,
      me: `${CDN}/assets/locations/width/montenegro-bucht-von-kotor-gospa-od-milosti.jpg`,
      nl: `${CDN}/assets/locations/width/niederlande-nordholland-zaanstad.jpg`,
      no: `${CDN}/assets/locations/width/norwegen-haus-am-see.jpg`,
      pl: `${CDN}/assets/locations/width/polen-warschau.jpg`,
      pt: `${CDN}/assets/locations/width/portugal-algarve.jpg`,
      se: `${CDN}/assets/locations/width/schweden-ramsoe.jpg`,
      ch: `${CDN}/assets/locations/width/schweiz-first-bachalpsee.jpg`,
      sk: `${CDN}/assets/locations/width/slowakei-bratislava.jpg`,
      si: `${CDN}/assets/locations/width/slowenien-bleder-see.jpg`,
      es: `${CDN}/assets/locations/width/spanien-mallorca-palma.jpg`,
      za: `${CDN}/assets/locations/width/suedafrika-stellenbosch.jpg`,
      cz: `${CDN}/assets/locations/width/tschechische-republik-prag.jpg`,
      tr: `${CDN}/assets/locations/width/tuerkei-lykien-oeluedeniz.jpg`,
      hu: `${CDN}/assets/locations/width/ungarn-balaton.jpg`,
      us: `${CDN}/assets/locations/width/usa-san-francisco.jpg`,
      at: `${CDN}/assets/locations/width/oesterreich-hallstatt.jpg`,
    },
    /** Check Image ALT Texts (altTexts.json) if you change Country/Region Images  */
    backgroundImagesRegion: {
      de_83: `${CDN}/assets/locations/width/uckermark-barnim-deutschland-83.jpg`,
      de_89: `${CDN}/assets/locations/width/nordsee-inseln-deutschland-89.jpg`,
      de_100: `${CDN}/assets/locations/width/deutschland-schwarzwald-100.jpg`,
      de_104: `${CDN}/assets/locations/width/schwaebische-alb-deutschland-104.jpg`,
      de_106: `${CDN}/assets/locations/width/de-odenwald-bayern-106.jpg`,
      de_107: `${CDN}/assets/locations/width/pfalz-deutschland-107.jpg`,
      de_108: `${CDN}/assets/locations/width/hunsrueck-deutschland-burg-baldenau-108.jpg`,
      de_112: `${CDN}/assets/locations/width/deutschland-sauerland-112.jpg`,
      de_113: `${CDN}/assets/locations/width/teutoburger-wald-de-113.jpg`,
      de_125: `${CDN}/assets/locations/width/havelberg-havelland-flaeming-deutschland-125.jpg`,
      de_131: `${CDN}/assets/locations/width/westerbergland-deutschland-131.jpg`,
      de_147: `${CDN}/assets/locations/width/holsteinische-schweiz-147.jpg`,
      de_1043: `${CDN}/assets/locations/width/millstaetter-see-kaernten-oesterreich-1043.jpg`,
      de_1147: `${CDN}/assets/locations/width/wilhelmshaven-nordseekueste-de-1147.jpg`,
      de_1149: `${CDN}/assets/locations/width/deutschland-bremerhaven-1149.jpg`,
      de_1150: `${CDN}/assets/locations/width/deutschland-cuxhaven-1150.jpg`,
      de_1151: `${CDN}/assets/locations/width/nordfriesland-nordseekueste-deutschland-1151.jpg`,
      de_1152: `${CDN}/assets/locations/width/dithmarschen-nordseekueste-deutschland-1152.jpg`,
      de_1154: `${CDN}/assets/locations/width/mecklenburgische-ostseekueste-deutschland-1154.jpg`,
      de_1155: `${CDN}/assets/locations/width/deutschland-ostsee-fischland-darss-1155.jpg`,
      de_1156: `${CDN}/assets/locations/width/deutschland-stralsund-mecklenburg-vorpommern-1156.jpg`,
      de_1158: `${CDN}/assets/locations/width/ostfriesische-inseln-juist-deutschland-1158.jpg`,
      de_1159: `${CDN}/assets/locations/width/nordfriesische-inseln-nordsee-inseln-deutschland-1159.jpg`,
      de_1160: `${CDN}/assets/locations/width/deutschland-emden-ostfriesland-1160.jpg`,
      de_1161: `${CDN}/assets/locations/width/aurich-pilsum-ostfriesland-deutschland-1161.jpg`,
      de_1165: `${CDN}/assets/locations/width/schlei-region-eckernfoerder-bucht-holsteinische-ostseekueste-1165.jpg`,
      de_1166: `${CDN}/assets/locations/width/deutschland-luebecker-bucht-1166.jpg`,
      de_1167: `${CDN}/assets/locations/width/kieler-foerde-hafen-kiel-1167.jpg`,
      de_1168: `${CDN}/assets/locations/width/flensburger-foerde-flensburg-deutschland-1168.jpg`,
      de_1169: `${CDN}/assets/locations/width/leer-ostfriesland-deutschland-1169.jpg`,
      de_1170: `${CDN}/assets/locations/width/baden-wuerttemberg-heidelberg-1170.jpg`,
      de_1171: `${CDN}/assets/locations/width/bayern-neuschwanstein-1171.jpg`,
      de_1172: `${CDN}/assets/locations/width/brandenburg-uckersee-1172.jpg`,
      de_1173: `${CDN}/assets/locations/width/hessen-frankfurt-1173.jpg`,
      de_1174: `${CDN}/assets/locations/width/mecklenburg-vorpommern-binz-1174.jpg`,
      de_1175: `${CDN}/assets/locations/width/niedersachsen-pilsum-1175.jpg`,
      de_1176: `${CDN}/assets/locations/width/nordrhein-westfalen-duesseldorf-1176.jpg`,
      de_1177: `${CDN}/assets/locations/width/rheinland-pfalz-ruedesheim-1177.jpg`,
      de_1178: `${CDN}/assets/locations/width/saarland-saar-1178.jpg`,
      de_1179: `${CDN}/assets/locations/width/sachsen-dresden-1179.jpg`,
      de_118: `${CDN}/assets/locations/width/deutschland-niedersachsen-118.jpg`,
      de_1180: `${CDN}/assets/locations/width/sachsen-anhalt-magdeburg-1180.jpg`,
      de_1181: `${CDN}/assets/locations/width/schleswig-holstein-sylt-1181.jpg`,
      de_1182: `${CDN}/assets/locations/width/thueringen-schloss-burgk-an-der-saale-1182.jpg`,
      de_1196: `${CDN}/assets/locations/width/deutschland-westerwald-1196.jpg`,
      de_1204: `${CDN}/assets/locations/width/oberbayern-deutschland-1204.jpg`,
      de_1206: `${CDN}/assets/locations/width/chiemgau-deutschland-1206.jpg`,
      de_1207: `${CDN}/assets/locations/width/deutschland-bayern-starnberger-see-1207.jpg`,
      de_1210: `${CDN}/assets/locations/width/chiemsee-fraueninsel-deutschland-1210.jpg`,
      de_1214: `${CDN}/assets/locations/width/berchtesgaden-im-berchtesgadener-land-deutschland-1214.jpg`,
      de_1215: `${CDN}/assets/locations/width/deutschland-bayern-tegernsee-1215.jpg`,
      de_1217: `${CDN}/assets/locations/width/deutschland-zugspitze-1217.jpg`,
      de_1219: `${CDN}/assets/locations/width/fraenkische-schweiz-frankenalb-deutschland-1219.jpg`,
      de_1216: `${CDN}/assets/locations/width/chiemgauer-alpen-1216.jpg`,
      de_122: `${CDN}/assets/locations/width/deutschland-mecklenburg-vorpommern-122.jpg`,
      de_1221: `${CDN}/assets/locations/width/deutschland-bayern-altmuehlsee-1221.jpg`,
      de_1228: `${CDN}/assets/locations/width/altmuehltal-deutschland-1228.jpg`,
      de_133: `${CDN}/assets/locations/width/deutschland-harz-133.jpg`,
      de_137: `${CDN}/assets/locations/width/thueringer-wald-deutschland-137.jpg`,
      de_143: `${CDN}/assets/locations/width/bamberg-bayern-franken-143.jpg`,
      de_1565: `${CDN}/assets/locations/width/oberallgaeu-deutschland-1565.jpg`,
      de_1584: `${CDN}/assets/locations/width/deutschland-mueritz-hafen-1584.jpg`,
      de_1597: `${CDN}/assets/locations/width/deutschland-regensburger-land-1597.jpg`,
      de_1724: `${CDN}/assets/locations/width/deutschland-rhein-1724.jpg`,
      de_1739: `${CDN}/assets/locations/width/deutschland-bodensee-lindau-1739.jpg`,
      de_1822: `${CDN}/assets/locations/width/vulkaneifel-deutschland-1822.jpg`,
      de_1837: `${CDN}/assets/locations/width/deutschland-laachersee-1837.jpg`,
      de_1912: `${CDN}/assets/locations/width/kellerwald-edersee-nordhessen-deutschland-1912.jpg`,
      de_1914: `${CDN}/assets/locations/width/deutschland-twistesee-1914.jpg`,
      de_1916: `${CDN}/assets/locations/width/buedingen-hessen-de-1916.jpg`,
      de_2092: `${CDN}/assets/locations/width/deutschland-ostseespitze-wagrien-holsteinische-ostseekueste-heiligenhafen-2092.jpg`,
      de_2782: `${CDN}/assets/locations/width/ostsee-ruegen-2782.jpg`,
      de_2783: `${CDN}/assets/locations/width/nordsee-ostfriesland-2783.jpg`,
      de_58: `${CDN}/assets/locations/width/deutschland-bremen-58.jpg`,
      de_61: `${CDN}/assets/locations/width/hamburg-61.jpg`,
      de_66: `${CDN}/assets/locations/width/berlin-66.jpg`,
      de_71: `${CDN}/assets/locations/width/allgaeu-deutschland-71.jpg`,
      de_72: `${CDN}/assets/locations/width/deutschland-bayern-alpen-72.jpg`,
      de_74: `${CDN}/assets/locations/width/deutschland-bayerischer-wald-74.jpg`,
      de_76: `${CDN}/assets/locations/width/fichtelgebirge-deutschland-76.jpg`,
      de_77: `${CDN}/assets/locations/width/deutschland-erzgebirge-77.jpg`,
      de_78: `${CDN}/assets/locations/width/saechsische-schweiz-sachsen-deutschland-bastei-78.jpg`,
      de_79: `${CDN}/assets/locations/width/lausitz-brandenburg-de-79.jpg`,
      de_80: `${CDN}/assets/locations/width/spreewald-deutschland-80.jpg`,
      de_90: `${CDN}/assets/locations/width/ostfriesland-juist-deutschland-90.jpg`,
      de_91: `${CDN}/assets/locations/width/emsland-meppen-deutschland-91.jpg`,
      de_92: `${CDN}/assets/locations/width/muensterland-muenster-deutschland-92.jpg`,
      de_827: `${CDN}/assets/locations/width/amrum-nordsee-inseln-deutschland-827.jpg`,
      de_829: `${CDN}/assets/locations/width/borkum-nordsee-inseln-deutschland-829.jpg`,
      de_830: `${CDN}/assets/locations/width/deutschland-ostsee-fehmarn-830.jpg`,
      de_831: `${CDN}/assets/locations/width/foehr-strandkoerbe-nordseeinseln-deutschland-831.jpg`,
      de_832: `${CDN}/assets/locations/width/helgoland-nordsee-inseln-de-832.jpg`,
      de_833: `${CDN}/assets/locations/width/deutschland-hiddensee-833.jpg`,
      de_835: `${CDN}/assets/locations/width/deutschland-langeoog-nordsee-835.jpg`,
      de_837: `${CDN}/assets/locations/width/pellworm-nordsee-inseln-de-837.jpg`,
      de_838: `${CDN}/assets/locations/width/poel-ostseeinseln-deutschland-838.jpg`,
      de_839: `${CDN}/assets/locations/width/deutschland-ruegen-strand-839.jpg`,
      de_841: `${CDN}/assets/locations/width/sylt-nordseeinsel-deutschland-841.jpg`,
      de_842: `${CDN}/assets/locations/width/deutschland-usedom-urlaub-842.jpg`,
      de_843: `${CDN}/assets/locations/width/wangerooge-nordsee-inseln-deutschland-843.jpg`,
      de_844: `${CDN}/assets/locations/width/deutschland-ostsee-usedom-844.jpg`,
      de_85: `${CDN}/assets/locations/width/deutschland-urlaub-ostsee-85.jpg`,
      de_88: `${CDN}/assets/locations/width/deutschland-nordsee-leuchtturm-88.jpg`,
      de_96: `${CDN}/assets/locations/width/deutschland-eifel-96.jpg`,
      de_954: `${CDN}/assets/locations/width/vogtland-deutschland-954.jpg`,
      de_967: `${CDN}/assets/locations/width/deutschland-mittelrhein-967.jpg`,
      de_97: `${CDN}/assets/locations/width/deutschland-mosel-97.jpg`,
      dk_34: `${CDN}/assets/locations/width/bornholm-daenemark-34.jpg`,
      dk_1529: `${CDN}/assets/locations/width/juetland-daenemark-1529.jpg`,
      fi_152: `${CDN}/assets/locations/width/finnland-helsinki-152.jpg`,
      fi_151: `${CDN}/assets/locations/width/finnland-lappland-151.jpg`,
      fr_158: `${CDN}/assets/locations/width/frankreich-bretagne-158.jpg`,
      fr_162: `${CDN}/assets/locations/width/frankreich-cote-d-azur-provence-alpes-cote-d-azur-162.jpg`,
      fr_165: `${CDN}/assets/locations/width/frankreich-elsass-165.jpg`,
      fr_155: `${CDN}/assets/locations/width/frankreich-franzoesische-alpen-155.jpg`,
      fr_172: `${CDN}/assets/locations/width/frankreich-korsika-172.jpg`,
      fr_182: `${CDN}/assets/locations/width/frankreich-nord-pas-de-calais-182.jpg`,
      fr_194: `${CDN}/assets/locations/width/frankreich-paris-194.jpg`,
      fr_154: `${CDN}/assets/locations/width/frankreich-provence-154.jpg`,
      fr_186: `${CDN}/assets/locations/width/frankreich-provence-alpes-cote-d-azur-186.jpg`,
      fr_173: `${CDN}/assets/locations/width/frankreich-suedteil-korsika-173.jpg`,
      fr_1532: `${CDN}/assets/locations/width/suedfrankreich-frankreich-cote-dazur-1532.jpg`,
      gr_259: `${CDN}/assets/locations/width/griechenland-kefalonia-ionische-inseln-259.jpg`,
      gr_263: `${CDN}/assets/locations/width/griechenland-kos-dodekanes-263.jpg`,
      gr_265: `${CDN}/assets/locations/width/griechenland-kreta-265.jpg`,
      gr_314: `${CDN}/assets/locations/width/griechenland-kreta-314.jpg`,
      gr_269: `${CDN}/assets/locations/width/griechenland-lefkada-269.jpg`,
      gr_291: `${CDN}/assets/locations/width/griechenland-peloponnes-291.jpg`,
      gr_297: `${CDN}/assets/locations/width/griechenland-rhodos-297.jpg`,
      gr_1536: `${CDN}/assets/locations/width/griechenland-sithonia-peninsula-chalkidiki-1536.jpg`,
      gr_309: `${CDN}/assets/locations/width/griechenland-skiathos-sporaden-309.jpg`,
      gr_316: `${CDN}/assets/locations/width/griechenland-thassos-aegaeische-inseln-316.jpg`,
      gr_324: `${CDN}/assets/locations/width/griechenland-zakynthos-ionische-inseln-324.jpg`,
      gb_197: `${CDN}/assets/locations/width/grossbritannien-cornwall-197.jpg`,
      it_1306: `${CDN}/assets/locations/width/italien-adriakueste-1306.jpg`,
      it_710: `${CDN}/assets/locations/width/italien-apulien-710.jpg`,
      it_715: `${CDN}/assets/locations/width/marche-italien-715.jpg`,
      it_728: `${CDN}/assets/locations/width/lucca-toskana-italien-728.jpg`,
      it_1236: `${CDN}/assets/locations/width/italien-bari-apulien-1236.jpg`,
      it_757: `${CDN}/assets/locations/width/italien-comer-see-lombardei-757.jpg`,
      it_731: `${CDN}/assets/locations/width/pisa-toskana-italien-731.jpg`,
      it_738: `${CDN}/assets/locations/width/italien-elba-toskana-738.jpg`,
      it_751: `${CDN}/assets/locations/width/italien-gardasee-751.jpg`,
      it_756: `${CDN}/assets/locations/width/italien-lago-d-iseo-lombardei-756.jpg`,
      it_763: `${CDN}/assets/locations/width/italien-lago-di-lugano-lombardei-763.jpg`,
      it_766: `${CDN}/assets/locations/width/italien-lago-maggiore-766.jpg`,
      it_703: `${CDN}/assets/locations/width/italien-ligurien-703.jpg`,
      it_1274: `${CDN}/assets/locations/width/italien-meraner-land-1274.jpg`,
      it_1302: `${CDN}/assets/locations/width/dolomiten-italien-1302.jpg`,
      it_698: `${CDN}/assets/locations/width/italien-piemont-698.jpg`,
      it_742: `${CDN}/assets/locations/width/italien-rom-742.jpg`,
      it_714: `${CDN}/assets/locations/width/italien-sardinien-714.jpg`,
      it_700: `${CDN}/assets/locations/width/italien-suedtirol-700.jpg`,
      it_704: `${CDN}/assets/locations/width/italien-toskana-704.jpg`,
      it_713: `${CDN}/assets/locations/width/sizilien-italien-713.jpg`,
      it_744: `${CDN}/assets/locations/width/italien-verona-744.jpg`,
      ch_960: `${CDN}/assets/locations/width/bergell-960.jpg`,
      it_1273: `${CDN}/assets/locations/width/italien-vinschgau-suedtirol-1273.jpg`,
      hr_386: `${CDN}/assets/locations/width/kroatien-dalmatien-386.jpg`,
      hr_387: `${CDN}/assets/locations/width/kroatien-dugi-otok-387.jpg`,
      hr_893: `${CDN}/assets/locations/width/kroatien-inseln-893.jpg`,
      hr_389: `${CDN}/assets/locations/width/kroatien-istrien-389.jpg`,
      hr_377: `${CDN}/assets/locations/width/kroatien-krk-377.jpg`,
      hr_390: `${CDN}/assets/locations/width/korcula-kroatische-inseln-390.jpg`,
      hr_396: `${CDN}/assets/locations/width/kroatien-pasman-396.jpg`,
      hr_395: `${CDN}/assets/locations/width/pag-kroatische-inseln-kroatien-395.jpg`,
      hr_397: `${CDN}/assets/locations/width/kroatien-rab-397.jpg`,
      hr_2413: `${CDN}/assets/locations/width/kvarner-bucht-kroatien-2413.jpg`,
      lu_1614: `${CDN}/assets/locations/width/luxemburg-echternach-1614.jpg`,
      mt_822: `${CDN}/assets/locations/width/822-malta-hauptinsel.jpg`,
      mt_823: `${CDN}/assets/locations/width/823-malta-gozo.jpg`,
      nl_331: `${CDN}/assets/locations/width/kinderdijk-holland-331.jpg`,
      nl_336: `${CDN}/assets/locations/width/niederlande-limburg-336.jpg`,
      nl_338: `${CDN}/assets/locations/width/zeeland-niederlande-zeeland-bruecke-338.jpg`,
      nl_339: `${CDN}/assets/locations/width/niederlande-noord-brabant-339.jpg`,
      nl_1477: `${CDN}/assets/locations/width/niederlande-texel-friesische-inseln-1477.jpg`,
      nl_1480: `${CDN}/assets/locations/width/ameland-friesische-inseln-niederlande-1480.jpg`,
      no_406: `${CDN}/assets/locations/width/norwegen-hardangerfjord-406.jpg`,
      no_410: `${CDN}/assets/locations/width/norwegen-oslo-oslofjord-410.jpg`,
      no_412: `${CDN}/assets/locations/width/norwegen-sognefjord-nordfjord-412.jpg`,
      no_414: `${CDN}/assets/locations/width/norwegen-trondheimfjord-nord-414.jpg`,
      at_416: `${CDN}/assets/locations/width/steiermark-oesterreich-416.jpg`,
      at_418: `${CDN}/assets/locations/width/tirol-oesterreich-418.jpg`,
      at_420: `${CDN}/assets/locations/width/salzburger-land-oesterreich-420.jpg`,
      at_421: `${CDN}/assets/locations/width/kaernten-hermagor-oesterreich-421.jpg`,
      at_1129: `${CDN}/assets/locations/width/oesterreich-achensee-1129.jpg`,
      at_1137: `${CDN}/assets/locations/width/oesterreich-gesaeuse-1137.jpg`,
      at_951: `${CDN}/assets/locations/width/wien-oesterreich-951.jpg`,
      at_984: `${CDN}/assets/locations/width/oesterreich-innsbruck-984.jpg`,
      at_993: `${CDN}/assets/locations/width/oetztal-tirol-oesterreich-993.jpg`,
      at_1008: `${CDN}/assets/locations/width/oesterreich-obertauern-1008.jpg`,
      at_1022: `${CDN}/assets/locations/width/zell-am-see-pinzgau-oesterreich-1022.jpg`,
      at_1024: `${CDN}/assets/locations/width/oesterreich-kleinwalsertal-vorarlberg-1024.jpg`,
      at_1028: `${CDN}/assets/locations/width/oesterreich-vorarlberg-1028.jpg`,
      at_1078: `${CDN}/assets/locations/width/neusiedler-see-burgenland-oesterreich-1078.jpg`,
      at_1130: `${CDN}/assets/locations/width/oesterreich-serfaus-fiss-ladis-tirol-1130.jpg`,
      at_1133: `${CDN}/assets/locations/width/stubaital-oesterreich-1133.jpg`,
      at_1134: `${CDN}/assets/locations/width/oesterreich-tannheimertal-1134.jpg`,
      at_1136: `${CDN}/assets/locations/width/montafon-vorarlberg-oesterreich-1136.jpg`,
      at_1140: `${CDN}/assets/locations/width/woerthersee-kaernten-oesterreich-1140.jpg`,
      at_999: `${CDN}/assets/locations/width/oesterreich-wilder-kaiser-999.jpg`,
      at_980: `${CDN}/assets/locations/width/oesterreich-zillertal-980.jpg`,
      at_990: `${CDN}/assets/locations/width/mayrhofen-tirol-oesterreich-990.jpg`,
      at_1002: `${CDN}/assets/locations/width/oesterreich-zillertal-arena-tirol-1002.jpg`,
      at_1057: `${CDN}/assets/locations/width/ossiacher-see-kaernten-oesterreich-1057.jpg`,
      at_1080: `${CDN}/assets/locations/width/attersee-salzkammergut-oesterreich-1080.jpg`,
      pl_1439: `${CDN}/assets/locations/width/polen-danziger-bucht-1439.jpg`,
      pl_1440: `${CDN}/assets/locations/width/polen-hel-1440.jpg`,
      pl_691: `${CDN}/assets/locations/width/polen-masuren-691.jpg`,
      pl_1446: `${CDN}/assets/locations/width/polen-masurien-panorama-1446.jpg`,
      pl_1436: `${CDN}/assets/locations/width/polen-ostsee-1436.jpg`,
      pl_685: `${CDN}/assets/locations/width/polen-ostsee-strand-685.jpg`,
      pl_1449: `${CDN}/assets/locations/width/polen-stettiner-haff-1449.jpg`,
      pl_772: `${CDN}/assets/locations/width/portugal-madeira-772.jpg`,
      pt_430: `${CDN}/assets/locations/width/algarve-portugal-430.jpg`,
      ro_436: `${CDN}/assets/locations/width/rumaenien-schwarzmeer-436.jpg`,
      ro_439: `${CDN}/assets/locations/width/rumaenien-siebenbuergen-439.jpg`,
      gb_214: `${CDN}/assets/locations/width/schottland-highlands-214.jpg`,
      se_462: `${CDN}/assets/locations/width/malmo-skane-laen-suedschweden-schweden-462.jpg`,
      se_464: `${CDN}/assets/locations/width/schweden-goeteborg-464.jpg`,
      se_958: `${CDN}/assets/locations/width/schweden-goeteborg-958.jpg`,
      se_956: `${CDN}/assets/locations/width/schweden-stockholm-provinz-956.jpg`,
      se_959: `${CDN}/assets/locations/width/suedschweden-karlskrona-959.jpg`,
      ch_477: `${CDN}/assets/locations/width/schweiz-berner-oberland-477.jpg`,
      ch_1426: `${CDN}/assets/locations/width/schweiz-brienzersee-1426.jpg`,
      ch_474: `${CDN}/assets/locations/width/schweiz-genfersee-474.jpg`,
      ch_469: `${CDN}/assets/locations/width/schweiz-graubuenden-469.jpg`,
      ch_1416: `${CDN}/assets/locations/width/locarno-lago-maggiore-schweiz-schweiz-1416.jpg`,
      ch_1422: `${CDN}/assets/locations/width/schweiz-lenzerheide-1422.jpg`,
      ch_768: `${CDN}/assets/locations/width/schweiz-lugano-urlaub-tessin-768.jpg`,
      ch_880: `${CDN}/assets/locations/width/schweiz-oberengadin-880.jpg`,
      ch_969: `${CDN}/assets/locations/width/schweiz-samnaun-graubuenden-969.jpg`,
      ch_1373: `${CDN}/assets/locations/width/schweiz-surselva-flims-laax-falera-1373.jpg`,
      ch_1379: `${CDN}/assets/locations/engadin-schweiz-1379.jpg,`,
      ch_468: `${CDN}/assets/locations/width/schweiz-tessin-468.jpg`,
      ch_855: `${CDN}/assets/locations/width/schweiz-thunersee-855.jpg`,
      ch_867: `${CDN}/assets/locations/width/schweiz-thurgau-867.jpg`,
      ch_866: `${CDN}/assets/locations/width/schweiz-toggenburg-866.jpg`,
      ch_1414: `${CDN}/assets/locations/width/schweiz-verzascatal-1414.jpg`,
      ch_465: `${CDN}/assets/locations/width/schweiz-wallis-465.jpg`,
      ch_964: `${CDN}/assets/locations/width/schweiz-weggis-vitznau-rigi-964.jpg`,
      ch_1475: `${CDN}/assets/locations/width/schweiz-zermatt-1475.jpg`,
      ch_467: `${CDN}/assets/locations/width/schweiz-zuerichsee-467.jpg`,
      ch_871: `${CDN}/assets/locations/width/schweiz-zugersee-871.jpg`,
      ch_476: `${CDN}/assets/categories/width/fribourg-min.jpg`,
      ch_466: `${CDN}/assets/categories/width/ostschweiz-schweiz-min.jpg`,
      ch_470: `${CDN}/assets/categories/width/zentralschweiz-min.jpg`,
      ch_472: `${CDN}/assets/categories/width/schweizer-mittelland-min.jpg`,
      ch_1350: `${CDN}/assets/categories/width/jura-gebirge-schweiz-1-min.jpg`,
      es_511: `${CDN}/assets/locations/width/sevilla-andalusien-511.jpg`,
      es_514: `${CDN}/assets/locations/width/spanien-costa-blanca-514.jpg`,
      es_515: `${CDN}/assets/locations/width/spanien-costa-brava-515.jpg`,
      es_517: `${CDN}/assets/locations/width/costa-daurada-spanien-517.jpg`,
      es_534: `${CDN}/assets/locations/width/spanien-barcelona-provinz-katalonien-534.jpg`,
      es_551: `${CDN}/assets/locations/width/spanien-fuerteventura-kanarische-inseln-551.jpg`,
      es_553: `${CDN}/assets/locations/width/spanien-gran-canaria-kanarische-inseln-553.jpg`,
      es_564: `${CDN}/assets/locations/width/spanien-katalonien-564.jpg`,
      es_566: `${CDN}/assets/locations/width/la-palma-kanarische-inseln-spanien-566.jpg`,
      es_573: `${CDN}/assets/locations/width/spanien-mallorca-palma-573.jpg`,
      es_574: `${CDN}/assets/locations/width/spanien-menorca-574.jpg`,
      es_575: `${CDN}/assets/locations/width/malaga-provinz-andalusien-spanien-575.jpg`,
      es_587: `${CDN}/assets/locations/width/spanien-teneriffa-kanarische-inseln-587.jpg`,
      es_543: `${CDN}/assets/locations/width/costa-del-sol-spanien-543.jpg`,
      es_568: `${CDN}/assets/locations/width/spanien-lanzarote-568.jpg`,
      es_1462: `${CDN}/assets/locations/width/ibiza-spanien-1462.jpg`,
      es_1553: `${CDN}/assets/locations/width/barcelona-barcelona-provinz-spanien-1553.jpg`,
      cz_598: `${CDN}/assets/locations/width/tschechische-republik-pilsen-598.jpg`,
      hu_620: `${CDN}/assets/locations/width/ungarn-balaton-620.jpg`,
      hu_624: `${CDN}/assets/locations/width/ungarn-theiss-see-624.jpg`,
      us_657: `${CDN}/assets/locations/width/usa-florida-657.jpg`,
    },
    backgroundImagesPath: [ // background images defined by path EDVUE-598
      { offerID: 122, path: '/skiurlaub', img: `${CDN}/assets/categories/width/skiurlaub.jpg` },
      { offerID: 586, path: '/besondere-angebote/586/angelurlaub-deutschland', img: `${CDN}/assets/categories/width/deutschland-angeln.jpg` },
      { offerID: 88, path: '/nordseeurlaub-mit-hund', img: `${CDN}/assets/categories/width/hund-an-der-nordsee.jpg` },
      // { offerID: 49, path: '/besondere-angebote/49/poolhaeuser', img: `${CDN}/assets/categories/width/ferienhaus-luxus-pool.jpg` },
      { offerID: 49, path: '/besondere-angebote/49/poolhaeuser', img: `${CDN}/assets/categories/width/ferienhaeuser-mit-pool.jpg` },
      { path: '/ferienhaus-silvester', img: `${CDN}/assets/categories/width/silvester.jpg` }, // 404
      { offerID: 26, path: '/besondere-angebote/26/skihuetten', img: `${CDN}/assets/categories/width/skiurlaub-huette.jpg` },
      { name: 'Lastminute', path: '/lastminute', img: `${CDN}/assets/categories/width/lastminute.jpg` },
      { offerID: 200, path: '/besondere-angebote/200/ferienhaus-am-see', img: `${CDN}/assets/categories/width/ferienhaus-see.jpg` },
      { name: 'Pets', path: '/urlaub-mit-hund', img: `${CDN}/assets/categories/width/urlaub-hund.jpg` },
      { offerID: 87, path: '/ostseeurlaub-mit-hund', img: `${CDN}/assets/categories/width/hund-an-der-ostsee.jpg` },
      { offerID: 9, path: '/besondere-angebote/9/huetten-und-chalets', img: `${CDN}/assets/categories/width/chalet.jpg` },
      { offerID: 487, path: '/besondere-angebote/487/huetten-deutschland', img: `${CDN}/assets/categories/width/huetten-in-deutschland.jpg` },
      { offerID: 743, path: '/besondere-angebote/743/bayern-am-see', img: `${CDN}/assets/categories/width/deuschland-bayern-eibsee.jpg` },
      { offerID: 649, path: '/besondere-angebote/649/kroatien-mit-hund', img: `${CDN}/assets/categories/width/kroatien-mit-hund.jpg` },
      { offerID: 884, path: '/besondere-angebote/884/bungalow-mieten', img: `${CDN}/assets/categories/width/bungalow.jpg` },
      { offerID: 556, path: '/besondere-angebote/556/sauna', img: `${CDN}/assets/categories/width/sauna.jpg` },
      { offerID: 12, path: '/besondere-angebote/12/hausboote', img: `${CDN}/assets/categories/width/hausboot.jpg` },
      { offerID: 744, path: '/besondere-angebote/744/brandenburg-am-see', img: `${CDN}/assets/categories/width/brandenburg-am-see.jpg` },
      { offerID: 89, path: '/toskanaurlaub-mit-hund', img: `${CDN}/assets/categories/width/toskana-mit-hund.jpg` },
      { offerID: 896, path: '/besondere-angebote/896/bungalows-an-der-ostsee', img: `${CDN}/assets/categories/width/ostsee-timmendorfer-strand.jpg` },
      { offerID: 486, path: '/besondere-angebote/486/huetten-oesterreich', img: `${CDN}/assets/categories/width/oesterreich-chalet.jpg` },
      { offerID: 585, path: '/besondere-angebote/585/angelurlaub-daenemark', img: `${CDN}/assets/categories/width/daenemark-norre-vorupor.jpg` },
      { offerID: 665, path: '/besondere-angebote/665/angelurlaub-suedschweden', img: `${CDN}/assets/categories/width/angelurlaub-suedschweden.jpg` },
      { offerID: 593, path: '/besondere-angebote/593/angelurlaub-polen', img: `${CDN}/assets/categories/width/angeln-see-polen.jpg` },
      { offerID: 10, path: '/besondere-angebote/10/behindertengerechte-ferienhaeuser', img: `${CDN}/assets/categories/width/barrierefrei.jpg` },
      { offerID: 6, path: '/besondere-angebote/6/aussergewoehnliche-ferienhaeuser-und-ferienwohnungen', img: `${CDN}/assets/categories/width/aussergewoehnliche-unterkuenfte.jpg` },
      { offerID: 658, path: '/besondere-angebote/658/angelurlaub-brandenburg', img: `${CDN}/assets/categories/width/angeln-deutschland.jpg` },
      { offerID: 622, path: '/besondere-angebote/622/oesterreich-am-see', img: `${CDN}/assets/categories/width/oesterreich-achensee.jpg` },
      { offerID: 661, path: '/besondere-angebote/661/angelurlaub-mecklenburg-vorpommern', img: `${CDN}/assets/categories/width/angeln-urlaub.jpg` },
      { offerID: 838, path: '/besondere-angebote/838/suedtirol-mit-hund', img: `${CDN}/assets/categories/width/suedtirol-mit-hund.jpg` },
      { offerID: 742, path: '/besondere-angebote/742/toskana-am-meer', img: `${CDN}/assets/categories/width/toskana-am-meer-porto-ercole.jpg` },
      { offerID: 828, path: '/besondere-angebote/828/mecklenburgische-seenplatte-mit-hund', img: `${CDN}/assets/categories/width/mecklenburg-urlaub-hund.jpg` },
      { offerID: 755, path: '/besondere-angebote/755/toskana-mit-pool', img: `${CDN}/assets/categories/width/toskana-mit-pool.jpg` },
      { offerID: 635, path: '/besondere-angebote/635/luxus-daenemark', img: `${CDN}/assets/categories/width/luxus-daenemark.jpg` },
      { offerID: 591, path: '/besondere-angebote/591/angelurlaub-norwegen', img: `${CDN}/assets/categories/width/angeln-norwegen.jpg` },
      { offerID: 180, path: '/besondere-angebote/180/ferienhaus-am-meer', img: `${CDN}/assets/categories/width/ferienhaus-am-meer.jpg` },
      { offerID: 647, path: '/besondere-angebote/647/frankreich-mit-hund', img: `${CDN}/assets/categories/width/frankreich-mit-hund-cap-ferre-aquitaine.jpg` },
      { offerID: 638, path: '/besondere-angebote/638/luxus-kroatien', img: `${CDN}/assets/categories/width/kroatien-luxus.jpg` },
      { offerID: 70, path: '/besondere-angebote/70/mobile-feriendomizile', img: `${CDN}/assets/categories/width/mobile-ferienhaus.jpg` },
      { offerID: 652, path: '/besondere-angebote/652/polen-mit-hund', img: `${CDN}/assets/categories/width/polen-hund-ostsee.jpg` },
      { offerID: 660, path: '/besondere-angebote/660/angelurlaub-mecklenburgische-seenplatte', img: `${CDN}/assets/categories/width/mecklenburg-angeln.jpg` },
      { offerID: 787, path: '/besondere-angebote/787/luxus-sylt', img: `${CDN}/assets/categories/width/sylt-luxus.jpg` },
      { offerID: 784, path: '/besondere-angebote/784/luxus-gardasee', img: `${CDN}/assets/categories/width/luxus-gardasee.jpg` },
      { offerID: 588, path: '/besondere-angebote/588/angelurlaub-frankreich', img: `${CDN}/assets/categories/width/ferienhaus-am-meer.jpg` },
      { offerID: 2, path: '/besondere-angebote/2/weingueter', img: `${CDN}/assets/categories/width/urlaub-auf-weingut.jpg` },
      { offerID: 895, path: '/besondere-angebote/895/bungalows-in-italien', img: `${CDN}/assets/categories/width/italien-luxus.jpg` },
      { name: 'HomeLanding', path: '/ferienhaus-suche', img: `${CDN}/assets/categories/width/ferienhaus-suchen.jpg` },
      { path: '/ferienhaus-weihnachten', img: `${CDN}/assets/categories/width/weihnachten.jpg` }, // 404
      { offerID: 829, path: '/besondere-angebote/829/mecklenburg-vorpommern-mit-hund', img: `${CDN}/assets/categories/width/mecklenburg-mit-hund.jpg` },
      { offerID: 737, path: '/besondere-angebote/737/mallorca-mit-meerblick', img: `${CDN}/assets/categories/width/mallorca-mit-meerblick.jpg` },
      { offerID: 73, path: '/besondere-angebote/73/ferienhaus-silvester', img: `${CDN}/assets/categories/width/silvester-im-ferienhaus-73.jpg` },
      { offerID: 640, path: '/besondere-angebote/640/luxus-schweden', img: `${CDN}/assets/categories/width/luxusunterkuenfte-in-schweden.jpg` },
      { offerID: 631, path: '/besondere-angebote/631/finca-spanien', img: `${CDN}/assets/categories/width/finca-spanien.jpg` },
      { offerID: 123, path: '/besondere-angebote/123/skiurlaub-oesterreich', img: `${CDN}/assets/categories/width/skiurlaub-oesterreich.jpg` },
      { offerID: 614, path: '/besondere-angebote/614/deutschland-am-meer', img: `${CDN}/assets/categories/width/ostsee-meerblick.jpg` },
      { offerID: 897, path: '/besondere-angebote/897/bungalows-auf-ruegen', img: `${CDN}/assets/categories/width/ruegen.jpg` },
      { offerID: 637, path: '/besondere-angebote/637/luxus-italien', img: `${CDN}/assets/categories/width/luxus-italien.jpg` },
      { offerID: 749, path: '/besondere-angebote/749/costa-brava-mit-pool', img: `${CDN}/assets/categories/width/costa-brava-mit-pool.jpg` },
      { offerID: 899, path: '/besondere-angebote/899/bungalows-auf-usedom', img: `${CDN}/assets/categories/width/usedom.jpg` },
      { offerID: 662, path: '/besondere-angebote/662/angelurlaub-niedersachsen', img: `${CDN}/assets/categories/width/angelurlaub-niedersachsen.jpg` },
      { name: 'SearchSkiCH', path: '/skiurlaub-schweiz', img: `${CDN}/assets/categories/width/skiurlaub-schweiz.jpg` },
      { offerID: 62, path: '/besondere-angebote/62/gruppenurlaub-im-winter', img: `${CDN}/assets/categories/width/gruppenurlaub-im-winter.jpg` },
      { offerID: 786, path: '/besondere-angebote/786/luxus-ruegen', img: `${CDN}/assets/categories/width/luxus-ruegen.jpg` },
      { offerID: 898, path: '/besondere-angebote/898/bungalows-am-gardasee', img: `${CDN}/assets/categories/width/bungalows-am-gardasee.jpg` },
      { offerID: 15, path: '/besondere-angebote/15/angelurlaub', img: `${CDN}/assets/categories/width/angelurlaub.jpg` },
      { offerID: 841, path: '/besondere-angebote/841/tirol-mit-hund', img: `${CDN}/assets/categories/width/tirol-mit-hund.jpg` },
      { offerID: 639, path: '/besondere-angebote/639/luxus-oesterreich', img: `${CDN}/assets/categories/width/luxus-in-oesterreich.jpg` },
      { offerID: 555, path: '/besondere-angebote/555/meerblick', img: `${CDN}/assets/categories/width/meerblick.jpg` },
      { name: 'SearchSkiFR', path: '/skiurlaub-frankreich', img: `${CDN}/assets/categories/width/skilift-frankreich.jpg` },
      { offerID: 894, path: '/besondere-angebote/894/bungalows-in-holland', img: `${CDN}/assets/categories/width/holland.jpg` },
      { offerID: 900, path: '/besondere-angebote/900/bungalows-in-kroatien', img: `${CDN}/assets/categories/width/bungalow-pool.jpg` },
      { offerID: 642, path: '/besondere-angebote/642/skiurlaub-polen', img: `${CDN}/assets/categories/width/zakopane-polen-ski.jpg` },
      { offerID: 589, path: '/besondere-angebote/589/angelurlaub-irland', img: `${CDN}/assets/categories/width/angelurlaub-irland.jpg` },
      { offerID: 597, path: '/besondere-angebote/597/chalet-schweiz', img: `${CDN}/assets/categories/width/schweiz-ski-urlaub.jpg` },
      { offerID: 815, path: '/besondere-angebote/815/bayern-mit-hund', img: `${CDN}/assets/categories/width/bayern-hund-see.jpg` },
      { path: '/reisetipps/ferientermine/land/DK', img: `${CDN}/assets/categories/width/vagar-dk.jpg` }, // 404
      { offerID: 613, path: '/besondere-angebote/613/deutschland-mit-meerblick', img: `${CDN}/assets/categories/width/deutschland-ostsee-ferienhaus-strand.jpg` },
      { name: 'Family', path: '/familienurlaub', img: `${CDN}/assets/categories/width/familie-urlaub.jpg` },
      { offerID: 19, path: '/besondere-angebote/19/wellnessurlaub', img: `${CDN}/assets/categories/width/wellness.jpg` },
      { offerID: 790, path: '/besondere-angebote/790/skiurlaub-bayern', img: `${CDN}/assets/categories/width/skiurlaub-bayern.jpg` },
      { offerID: 904, path: '/besondere-angebote/904/bungalows-in-graal-mueritz', img: `${CDN}/assets/categories/width/graal-mueritz.jpg` },
      { offerID: 620, path: '/besondere-angebote/620/kroatien-mit-meerblick', img: `${CDN}/assets/categories/width/kroatien-zadar-mit-meerblick.jpg` },
      { offerID: 208, path: '/besondere-angebote/208/familienurlaub-pfingsten', img: `${CDN}/assets/categories/width/familienurlaub-pfingsten.jpg` },
      { name: 'HolidayParks', path: '/ferienparks', img: `${CDN}/assets/categories/width/ferienpark.jpg` },
      { name: 'WinterVacation', path: '/winterurlaub', img: `${CDN}/assets/categories/width/skiurlaub-gruppe.jpg` },
      { offerID: 618, path: '/besondere-angebote/618/italien-mit-meerblick', img: `${CDN}/assets/categories/width/italien-positano-amalfi-meerblick.jpg` },
      { offerID: 817, path: '/besondere-angebote/817/brandenburg-mit-hund', img: `${CDN}/assets/categories/width/brandenburg-mit-hund.jpg` },
      { offerID: 7, path: '/besondere-angebote/7/schloesser-und-palazzi', img: `${CDN}/assets/categories/width/schloss.jpg` },
      { offerID: 654, path: '/besondere-angebote/654/schweiz-mit-hund', img: `${CDN}/assets/categories/width/schweiz-mit-hund.jpg` },
      { offerID: 127, path: '/besondere-angebote/127/skiurlaub-italien', img: `${CDN}/assets/categories/width/skiurlaub-italien.jpg` },
      { offerID: 117, path: '/besondere-angebote/117/klosterurlaub', img: `${CDN}/assets/categories/width/kloster.jpg` },
      { offerID: 641, path: '/besondere-angebote/641/skiurlaub-norwegen', img: `${CDN}/assets/categories/width/skiurlaub-norwegen.jpg` },
      { offerID: 16, path: '/besondere-angebote/16/reiterferien', img: `${CDN}/assets/categories/width/reitenferien.jpg` },
      { offerID: 800, path: '/besondere-angebote/800/skiurlaub-riesengebirge', img: `${CDN}/assets/categories/width/riesengebirge-skiurlaub.jpg` },
      { offerID: 812, path: '/besondere-angebote/812/skiurlaub-zillertal', img: `${CDN}/assets/categories/width/zillertal.jpg` },
      { offerID: 803, path: '/besondere-angebote/803/skiurlaub-salzburger-land', img: `${CDN}/assets/categories/width/salzburgerland-ski.jpg` },
      { offerID: 199, path: '/besondere-angebote/199/familienurlaub-ostern', img: `${CDN}/assets/categories/width/ostern.jpg` },
      { offerID: 891, path: '/besondere-angebote/891/strandurlaub-italien', img: `${CDN}/assets/categories/width/strandurlaub-italien.jpg` },
      { offerID: 191, path: '/besondere-angebote/191/wellnessurlaub-im-winter', img: `${CDN}/assets/categories/width/wellness-winter.jpg` },
      { offerID: 780, path: '/besondere-angebote/780/gruppenunterkuenfte-bayern', img: `${CDN}/assets/categories/width/gruppenurlaub-in-bayern.jpg` },
      { offerID: 801, path: '/besondere-angebote/801/skiurlaub-saalbach-hinterglemm', img: `${CDN}/assets/categories/width/oesterreich-saalbach-hinterglemm.jpg` },
      { offerID: 632, path: '/besondere-angebote/632/gruppenunterkuenfte-oesterreich', img: `${CDN}/assets/categories/width/gruppenurlaub-in-oesterreich.jpg` },
      { offerID: 636, path: '/besondere-angebote/636/luxus-deutschland', img: `${CDN}/assets/categories/width/luxus-deutschland.jpg` },
      { offerID: 5, path: '/besondere-angebote/5/luxusferienhaeuser', img: `${CDN}/assets/categories/width/luxus-ferienhaus.jpg` },
      { offerID: 820, path: '/besondere-angebote/820/gardasee-mit-hund', img: `${CDN}/assets/categories/width/italien-gardasee.jpg` },
      { offerID: 822, path: '/besondere-angebote/822/harz-mit-hund', img: `${CDN}/assets/categories/width/harz.jpg` },
      { offerID: 833, path: '/besondere-angebote/833/ruegen-mit-hund', img: `${CDN}/assets/categories/width/ruegen-mit-hund.jpg` },
      { offerID: 590, path: '/besondere-angebote/590/angelurlaub-niederlande', img: `${CDN}/assets/categories/width/angelurlaub-niederlande.jpg` },
      { offerID: 814, path: '/besondere-angebote/814/bayerischer-wald-mit-hund', img: `${CDN}/assets/categories/width/bayerischer-wald-mit-hund.jpg` },
      { offerID: 840, path: '/besondere-angebote/840/thueringer-wald-mit-hund', img: `${CDN}/assets/categories/width/thueringer-wald-mit-hund.jpg` },
      { offerID: 750, path: '/besondere-angebote/750/gardasee-mit-pool', img: `${CDN}/assets/categories/width/gardasee-pool.jpg` },
      { offerID: 616, path: '/besondere-angebote/616/frankreich-mit-meerblick', img: `${CDN}/assets/categories/width/frankreich-mit-meerblick.jpg` },
      { offerID: 835, path: '/besondere-angebote/835/sauerland-mit-hund', img: `${CDN}/assets/categories/width/sauerland-mit-hund.jpg` },
      // { offerID: 656, path: '/besondere-angebote/656/ungarn-mit-hund',img: '//'+cdn+'/assets/categories/width/ungarn-mit-hund.jpg' },
      { offerID: 656, path: '/besondere-angebote/656/ungarn-mit-hund', img: `${CDN}/assets/categories/width/ungarn-mit-hund-656.jpg` },
      { offerID: 842, path: '/besondere-angebote/842/zeeland-mit-hund', img: `${CDN}/assets/categories/width/zeeland-mit-hund.jpg` },
      { offerID: 825, path: '/besondere-angebote/825/lueneburger-heide-mit-hund', img: `${CDN}/assets/categories/width/lueneburger-heide-mit-hund.jpg` },
      { offerID: 837, path: '/besondere-angebote/837/spreewald-mit-hund', img: `${CDN}/assets/categories/width/spreewald-mit-hund.jpg` },
      { offerID: 834, path: '/besondere-angebote/834/saechsische-schweiz-mit-hund', img: `${CDN}/assets/categories/width/saechsische-schweiz-mit-hund.jpg` },
      { offerID: 753, path: '/besondere-angebote/753/sardinien-mit-pool', img: `${CDN}/assets/categories/width/sardinien-mit-pool.jpg` },
      { offerID: 657, path: '/besondere-angebote/657/angelurlaub-bayern', img: `${CDN}/assets/categories/width/angelurlaub-bayern.jpg` },
      { offerID: 832, path: '/besondere-angebote/832/ostfriesland-mit-hund', img: `${CDN}/assets/categories/width/ostfriesland-mit-hund.jpg` },
      { offerID: 788, path: '/besondere-angebote/788/luxus-toskana', img: `${CDN}/assets/categories/width/luxus-toskana.jpg` },
      { offerID: 823, path: '/besondere-angebote/823/kaernten-mit-hund', img: `${CDN}/assets/categories/width/kaernten-mit-hund.jpg` },
      { offerID: 751, path: '/besondere-angebote/751/ligurien-mit-pool', img: `${CDN}/assets/categories/width/ligurien-mit-pool.jpg` },
      { offerID: 816, path: '/besondere-angebote/816/berlin-mit-hund', img: `${CDN}/assets/categories/width/berlin-mit-hund.jpg` },
      { offerID: 830, path: '/besondere-angebote/830/normandie-mit-hund', img: `${CDN}/assets/categories/width/normandie-mit-hund.jpg` },
      { offerID: 831, path: '/besondere-angebote/831/oberbayern-mit-hund', img: `${CDN}/assets/categories/width/oberbayern-mit-hund.jpg` },
      { offerID: 592, path: '/besondere-angebote/592/angelurlaub-oesterreich', img: `${CDN}/assets/categories/width/angelurlaub-oesterreich.jpg` },
      { offerID: 819, path: '/besondere-angebote/819/fehmarn-mit-hund', img: `${CDN}/assets/categories/width/fehmarn.jpg` },
      { offerID: 747, path: '/besondere-angebote/747/andalusien-mit-pool', img: `${CDN}/assets/categories/width/andalusien-mit-pool.jpg` },
      { offerID: 824, path: '/besondere-angebote/824/lago-maggiore-mit-hund', img: `${CDN}/assets/categories/width/lago-maggiore-mit-hund.jpg` },
      { offerID: 813, path: '/besondere-angebote/813/allgaeu-mit-hund', img: `${CDN}/assets/categories/width/hund-in-den-alpen.jpg` },
      { offerID: 748, path: '/besondere-angebote/748/costa-blanca-mit-pool', img: `${CDN}/assets/categories/width/costa-blanca-mit-pool.jpg` },
      { offerID: 1, path: '/besondere-angebote/1/finca-urlaub', img: `${CDN}/assets/categories/width/mallorca-finca.jpg` },
      { offerID: 781, path: '/besondere-angebote/781/gruppenunterkuenfte-harz', img: `${CDN}/assets/categories/width/ski-gruppe.jpg` },
      { offerID: 754, path: '/besondere-angebote/754/suedtirol-mit-pool', img: `${CDN}/assets/categories/width/suedtirol-mit-pool.jpg` },
      { offerID: 821, path: '/besondere-angebote/821/hamburg-mit-hund', img: `${CDN}/assets/categories/width/hamburg-mit-hund.jpg` },
      { offerID: 653, path: '/besondere-angebote/653/schweden-mit-hund', img: `${CDN}/assets/categories/width/schweden-mit-hund.jpg` },
      { offerID: 818, path: '/besondere-angebote/818/bretagne-mit-hund', img: `${CDN}/assets/categories/width/bretagne-mit-hund.jpg` },
      { offerID: 3, path: '/besondere-angebote/3/urlaub-auf-dem-bauernhof/', img: `${CDN}/assets/categories/width/urlaub-auf-dem-bauernhof.jpg` },
      { offerID: 757, path: '/besondere-angebote/757/finca-andalusien', img: `${CDN}/assets/categories/width/finca-andalusien.jpg` },
      { offerID: 664, path: '/besondere-angebote/664/angelurlaub-ruegen', img: `${CDN}/assets/categories/width/deutschland-ruegen.jpg` },
      { offerID: 903, path: '/besondere-angebote/903/bungalows-in-bibione', img: `${CDN}/assets/categories/width/bibione.jpg` },
      { offerID: 663, path: '/besondere-angebote/663/angelurlaub-ostfriesland', img: `${CDN}/assets/categories/width/ostfriesland.jpg` },
      { offerID: 778, path: '/besondere-angebote/778/finca-teneriffa', img: `${CDN}/assets/categories/width/teneriffa.jpg` },
      { offerID: 927, path: '/besondere-angebote/927/ferienhaus-oder-ferienwohnung-mittelmeer', img: `${CDN}/assets/categories/width/ferienhaus-am-mittelmeer.jpg` },
      { offerID: 836, path: '/besondere-angebote/836/schleswig-holstein-mit-hund', img: `${CDN}/assets/categories/width/schleswig-holstein-mit-hund.jpg` },
      { offerID: 304, path: '/besondere-angebote/304/ferienhaus-im-sommer', img: `${CDN}/assets/categories/width/ferienhaus-im-sommer.jpg` },
      { offerID: 739, path: '/besondere-angebote/739/sardinien-mit-meerblick', img: `${CDN}/assets/categories/width/sardinien-meerblick.jpg` },
      { offerID: 90, path: '/daenemarkurlaub-mit-hund', img: `${CDN}/assets/categories/width/daenemarkurlaub-mit-hund.jpg` },
      { offerID: 791, path: '/besondere-angebote/791/skiurlaub-harz', img: `${CDN}/assets/categories/width/skiurlaub-harz.jpg` },
      { offerID: 261, path: '/besondere-angebote/261/luxus-unterkuenfte', img: `${CDN}/assets/categories/width/luxus-unterkuenfte.jpg` },
      { offerID: 839, path: '/besondere-angebote/839/thueringen-mit-hund', img: `${CDN}/assets/categories/width/thueringen-mit-hund.jpg` },
      { offerID: 772, path: '/besondere-angebote/772/finca-lanzarote', img: `${CDN}/assets/categories/width/finca-lanzarote.jpg` },
      { offerID: 741, path: '/besondere-angebote/741/toskana-mit-meerblick', img: `${CDN}/assets/categories/width/toskana-mit-meerblick.jpg` },
      { offerID: 587, path: '/besondere-angebote/587/angelurlaub-finnland', img: `${CDN}/assets/categories/width/finnland.jpg` },
      { offerID: 768, path: '/besondere-angebote/768/finca-ibiza', img: `${CDN}/assets/categories/width/ibiza.jpg` },
      { offerID: 645, path: '/besondere-angebote/645/belgien-mit-hund', img: `${CDN}/assets/categories/width/hund-belgien.jpg` },
      { offerID: 758, path: '/besondere-angebote/758/finca-balearen', img: `${CDN}/assets/categories/width/finca-balearen.jpg` },
      { offerID: 761, path: '/besondere-angebote/761/finca-costa-brava', img: `${CDN}/assets/categories/width/finca-landhaus.jpg` },
      { offerID: 671, path: '/besondere-angebote/671/chalet-suedtirol', img: `${CDN}/assets/categories/width/chalet-suedtirol.jpg` },
      { offerID: 76, path: '/besondere-angebote/76/urlaub-mit-hund', img: `${CDN}/assets/categories/width/urlaub-mit-hund.jpg` },
      { offerID: 122, path: '/besondere-angebote/122/skiurlaub', img: `${CDN}/assets/categories/width/skiurlaub.jpg` },
      { offerID: 25, path: '/besondere-angebote/25/familienurlaub', img: `${CDN}/assets/categories/width/familienurlaub.jpg` },
      { offerID: 14, path: '/besondere-angebote/14/golfurlaub', img: `${CDN}/assets/categories/width/golfurlaub.jpg` },
      { offerID: 100, path: '/besondere-angebote/100/landal', img: `${CDN}/assets/categories/width/landal-green-parks.jpg` },
      { offerID: 1233, path: '/besondere-angebote/1233/landal-wandern', img: `${CDN}/assets/categories/width/landal-green-parks.jpg` },
      { offerID: 956, path: '/besondere-angebote/956/eckernfoerder-bucht', img: `${CDN}/assets/categories/width/eckernfoerder-bucht.jpg` },
      { offerID: 552, path: '/besondere-angebote/552/center-parcs', img: `${CDN}/assets/categories/width/center-park-001.jpg` },
      { offerID: 883, path: '/besondere-angebote/883/ferienhaus-am-strand', img: `${CDN}/assets/categories/width/ferienhaus-am-strand-883.jpg` },
      { offerID: 237, path: '/besondere-angebote/237/trulli/', img: `${CDN}/assets/categories/width/trulli.jpg` },
      { offerID: 1097, path: '/besondere-angebote/1097/finnhaeuser-und-nurdachhaeuser/', img: `${CDN}/assets/categories/width/nurdachhaeuser.jpg` },
      { offerID: 1272, path: '/besondere-angebote/1272/reetdachhaeuser/', img: `${CDN}/assets/categories/width/reetdachhaeuser.jpg` },
      { offerID: 1273, path: '/besondere-angebote/1273/windmuehlen/', img: `${CDN}/assets/categories/width/windmuehlen.jpg` },
      { offerID: 1274, path: '/besondere-angebote/1274/tiny-houses/', img: `${CDN}/assets/categories/width/tiny-houses.jpg` },
      { offerID: 1275, img: `${CDN}/assets/categories/width/elektroauto.jpg` },
      { offerID: 1276, path: '/besondere-angebote/1276/zelte-und-jurten/', img: `${CDN}/assets/categories/width/zelte-und-jurten.jpg` },
      { offerID: 1277, path: '/besondere-angebote/1277/glamping/', img: `${CDN}/assets/categories/width/glamping.jpg` },
      { offerID: 1291, path: '/besondere-angebote/1291/urlaub-mit-hund-am-meer/', img: `${CDN}/assets/categories/width/urlaub-mit-hund-am-meer-1291.jpg` },
      { offerID: 1292, path: '/besondere-angebote/1292/urlaub-mit-hund-am-see/', img: `${CDN}/assets/categories/width/urlaub-mit-hund-am-see-1292.jpg` },
    ],
  },
  home: {
    teaserTopics: [

      // {
      //   teaser: 'at-sea',
      //   img: `${CDN}/assets/categories/see-870x490.jpg`,
      //   href: { name: 'SearchOffer', params: { foo: 'ferienhaus-am-see', offer: '200' } },
      //   title: 'home:teaserTopics.atSea',
      //   logo: '',
      // },
      // {
      //   teaser: 'easterHolidays_22',
      //   img: `${CDN}/assets/categories/easter_holidays_2022.jpeg`,
      //   href: 'home:teaserTopicsUrls.easterHolidays2022',
      //   title: 'home:teaserTopics.easterHolidays2022'
      // },
      {
        teaser: 'topLandlord_21',
        img: `${CDN}/assets/categories/Top-Vermieter_3_870x490.jpg`,
        href: 'home:teaserTopicsUrls.topLandlord2021',
        title: 'home:teaserTopics.topLandlord2021',
        // logo: 'src/icons/award/top-vermieter-badge.svg',
      },
      // {
      //   teaser: 'winter-2021',
      //   img: `${CDN}/assets/categories/skiurlaub-2021-2022.jpg`,
      //   href: { name: 'Wintervacation' },
      //   title: 'home:teaserTopics.winter2021',
      // },
      {
        teaser: 'Pets',
        img: `${CDN}/assets/categories/woman_and_dog_beach.jpeg`,
        href: { name: 'Pets' },
        title: 'home:teaserTopics.pets',
      },
      // {
      //   teaser: 'silvester-2021',
      //   img: `${CDN}/assets/categories/silvester-im-ferienhaus.jpg`,
      //   href: { name: 'SearchOffer', params: { foo: 'ferienhaus-silvester', offer: '73' } },
      //   title: 'home:teaserTopics.silvester2021',
      // },
      // {
      //   teaser: 'electric-auto',
      //   img: `${CDN}/assets/categories/elektroauto-ladestation.jpg`,
      //   href: { name: 'SearchOffer', params: { foo: 'ferienhaeuser-mit-ladestation', offer: '1275' } },
      //   title: 'home:teaserTopics.electricAuto',
      // },
      // {
      //   teaser: 'top-rated',
      //   img: `${CDN}/assets/categories/kundenbewertungen-400x225.jpg`,
      //   href: { name: 'SearchRating' },
      //   title: 'home:teaserTopics.toprated',
      // },
      // https://cdn.tourist-online.de/assets/categories/ukraine540x325.jpg
      {
        teaser: 'help-akraine',
        img: `${CDN}/assets/categories/ukraine540x325.jpg`,
        href: { name: 'HelpUkraine' },
        title: 'home:teaserTopics.helpUkraine',
      },
      // {
      //   teaser: 'newsletter',
      //   img: `${CDN}/assets/categories/ferienhaus-newsletter.jpg`,
      //   target: '_blank',
      //   href: { name: 'Newsletter', query: { source2: 'NL2' } },
      //   title: 'home:teaserTopics.newsletter',
      //   logo: `${CDN}/assets/categories/ferienhaus-newsletter-badge.svg`,
      // },
      {
        teaser: 'farm',
        img: `${CDN}/assets/categories/Bauernhofferien_Teaser-klein_400x225.jpg`,
        target: '_blank',
        href: 'home:FavoriteTopics.farm.href',
        title: 'home:FavoriteTopics.farm.title',
      },
      {
        teaser: 'lastminute',
        img: `${CDN}/assets/categories/last-minute-400x225.jpg`,
        href: { name: 'Lastminute' },
        title: 'home:teaserTopics.lastminute',
      },
    ],
    teaserBenefits: [
      {
        href: { name: 'Faq', params: { part: 'faq2' } },
        title: 'home:teaserBenefits.retailerFaq.title',
        head: 'home:teaserBenefits.retailerFaq.head',
        html: 'home:teaserBenefits.retailerFaq.html',
      },
      {
        href: { name: 'Distribution' },
        title: 'home:teaserBenefits.partner.title',
        head: 'home:teaserBenefits.partner.head',
        html: 'home:teaserBenefits.partner.html',
      },
      {
        href: { name: 'About' },
        title: 'home:teaserBenefits.about.title',
        head: 'home:teaserBenefits.about.head',
        html: 'home:teaserBenefits.about.html',
      },
    ],
    favoriteTopics: [
    ],
    favoriteCountries: [
    ],
  },
  offerList: {
  // current GER  hierachy for distinctions can be seen in EDMT-4947
    distinctions: [
      {
        id: '1285', variant: 'primary', icon: 'icon-award-solid', ignore: '1214',
      },
      { id: '1214', variant: 'primary', icon: 'icon-award-solid' },
      { id: '1104', variant: 'info', icon: null },
      { id: '1105', variant: 'info', icon: null },
      { id: '489', variant: 'info', icon: null },
      { id: '1241', variant: 'info', icon: null },
      { id: 'push', variant: 'recommendation', icon: 'icon-thumbs-up-alternative' },
    ],
    infolabel: [
      {
        id: '1247',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2021-01-01',
        to: '2021-12-31',
        deadline: 61,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
      {
        id: '1250',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2021-01-01',
        to: '2021-10-31',
        deadline: 31,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
      {
        id: '1254',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2021-01-01',
        to: '2021-10-31',
        deadline: 31,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
      {
        id: '1255',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2021-05-01',
        to: '2021-09-30',
        deadline: 30,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
      {
        id: '1256',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2020-12-15',
        to: '2021-12-31',
        deadline: 21,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
      {
        id: '1257',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2020-12-15',
        to: '2021-12-31',
        deadline: 31,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
      {
        id: '1266',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2021-01-01',
        to: '2021-12-31',
        deadline: 43,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
      {
        id: '1270',
        class: 'success',
        icon: 'icon-check-circle',
        from: '2021-01-01',
        to: '2021-12-31',
        deadline: 30,
        label: 'offers:cancellation',
        head: 'offers:offers.head',
        text: 'offers:offers',
      },
    ],
  },
  voucherAndConditions: {
    travelVoucher: {
      conditionList: [],
    },
  },
  search: {
    showCancelations: false,
    showClaim: true,
  },
  object: {
    showCancelations: true,
    showPartner: true,
    faqNote: {
      visible: true,
      iconVariant: 'light',
    },
  },
  holidayParks: {
    teaserParks: [],
    teaserRecommendations: [],
  },
  contactForm: {
    contactTopics: {
      partner: {
        default: { value: '', text: 'contact:vacationer.selectRequestReason' },
        agencyCoop: { value: 'partner-agency-cooperation', text: 'contact:contactTopics.partner.agencyCoop' },
        travelAgency: { value: 'partner-office-cooperation', text: 'contact:contactTopics.partner.travelAgency' },
        affiliate: { value: 'partner-affiliate-cooperation', text: 'contact:contactTopics.partner.affiliateCoop' },
      },
      tourist: {
        default: { value: '', text: 'contact:vacationer.selectRequestReason' },
        general: { value: 'tourist-general', text: 'contact:contactTopics.vacationer.generalRequest' },
        booking: { value: 'tourist-booking', text: 'contact:contactTopics.vacationer.bookingRequest' },
        domicile: { value: 'tourist-domicile', text: 'contact:contactTopics.vacationer.domicileRequest' },
      },
    },
  },
  FAQ: {
    touristFAQ: {
      configParams: {
        booking: ['1', '2', '3', '4'],
        documents: ['1'],
        search: ['1', '2', '3', '4', '5'],
        information: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
        startend: ['1', '2', '3', '4', '5'],
        occupation: ['1', '2', '3', '4', '5', '6', '7'],
        contact: ['1'],
        newsletter: ['1'],
      },
    },
    landLordFAQ: {
      configParams: {
        achievement: ['1', '2', '3', '4', '5'],
        myhouse: ['1', '2', '3', '4', '5', '6', '7'],
        internet: ['1', '2'],
        service: ['1', '2', '3', '4', '5'],
      },
    },

  },
  agencies: [],
  headerBanner: {
    specialHolidaysOpenings: false,
  },
  office: {
    // this is the default used config for office templates
    // gets overwritten with route configs, defined underneath "default" (i.e. partner, retailer...)
    default: {
      header: {
        contactImage: {
          source: 'officeStream',
        },
        top: {
          display: {
            source: 'officeStream',
          },
        },
        middle: {
          display: {
            string: 'office:openingsStatic.global.contactPhoneDisplay'
          },
          interact: {
            string: 'office:openingsStatic.global.contactPhoneInteract'
          },
        },
        bottom: {
          display: {
            source: 'officeStream',
          },
          interact: false
        },
      },
      card: {
        contactImage: {
          source: 'officeStream',
        },
        overview: {
          top: {
            display: {
              source: 'officeStream',
            },
            interact: false,
          },
          middle: {
            display: {
              source: 'officeStream',
            },
            interact: false,
          },
          bottom: false,
        },
        interact: {
          top: {
            display: {
              string: 'office:card.commercial.askQuestion'
            },
            interact: {
              name: 'Contact'
            },
          },
          bottom: {
            display: {
              string: 'office:openingsStatic.global.contactPhoneDisplay'
            },
            interact: {
              string: 'office:openingsStatic.global.contactPhoneInteract'
            },
          },
        }
      },
    },
    objectPage: {
      card: {
        overview: {
          bottom: {
            display: {
              string: 'office:card.objectNumber',
            }
          },
        },
        interact: {
          top: {
            display: {
              string: 'office:card.commercial.askQuestion'
            },
            interact: {
              name: 'Request'
            },
          },
          bottom: {
            display: {
              string: 'office:openingsStatic.global.contactPhoneDisplay'
            },
            interact: {
              string: 'office:openingsStatic.global.contactPhoneInteract'
            },
          },
        }
      },
    },
    retailer: {
      header: {
        contactImage: false,
        top: {
          display: {
            source: false,
            string: 'office:openingsStatic.retailer.contactName'
          }
        },
        middle: {
          display: {
            string: 'office:openingsStatic.retailer.contactPhoneDisplay'
          },
          interact: {
            string: 'office:openingsStatic.retailer.contactPhoneInteract'
          },
        },
        bottom: {
          display: {
            source: false,
            string: 'office:openingsStatic.retailer.contactTimes'
          }
        },
      },
      card: {
        contactImage: false,
        overview: {
          top: {
            display: {
              source: false,
              string: 'office:openingsStatic.retailer.contactName',
            }
          },
          middle: {
            display: {
              source: false,
              string: 'office:openingsStatic.retailer.contactTimes',
            }
          },
          bottom: false,
        },
        interact: {
          top: {
            display: {
              string: 'office:openingsStatic.retailer.contactMailDisplay',
            },
            interact: {
              name: 'ContactLandlord',
            },
          },
          bottom: {
            display: {
              string: 'office:openingsStatic.retailer.contactPhoneDisplay',
            },
            interact: {
              string: 'office:openingsStatic.retailer.contactPhoneInteract'
            },
          },
        }
      }
    },
    agency: {
      card: {
        interact: {
          top: {
            display: {
              string: 'office:openingsStatic.agency.contactMailDisplay',
            },
            interact: {
              name: 'ContactPartner',
            },
          },
          bottom: {
            display: {
              string: 'office:openingsStatic.agency.contactPhoneDisplay',
            },
            interact: {
              string: 'office:openingsStatic.agency.contactPhoneInteract'
            },
          },
        }
      }
    },
    partner: {
      header: {
        contactImage: false,
        top: {
          display: {
            source: false,
            string: 'office:openingsStatic.partner.contactName'
          }
        },
        middle: {
          display: {
            string: 'office:openingsStatic.partner.contactPhoneDisplay'
          },
          interact: {
            string: 'office:openingsStatic.partner.contactPhoneInteract'
          },
        },
        bottom: {
          display: {
            source: false,
            string: 'office:openingsStatic.partner.contactTimes'
          }
        },
      },
      card: {
        contactImage: false,
        overview: {
          top: {
            display: {
              source: false,
              string: 'office:openingsStatic.partner.contactName',
            }
          },
          middle: {
            display: {
              source: false,
              string: 'office:openingsStatic.partner.contactTimes',
            }
          },
          bottom: false,
        },
        interact: {
          top: {
            display: {
              string: 'office:openingsStatic.partner.contactMailDisplay',
            },
            interact: {
              name: 'ContactPartner',
            },
          },
          bottom: {
            display: {
              string: 'office:openingsStatic.partner.contactPhoneDisplay',
            },
            interact: {
              string: 'office:openingsStatic.partner.contactPhoneInteract'
            },
          },
        }
      }
    },
  },
  newsletter: {
    imgHeader: '/assets/categories/newsletter.jpg',
    svgStoerer: '/assets/categories/ferienhaus-newsletter-badge.svg',
    bulletPoints: [],
    newsletterAvailableCheckFnc: (language = true) => language,
    socialMediaAvailableCheckFnc: (language = true) => language,
    showTextAboveSubmitBtn: true,
    classes: {
      bullets: 'col-8',
      stoerer: 'd-block',
    },
  },
  affiliate: {
    redirect: {
      showCheckbox: true,
    }
  },
};
