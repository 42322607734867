<template>
  <div />
</template>

<script>
import { trackingParamsList, pidList } from 'config/trackingParams';
import { toDateString } from 'utils/DateHelper';
import GtmTools from 'mixins/GtmTools';
import DeviceHelper from 'mixins/DeviceHelper';
import UserApi from 'mixins/UserApi';
import {
  getCookieVal,
} from 'mixins/composition/CookieApi';
// eslint-disable-next-line import/no-unresolved
import Sentry from 'plugins/sentry.TARGET';

export default {
  name: 'UidTrack',
  mixins: [
    DeviceHelper,
    GtmTools,
    UserApi,
  ],
  setup() {
    return {
      getCookieVal,
    };
  },
  computed: {
    ebk() {
      return this.$store.getters.getEbk || this.$route.query.ebk;
    },
    watchListUID() {
      if (this.$route.query.watchListUID) {
        return this.$route.query.watchListUID;
      }

      return this.$store.getters.getWatchListUID;
    },
    trackingParams() {
      const trackingParams = {};
      Object.keys(this.$route.query).forEach((paramKey) => {
        Object.keys(trackingParamsList).forEach((trackingParam) => {
          // must match case insensitive
          if (trackingParam.toLowerCase() === paramKey.toLowerCase()) {
            // EDVUE-3445 due to wrong links, pid in query is sometimes array
            // if this is the case, just use first pid from array
            let pidParam = this.$route.query[paramKey];
            if (Array.isArray(pidParam)) {
              [pidParam] = pidParam;
            }

            if (pidParam) {
              pidParam = String(pidParam);
              // only allow numbers as partnerID
              if (pidParam.match(/^[0-9]+$/)) {
                if (trackingParamsList[paramKey.toLowerCase()]?.isPid === true) {
                  trackingParams.pid = pidParam.trim();
                } else {
                  trackingParams[paramKey.toLowerCase()] = pidParam.trim();
                }
              }
            }
          }
        });
      });
      return trackingParams;
    },
    pidParams() {
      const pidParams = [];
      Object.keys(this.trackingParams).forEach((paramKey) => {
        const paramVal = this.trackingParams[paramKey];
        if (trackingParamsList[paramKey]?.isPid === true) {
          const timestampObj = new Date();
          const timestamp = toDateString(timestampObj, 'yyyy-MM-dd HH:mm:ss');
          pidParams.push({
            pid: paramVal.trim(),
            timestamp,
          });
        }
      });

      return pidParams;
    },
    pidLatest() {
      return this.$store.getters.getPidLatest;
    },
    gclidLatest() {
      return this.$store.getters.getGclidLatest || this.$route.query.gclid;
    },
    pidHistoryString() {
      const pidHistory = this.$store.getters.getPidHistory;
      let pidHistoryReturn = '';
      if (Object.keys(pidHistory).length) {
        Object.values(pidHistory).forEach((pidHistoryData) => {
          pidHistoryReturn += `${pidHistoryData.pid.trim()},`;
        });
      }
      // remove trailing comma
      return pidHistoryReturn.slice(0, -1);
    },
    experimentData() {
      return this.$store.getters.getExperiments;
    },
    historyParams() {
      let queryStr = '';
      if (Object.keys(this.$route.query).length > 0) {
        queryStr = JSON.stringify(this.$route.query);
      }
      return {
        currentView: this.$route.matched[0]?.components?.default?.name || '',
        currentPath: this.$route.path,
        currentlocale: this.UserApi_currentLocale,
        queryStr,
      };
    },
  },
  watch: {
    $route() {
      // log user history on every navigation
      this.logHistory();
    },
  },
  mounted() {
    this.subscribeEbk();
    this.initUserData();
  },
  methods: {
    // watch mutation of user ebk in store and push to datalayer
    subscribeEbk() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'SET_USER_EBK') {
          if (state.user.ebk) {
            this.dataLayerPush({
              EBK: state.user.ebk,
            });
          }
        }
      });
    },
    initUserData() {
      // everything depends on getUser request
      this.UserApi_getUser()
        .then(() => Promise.all([
          this.getWatchedObjects(),
          this.getLastSeenObjects(),
        ]))
      // axios error processing happens in apiclient -> errorInterceptor
        .catch(() => {})
        .then(() => Promise.all([
          this.setPidLatest(),
          this.setPtcLatest(),
          this.setGclidLatest(),
          this.setMsclkidLatest(),
          this.setDclidLatest(),
        ]))
      // above functions (pid, ptc...) are no axios, so we need specific error handling
        .catch((error) => {
          if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(error);
          } else {
          // eslint-disable-next-line no-console
            console.error(error);
          }
        })
        .then(() => {
        // always write to dataLayer (after uid is fetched from API)
          this.setUserDataLayer();
          // log user history once on initial page visit
          return this.logHistory();
        })
      // axios error processing happens in apiclient -> errorInterceptor
        .catch(() => {});
    },
    getWatchedObjects() {
      // initial retrieval of watched objects from user to save in vuex store
      // not necessary if user is on watchlist route
      if (this.$route.meta?.name !== 'WatchList') {
        return this.$store.dispatch('GET_WATCHED_OBJECTS', {
          watchListUID: this.watchListUID,
        }).catch(() => {});
      }
      return Promise.resolve();
    },
    getLastSeenObjects() {
      // initial retrieval of lastseen objects from user to save in vuex store
      return this.$store.dispatch('GET_LAST_SEEN_OBJECTS', { uid: this.UserApi_uid }).catch(() => {});
    },
    setPtcLatest() {
      const ptc = this.trackingParams?.ptc;
      if (ptc) {
        return this.$store.dispatch('SAVE_PTC_LATEST', {
          ptc,
        });
      }
      return Promise.resolve();
    },
    setGclidLatest() {
      const gclid = this.trackingParams?.gclid;
      if (gclid) {
        return this.$store.dispatch('SAVE_GCLID_LATEST', {
          gclid,
        });
      }
      return Promise.resolve();
    },
    setMsclkidLatest() {
      const msclkid = this.trackingParams?.msclkid;
      if (msclkid) {
        return this.$store.dispatch('SAVE_MSCLKID_LATEST', {
          msclkid,
        });
      }
      return Promise.resolve();
    },
    setDclidLatest() {
      const dclid = this.trackingParams?.dclid;
      if (dclid) {
        return this.$store.dispatch('SAVE_DCLID_LATEST', {
          dclid,
        });
      }
      return Promise.resolve();
    },
    setPidLatest() {
      const pid = this.pidParams.slice(-1)[0]?.pid.trim();
      // pid was in get-param (SEA)
      if (pid) {
        return this.$store.dispatch('SAVE_PID_LATEST', {
          pid,
        });
      }

      // no SEA pid, but SEO referrer. set organic pid
      if (!pid && document?.referrer) {
        let pidSeo = '';
        const { referrer } = document;
        Object.keys(pidList).forEach((pidnumber) => {
          if (pidList[pidnumber].isReferrerPID) {
            pidList[pidnumber].referrers.forEach((referrerStr) => {
              if (referrer.indexOf(referrerStr) >= 0) {
                pidSeo = pidnumber.trim();
              }
            });
          }
        });

        if (pidSeo) {
          this.$store.dispatch('SAVE_PID_LATEST', {
            pid: pidSeo,
          });
          this.$store.dispatch('SAVE_TRACKING_PARAMS', {
            trackingParams: { pid: pidSeo },
          }).catch(() => {});
        }
      }
      return Promise.resolve();
    },
    setUserDataLayer() {
      this.dataLayerPush({
        event: 'uid',
        UserID: this.UserApi_uid || undefined,
        EBK: this.ebk || undefined,
        PartnerID: this.pidLatest || undefined,
        pidHistory: this.pidHistoryString || undefined,
        gclidLatest: this.gclidLatest
      });
      this.$store.state.userSaved = 'userSaved';
      return Promise.resolve();
    },
    logHistory() {
      return this.$store.dispatch('USER_LOG_HISTORY', {
        uid: this.UserApi_uid,
        jwt: this.UserApi_jwt,
        historyParams: this.historyParams,
      }).catch(() => {});
    },
  },
};
</script>
