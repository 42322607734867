import axios from 'apiclient';
/**
 * TODO get response errors and reject promise
 * @param {*} userData
 */
const newsletterSubscribe = (userData) => axios.get('/api/mailer/newsletter/', {
  params: { mandant: process.env.CLIENTNAME, ...userData },
})
  .then((res) => res.data);

const contactRequest = ({ requestData, uid }) => axios.post('/api/mailer/contact', {
  mandant: process.env.CLIENTNAME,
  formData: {
    ...requestData,
    uid,
  },
})
  .then((result) => result.data);

export {
  newsletterSubscribe,
  contactRequest,
};
