// cms module
import tiptapState from './state';
import tiptapActions from './actions';
import tiptapGetters from './getters';
import tiptapMutations from './mutations';

export default {
  state: tiptapState,
  actions: tiptapActions,
  getters: tiptapGetters,
  mutations: tiptapMutations,
};
