// using @ alias here and bypassing the webpack resolvers is intended here!
// please consult the configuration docs for more info!
import localesConfig from 'config/app/locales';
import bookingConfig from 'config/app/bookings';
import defaultsConfig from 'config/app/defaults';
import rulesConfig from 'config/app/rules';
import routerConfig from 'config/app/router';
import cookieLaw from 'config/app/cookieLaw';
import heroImages from 'config/app/heroImages';
import object from 'config/app/object';
import search from 'config/app/search';
import home from 'config/components/home';
import defaultsDeep from 'lodash.defaultsdeep';
import merge from 'lodash.merge';
import travelVoucherAndConditions from 'config/app/travelVoucherAndConditions';
import holidayParks from 'config/app/holidayParks';
import contactForm from 'config/app/contactForm';
import faqConfig from 'config/app/faqConfig';
import teaserBenefits from 'config/app/teaserBenefits';
import filter from 'config/components/search/filter';
import headerBanner from 'config/app/headerBanner';
import logoCloud from 'config/app/logoCloud';
import portalsConfig from 'config/app/footerPortals';
import privacyConfig from 'config/app/privacy';
import disclaimerConfig from 'config/app/disclaimer';
import travelConditionsConfig from 'config/app/travelConditions';
import metaInfos from 'config/app/metaInfos';
import officeConfig from 'config/app/office';
import newsletterExceptions from 'config/app/newsletterExceptions';
import newsletter from 'config/app/newsletter';
import affiliate from 'config/app/affiliate';
import rating from 'config/app/rating';
import mainConfig from '@/config/app/index';
import offerListConfig from '@/config/app/offerList';

const clientConfig = merge(
  {},
  localesConfig,
  defaultsConfig,
  rulesConfig,
  bookingConfig,
  cookieLaw,
  heroImages,
  home,
  routerConfig,
  offerListConfig,
  travelVoucherAndConditions,
  object,
  search,
  holidayParks,
  contactForm,
  faqConfig,
  teaserBenefits,
  filter,
  headerBanner,
  logoCloud,
  portalsConfig,
  privacyConfig,
  disclaimerConfig,
  travelConditionsConfig,
  metaInfos,
  officeConfig,
  newsletterExceptions,
  newsletter,
  affiliate,
  rating,
);
const config = defaultsDeep({}, clientConfig, mainConfig);

config.locales.helper = {
  supportedLanguageLength: () => Object.keys(config.locales.supportedLanguages).length,
  supportedLanguageIdents: () => Object.keys(config.locales.supportedLanguages),
  getLanguageParams: (lang) => config.locales.supportedLanguages[lang],
};

export default config;
