import { searchAllFilterNames } from 'router/guards/queryConfig';
import config from 'config/appConfig';

const testParam = (paramName, paramValue, ignoreList = [], exclusiveList = []) => {
  // EDVUE-2894 can be deleted if EDVUE-2894 is done. Ignore empty tolerance
  // if (
  //  paramName === 'tolerance'
  //  && (
  //    paramValue === ''
  //    || paramValue === '0'
  //    || paramValue === 0
  //    || paramValue === null
  //  )
  // ) {
  //  return true;
  // }

  // filter these conditions no matter what, default and null values
  if (
    paramValue === ''
      || paramValue === 0
      || paramValue === '0'
      || paramValue === null
      || paramValue === []
      || paramValue === {}
      || (paramName === 'priceMax' && paramValue >= config.defaults.searchPriceMax)
      || (paramName === 'persons' && (paramValue === 2 || paramValue === '2'))
      || (paramName === 'page' && (paramValue === 1 || paramValue === '1'))
  ) {
    return false;
  }

  if (
    (ignoreList.length && ignoreList.includes(paramName))
    || (exclusiveList.length && !exclusiveList.includes(paramName))
  ) {
    return false;
  }

  return true;
};

/**
   * pass an object with url query params
   * ignore ignoreFilterInUrl in return
   * get only getOnlyFilterInUrl in return
   * it returns an object where unneeded query params (empty, 0, etc.) are kicked out
   *
   * @param {Object} queryParams
   * @param {Array} ignoreFilterInUrl
   * @param {Array} getOnlyFilterInUrl
   */
const getClearedFilter = (
  queryParams = {}, ignoreList = [], exclusiveList = [],
) => {
  const filteredParams = {};

  // need to copy ignoreList, if it's a store object, it'll mutate the store
  const ignoreListCopy = JSON.parse(JSON.stringify(ignoreList));
  ignoreListCopy.push('objectsPerPage'); // global ignore, we never show this in params on any page

  Object.keys(queryParams).forEach((param) => {
    const value = queryParams[param];
    if (testParam(param, value, ignoreListCopy, exclusiveList)) filteredParams[param] = value;
  });

  return filteredParams;
};

// get current search filter from query
const getQuerySearchFilters = (query) => Object.keys({ ...query })
  .filter((key) => searchAllFilterNames.includes(key))
  .reduce((merged, current) => { merged[current] = query[current]; return merged; }, {});

// get all other params from query
const getOtherQueryParams = (query) => Object.keys({ ...query })
  .filter((key) => !searchAllFilterNames.includes(key))
  .reduce((merged, current) => { merged[current] = query[current]; return merged; }, {});

// get filters to append to links on search page
const getSearchLinksQuery = (store, route, filterIgnoreList = []) => ({
  ...getClearedFilter(store.getters.getMergedTerms, filterIgnoreList),
  ...getClearedFilter(route.query, filterIgnoreList),
});

// converts all object values to strings
const getCompareJSON = (obj) => JSON.stringify(Object.keys(obj).sort().reduce((acc, cur) => { acc[cur] = `${obj[cur]}`; return acc; }, {}));

export {
  getClearedFilter, testParam, getQuerySearchFilters, getOtherQueryParams, getSearchLinksQuery, getCompareJSON,
};
