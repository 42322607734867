const HomeLanding = () => import(/* webpackChunkName: "view-home-landing" */ 'views/HomeLanding.vue');
const Contact = () => import(/* webpackChunkName: "view-contact" */ 'views/Contact.vue');
const Booking = () => import(/* webpackChunkName: "view-booking" */ 'views/Booking.vue');
const Request = () => import(/* webpackChunkName: "view-request-page" */ 'views/Request.vue');
const PartnerRequired = () => import(/* webpackChunkName: "view-partnerequired-page" */ 'views/PartnerRequired.vue');

const routes = [
  { // open search landingpage
    path: '/ferienhaus-suche/',
    name: 'HomeLanding',
    component: HomeLanding,
    meta: {
      contentGroup: 5,
    },
  },
  {
    path: '/hilfe-kontakt/formular-gast/',
    name: 'ContactGuest',
    props(route) {
      const props = { ...route.params };
      return props;
    },
    component: Contact,
  },
  {
    path: '/booking/start/:objectNumber?/',
    name: 'Booking',
    props: (route) => ({
      objectNumber: route.params.objectNumber,
      actStep: 1,
    }),
    component: Booking,
    meta: {
      hideCookieSettings: 1,
    },
  },
  {
    path: '/booking/summary/:objectNumber/',
    name: 'BookingSummary',
    props: (route) => ({
      objectNumber: route.params.objectNumber,
      actStep: 2,
    }),
    meta: {
      hideCookieSettings: 1,
    },
    component: Booking,
  },
  {
    path: '/booking/finish/:objectNumber/',
    name: 'BookingFinish',
    props: (route) => ({
      objectNumber: route.params.objectNumber,
      actStep: 3,
    }),
    meta: {
      hideCookieSettings: 1,
    },
    component: Booking,
  },
  {
    path: '/option/start/:objectNumber?/',
    name: 'Option',
    props: (route) => ({
      objectNumber: route.params.objectNumber,
      actStep: 1,
      bookingMethod: 'option',
    }),
    meta: {
      hideCookieSettings: 1,
    },
    component: Booking,
  },
  {
    path: '/option/summary/:objectNumber/',
    name: 'OptionSummary',
    props: (route) => ({
      objectNumber: route.params.objectNumber,
      actStep: 2,
    }),
    meta: {
      hideCookieSettings: 1,
    },

    component: Booking,
  },
  {
    path: '/option/finish/:objectNumber/',
    name: 'OptionFinish',
    props: (route) => ({
      objectNumber: route.params.objectNumber,
      actStep: 3,
    }),
    component: Booking,
  },
  {
    path: '/request/:objectNumber(\\d+)/',
    name: 'Request',
    props(route) {
      const props = { ...route.params };
      if (props.objectNumber) {
        props.objectNumber = parseInt(props.objectNumber, 10);
      }
      return props;
    },
    component: Request,
  },
  { // open page if partner is missing
    path: '/incorrect/',
    name: 'PartnerRequired',
    component: PartnerRequired,
  },
];

export default routes;
