// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/quicksand-v20-latin-300.eot";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/quicksand-v20-latin-300.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "../assets/fonts/quicksand-v20-latin-300.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "../assets/fonts/quicksand-v20-latin-300.ttf";
import ___CSS_LOADER_URL_IMPORT_4___ from "../assets/fonts/quicksand-v20-latin-regular.eot";
import ___CSS_LOADER_URL_IMPORT_5___ from "../assets/fonts/quicksand-v20-latin-regular.woff2";
import ___CSS_LOADER_URL_IMPORT_6___ from "../assets/fonts/quicksand-v20-latin-regular.woff";
import ___CSS_LOADER_URL_IMPORT_7___ from "../assets/fonts/quicksand-v20-latin-regular.ttf";
import ___CSS_LOADER_URL_IMPORT_8___ from "../assets/fonts/quicksand-v20-latin-500.eot";
import ___CSS_LOADER_URL_IMPORT_9___ from "../assets/fonts/quicksand-v20-latin-500.woff2";
import ___CSS_LOADER_URL_IMPORT_10___ from "../assets/fonts/quicksand-v20-latin-500.woff";
import ___CSS_LOADER_URL_IMPORT_11___ from "../assets/fonts/quicksand-v20-latin-500.ttf";
import ___CSS_LOADER_URL_IMPORT_12___ from "../assets/fonts/quicksand-v20-latin-700.eot";
import ___CSS_LOADER_URL_IMPORT_13___ from "../assets/fonts/quicksand-v20-latin-700.woff2";
import ___CSS_LOADER_URL_IMPORT_14___ from "../assets/fonts/quicksand-v20-latin-700.woff";
import ___CSS_LOADER_URL_IMPORT_15___ from "../assets/fonts/quicksand-v20-latin-700.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_16___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_17___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_18___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_19___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Quicksand;font-style:normal;font-weight:300;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:local(\"Quicksand Light\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\")}@font-face{font-family:Quicksand;font-style:normal;font-weight:400;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");src:local(\"Quicksand Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"truetype\")}@font-face{font-family:Quicksand;font-style:normal;font-weight:500;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ");src:local(\"Quicksand Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_14___ + ") format(\"truetype\")}@font-face{font-family:Quicksand;font-style:normal;font-weight:700;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_15___ + ");src:local(\"Quicksand Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_16___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_17___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_18___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_19___ + ") format(\"truetype\")}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
