// import sentry.io integration for error logging
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Vue from 'vue';

const { isProduction, clientName } = require('../../env');
// const isProduction = true;

// Sentry.io integration
// eslint-disable-next-line no-constant-condition
if (isProduction) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN || 'https://efdbcc5ba16147acbd4948c1722597df@sentry.io/1490007',
    integrations: [new Integrations.Vue({
      Vue,
    })],
    attachProps: true,
    logErrors: true,
    release: process.env.REVISION || 'local',
    debug: false,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'searchResult&zanpid'],
    // sampleRate: 0.01,
  });
  Sentry.setTag('client_name', clientName);
}

export default Sentry;
