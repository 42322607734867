import axios from 'apiclient';

const rkiData = (landkreisID) => axios.get('/api/location/rki', { params: { RS: landkreisID } }, {
  timeout: 125000,
  cache: {
    ignoreCache: false,
    maxAge: 60 * 60 * 1000,
  },
})
  .then((result) => result.data)
  .catch(() => {});

export default rkiData;
