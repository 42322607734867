<template>
  <a
    :href="arp_linkBuilder(to)"
    :title="title"
    :target="target !== '' ? target : false"
    :rel="rel"
    :aria-label="$t(ariaLabel)"
    @[eventCondition].prevent="arp_linkClicker(to)"
  >
    <!-- Default slot for the link text -->
    <slot>{{ text }}</slot>
  </a>
</template>

<script>
import AnchorRouterPush from 'mixins/AnchorRouterPush';

export default {
  name: 'RouterLinkSimple',
  mixins: [AnchorRouterPush],
  props: {
    // the route object that's forwarded to the router push function
    to: {
      type: [Object, String],
      required: true,
    },
    // text of the anchor link
    text: {
      type: String,
      default: '',
    },
    // also decide if event is fired, when target is not used
    target: {
      type: String,
      default: '',
    },
    rel: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      default: '',
    }
  },
  computed: {
    eventCondition() {
      if (!this.target) {
        return 'click';
      }
      return null;
    },
  },
};
</script>
