import axios from 'apiclient';

const userRegistrationConfirm = (confirmID) => axios.post('/api/user/registration/confirm', {
  mandant: process.env.CLIENTNAME,
  confirmID,
}, {
  timeout: 20000,
}).then((result) => Promise.resolve(result.data));

export default userRegistrationConfirm;
