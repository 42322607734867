import loadBookingData from 'requests/landlord/bookingConfirmation';

export default {
  GET_BOOKING_DATA: ({ commit }, { bookingId, bookingHash }) => loadBookingData({ bookingId, bookingHash }).then((bookingData) => {
    // console.log('response bookingData', bookingData);
    commit('SET_BOOKING_CONFIRMATION_DATA', bookingData);
  }).catch((error) => {
    commit('SET_BOOKING_CONFIRMATION_ERROR', error);
  }),
};
