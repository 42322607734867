import './installCompositionApi';
import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import VueGtm from '@gtm-support/vue2-gtm'; // add Google Tag Manager with https://github.com/mib200/vue-gtm
import { sync } from 'vuex-router-sync';
import Vue2TouchEvents from 'vue2-touch-events';
import ToastPlugin from 'plugins/vue-toast.TARGET';
import PortalVue from 'portal-vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';

import VueLazyload from 'vue-lazyload';

import VueSmoothScroll from 'plugins/vue2-smooth-scroll.TARGET';

import { ObserveVisibility } from 'vue-observe-visibility';
import createLocalisation from 'plugins/i18next';
import VModal from 'plugins/vue-js-modal.TARGET';

import dompurifyConfig from 'config/dompurify.json';
import createStore from './store/index';
import App from './App.vue';
import createRouter from './router';

import 'filters';

import { gtmClient } from './config/trackingParams';

// // Sentry.io integration
// // eslint-disable-next-line no-constant-condition
// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: process.env.SENTRY_DSN || 'https://efdbcc5ba16147acbd4948c1722597df@sentry.io/1490007',
//     integrations: [new Integrations.Vue({
//       Vue,
//     })],
//     attachProps: true,
//     logErrors: true,
//     release: process.env.REVISION || 'local',
//     debug: false,
//     // sampleRate: 0.01,
//   });
// }
Vue.use(Router);
Vue.use(VueMeta, {
  ssrAppId: '1', // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
  refreshOnceOnNavigation: true,
});
Vue.directive('observe-visibility', ObserveVisibility);
if (VModal !== null) Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

// Vue.use(VueCompositionApi);

Vue.use(Vue2TouchEvents, {
  swipeTolerance: 50,
});

if (ToastPlugin !== null) {
  Vue.use(ToastPlugin);
}

// Vue.use(CurrencyFormat); // include env lang here in the future
Vue.use(VueLazyload, {
  preLoad: 1.3,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },
  silent: true,
});
if (VueSmoothScroll !== null) {
  Vue.use(VueSmoothScroll);
}
Vue.use(PortalVue);

Vue.use(VueDOMPurifyHTML, {
  default: {
    // allow target="_blank"
    // https://github.com/cure53/DOMPurify/issues/317
    ADD_ATTR: [...dompurifyConfig.allowedAttributes],
  },
});

// *** ERROR/WARNING handling ***

/* eslint-disable-next-line */
// Vue.config.errorHandler = function (err, vm, info) {
/* eslint-disable-next-line */
// console.log(`Error: ${err.toString()}\nInfo: ${info}`);
// };

/* eslint-disable-next-line */
// Vue.config.warnHandler = function (err, vm, info) {
/* eslint-disable-next-line */
// console.log(`Warning: ${err.toString()}\nInfo: ${info}`);
// };

// ***

const isDev = process.env.NODE_ENV !== 'production';
Vue.config.performance = isDev;

export default function createApp({ isNode }) {
  const store = createStore();
  // We initialize the store state with the data injected from the server
  // eslint-disable-next-line no-underscore-dangle
  if (typeof window !== 'undefined' && window.__INITIAL_STATE__) {
    store.replaceState(
      // need to filter route to not get a warning from sync() below
      // eslint-disable-next-line no-underscore-dangle
      Object.keys(window.__INITIAL_STATE__).filter((el) => el !== 'route').reduce((aggr, cur) => { aggr[cur] = window.__INITIAL_STATE__[cur]; return aggr; }, {}),
    );
  }
  // eslint-disable-next-line no-underscore-dangle
  if (typeof window !== 'undefined' && window.__IS_SPA__ === true) {
    store.state.app.isSPA = true;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (typeof window !== 'undefined' && window.__SERVER_CONTEXT__) {
    const {
      experimentDataVuex, isBot, usertype, partnerConfig, isMobile, userAgent,
    // eslint-disable-next-line no-underscore-dangle
    } = window.__SERVER_CONTEXT__;

    store.commit('SET_APP_ISBOT', isBot);
    store.commit('SET_APP_ISMOBILE', isMobile);
    store.commit('SET_USERTYPE', usertype);
    store.commit('SET_APP_UA', userAgent);

    // push experiments-data (ab-tests) into vuex store
    if (Object.keys(experimentDataVuex).length) {
      store.commit('SET_EXPERIMENT_DATA', experimentDataVuex);
    }

    // push partner-configs into vuex store
    if (Object.keys(partnerConfig).length) {
      store.commit('SET_PARTNER_CONFIG', partnerConfig);
      store.commit('SET_PID_LATEST', partnerConfig.partnerID);
    }
  }

  store.commit('SET_APP_ISNODE', isNode);
  // i18n module needs the store so it can initialize with the correct language set by the server
  const i18n = createLocalisation(store);
  const router = createRouter(store);

  const gtmConfig = gtmClient[process.env.CLIENTNAME];
  const { GTM_CONTAINER_ID } = gtmConfig;

  Vue.use(VueGtm, { // params, please have a look at https://github.com/mib200/vue-gtm
    id: GTM_CONTAINER_ID, // NEEDS TO BE DYNAMIC, also hardcoded in public/index.html <noscript> tag
    // id: 'GTM-TJZ6X2C',
    // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    //  gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
    //  gtm_preview:'env-4',
    //  gtm_cookies_win:'x'
    // },
    enabled: false, // will be enabled in App.vue to avoid ssr problems
    debug: process.env.NODE_ENV !== 'production',
    loadScript: true,
    vueRouter: router,
    defer: false,
    // ignoredViews: ['homepage']
  });

  // sync the router with the vuex store.
  // this registers `store.state.route`
  sync(store, router);
  store.$router = router;

  // create the app instance.
  // here we inject the router, store and ssr context sto all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
  });

  // expose the app, the router and the store.
  // note we are not mounting the app here, since bootstrapping will be
  // different depending on whether we are in a browser or on the server.
  return {
    app, router, store, i18n,
  };
}
