const Partner = {
  components: {
    IconLogoAdac: () => import('icons/adac-logo.svg'), // zu groß
    IconLogoDer: () => import('icons/der-logo.svg'),
    IconLogoDerPart: () => import('icons/derpart-logo.svg'),
    IconLogoDerTouristik: () => import('icons/der-touristik-partner-logo.svg'), // schwarz
    IconLogoDrr: () => import('icons/drr-logo_bg-white.svg'), // zu groß
    IconLogoGaleriaReisen: () => import('icons/galeria-reisen-logo.svg'),
    IconLogoProTour: () => import('icons/pro-tours-logo_bg-white.svg'), // zu groß
    IconLogoTourContact: () => import('icons/tour-contact-logo_bg-white.svg'), // zu groß
  },
  computed: {
    headerLogo() {
      switch (this.$store.getters.getPartnerConfigValue('partnerParent')) {
        case 'DERTOUR': return 'IconLogoDer';
        case 'DERPART': return 'IconLogoDerPart';
        case 'GES_ADAC': return 'IconLogoAdac';
        case 'ProTours': return 'IconLogoProTour';
        case 'DTPU': return 'IconLogoDerTouristik';
        case 'TourContact': return 'IconLogoTourContact';
        case 'DeutscherReisering': return 'IconLogoDrr';
        case 'GaleriaReisen': return 'IconLogoGaleriaReisen';
        default: return '';
      }
    },
    mxn_logoPath() {
      switch (this.$store.getters.getPartnerConfigValue('partnerParent')) {
        case 'DERTOUR': return '/icons/dertour-logo.svg';
        case 'DERPART': return '/icons/derpart-logo.svg';
        case 'GES_ADAC': return '/icons/adac-logo.svg';
        case 'ProTours': return '/icons/pro-tours-logo_bg-white.svg';
        case 'DTPU': return '/icons/der-touristik-partner-logo.svg';
        case 'TourContact': return '/icons/tour-contact-logo_bg-white.svg';
        case 'DeutscherReisering': return '/icons/drr-logo_bg-white.svg';
        case 'GaleriaReisen': return '/icons/galeria-reisen-logo.svg';
        default: return '';
      }
    },
    showHeader() {
      if (this.$store.getters.getPartnerConfigValue('showHeader') === 'N') return false;
      return true;
    },
    showHeaderLogo() {
      if (this.$store.getters.getPartnerConfigValue('showHeaderLogo') === 'N' || this.headerLogo === '') return false;
      return true;
    },
  },
};

export default Partner;
