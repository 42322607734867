// mixes default params into request params so that you always build the same keys for the store independant of how many params are supplied
const objectPriceDefaults = ({
  objectNumber, startDate, endDate, persons, duration, teens = 0, childs = 0, pets = 0, petSizes = [], country = '', isBooking = 0, childAges = [], bonus = '', bookingHash = '', language = 'de', partner = '',
}) => ({
  objectNumber,
  startDate,
  endDate,
  persons,
  duration,
  teens,
  childs,
  pets,
  petSizes,
  country,
  isBooking,
  childAges,
  bonus,
  bookingHash,
  language,
  partner,
});

const objectPriceKey = (params) => [Object.values(objectPriceDefaults(params))].join(',');

export { objectPriceKey, objectPriceDefaults };
