import config from 'config/images';

const { CDN } = config;

export default {
  home: {
    favoriteCountries: [
      {
        href: 'locationLinks:country.AL',
        title: 'countries:AL.title',
        name: 'countries:AL.name',
        hide: true,
        order: 27,
        countryCode: 'AL',
      },
      {
        href: 'locationLinks:country.AD',
        title: 'countries:AD.title',
        name: 'countries:AL.name',
        hide: true,
        order: 32,
        countryCode: 'AD',
      },
      {
        href: 'locationLinks:country.BE',
        title: 'countries:BE.title',
        name: 'countries:BE.name',
        order: 17,
        countryCode: 'BE',
      },
      {
        href: 'locationLinks:country.BG',
        title: 'countries:BG.title',
        name: 'countries:BG.name',
        hide: true,
        order: 30,
        countryCode: 'BG',
      },
      {
        href: 'locationLinks:country.DK',
        title: 'countries:DK.title',
        name: 'countries:DK.name',
        order: 8,
        countryCode: 'DK',
      },
      {
        href: 'locationLinks:country.DE',
        title: 'countries:DE.title',
        name: 'countries:DE.name',
        order: 1,
        countryCode: 'DE',
        contentLink: null,
      },
      {
        href: 'locationLinks:country.FI',
        title: 'countries:FI.title',
        name: 'countries:FI.name',
        hide: true,
        order: 20,
        countryCode: 'FI',
      },
      {
        href: 'locationLinks:country.FR',
        title: 'countries:FR.title',
        name: 'countries:FR.name',
        order: 7,
        countryCode: 'FR',
        contentLink: null,
      },
      {
        href: 'locationLinks:country.GR',
        title: 'countries:GR.title',
        name: 'countries:GR.name',
        order: 13,
        countryCode: 'GR',
      },
      {
        href: 'locationLinks:country.GB',
        title: 'countries:GB.title',
        name: 'countries:GB.name',
        order: 18,
        countryCode: 'GB',
      },
      {
        href: 'locationLinks:country.IE',
        title: 'countries:IE.title',
        name: 'countries:IE.name',
        hide: true,
        order: 22,
        countryCode: 'IE',
      },
      {
        href: 'locationLinks:country.IS',
        title: 'countries:IS.title',
        name: 'countries:IS.name',
        hide: true,
        order: 29,
        countryCode: 'IS',
      },
      {
        href: 'locationLinks:country.IT',
        title: 'countries:IT.title',
        name: 'countries:IT.name',
        order: 3,
        countryCode: 'IT',
        contentLink: null,
      },
      {
        href: 'locationLinks:country.HR',
        title: 'countries:HR.title',
        name: 'countries:HR.name',
        order: 2,
        countryCode: 'HR',
        contentLink: null,
      },
      {
        href: 'locationLinks:country.LU',
        title: 'countries:LU.title',
        name: 'countries:LU.name',
        hide: true,
        order: 31,
        countryCode: 'LU',
      },
      {
        href: 'locationLinks:country.MT',
        title: 'countries:MT.title',
        name: 'countries:MT.name',
        hide: true,
        order: 21,
        countryCode: 'MT',
      },
      {
        href: 'locationLinks:country.ME',
        title: 'countries:ME.title',
        name: 'countries:ME.name',
        hide: true,
        order: 25,
        countryCode: 'ME',
      },
      {
        href: 'locationLinks:country.NL',
        title: 'countries:NL.title',
        name: 'countries:NL.name',
        order: 9,
        countryCode: 'NL',
      },
      {
        href: 'locationLinks:country.NO',
        title: 'countries:NO.title',
        name: 'countries:NO.name',
        order: 15,
        countryCode: 'NO',
      },
      {
        href: 'locationLinks:country.AT',
        title: 'countries:AT.title',
        name: 'countries:AT.name',
        order: 6,
        countryCode: 'AT',
        contentLink: null,

      },
      {
        href: 'locationLinks:country.PL',
        title: 'countries:PL.title',
        name: 'countries:PL.name',
        order: 11,
        countryCode: 'PL',
      },
      {
        href: 'locationLinks:country.PT',
        title: 'countries:PT.title',
        name: 'countries:PT.name',
        order: 12,
        countryCode: 'PT',
      },
      {
        href: 'locationLinks:country.SE',
        title: 'countries:SE.title',
        name: 'countries:SE.name',
        order: 10,
        countryCode: 'SE',
      },
      {
        href: 'locationLinks:country.CH',
        title: 'countries:CH.title',
        name: 'countries:CH.name',
        order: 4,
        countryCode: 'CH',
        contentLink: null,

      },
      {
        href: 'locationLinks:country.SK',
        title: 'countries:SK.title',
        name: 'countries:SK.name',
        hide: true,
        order: 26,
        countryCode: 'SK',
      },
      {
        href: 'locationLinks:country.SI',
        title: 'countries:SI.title',
        name: 'countries:SI.name',
        order: 19,
        countryCode: 'SI',
      },
      {
        href: 'locationLinks:country.ES',
        title: 'countries:ES.title',
        name: 'countries:ES.name',
        order: 5,
        countryCode: 'ES',
        contentLink: null,
      },
      {
        href: 'locationLinks:country.CZ',
        title: 'countries:CZ.title',
        name: 'countries:CZ.name',
        order: 16,
        countryCode: 'CZ',
      },
      {
        href: 'locationLinks:country.TR',
        title: 'countries:TR.title',
        name: 'countries:TR.name',
        hide: true,
        order: 28,
        countryCode: 'TR',
      },
      {
        href: 'locationLinks:country.HU',
        title: 'countries:HU.title',
        name: 'countries:HU.name',
        order: 14,
        countryCode: 'HU',
      },
      {
        href: 'locationLinks:country.CY',
        title: 'countries:CY.title',
        name: 'countries:CY.name',
        hide: true,
        order: 23,
        countryCode: 'CY',
      },
      {
        href: 'locationLinks:country.MX',
        title: 'countries:MX.title',
        name: 'countries:MX.name',
        hide: true,
        order: 33,
        countryCode: 'MX',
      },
      {
        href: 'locationLinks:country.CA',
        title: 'countries:CA.title',
        name: 'countries:CA.name',
        hide: true,
        order: 34,
        countryCode: 'CA',
      },
      {
        href: 'locationLinks:country.GB-SCT',
        name: 'countries:GB-SCT.name',
        title: 'countries:GB-SCT.title',
        hide: true,
        order: 35,
        countryCode: 'GB-SCT',
      },

    ],
    teaserTopics: [
      {
        teaser: 'travelAtSea',
        img: `${CDN}/assets/categories/see-870x490.jpg`,
        href: 'home:teaserTopicsUrls.travelAtSea',
        title: 'home:teaserTopics.travelAtSea',
        logo: '',
      },
      {
        teaser: 'pets',
        img: `${CDN}/assets/categories/urlaub-hund-haustier-400x225.jpg`,
        href: 'home:teaserTopicsUrls.pets',
        title: 'home:teaserTopics.pets',
      },
      {
        teaser: 'extraordinary',
        img: `${CDN}/assets/categories/aussergewoehnliche-unterkuenfte-400x225.jpg`,
        href: 'home:teaserTopicsUrls.extraordinary',
        title: 'home:teaserTopics.extraordinary',
      },
      {
        teaser: 'pool',
        img: `${CDN}/assets/categories/ferienhaeuser-mit-pool-400x225.jpg`,
        target: '',
        href: 'home:teaserTopicsUrls.pool',
        title: 'home:teaserTopics.pool',
        logo: '',
      },
      {
        teaser: 'lastminute',
        img: `${CDN}/assets/categories/last-minute-400x225.jpg`,
        href: 'home:teaserTopicsUrls.lastminute',
        title: 'home:teaserTopics.lastminute',
      },
    ],
    favoriteRegions: [
      { title: 'home:favoriteRegions.luebeckerbucht', href: 'locationLinks:region.luebeckerbucht', hide: false },
      { title: 'home:favoriteRegions.ruegen', href: 'locationLinks:region.ruegen', hide: false },
      { title: 'home:favoriteRegions.dithmarschen', href: 'locationLinks:region.dithmarschen', hide: false },
      { title: 'home:favoriteRegions.aurich', href: 'locationLinks:region.aurich', hide: false },
      { title: 'home:favoriteRegions.istrien', href: 'locationLinks:region.istrien', hide: false },
      { title: 'home:favoriteRegions.mosel', href: 'locationLinks:region.mosel', hide: false },
      { title: 'home:favoriteRegions.usedom', href: 'locationLinks:region.usedom', hide: false },
      { title: 'home:favoriteRegions.mecklenburgischeostseekueste', href: 'locationLinks:region.mecklenburgischeostseekueste', hide: false },
      { title: 'home:favoriteRegions.fischland', href: 'locationLinks:region.fischland', hide: false },
      { title: 'home:favoriteRegions.lueneburgerheide', href: 'locationLinks:region.lueneburgerheide', hide: false },
      { title: 'home:favoriteRegions.eiderstedt', href: 'locationLinks:region.eiderstedt', hide: false },
      { title: 'home:favoriteRegions.harz', href: 'locationLinks:region.harz', hide: false },
      { title: 'home:favoriteRegions.nordfriesland', href: 'locationLinks:region.nordfriesland', hide: false },
      { title: 'home:favoriteRegions.salzhaff', href: 'locationLinks:region.salzhaff', hide: false },
      { title: 'home:favoriteRegions.ostseespitze', href: 'locationLinks:region.ostseespitze', hide: false },
      { title: 'home:favoriteRegions.jadebusen', href: 'locationLinks:region.jadebusen', hide: false },
    ],
  },
};
