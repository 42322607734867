export default {
  uid: '',
  sharedWatchListUserUID: '',
  ebk: '',
  watchListUID: '',
  storeSubscriptions: {},
  lastSeenObjects: [],
  watchedObjects: [],
  bookingList: [],
  bookingDetails: {},
  watchedObjectsShared: [],
  watchedObjectsSearch: [],
  watchedObjectsAggregations: {
    searchBounds: {},
  },
  pidLatest: '',
  pidHistory: [],
  ptcLatest: '',
  gclidLatest: '',
  msclkidLatest: '',
  dclidLatest: '',
  encouragementFlag: '',
  experiments: {},
  jwt: '',
  accountData: {
    accountID: null,
    accountEmail: '',
    accountFirstName: '',
    accountLastName: '',
    accountShortName: '',
    accountPermissions: {},
  },
};
