import axios from 'axios';
import axiosConfig from 'axiosconfig';
import {
  responseInterceptor, errorInterceptor, reqInterceptor,
} from 'apiclient';

let cancel;
const { CancelToken } = axios;

const searchRequest = axios.create(axiosConfig);
searchRequest.interceptors.request.use(reqInterceptor);
searchRequest.interceptors.response.use(responseInterceptor, errorInterceptor);

// eslint-disable-next-line func-names
const loadSearchObjects = function (searchTerms, languageCode, cancelable = true) {
  if (cancelable && cancel !== undefined) {
    cancel('Operation canceled; deprecated request');
  }

  const partnerID = searchTerms?.partner || '';
  return searchRequest.get('/api/search', {
    cancelToken: new CancelToken(((c) => {
      if (cancelable) {
        cancel = c;
      }
    })),
    params: {
      query: searchTerms,
      languageCode,
      mandant: process.env.CLIENTNAME,
      partner: partnerID,
    },
  }, {
    cache: {
      ignoreCache: false,
      maxAge: 15 * 60 * 10000,
    },
  })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isCancel(error)) {
        // eslint-disable-next-line no-console
        console.log('Request canceled', error.statusText.msg);
        return Promise.resolve();
      }

      return Promise.reject(error);
    });
};

const capacityRequest = axios.create(axiosConfig);
capacityRequest.interceptors.response.use(responseInterceptor, errorInterceptor);
export function getCapacity(searchTerms) {
  return capacityRequest.get('/api/capacity', {
    params: {
      query: searchTerms,
      mandant: process.env.CLIENTNAME,
    },
  }, {
    cache: {
      ignoreCache: false,
      maxAge: 15 * 60 * 10000,
    },
  }).then((res) => res.data)
    .catch((result) => Promise.reject(result.errors));
}

const searchTrackRequest = axios.create(axiosConfig);
searchTrackRequest.interceptors.response.use(responseInterceptor, errorInterceptor);
export function trackSearch(searchTrackVars, uid, searchTotalObjects) {
  return searchTrackRequest.post('/api/searchTrack', {
    trackVars: searchTrackVars,
    totalObjects: searchTotalObjects,
    mandant: process.env.CLIENTNAME,
    uid,
  }, {
    cache: {
      ignoreCache: true,
    },
  }).then((res) => res.data)
    .catch((result) => Promise.reject(result.errors));
}

const searchTrackObjectRequest = axios.create(axiosConfig);
searchTrackObjectRequest.interceptors.response.use(responseInterceptor, errorInterceptor);
export function trackSearchObject(objectNr) {
  return searchTrackRequest.post('/api/searchTrackObject', {
    objectNumber: objectNr,
    mandant: process.env.CLIENTNAME,
  }, {
    cache: {
      ignoreCache: true,
    },
  }).then((res) => res.data)
    .catch((result) => Promise.reject(result.errors));
}

export default loadSearchObjects;
