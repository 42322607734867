import Vue from 'vue';
import {
  setCookie,
  getCookieVal,
  removeCookie,
} from 'mixins/composition/CookieApi';
import config from 'config/appConfig';

export default {
  SET_USER_UID: (state, { uid }) => {
    if (uid) {
      setCookie({
        name: 'uid',
        value: uid,
        expires: '1y',
      });
      Vue.set(state, 'uid', uid);
    }

    // delete "undefined" uid cookie to prevent it from lasting 365d
    if (getCookieVal('uid') === 'undefined') {
      removeCookie('uid');
    }
  },
  SET_SHARED_WATCHLIST_USER_UID: (state, { uid }) => {
    Vue.set(state, 'sharedWatchListUserUID', uid);
  },
  SET_USER_WATCHLISTUID: (state, watchListUID) => {
    Vue.set(state, 'watchListUID', watchListUID);
  },
  SET_STORE_SUBSCRIPTION: (state, subscriptionTarget) => {
    Vue.set(state.storeSubscriptions, subscriptionTarget, true);
  },
  SET_BOOKINGLIST: (state, bookingList) => {
    Vue.set(state, 'bookingList', bookingList);
  },
  SET_BOOKINGDETAILS: (state, bookingDetails) => {
    Vue.set(state, 'bookingDetails', bookingDetails);
  },
  SET_WATCHED_OBJECT: (state, objectData) => {
    // store objectNumber as integer
    objectData.objectNumber = parseInt(objectData.objectNumber, 10);

    // check if object is already stored
    let stateIndex = state.watchedObjects.length;
    state.watchedObjects.forEach((watchedObject, watchedObjectIndex) => {
      if (watchedObject.objectNumber === objectData.objectNumber) {
        stateIndex = watchedObjectIndex;
      }
    });

    // update object in store or set new object in store
    Vue.set(state.watchedObjects, stateIndex, objectData);
  },
  SET_WATCHED_OBJECT_SHARED: (state, objectData) => {
    // store objectNumber as integer
    objectData.objectNumber = parseInt(objectData.objectNumber, 10);

    // check if object is already stored
    let stateIndex = state.watchedObjectsShared.length;
    state.watchedObjectsShared.forEach((watchedObjectShared, watchedObjectSharedIndex) => {
      if (watchedObjectShared.objectNumber === objectData.objectNumber) {
        stateIndex = watchedObjectSharedIndex;
      }
    });

    // update object in store or set new object in store
    Vue.set(state.watchedObjectsShared, stateIndex, objectData);
  },
  RESET_WATCHED_OBJECT_SHARED: (state) => {
    // delete all objects from watchedObjectsShared
    Vue.set(state, 'watchedObjectsShared', []);
  },
  // last seen objects
  SAVE_LASTSEENOBJECTS: (state, lastSeenObjects) => {
    state.lastSeenObjects = [];
    if (lastSeenObjects && typeof lastSeenObjects !== 'undefined') {
      Object.values(lastSeenObjects).forEach((lastSeenObject) => {
        state.lastSeenObjects.push(parseInt(lastSeenObject.objectNumber, 10));
      });
    }
  },
  SET_PID_LATEST(state, pid) {
    if (pid) {
      // pid is sometimes an array, only store one pid
      let pidLatest = pid;
      if (typeof pidLatest === 'object') {
        pidLatest = pidLatest.pop();
      }
      const typeOfPidLatest = typeof pidLatest;
      const matchAble = ['string', 'number'].includes(typeOfPidLatest);
      if (matchAble) {
        pidLatest = String(pidLatest);
        // only allow numbers as partnerID
        if (pidLatest.match(/^[0-9]+$/)) {
          Vue.set(state, 'pidLatest', pidLatest);
        }
      }
    }
  },
  SET_PID_HISTORY(state, pidHistory) {
    if (pidHistory) {
      Vue.set(state, 'pidHistory', pidHistory);
    }
  },
  SET_PTC_LATEST(state, ptc) {
    if (ptc) {
      Vue.set(state, 'ptcLatest', ptc);
    }
  },
  SET_GCLID_LATEST(state, gclid) {
    if (gclid) {
      Vue.set(state, 'gclidLatest', gclid);
    }
  },
  SET_MSCLKID_LATEST(state, msclkid) {
    if (msclkid) {
      Vue.set(state, 'msclkidLatest', msclkid);
    }
  },
  SET_DCLID_LATEST(state, dclid) {
    if (dclid) {
      Vue.set(state, 'dclidLatest', dclid);
    }
  },
  SET_WATCHED_OBJECTS_SEARCH: (state, { watchedObjectsArray, requestId }) => {
    state.watchedObjectsSearch = [];
    if (watchedObjectsArray?.length) {
      watchedObjectsArray.forEach((object) => {
        if (object?.objectNumber) {
          Vue.set(state.watchedObjectsSearch, state.watchedObjectsSearch.length, object);
        }
      });
      if (requestId) {
        Vue.set(state, 'mapData', { id: requestId });
      }
    }
  },
  SET_AGGREGATIONS_WATCHLIST: (state, searchResultAggs) => {
    if (searchResultAggs) {
      Vue.set(state, 'watchedObjectsAggregations', searchResultAggs);
    }
  },
  SET_USER_EBK: (state, { ebk }) => {
    Vue.set(state, 'ebk', ebk);
  },
  SET_ENCOURAGEMENT_FLAG: (state, { objectNumber }) => {
    Vue.set(state, 'encouragementFlag', objectNumber);
  },
  SET_EXPERIMENT_DATA: (state, experimentData) => {
    Vue.set(state, 'experiments', experimentData);
  },
  SET_JWT: (state, { jwt }) => {
    let jwtVal = jwt;
    if (jwtVal === 'null') {
      jwtVal = '';
    }
    Vue.set(state, 'jwt', jwtVal);
    setCookie({
      name: 'jwt',
      value: jwtVal,
      expires: '7d',
    });
  },
  SET_ACCOUNT_DATA: (state, { accountData }) => {
    // must saved separately to make accountPermissions reactive
    Vue.set(state.accountData, 'accountID', accountData?.accountID || null);
    Vue.set(state.accountData, 'accountEmail', accountData?.accountEmail || '');
    Vue.set(state.accountData, 'accountFirstName', accountData?.accountFirstName || '');
    Vue.set(state.accountData, 'accountLastName', accountData?.accountLastName || '');
    Vue.set(state.accountData, 'accountShortName', accountData?.accountShortName || '');
    if (accountData.accountPermissions?.length) {
      Object.values(accountData.accountPermissions).forEach((permissionName) => {
        Vue.set(state.accountData.accountPermissions, permissionName, true);
        if (permissionName === 'localeEditor.update') {
          config.locales.supportedLanguages = Object.assign(config.locales.supportedLanguages, {
            db: {
              currency: 'EUR',
              locale: 'db',
            }
          });
        }
      });
    } else {
      Vue.set(state.accountData, 'accountPermissions', {});
    }
  },
};
