import Vue from 'vue';
import Vuex from 'vuex';

import config from 'config/appConfig';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
// import searchPlugin from './searchPlugin';

import objectModule from './object/module';
import userModule from './user/module';
import searchModule from './search/module';
import landlordModule from './landlord/module';
import tiptapModule from './tiptap/module';

import locationDefault from './defaults/location';


Vue.use(Vuex);

const getStore = () => new Vuex.Store({
  // plugins: [searchPlugin],
  modules: {
    objects: objectModule,
    user: userModule,
    search: searchModule,
    landlord: landlordModule,
    tiptap: tiptapModule,
  },
  state: () => ({
    menuOpen: false,
    flatpickrOpened: false,
    gtmPush: 0,
    gaProperty: '',
    usertype: 'undefined',
    userSaved: 'false',
    routerPageview: false,
    pois: {}, // pois of objects and landing-pages
    mapdata: {},
    showModalStatus: false, // show/hide modal by setting this state
    showMapStatus: false,
    searchMapFullscreen: false, // fullscreen true/false modal by setting this state
    decreaseZoom: false, // if true, zoom out
    searchOvergrey: false, // grey overlay over search object list on/off
    // data for the currently active object in object view
    pageLoading: false,
    searchSuccessfull: false,
    searchHasErrors: false,
    searchCapacity: 0,
    searchObjects: {},
    searchObjectsTeaser: {},
    searchObjectsLastSeen: {},
    searchTrackVars: {},
    searchTotalObjects: 0,
    searchTotalObjectsCollapse: 0,
    searchTotalObjectsCriteriaMatch: 0,
    criteriaMatchSearch: false,
    searchTimeES: 0,
    isSeoPage: null,
    isSeaPage: null,
    usePageCache: false,
    dyncomponent: {},
    locationSuccessfull: false,
    locationInformations: locationDefault,
    coordinatesSearch: false,
    locationRequestInprogress: null,
    // search via Location IDs or Radius or Rectangle
    searchLocationFilter: {},
    offerInformations: [],
    // generally valid filter
    searchFilter: {},
    searchFilterForced: {},
    lastSearchFilters: {},
    searchAggregations: {}, // data of aggregations shown in search
    directusContent: {},
    retailerResponse: {}, // response of retailer for rating
    officeContact: {
      officeRandom: 0,
      officeExpires: 0,
      employeeData: {
        staff: [],
        staffTimes: {},
      },
    },
    officeInfoFull: [],
    officeOverwrites: {},
    appIntersectClass: {},
    i18next: {
      de: {
        common: {},
      },
    },
    app: {
      isNode: false,
      isSPA: false,
      isBot: false,
      isMobile: false,
      ua: {},
      ssrHttpStatus: 200,
      httpRewriteTo: '',
      apiHttpStatus: {
        offer: 200,
        officeContact: 0,
        officeInfo: 0,
        officeInfoFull: 0,
        directusList: 0,
      },
      apiErrorText: {
        header: '',
        body: '',
      },
      currentLanguage: config.locales.standardLanguage,
      currentCurrency: config.locales.helper.getLanguageParams(config.locales.standardLanguage).currency,
      currentLocale: config.locales.helper.getLanguageParams(config.locales.standardLanguage).locale,
      addCanonical: true,
      router: {
        whoami: {
          data: {},
          list: [],
        },
        offer: {
          data: {},
          list: [],
        },
        popstate: false,
        navigationFromPopstate: false,
        seoSearchFilter: [],
        lastAppHeight: 0,
        isEntryRoute: true,
      },
    },
    ssr: {
      headerSearchBgImage: null,
      headerSearchBgImageAlt: null,
    },
    awinClickChecksum: false,
    tracking: {
      cgpDebounce: false,
      performance: {
        webVitals: {
          done: 0,
        },
      },
      consent: {
        default: {
          done: 0,
        },
      },
      test: 15,
    },
    test: 120,
    objectInternalData: {},
    covidStateStatus: [],
    cookieLaw: {
      required: true,
      statistic: false,
      marketing: false,
      confirmed: false,
    },
    partnerConfig: {
      partnerID: '',
      partnerTagName: '',
      partnerName: '',
      partnerParent: '',
      partnerPhone: '',
      partnerUrl: '',
      ignorePartnerUrl: '',
      partnerEmail: '',
      paxloungeNr: '',
      hideInsurances: 'N',
      hideNewsletter: 'N',
      hideBonus: 'N',
      // addPartnerTagsOrder: 'N',
      allowMidocoUsage: 'N',
    },
    affiliateRedirectLink: '',
    recommendations: [],
  }),
  mutations,
  actions,
  getters,
});

export default getStore;
