// currently used by action.js to set up experiments and partner ID for SEARCH_OBJECTS and SEARCH_OBJECTS_TEASER


// additional experiments can be added here
// eslint-disable-next-line no-unused-vars
const addExperimentFilter = (experimentObject) => {
  const addedFilters = { };
  /* ########## OLD EXPERIMENTS ########## */
  // if (experimentObject.experimentID === 'experiment_EDVUE-3367') {
  //   if (experimentObject.experiment?.variant !== '' && experimentObject.experiment?.experimentID !== '' && typeof experimentObject.experiment?.experimentID !== 'undefined') {
  //     // storeFilters = Object.assign(storeFilters, { experimentValue: searchExperimentValueEDVUE3367.variant });
  //     // storeFilters = Object.assign(storeFilters, { experiment: 'EDVUE-3367' });
  //     addedFilters.experimentValue = experimentObject.experiment.variant;
  //     addedFilters.experiment = 'EDVUE-3367';
  //   }
  // }
  /* #################### */
  return addedFilters;
};

const setPartnerID = (partnerID) => {
  let addedFilters = {};
  if (partnerID !== '') {
    addedFilters = { ...{ partner: partnerID } };
  }
  return addedFilters;
};


export { addExperimentFilter, setPartnerID };
