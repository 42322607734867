export default {
  priceObject: {
    currency: 'EUR',
    original: null,
    price: null,
    priceComplete: null,
    pricePercentReduction: null,
    originalWithoutAdditions: null,
    persons: 2,
    startDate: null,
    endDate: null,
    duration: 0,
  },
  priceServicesMandatory: {},
  priceServicesOnSiteMandatory: {},
  priceServicesOnSiteOptional: {},
  priceCalculated: 'N',
  externalBookingOK: '',
};
