import axios from 'apiclient';

const userGet = (uid, uidOrigin, jwt, trackingParams, revision, experiments, breakPoint, screenWidth, screenHeight, localeInit) => axios.post('/api/user/get', {
  mandant: process.env.CLIENTNAME,
  uid,
  uidOrigin,
  jwt,
  trackingParams,
  revision,
  experiments,
  breakPoint,
  screenWidth,
  screenHeight,
  localeInit,
}, {
  timeout: 20000,
}).then((result) => Promise.resolve(result.data));

export default userGet;
