// import { redirectTo } from 'router/guards/navigationGuards';
import loadOffer from 'requests/loadOffer';
import config from 'config/appConfig';
import { getQuerySearchFilters } from 'utils/SearchFilterHelper';

export default ({
  from, store, to, next,
// eslint-disable-next-line consistent-return
}) => {
  if (!to.params.offer && !to.query.offer && !to.meta?.searchFilter?.offer) {
    store.commit('SET_OFFER_LANDINGPAGE', { isoffer: false });
    next();
    return;
  }
  // Is not foolproof, but will suffice for most cases
  const startPage = (Object.keys(getQuerySearchFilters(to.query)).length <= 3);
  store.commit('SET_OFFER_LANDINGPAGE', { isoffer: startPage });

  const offerID = parseInt(to.params.offer || to.query.offer || to.meta?.searchFilter?.offer, 10);
  const offerData = store.getters.getOfferData(offerID);

  // ssr error with offer request
  if (store.getters.getApiHttpStatus('offer') !== 200) {
    store.commit('SET_SEARCHFILTER', { offer: '' });
    store.commit('SET_APP_HTTPSTATUS', store.getters.getApiHttpStatus('offer'));
    next();
    return;
  }

  // no offer data, grab it
  if (offerData === false) {
    loadOffer(store.state.app.currentLanguage, offerID)
      .then((offerInfo) => {
        const data = offerInfo.offers[0];
        if (data.seoUrl && data.seoUrl !== '') {
          if (store.state.app.currentLanguage !== config.locales.standardLanguage) {
            data.seoUrl = `/${store.state.app.currentLanguage}${data.seoUrl}`;
          }
        }
        store.commit('SET_OFFER_DATA', {
          id: `${offerID}_${store.state.app.currentLanguage}`,
          data,
        });
        store.commit('SET_SEARCHFILTER', data.query);
        // }
        next();
      })
      .catch((err) => {
        store.commit('SET_APP_APIHTTPSTATUS', { type: 'offer', status: err.status });
        store.commit('SET_APP_HTTPSTATUS', err.status);
        next();
      });
  } else if (typeof to?.matched?.[0]?.components?.default === 'function') {
    // only use offerData if we come from another component i.E. no from /besondere-angebote/199/familienurlaub-ostern/ (Search) => /search/ (Search)
    to?.matched?.[0]?.components?.default().then((module) => {
      if (from?.matched?.[0]?.components?.default.name !== module.default.name) {
        store.commit('SET_SEARCHFILTER', { offer: '' });
        store.commit('SET_SEARCHFILTER', offerData?.query);
      }
      next();
    }).catch((e) => { next(); return Promise.reject(e); });
  } else {
    if (from?.matched?.[0]?.components?.default.name !== to?.matched?.[0]?.components?.default.name
        ||
      from?.path === to?.path
        ||
      store?.state?.app?.router?.popstate // browser back
    ) {
      store.commit('SET_SEARCHFILTER', { offer: '' });
      store.commit('SET_SEARCHFILTER', offerData?.query);
    }
    next();
  }
};
