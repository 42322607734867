import { getClearedFilter } from 'utils/SearchFilterHelper';

const saveQueryToSearchStore = ({
  store, next, to,
}) => {
  // write query to store, this might be better to put elsewhere?
  const searchFilter = Object.keys(to.query).reduce((aggregated, current) => {
    // check for integer or NULL
    if (/^\d+$/.test(to.query[current]) || !to.query[current]) {
      aggregated[current] = parseInt(to.query[current], 10) || 0;
    } else {
      // express (ssr) does decodeURIComponent() automatically
      //  -> see https://expressjs.com/en/api.html#req.params
      // but client does not do decodeURIComponent() automatically
      // by using it here, we can make sure that ssr and client
      // have the same decoded values in store
      aggregated[current] = decodeURIComponent(to.query[current]);
    }
    return aggregated;
  }, {});

  // set search filter of parameter that have to be set for this user
  /*
  const accountData = store.getters.getAccountData;
  if (accountData?.accountPermissions && accountData?.accountPermissions?.['search.useForcedFilters']) {
    const forcedFilters = store.getters.getSearchFilterForced;
    if (Object.keys(forcedFilters).length > 0) {
      Object.entries(forcedFilters).forEach((filter) => {
        const [filterName, filterValue] = filter;
        if (typeof searchFilter[filterName] === 'undefined') {
          Object.assign(searchFilter, { [filterName]: filterValue });
        }
      });
    }
  }
  */

  store.commit('SET_SEARCHFILTER', searchFilter);
  next();
};

const saveMetaToSearchStore = ({ store, next, to }) => {
  store.commit('SET_SEARCHFILTER', to.meta.searchFilter);
  next();
};

const resetSearchFilters = (store, ignoreList = [], exclusiveList = []) => {
  const filter = getClearedFilter(store.state.searchFilter, ignoreList, exclusiveList);
  const emptySearchFilter = Object.keys(filter).reduce((collection, current) => {
    collection[current] = null; // setting to zero deletes key
    return collection;
  }, {});
  store.commit('SET_SEARCHFILTER', emptySearchFilter);

  const locationFilter = getClearedFilter(store.state.searchLocationFilter, ignoreList, exclusiveList);
  const emptyLocationFilter = Object.keys(locationFilter).reduce((collection, current) => {
    collection[current] = null; // setting to zero deletes key
    return collection;
  }, {});
  store.commit('SET_SEARCHFILTER', emptyLocationFilter);
};

const resetAllSearchFilters = ({ store, next }) => {
  resetSearchFilters(store);
  next();
};

export {
  saveQueryToSearchStore, resetSearchFilters, resetAllSearchFilters, saveMetaToSearchStore,
};
