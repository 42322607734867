<template>
  <div class="contact-header">
    <div class="text">
      <small v-if="contactHeader.top.display.string">
        {{ $t(contactHeader.top.display.string, contactHeader.top.display.stringData) }}
      </small>
      <router-link-simple
        v-if="contactHeader.middle.interact"
        class="contact-method"
        :to="arp_linkBuilder({href: $t(contactHeader.middle.interact.string, contactHeader.middle.interact.stringData)})"
        :data-ident="`contactMiddleInteract`"
        @click.prevent="arp_linkClicker({href: $t(contactHeader.middle.interact.string, contactHeader.middle.interact.stringData)})"
      >
        <em>
          <i class="icon">
            <icon-phone />
          </i>
          {{ $t(contactHeader.middle.display.string, contactHeader.middle.display.stringData) }}
        </em>
      </router-link-simple>
      <small>
        <template v-if="contactHeader.bottom.interact.prefix">
          {{ $t(contactHeader.bottom.interact.prefix) }}
        </template>
        <router-link-simple
          v-if="contactHeader.bottom.interact"
          :to="arp_linkBuilder({href: $t(contactHeader.bottom.interact.string, contactHeader.bottom.interact.stringData)})"
          :data-ident="`contactBottomInteract`"
          @click.prevent="arp_linkClicker({href: $t(contactHeader.bottom.interact.string, contactHeader.bottom.interact.stringData)})"
        >
          {{ $t(contactHeader.bottom.display.string, contactHeader.bottom.display.stringData) }}
        </router-link-simple>
        <template v-else>
          {{ $t(contactHeader.bottom.display.string, contactHeader.bottom.display.stringData) }}
        </template>
      </small>
    </div>
    <div
      v-if="contactHeader.contactImage.images"
      class="profile"
    >
      <picture>
        <source
          :srcset="$t(contactHeader.contactImage.images['45x45_webp'])"
          type="image/webp"
        >
        <source
          :srcset="$t(contactHeader.contactImage.images['45x45'])"
          type="image/jpeg"
        >
        <img
          :src="$t(contactHeader.contactImage.images['45x45'])"
          :alt="$t('office:openingsStatic.global.contactName')"
          width="45"
          height="45"
        >
      </picture>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';
import AnchorRouterPush from 'mixins/AnchorRouterPush';
import IconPhone from 'ICON:IconPhone';

export default {
  name: 'OfficeContactHeader',
  components: {
    RouterLinkSimple,
    IconPhone,
  },
  mixins: [
    AnchorRouterPush,
  ],
  i18nOptions: {
    namespaces: ['office']
  },
  props: {
    contactHeader: {
      type: Object,
      required: true,
    },
    contactCard: {
      type: Object,
      required: true,
    },
    objectNumber: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    startDate: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    endDate: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },
};
</script>
