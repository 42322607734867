import axios from 'apiclient';

const bookingListGet = (uid, jwt) => axios.post('/api/user/getBookings', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  type: 'bookings',
}).then((result) => Promise.resolve(result.data));

export default bookingListGet;
