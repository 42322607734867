export default {
  gaProperties: {
    de: {
      customer: '',
      service: '',
      shortcut: 'WLV',
    },
    fallback: {
      customer: '',
      service: '',
      shortcut: '',
    },
  },
};
