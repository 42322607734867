import axios from 'apiclient';

const userPasswordReset = (uid, resetID, user) => axios.post('/api/user/password/reset', {
  mandant: process.env.CLIENTNAME,
  uid,
  resetID,
  user,
}, {
  timeout: 20000,
}).then((result) => Promise.resolve(result.data));

export default userPasswordReset;
