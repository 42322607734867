export default ({ to, store, next }) => {
  if (store.state.app.router.popstate && to.hash) {
    next({
      ...to,
      hash: '',
    });
  } else {
    next();
  }
};

