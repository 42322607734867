import { routeBuilder } from 'utils/locale/routeHelpers';
import config from 'config/appConfig';

// generate href attribute from router object
export default {
  data() {
    return {
      // check if link leaves the application, i.e. other domain, "tel:" or "mailto:"
      regexLinkLeavesApp: /^(http(s)?|\/\/|mailto:|tel:)/,
    };
  },
  methods: {
    arp_linkBuilder(to) {
      let toString = to;
      let toObject = to;
      if (to.href) {
        toString = to.href;
      }
      if (typeof toString === 'string' && toString.match(/^[^:]+:[^:]+$/) && toString.match(this.regexLinkLeavesApp) === null) {
        toObject = this.$t(toString);
        if (typeof toObject === 'string') {
          if (toObject.match(this.regexLinkLeavesApp) !== null) {
            return toObject;
          }
        }
      }
      if (typeof toString === 'string') {
        if (toString.match(this.regexLinkLeavesApp) !== null) {
          return toString;
        }
      }
      const resolvedRoute = this.$router.resolve(routeBuilder(toObject, this.$router, this.$store));
      if (this.$store.state.usertype === 'service' || config.router.ota.domain === null || config.router.to.whitelist.includes(resolvedRoute?.resolved?.path)) {
        return resolvedRoute?.resolved?.fullPath ?? '/';
      }
      return config.router.ota.domain + (resolvedRoute?.resolved?.fullPath ?? '/');
    },
    arp_linkClicker(to) {
      let toString = this.arp_linkBuilder(to);

      if (toString.match(/^[^:]+:[^:]+$/) && toString.match(this.regexLinkLeavesApp) === null) {
        toString = this.$t(toString);
      }

      if (typeof toString === 'string' && toString.match(this.regexLinkLeavesApp) !== null) {
        const newTab = window.open(toString, toString.indexOf(config.router.ota.domain) !== -1 ? '_self' : '_blank', 'noopener');
        if (newTab) {
          newTab.opener = false;
        }
        return;
      }

      if (this.$route.fullPath.replace(/\//g, '') !== this.arp_linkBuilder(toString).replace(/\//g, '')) {
        this.$router.push(this.arp_linkBuilder(toString));
      }
    },
  },
};
