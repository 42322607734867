import Vue from 'vue';
import { diff } from 'deep-object-diff';
import defaultsDeep from 'lodash.defaultsdeep';
import { searchLocationFilterNames } from 'router/guards/queryConfig';
import loadSearchObjects, { getCapacity } from 'requests/searchObjects';
import loadOfficeContact from 'requests/loadOfficeContact';
import loadOfficeInfoFull from 'requests/loadOfficeInfoFull';
import loadOfficeOverwrites from 'requests/loadOfficeOverwrites';
import updateOfficeOverwrites from 'requests/updateOfficeOverwrites';
import { loadPoi, loadMultiplePois } from 'requests/loadPoi';
import loadLocation from 'requests/loadLocation';
import loadOffer from 'requests/loadOffer';
import { newsletterSubscribe, contactRequest } from 'requests/mailerGateway';
import doiConfirm from 'requests/newsletter';
import { loadObjectInternal } from 'requests/loadObject';
import { getCookieVal } from 'mixins/composition/CookieApi';
import getCovidData, { fetchCovidStateStatus } from 'requests/covidRegulations';
import { redeemTravelVoucherRequest, travelVoucherPayments } from 'requests/redeemTravelVoucher';
import { loadResponse, setResponse } from 'requests/retailerResponse';
import config from 'config/appConfig';
import { sha256 } from 'js-sha256';
import { addExperimentFilter, setPartnerID } from 'mixins/searchObjectsAdditionalFilters';
import loadRecommendations from 'requests/loadRecommendations';
import getUserdata, { setUserdata } from 'requests/mailerPreferences';
import sendAnswer from 'requests/sendICBEAndPartnerRegistration';

export default {
  async SEARCH_OBJECTS({
    state, commit, getters, dispatch,
  }, {
    preventSearch = false, forceSearch = false,
  }) {
    // first search always should be forced
    const firstSearch = (!state.searchSuccessfull && Object.keys(state.lastSearchFilters).length === 0);

    // commit new filters first so they're included in search
    const storeFiltersGet = getters.getMergedTerms;
    let storeFilters = { ...storeFiltersGet };

    const languageCode = state.app.currentLanguage;
    // storeFilters = Object.assign(storeFilters, { language: languageCode });

    const storeFilterDiff = diff(state.lastSearchFilters, storeFilters);
    const storeFiltersChanged = Object.keys(storeFilterDiff).length > 0;

    // check for new location filters
    let locationFiltersChanged = Object.keys(storeFilterDiff).filter((key) => searchLocationFilterNames.includes(key)).length > 0;
    if (storeFilterDiff.language) locationFiltersChanged = true;

    // if not location filters changed we might need to add q here
    // this is the case if we're coming from SSR
    if (!locationFiltersChanged) {
      if (getters.getLocationBreadcrumbs.length && !getters.getSearchFilter('fulltext')) {
        commit('SET_SEARCHFILTER', { q: getters.getLocationName });
      }
    }

    // case: filters changed and we don't want to force search
    // essentially forceSearch can be used to trigger search without supplying any filters
    if (!storeFiltersChanged && !forceSearch && !firstSearch) {
      return Promise.resolve();
    }

    // save last search terms
    commit('SET_LASTSEARCHFILTER', storeFilters);
    delete storeFilters.language;

    // wait for additional filters then continue
    const additionalFilters = await dispatch('ADDITIONAL_FILTERS');

    // merging store filters with experiments and partner ID, if no extra filter is set, we are spreading with
    // an empty object, so nothing should happen
    storeFilters = {
      ...storeFilters,
      ...additionalFilters,
    };

    // user doesn't want a search, so we can stop here
    if (preventSearch) return Promise.resolve();

    const countryID = getters.getSearchLocationFilter('country');
    const region = getters.getSearchLocationFilter('region');
    const place = getters.getSearchLocationFilter('place');
    const bbox = getters.getSearchLocationFilter('BBoxString');

    const searchDefaults = {
      persons: 2,
      priceMax: config.defaults.searchPriceMax,
    };

    Vue.set(state, 'searchSuccessfull', false);
    Vue.set(state, 'searchHasErrors', false);
    if (locationFiltersChanged) Vue.set(state, 'locationSuccessfull', false);
    return Promise.all([
      loadSearchObjects(defaultsDeep({}, storeFilters, searchDefaults), languageCode)
        .then((searchObjects) => {
          commit('SET_SEARCH_COUNTER', { searchObjects });
          commit('SET_OBJECTS', { searchObjects });
          commit('SET_AGGREGATIONS', { searchObjects });
          // set client search to true because search object list displays different lazy loads dependant on server or client
          if (!state.app.isNode) commit('SET_SEARCH_CLIENTSEARCH', true);
        }).catch((e) => {
          Vue.set(state, 'searchHasErrors', true);
          commit('SET_SEARCH_COUNTER', { hitsTotal: 0 });
          commit('SET_OBJECTS', { });
          commit('SET_AGGREGATIONS', { });
          return Promise.reject(e);
        }),
      Promise.all([
        dispatch('GET_CAPACITY'),
        (locationFiltersChanged
          ? dispatch('GET_LOCATION', {
            country: countryID, location: ((place !== '') ? place : region), bbox, languageCode,
          })
          : Promise.resolve())
          .finally(() => {
            // set content of fulltext search in case a location is set, but not through fulltext search
            // this is done to enable the user to remove locations set through landing pages etc.
            if (getters.getLocationBreadcrumbs.length && !getters.getSearchFilter('fulltext')) {
              commit('SET_SEARCHFILTER', { q: getters.getLocationName });
            }
          }),
      ])
        .catch(() => Promise.resolve()), // catch errors because we don't want this to bubble down to the search view,
    ]).finally(() => {
      Vue.set(state, 'searchSuccessfull', true);
      Vue.set(state, 'locationSuccessfull', true);
    });
  },
  // this search action is used to do a search without touching/changing the "normal" search store variables
  // used i.e. in CustomObjectTeaser.vue which displays objects for a specific search query
  async SEARCH_OBJECTS_TEASER({
    state, commit, getters, dispatch,
  }, {
    queryParams = {},
    searchURL = '',
  }) {
    // check if requested search already stored
    const shaKey = sha256(searchURL);
    if (getters.getSearchObjectsTeaser(shaKey)) {
      return Promise.resolve();
    }
    // wait for experiment filters, before continuing
    const additionalFilters = await dispatch('ADDITIONAL_FILTERS');

    // default search filters, can be overwritten
    const queryDefault = {
      persons: 2,
      priceMax: 6000,
      objectsPerPage: 5,
    };


    // merge all search params to later use in request
    const queryMerged = {
      ...queryDefault,
      ...queryParams,
      ...additionalFilters,
    };

    const languageCode = state.app.currentLanguage;

    return loadSearchObjects(queryMerged, languageCode, false)
      .then((searchResult) => {
        if (searchResult.hits) {
          commit('SET_SEARCH_TEASER', { content: searchResult.hits, key: shaKey });
        }
      });
  },
  SEARCH_OBJECTS_LAST_SEEN: ({
    state, commit, getters,
  }, {
    objectNumbers = [],
  }) => {
    // check if all requested objects already stored
    let objectsStored = true;
    objectNumbers.forEach((objectNumber) => {
      if (!getters.getSearchObjectLastSeen(objectNumber)) {
        objectsStored = false;
      }
    });
    if (objectsStored) {
      return Promise.resolve();
    }

    const languageCode = state.app.currentLanguage;
    const searchQuery = {
      objects: objectNumbers.join(','),
    };

    return loadSearchObjects(searchQuery, languageCode, false).then((searchResult) => {
      if (searchResult.hits?.length) {
        searchResult.hits.forEach((objectData) => {
          commit('SET_OBJECT_LAST_SEEN', { objectData });
        });
      }
      return Promise.resolve();
    });
  },
  GET_CAPACITY: ({ commit, getters }) => {
    const terms = getters.getMergedTerms;
    if (typeof terms.startDate !== 'undefined' && typeof terms.endDate !== 'undefined') {
      return getCapacity(terms).then((capacity) => {
        commit('SET_CAPACITY', capacity.percentage);
      }).finally(() => {
      });
    }

    commit('SET_CAPACITY', null);
    return Promise.resolve();
  },

  GET_OFFICE_CONTACT: ({ state, commit }) => {
    const nowStamp = Math.floor(new Date().getTime() / 1000);
    if (state.officeContact?.officeExpires < nowStamp) {
      return loadOfficeContact()
        .then((officeContact) => commit('SET_OFFICE_CONTACT', officeContact));
    }
    return Promise.resolve();
  },
  // all active employees
  GET_OFFICE_INFO_FULL: ({ state, commit }) => {
    if (!state.officeInfoFull.length) {
      return loadOfficeInfoFull()
        .then((officeInfo) => commit('SET_OFFICE_INFO_FULL', officeInfo));
    }
    return Promise.resolve();
  },

  OFFICE_OVERWRITES_GET: ({ commit }, { uid, jwt }) => loadOfficeOverwrites(uid, jwt)
    .then((response) => {
      commit('SET_OFFICE_OVERWRITES', response.data);
      return response;
    }),

  OFFICE_OVERWRITES_SET: ({ commit }, { uid, jwt, updateData }) => updateOfficeOverwrites(uid, jwt, updateData)
    .then((result) => {
      commit('SET_OFFICE_OVERWRITES', result.data);
      return Promise.resolve(result);
    }),

  OFFICE_OVERWRITES_ADD: ({ state, commit }, { mandant, overwritesData }) => {
    const currentOverwritesData = state.officeOverwrites;
    currentOverwritesData[mandant].push(overwritesData);
    commit('SET_OFFICE_OVERWRITES', { ...currentOverwritesData });
  },

  // eslint-disable-next-line no-unused-vars
  OFFICE_OVERWRITES_REMOVE: ({ state, commit }, { mandant, id }) => {
    const currentOverwritesData = state.officeOverwrites;
    let indexToDel = null;
    currentOverwritesData[mandant].forEach((elem, index) => {
      if (elem.id === id) {
        indexToDel = index;
      }
    });
    currentOverwritesData[mandant].splice(indexToDel, 1);
    commit('SET_OFFICE_OVERWRITES', { ...currentOverwritesData });
  },

  EDIT_APP_INTERSECT_CLASS: ({ commit }, { elemId, visbilityStatus }) => {
    commit('SET_APP_INTERSECT_CLASS', { elemId, visbilityStatus });
  },
  // load page of a single-poi
  GET_POI_PAGE: ({ commit }, { poiId }) => loadPoi(poiId).then((poi) => {
    commit('SET_DOCUMENT_POI', poi);
  }),
  // gets multiple pois; queryParameters are explained in Microservice
  GET_POIS: (
    { commit }, { name, queryParameters },
  ) => loadMultiplePois(queryParameters).then((poiList) => {
    commit('SET_POIS', { name, poiList });
  }),
  GET_LOCATION: ({ commit }, {
    country, location, bbox, languageCode,
  }) => {
    commit('LOCATION_REQUEST_INPROGRESS', true);
    return loadLocation(country, location, bbox, languageCode).then((locationInfo) => {
      commit('SET_LOCATION', locationInfo);
      commit('LOCATION_REQUEST_INPROGRESS', false);
    });
  },
  GET_OFFERS: ({ state, commit }) => loadOffer(state.app.currentLanguage).then((offerInfo) => {
    commit('SET_OFFERS', offerInfo.offers);
  }),
  // eslint-disable-next-line no-unused-vars
  SUBSCRIBE_NEWSLETTER: ({ commit }, userData) => newsletterSubscribe(userData),
  // eslint-disable-next-line no-unused-vars
  CONTACT_REQUEST: ({ commit }, { requestData, uid }) => contactRequest({ requestData, uid }),
  // eslint-disable-next-line no-unused-vars
  DOI_CONFIRM: ({ commit }, formData) => doiConfirm(formData),
  // just a trigger for opening the object page widget pickr from other component
  OPEN_WIDGET_PICKR: () => true,
  OPEN_MODAL_PICKR: () => true,
  // trigger for heaader search hydration prevention
  HYDRATE_SEARCH_OBJECTS: () => true,
  SEARCH_OBJECTS_MOUNTED: () => true,

  // get internal object data, based on user permissions
  GET_OBJECT_INTERNAL: ({ commit, rootState, getters }, { objectNumbers }) => {
    // check if request was done before
    if (getters.getObjectInternalSaved(objectNumbers)) {
      return Promise.resolve();
    }

    const jwt = rootState.user.jwt ? rootState.user.jwt : getCookieVal('jwt');
    const uid = rootState.user.uid ? rootState.user.uid : getCookieVal('uid');
    return loadObjectInternal(objectNumbers, uid, jwt)
      .then((objectInternal) => {
        // save internal object data to vuex
        objectInternal.forEach((objectInternalValue) => {
          commit('SET_OBJECT_INTERNAL', {
            objectNumber: objectInternalValue.objectNumber.value,
            objectInternal: objectInternalValue,
          });
        });
      });
  },
  // eslint-disable-next-line no-unused-vars
  GET_COVID_DATA: ({ commit }, load) => getCovidData(load),
  FETCH_COVID_STATE_STATUS: ({ commit }) => fetchCovidStateStatus()
    .then((data) => {
      commit('SAVE_COVID_STATE_STATUS', { stateData: data });
    }),

  SAVE_USEPAGECACHE: ({ commit }, usePageCache) => commit('SET_USEPAGECACHE', usePageCache),
  // eslint-disable-next-line no-unused-vars
  REDEEM_TRAVEL_VOUCHER: ({ commit }, formData) => redeemTravelVoucherRequest(formData),
  // eslint-disable-next-line no-unused-vars
  TRAVEL_VOUCHER_PAYMENT: ({ commit }, ids) => travelVoucherPayments(ids),

  // look if retailer-response already exist or not
  GET_RETAILER_RESPONSE: ({ commit }, { ratingNumber }) => loadResponse(ratingNumber)
    .then((response) => {
      commit('SET_RETAILER_RESPONSE', { ratingNumber, response: response.data });
    }),
  // saves response of retailer to existing data
  SET_RETAILER_RESPONSE: ({ commit }, { ratingNumber, ratingAnswer, oldResponse }) => setResponse(ratingNumber, ratingAnswer)
    .then((newResponse) => {
      oldResponse.ratingLandlordAnswer = ratingAnswer;
      commit('SET_RETAILER_RESPONSE', { ratingNumber, response: { ...oldResponse, ...newResponse.data } });
    }),
  /* experiment getters need to be set up in here, as we need to use them in two actions
  SEARCH_OBJECTS and SEARCH_OBJECTS_TEASER */
  ADDITIONAL_FILTERS({ getters }) {
    let additionalFilters = {};
    /* ########## OLD EXPERIMENTS ########## */
    // EDVUE-3367 set method by AB-Test oaiV5 vs oaiV6
    // const searchExperimentValueEDVUE3367 = { experiment: getters.getExperimentById('experiment_EDVUE-3367'), experimentID: 'experiment_EDVUE-3367' };
    /* #################### */

    // get PartnerID
    const partnerID = getters.getPartnerConfigValue('partnerID');
    // in here we call addExperimentFilter, which adds the filter for the corresponding test
    // and setPartnerID, which sets a partnerID, if set, to additionalFilters
    additionalFilters = {
      // pass empty object if no experiment filter is needed
      ...addExperimentFilter({}),
      ...setPartnerID(partnerID),
    };
    return additionalFilters;
  },
  GET_RECOMMENDATIONS: ({ commit }) => loadRecommendations().then((response) => {
    commit('SET_RECOMMENDATIONS', response);
  }),
  // eslint-disable-next-line no-unused-vars
  GET_USERDATA: ({ commit }, load) => getUserdata(load),
  // eslint-disable-next-line no-unused-vars
  SET_USERDATA: ({ commit }, load) => setUserdata(load),
  // eslint-disable-next-line no-unused-vars
  SEND_ICBE_PARTNER_REGISTRATION: ({ commit }, load) => sendAnswer(load),
};

// DELETE ME
