const defaultContentGroupPush = {
  data() {
    return {
      contentGroup: {
        1: 'Startseite',
        2: 'Suchtrefferliste',
        3: 'Objektseite',
        4: 'Buchungsstrecke',
        5: 'Sonstige',
      },
    };
  },
  methods: {
    defaultCGP() {
      if (!this.gtmContentGroupPush) { // no gtmContentGroupPush defined in page .vue
        if (this.userDataReady === 'userSaved') {
          this.$gtm.enable(true); //

          if (this.cgpdebounce !== false) {
            clearTimeout(this.cgpdebounce);
          }
          this.cgpdebounce = setTimeout(() => {
            const match = this.$route.matched[0];
            if (Math.abs(match?.meta?.contentGroup)) {
              this.defaultDataLayerPush( // gtm dataLayer push based on EDVUE-156
                {
                  event: 'contentGroupPush',
                  ContentGroupIndex: match.meta.contentGroup,
                  ContentGroup: this.contentGroup[match.meta.contentGroup], // get contentgroup from route meta object
                  ContentGroupData: this.$route.meta.name,
                },
              );
            }

            this.cgpdebounce = false;
          }, 2000);
        }
      }
    },
    defaultDataLayerPush(object = {}, delayed = 0) {
      // push given object to gtm dataLayer
      this.$nextTick(() => {
        if (typeof window !== 'undefined') {
          // eslint-disable-next-line no-multi-assign
          const dataLayer = (window.dataLayer = window.dataLayer || []);
          setTimeout(() => {
            dataLayer.push(object);
          }, delayed);
        }
      });
    },
  },
  computed: {
    userDataReady() {
      return this.$store.state.userSaved;
    },
    cgpdebounce: {
      get() {
        return this.$store.state.tracking.cgpDebounce;
      },
      set(status) {
        this.$store.state.tracking.cgpDebounce = status;
      },
    },
  },
  watch: {
    userDataReady: {
      immediate: true,
      handler: 'defaultCGP',
    },
    $route: [{ // watch route change
      immediate: false,
      handler: 'defaultCGP',
    }],
  },
};
export default defaultContentGroupPush;
