import axios from 'apiclient';

const watchedObjectsGet = (uid, jwt, watchListUID, watchList) => axios.post('/api/user/watchedobjects/get', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  watchListUID,
  watchList,
}).then((result) => Promise.resolve(result.data));

export default watchedObjectsGet;
