import Vue from 'vue';

export default {
  SET_BOOKING_CONFIRMATION_DATA: (state, bookingData) => {
    Vue.set(state, 'bookingConfirmationData', bookingData);
  },
  SET_BOOKING_CONFIRMATION_ERROR: (state, errorObject) => {
    Vue.set(state, 'bookingConfirmationError', errorObject);
  },
};
