import anyComponent from 'router/guards/anyComponent';
import offerRequest from 'router/guards/offerRequest';
import killHashesOnPopstate from 'router/guards/killHashesOnPopstate';
import setLocale from 'router/guards/setLocale';
import checkPermission from 'router/guards/checkPermission';
import closeMenu from 'router/guards/closeMenu';
import partnerCheck from 'router/guards/partnerCheck';
import {
  seaCheck, seoCheckSearch, seoCheckHome, seoCheckLandingPageWhoamiSearch, seoCheckLandingPageOffers, seoCheckLandingPageMeta,
} from 'router/guards/seoCheck';
import {
  redirectToOfferSeo, rewriteSearchUrls, rewriteOtherUrls, rewriteHomeUrls,
} from 'router/guards/rewriteUrl';
import redirectGuard from 'router/guards/redirect301';
import { saveQueryToSearchStore, resetAllSearchFilters, saveMetaToSearchStore } from 'router/guards/queryToStoreManagement';

const setHttpState = ({ next, store }, code) => {
  store.commit('SET_APP_HTTPSTATUS', code);
  next();
};

const setHttpstate404 = ({ next, store }) => { setHttpState({ next, store }, 404); };
const setHttpstate500 = ({ next, store }) => { setHttpState({ next, store }, 500); };

const isMatchedRoute = (route) => route.matched.length > 0;
const isUnmatchedRoute = (route) => route.matched.length === 0 || route.name === 'catchall';
const isHomeRoute = (route) => isMatchedRoute(route) && (route.name.match(/^Home/) !== null || route.name.match(/^HomeLegacy/) !== null || route.name.match(/^HomeLocale/) !== null);
const isSearchRoute = (route) => isMatchedRoute(route) && (route.meta.name === 'Search');
const is404Route = (route) => isMatchedRoute(route) && (route.name === 'Error404' || route.name.indexOf('whoami_Error404') !== -1);
const is500Route = (route) => isMatchedRoute(route) && (route.name === 'Error500' || route.name.indexOf('whoami_Error500') !== -1);
const isMatchedSearchRoute = (route) => isMatchedRoute(route) && (route.name.match(/Search/i) !== null || route.path.match(/Search/i) !== null);
const isSearchLandingPageOffers = (route) => isMatchedRoute(route) && (route.name.indexOf('SearchOffer') !== -1);
// const isObjectRoute = route => isMatchedRoute(route) && (route.name === 'objectpage' || route.name === 'objectpage-direct');
const isSearchLandingPageWhoami = (route) => isMatchedRoute(route) && (route.name.indexOf('whoami_Search') !== -1);
// const isWhoamiObjectRoute = route => isMatchedRoute(route) && (route.name.indexOf('whoami_Object') !== -1);

export {
  isSearchRoute, isSearchLandingPageOffers, isHomeRoute, isSearchLandingPageWhoami, isMatchedSearchRoute,
};

// set up default guards for every route
const defaultGuards = [setLocale, checkPermission, partnerCheck, closeMenu, killHashesOnPopstate];

// config is searched top to bottom and found guards concatenated
// guards are executed left to right
export default [
  // always
  { test: () => true, guards: defaultGuards },
  // unmatched always
  { test: (route) => isUnmatchedRoute(route), guards: [redirectGuard, anyComponent] },
  // http states
  { test: (route) => is404Route(route), guards: [setHttpstate404] },
  { test: (route) => is500Route(route), guards: [setHttpstate500] },
  // home route puts query into search store and kills all params except those present on home
  {
    test: (route) => isMatchedRoute(route),
    guards: [seaCheck],
  },
  {
    test: (route) => isHomeRoute(route),
    guards: [offerRequest, resetAllSearchFilters, saveQueryToSearchStore, seoCheckHome, rewriteHomeUrls, rewriteOtherUrls],
  },
  // legacy landing pages
  {
    test: (route) => route.name === 'home-landing' || route.name === 'lastminute',
    guards: [({ next, store }) => {
      store.commit('SET_ISSEOPAGE', true);
      next();
    }],
  },
  // any search route
  {
    test: (route) => isMatchedSearchRoute(route),
    guards: [resetAllSearchFilters, saveQueryToSearchStore],
  },
  // Suche search routes
  {
    test: (route) => {
      const searchRoute = isSearchRoute(route);
      // console.log('searchRoute', searchRoute);
      return searchRoute;
    },
    guards: [offerRequest, seoCheckSearch, rewriteSearchUrls],
  },
  // offer landing pages
  {
    test: (route) => {
      const searchLandingPageOffers = isSearchLandingPageOffers(route);
      // console.log('searchLandingPageOffers', searchLandingPageOffers, 'route.name', route.name);
      return searchLandingPageOffers;
    },
    guards: [offerRequest, seoCheckLandingPageOffers, redirectToOfferSeo],
  },
  // search route with meta params => isSearchLandingPageMeta
  {
    test: (route) => isMatchedSearchRoute(route) && Object.keys(route.meta?.searchFilter ?? {}).length > 0,
    guards: [saveMetaToSearchStore, offerRequest, seoCheckLandingPageMeta],
  },
  // landing pages that get searchFilter through whoami API
  {
    test: (route) => isSearchLandingPageWhoami(route),
    guards: [anyComponent, seoCheckLandingPageWhoamiSearch, rewriteSearchUrls, offerRequest],
  },
];
