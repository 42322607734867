import axios from 'apiclient';

const userSaveTrackingParams = (uid, trackingParams) => axios.post('/api/user/saveTrackingParams', {
  mandant: process.env.CLIENTNAME,
  uid,
  trackingParams,
}).then((result) => Promise.resolve(result.data));

export const userLogTimings = (uid, timingData) => axios.post('/api/user/logTimings', {
  mandant: process.env.CLIENTNAME,
  uid,
  ...timingData,
}).then((result) => result.data)
  .catch(() => {}); // axios error processing happens in apiclient -> errorInterceptor

export const userLogConsentCookie = (uid, accepted) => axios.post('/api/user/logCookieConsent', {
  mandant: process.env.CLIENTNAME,
  uid,
  accepted,
}).then((result) => result.data)
  .catch(() => {});

export const userLogHistory = (uid, jwt, historyData) => axios.post('/api/user/logHistory', {
  uid,
  jwt,
  ...historyData,
  mandant: process.env.CLIENTNAME,
}).then((result) => result.data);

export default userSaveTrackingParams;
