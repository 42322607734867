// eslint-disable-next-line import/no-unresolved
import config from 'config/appConfig';
import { routeBuilder } from 'utils/locale/routeHelpers';
import routeWhoami from 'requests/routeWhoami';

const push = ({
  lng, reload = false, router, route, store, i18n,
}) => {
  if (reload && typeof window !== 'undefined') {
    window.location.assign(window.location.origin + router.resolve(route).href);
  } else {
    i18n.i18next.changeLanguage(lng);
    store.commit('SET_LASTSEARCHFILTER', {});
    router.push(route);
  }
};

export default ({
  lng, reRoute = true, store, i18n, router, reload = false,
}) => {
  if (i18n.i18next.language !== lng) {
    // sync i18n language and store
    store.commit('SET_APP_CURRENT_LANGUAGE', lng);
    store.commit('SET_APP_CURRENT_CURRENCY', config.locales.helper.getLanguageParams(store.state.app.currentLanguage).currency);
    store.commit('SET_APP_CURRENT_LOCALE', config.locales.helper.getLanguageParams(store.state.app.currentLanguage).locale);

    // push route to change url
    if (reRoute) {
      // build new route
      const routeName = store.state.route.meta.name === 'Home' ? 'HomeLocale' : store.state.route.meta.name;
      const route = routeBuilder({
        ...store.state.route,
        name: routeName,
      }, router, store);

      // on no match call routewhoami to get correct language URL
      // this prevents double rendering on the server and slightly higher load times
      if (router.resolve(route).resolved.matched.length === 0) {
        routeWhoami(route.path, lng).then((component) => {
          if (component.datas.location) {
            push({
              i18n, router, route: routeBuilder({ path: component.datas.location }, router, store), lng, reload, store,
            });
          }
        })
          .catch(() => {
            // on whoami error just redirect to the wrong url, causing a 301
            push({
              i18n, router, route, lng, reload, store,
            });
          });
      } else {
        push({
          i18n, router, route, lng, reload, store,
        });
      }
    } else {
      i18n.i18next.changeLanguage(lng);
    }
  }
};
