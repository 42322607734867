export default (to, from, savedPosition) => {
  // fixes only mobile device scroll bouncing error (android chrome after using pagination with smooth scroll)
  // https://dev.to/uwutrinket/fix-scroll-jump---vue-router-45ja
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
  if (savedPosition) { // https://router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
    return savedPosition;
  }

  let delay = 200;
  if (to.hash) {
    if (to.hash.match(/Sentinel/)) {
      delay = 2000;
    }
    let element = null;
    if (to.hash.length < 30 && typeof document !== 'undefined' && !to.hash.match(/=/)) {
      element = document.querySelector(to.hash);
    }

    if (element) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: {
              y: 0,
              x: 0,
            },
          });
        }, delay);
      });
    }
    return false; // if no element found, do not scroll
  }

  if (to.params.scrollto) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ y: to.params.scrollto, x: 0 });
      }, delay);
    });
  }

  return { x: 0, y: 0 };
};
