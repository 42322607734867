import { getQuerySearchFilters, getOtherQueryParams } from 'utils/SearchFilterHelper';
import { redirectTo } from 'router/guards/navigationGuards';
import { resetSearchFilters } from 'router/guards/queryToStoreManagement';

const setObjectsPerPage = (store) => {
  // let's also change objects per page here
  // you can do this somewhere else as long as its before the search
  let searchObjectsPerPage = 1;
  if (store.state.isSeoPage || store.state.isSeaPage) searchObjectsPerPage = process.env.OBJECTS_PER_SEO_PAGE || 1;
  else searchObjectsPerPage = process.env.OBJECTS_PER_PAGE || 2;
  store.commit('SET_SEARCHFILTER', { objectsPerPage: searchObjectsPerPage });
};

const setSeaPage = (store, to) => {
  // const isSeaPagePID = Object.keys(to.query).includes('PID') && to.query.PID !== '';
  // const isSeaPagePartner = Object.keys(to.query).includes('partner') && to.query.partner !== '';
  // const isSeaPage = (!!((isSeaPagePID || isSeaPagePartner)));
  const isSeaPage = Object.keys(to.query).includes('PID') && to.query.PID !== '';
  store.commit('SET_ISSEAPAGE', isSeaPage);
  setObjectsPerPage(store);
};

const seaCheck = ({ to, next, store }) => {
  setSeaPage(store, to);
  next();
};

// check home seo (basically just home without any search query)
const seoCheckHome = ({ to, next, store }) => {
  store.commit('SET_ISSEOPAGE', Object.keys(getQuerySearchFilters(to.query)).length === 0);
  next();
};

// normal search page is never seo
const seoCheckSearch = ({ next, store }) => {
  store.commit('SET_ISSEOPAGE', false);
  store.commit('SET_SEO_SEARCHFILTER', []);
  next();
};

// checks landing pages taht set certain filter through their URL
const seoCheckLandingPage = ({
  next, to, store,
}, seoFilter = []) => {
  let isSeoPage = true;
  seoFilter.push('objectsPerPage');

  // sea pages can't be seo pages
  setSeaPage(store, to);
  isSeoPage = !store.state.isSeaPage;

  // set and push current seo filters => need this for later use when rewriting urls
  store.commit('SET_SEO_SEARCHFILTER', seoFilter);

  // search seo pages shouldn't come with query and without PID
  if (Object.keys(getQuerySearchFilters(to.query)).length > 0) isSeoPage = false;

  if (isSeoPage) {
    // set / clear all filters not relevant for seo page
    resetSearchFilters(store, seoFilter);
  }

  // write seo in store, we need it later
  store.commit('SET_ISSEOPAGE', isSeoPage);

  setObjectsPerPage(store);

  if (!isSeoPage && !store.state.isSeaPage && to.path.indexOf('Suche') === -1) {
    next({
      ...redirectTo(to, {
        name: 'Search',
        query: { ...store.getters.getMergedTerms, ...getQuerySearchFilters(to.query), ...getOtherQueryParams(to.query) },
      }),
    });
  } else {
    next();
  }
};

const seoCheckLandingPageMeta = ({
  store, next, to,
}) => {
  // meta filter get pushed in saveMetaToSearchStore
  // so we only have to get into the standard landing page logic here
  seoCheckLandingPage({
    next, to, store,
  }, [
    ...Object.keys(to.meta.searchFilter),
    ...Object.keys(store.getters.getOfferData(to.meta?.searchFilter?.offer)?.query || []),
  ]);
};

const seoCheckLandingPageOffers = ({
  next, to, store,
}) => {
  // whoami pushes the filters into the store
  // so we only have to get into the standard landing page logic here
  const offerData = store.getters.getOfferData(to.params.offer)?.query ?? {};

  seoCheckLandingPage({
    next, to, store,
  }, Object.keys(offerData));
};

const seoCheckLandingPageWhoamiSearch = ({
  next, to, store,
}) => {
  // whoami pushes the filters into the store
  // so we only have to get into the standard landing page logic here
  seoCheckLandingPage({
    next, to, store,
  }, ['country', 'region', 'place']);
};

export {
  seaCheck, seoCheckSearch, seoCheckHome, seoCheckLandingPageWhoamiSearch, seoCheckLandingPageOffers, seoCheckLandingPageMeta,
};
