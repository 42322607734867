import { getCookieVal } from 'mixins/composition/CookieApi';
import tiptapContentGet from '@/requests/tiptapContentGet';
import tiptapContentSet from '@/requests/tiptapContentSet';
import tiptapContentLockToggle from '@/requests/tiptapContentLockToggle';

export default {
  TIPTAP_CONTENT_GET: ({ commit, rootState }, {
    language, page, containerKey, previewDraft,
  }) => {
    const uid = rootState.user.uid ? rootState.user.uid : getCookieVal('uid');
    const jwt = rootState.user.jwt ? rootState.user.jwt : getCookieVal('jwt');
    return tiptapContentGet({
      uid, jwt, language, page, containerKey, previewDraft,
    }).then((response) => {
      commit('TIPTAP_CONTENT_STORE', {
        language,
        page,
        containerKey,
        containerData: response[0],
      });
      return Promise.resolve(response[0]);
    });
  },
  TIPTAP_CONTENT_SET: ({ commit, rootState }, {
    language, page, containerKey, contentStatus, contentAction, containerContent,
  }) => {
    const uid = rootState.user.uid ? rootState.user.uid : getCookieVal('uid');
    const jwt = rootState.user.jwt ? rootState.user.jwt : getCookieVal('jwt');
    let containerContentJson = '';
    if (containerContent) {
      containerContentJson = JSON.stringify(containerContent);
    }
    return tiptapContentSet({
      uid, jwt, language, page, containerKey, contentStatus, contentAction, containerContent: containerContentJson,
    }).then((response) => {
      commit('TIPTAP_CONTENT_STORE', {
        language,
        page,
        containerKey,
        containerData: response[0],
      });
      return Promise.resolve(response);
    });
  },
  TIPTAP_CONTENT_LOCK_TOGGLE: ({ rootState }, {
    language, page, toggleState,
  }) => {
    const uid = rootState.user.uid ? rootState.user.uid : getCookieVal('uid');
    const jwt = rootState.user.jwt ? rootState.user.jwt : getCookieVal('jwt');
    return tiptapContentLockToggle({
      uid, jwt, language, page, toggleState,
    });
  },
};
