import Backend from 'i18next-http-backend';

const i18nOptions = {
  backend: {
    loadPath: `${process.env.API_URL_CLIENT}locales/{{lng}}/{{ns}}.json?v=${process.env.REVISION}`,
    crossDomain: true,
  },
};

const pluginOptions = {};

export { Backend, i18nOptions, pluginOptions };
