export default {
  type: 'application/ld+json',
  json: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: `https://product.${process.env.CLIENTNAME}`,
    logo: `https://product.${process.env.CLIENTNAME}/logo.png`,
  },
  vmid: 'structuredDataOrganizationJson',
};
