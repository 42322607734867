import axios from 'apiclient';

const userUpdate = (uid, jwt, userAccountData) => axios.post('/api/user/update', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  userAccountData,
}, {
  timeout: 20000,
}).then((result) => Promise.resolve(result.data));

export default userUpdate;
