/**
 * with this helper you can interact with userdata
 */

import {
  getCookieVal,
} from 'mixins/composition/CookieApi';
import { mapActions } from 'vuex';
import { format } from 'date-fns';

const UserApi = {
  setup() {
    return {
      getCookieVal,
    };
  },
  data() {
    return {
      UserApi_isMounted: false,
      UserApi_uidOrigin: false,
    };
  },
  mounted() {
    this.UserApi_isMounted = true;
  },
  computed: {
    UserApi_uid() {
      let uid = this.$store.getters.getUid;
      if (!uid) {
        uid = getCookieVal('uid');
      }
      return uid;
    },
    UserApi_uidQuery() {
      return this.$route.query.uid || false;
    },
    // just return jwt string
    UserApi_jwt() {
      let jwt = this.$store.getters.getJwt;
      if (!jwt) {
        jwt = getCookieVal('jwt');
        if (jwt === 'null') {
          jwt = '';
        }
      }
      return jwt;
    },
    // return data inside jwt
    UserApi_jwtData() {
      return this.UserApi_parseJwt(this.UserApi_jwt);
    },
    UserApi_loggedIn() {
      let loggedIn = false;
      if (this.UserApi_jwt && this.UserApi_accountData?.accountEmail) {
        loggedIn = true;
      }
      return loggedIn;
    },
    UserApi_accountData() {
      return this.$store.getters.getAccountData;
    },
    UserApi_accountPermissions() {
      return this.UserApi_accountData?.accountPermissions;
    },
    UserApi_currentLocale() {
      return this.$store.getters.getCurrentLanguage;
    },
  },
  methods: {
    ...mapActions({ userLogout: 'USER_LOGOUT' }),
    UserApi_getUser() {
      let clientWidth = 0;
      let clientHeight = 0;
      if (typeof window !== 'undefined') {
        clientWidth = window.innerWidth || window.screen.width;
        clientHeight = window.innerHeight || window.screen.height;
      }
      // store uid from cookie or API
      return this.$store
        .dispatch('GET_USER', {
          uid: this.UserApi_uid,
          uidOrigin: this.UserApi_uidOrigin,
          jwt: this.UserApi_jwt,
          trackingParams: this.trackingParams,
          revision: process.env.REVISION,
          experiments: this.experimentData,
          breakPoint: this.dh_breakpoint,
          screenWidth: clientWidth,
          screenHeight: clientHeight,
          localeInit: this.UserApi_currentLocale,
        }).catch(() => {});
    },
    UserApi_overwriteUser(oldUid, newUid) {
      this.UserApi_uidOrigin = oldUid;
      this.$store.commit('SET_USER_UID', { uid: newUid });
    },
    UserApi_logout() {
      return this.userLogout();
    },
    // check if user has "permissionCheck" permissions
    // return true or false
    UserApi_checkPermission(permissionCheck) {
      const checkPassed = this.UserApi_accountPermissions?.[permissionCheck] || false;
      return checkPassed && this.UserApi_isMounted;
    },
    // this function saves user mail address to DB and returns EBK (edom uid)
    UserApi_saveMail(email) {
      // simple mail regex with at least 2 chars for top level domain
      if (email.search(/.+@.+\.+..+/i) >= 0) {
        return this.$store
          .dispatch('USER_SAVE_CONTACT', {
            email,
          })
          .then(() => Promise.resolve())
          .catch(() => Promise.resolve());
      }
      return Promise.resolve();
    },
    // return human readable form of jwt string
    UserApi_parseJwt(jwtString) {
      let jsonPayloadParsed = '';
      // works with unicode. taken from https://stackoverflow.com/a/38552302
      if (jwtString && typeof jwtString !== 'undefined' && jwtString !== 'undefined' && jwtString !== 'null') {
        const base64Url = jwtString.split('.')[1];
        if (base64Url && typeof base64Url !== 'undefined') {
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
          jsonPayloadParsed = JSON.parse(jsonPayload);
          jsonPayloadParsed.iat = format(new Date(jsonPayloadParsed.iat * 1000), 'yyyy-MM-dd HH:mm:ss');
          jsonPayloadParsed.exp = format(new Date(jsonPayloadParsed.exp * 1000), 'yyyy-MM-dd HH:mm:ss');
        }
      }

      return jsonPayloadParsed;
    },
  },
};

export default UserApi;
