/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import './registerServiceWorker';
// eslint-disable-next-line import/no-unresolved
import 'intersection-observer'; // add polyfill for legacy and client builds, because IE and even newer iOS do not support IntersectionObserver API
import createApp from './main';

const {
  app, store, router,
} = createApp({
  isNode: false,
});

// logic required to make history api work with anyComponent
// it's a mess, better solution for dynamic routing needed!!!
const addPopStateToStore = () => {
  store.state.app.router.popstate = true;
};
window.addEventListener('popstate', addPopStateToStore);
app.$once('hook:destroyed', () => {
  window.removeEventListener('popstate', addPopStateToStore);
});
router.beforeEach((to, from, next) => {
  store.state.app.router.navigationFromPopstate = store.state.app.router.popstate;
  next();
});
router.afterEach(() => {
  store.state.app.router.popstate = false;
});

if (window.__INITIAL_STATE__) {
  // picks up store values and inits the client i18next with it
  // prevents reloading resources that the server has already fetched
  Object.keys(store.state.i18next).forEach((lng) => {
    Object.keys(store.state.i18next[lng]).forEach((ns) => {
      const data = store.state.i18next[lng][ns];
      app.$i18n.i18next.addResourceBundle(lng, ns, data, true, true);
    });
  });
}

router.onReady(() => {
  app.$mount('#app');
});
