import objectDefault from 'store/defaults/object';

import objectRatingsDefault from 'store/defaults/objectRatings';
import objectPriceDefault from 'store/defaults/objectPrice';

export default {
  getActiveObjectNumber: (state) => state.activeObjectNumber,
  getObjects: (state) => state.list.map((objectNumber) => state.data[objectNumber]),
  getObjectData: (state, getters) => (
    objectNumber = state.activeObjectNumber,
  ) => (getters.hasObjectData(objectNumber) ? state.data[objectNumber] : objectDefault),
  getActiveObjectData: (
    state, getters,
  ) => (getters.hasActiveObjectData ? state.data[state.activeObjectNumber] : objectDefault),
  getObjectDataByKey: (state, getters) => (
    keyName, objectNumber = state.activeObjectNumber,
  ) => getters.getObjectData(objectNumber)[keyName],
  /** get Feature Description with from correct language container  */
  getObjectFeatureDescription: (state, getters) => (objectNumber = state.activeObjectNumber) => {
    const objectData = getters.getObjectData(objectNumber);
    const { objectFeatureDescription } = objectData;
    const language = getters.getCurrentDescriptionLanguage;
    return objectFeatureDescription[language];
  },
  /** get current description language or current app language */
  getCurrentDescriptionLanguage: (state, getters, rootState) => {
    const { currentLanguage } = rootState.app;
    return state.currentDescriptionLanguage || currentLanguage;
  },
  getObjectText: (state, getters, rootState) => (objectNumber = state.activeObjectNumber) => {
    const objectData = getters.getObjectData(objectNumber);

    const { descriptions } = objectData;
    const { currentLanguage } = rootState.app;
    // console.log('objectData currentLanguage', currentLanguage);
    const defaultObject = { natives: {} };
    defaultObject.natives[currentLanguage] = {
      languageCode: currentLanguage,
      text: 'na',
    };
    const descriptionText = Object.assign(defaultObject, descriptions);
    // console.log('descriptionText', descriptionText);
    return descriptionText;
  },
  getObjectFeatures: (state, getters) => (objectNumber = state.activeObjectNumber) => {
    const {
      countRooms, countRoomsBath, countRoomsSleeping, detailPetAllowed, objectType,
      personsMax, squaremeterComplete, squaremeterLiving, searchCriteria,
    } = getters.getObjectData(objectNumber);

    return {
      countRooms,
      countRoomsBath,
      countRoomsSleeping,
      detailPetAllowed,
      objectType,
      personsMax,
      squaremeterComplete,
      squaremeterLiving,
      searchCriteria,
    };
  },
  // basic state checks for different object attributes
  hasObjectData: (state) => (
    objectNumber = state.activeObjectNumber,
  ) => state.list.includes(parseInt(objectNumber, 10)),
  hasActiveObjectData: (state) => state.list.includes(state.activeObjectNumber),

  // generic test that determines default type and runs a basic test
  hasObjectDataByKey: (state, getters) => (keyName, objectNumber = state.activeObjectNumber) => {
    const keyValue = getters.getObjectData(objectNumber)[keyName];
    const defaultValue = objectDefault[keyName];
    if (defaultValue === null) return keyValue !== null;
    if (typeof defaultValue === 'string') return keyValue !== '';
    if (defaultValue instanceof Array) return keyValue.length > 0;
    if (defaultValue instanceof Object
      && !(defaultValue instanceof Array)) return Object.keys(keyValue).length > 0;
    if (Number.isInteger(parseInt(defaultValue, 10))) return keyValue > 0;
    return false;
  },
  hasObjectAexeaDesc: (state, getters) => (objectNumber) => getters.getObjectDataByKey('descriptionsAexea', objectNumber).description !== '',
  hasObjectVacancy: (state, getters) => (objectNumber = getters.activeObjectNumber) => getters.getObjectDataByKey('availability', objectNumber).count > 0,
  // basic checks end

  // object ratings
  getObjectRatingsData: (state, getters) => (
    objectNumber = state.activeObjectNumber,
  ) => (getters.hasObjectRatingsData(objectNumber)
    ? state.ratingsData[objectNumber]
    : objectRatingsDefault),

  // check if in list
  hasObjectRatingsData: (state) => (
    objectNumber = state.activeObjectNumber,
  ) => state.ratingsList.includes(objectNumber),
  // object ratings end

  // price
  getPriceByKey: (
    state, getters,
  ) => (priceKey) => (
    getters.hasPriceKey(priceKey)
      ? state.pricesData[priceKey]
      : objectPriceDefault
  ),
  hasPriceKey: (state) => (priceKey) => state.pricesList.includes(priceKey),
  // price end

  // marker to check if pricerequest is running
  getIsFetchingPrice: (state) => state.isFetchingPrice,

  // marker to check if pricerequest failed
  getFetchingPriceError: (state) => state.fetchingPriceError,
  isCancelable: (state) => (objectNumber) => {
    const { cancelableObjects } = state;

    if (cancelableObjects.indexOf(objectNumber) > -1) {
      return true;
    }
    return false;
  },
  getRkiData: (state) => (landkreisID) => state.rkiData[landkreisID],
  getPrintBool: (state) => state.printData.printing,
  getPrintError: (state) => state.printData.printingError,
};
