/*

@@author        j0Shi
@@date          2019.07.30
@@desc          Help functions for i18n support
@@usedby        FlatPickrBase -> ObjectVacancyForm -> Object

*/

import defaultsDeep from 'lodash.defaultsdeep';

const i18nHelper = (i18next) => {
  /**
    *
    * checks whether a key exists and loads it in case
    * returns promise
    *
    */
  const checkKey = (key) => {
    if (i18next.exists(key)) {
      return Promise.resolve();
    }
    const resourceIdent = key.split(':')[0];
    // try to reload namespace
    return i18next.loadNamespaces(resourceIdent).then(() => {
      if (i18next.exists(key)) {
        return Promise.resolve();
      }
      return Promise.reject();
    });
  };

  /**
     *
     * gets a key
     * returns false if key not found in store
     *
     */
  const get = (key) => {
    const params = key.split(':')[1];
    if (i18next.t(key) === params) return false;
    return i18next.t(key);
  };

  /**
   *
   * get a resource from current lang
   *
   */

  const getResource = (key) => {
    const ns = key.split(':')[0];
    const param = key.split(':')[1];
    const mergedStore = i18next.languages.map((lng) => i18next.store.data[lng]).reduce((aggr, cur) => { defaultsDeep(aggr, cur); return aggr; }, {});
    return mergedStore[ns][param];
  };

  return {
    checkKey,
    getResource,
    get,
  };
};

export default i18nHelper;
