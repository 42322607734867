import axios from 'apiclient';

const redeemTravelVoucherRequest = (formData) => axios.post('/api/booking/voucher/redeem', {
  ...formData,
  mandant: process.env.CLIENTNAME,
}).then((result) => result);

const travelVoucherPayments = (data) => axios.post('/api/booking/voucher/payment', {
  mandant: process.env.CLIENTNAME, ...data,
}).then((result) => result);

export {
  redeemTravelVoucherRequest,
  travelVoucherPayments,

};
