/* eslint-disable vuejs-accessibility/click-events-have-key-events */
<template>
  <div
    v-show="showAnyway || (!hasConfirmed && !hideOverlay)"
    class="wrap"
  >
    <div
      class="overlay"
      :class="{'overlay-block':componentReady && !hasConfirmed && !hideOverlay}"
    />
    <div
      v-if="componentReady && (!hasConfirmed || showAnyway)"
    >
      <transition-group
        appear
        tag="div"
        name="disclaimer"
        class="disclaimer"
        :aria-modal="visible"
      >
        <div
          key="scrollable"
          class="flextop scrollable"
        >
          <div
            class="row "
          >
            <h3
              id="cookieHeader"
              class="first col-12"
            >
              {{ $t('cookieLaw:header') }}
            </h3>
            <div
              class="col-12"
            >
              <div
                v-dompurify-html="$t('cookieLaw:cookieUsage',{clientname:curName})"
                class="cookieUsage"
              />

              {{ $t('cookieLaw:furtherInformations') }}
              <!-- eslint-disable-next-line vuejs-accessibility/tabindex-no-positive -->
              <button
                :data-to="$t('cookieLaw:hrefs.privacy')"
                type="button"
                class="link"
                data-tabindex="3"
                data-ident="cookieDisclaimerPolicy"
                @click.prevent="scrollOn"
              >
                {{ $t('cookieLaw:privacyPolicy') }}
              </button>. |
              <!-- eslint-disable-next-line vuejs-accessibility/tabindex-no-positive -->
              <button
                data-tabindex="4"
                type="button"
                :data-to="$t('cookieLaw:hrefs.imprint')"
                class="link"
                data-ident="cookieDisclaimerImprint"
                @click.prevent="scrollOn"
              >
                {{ $t('cookieLaw:imprint') }}
              </button>
            </div>
            <div
              key="description"
              class="col-12 my-3 expander"
            >
              <a
                v-if="expand"
                class="link"
                href="#"
                data-ident="cookieDisclaimerToggle"
                @click="expand=!expand"
              >
                {{ $t('cookieLaw:hideDetails') }}
                <i class="icon">
                  <icon-chevron-up-alternative />
                </i>
              </a>
            </div>

            <div
              v-show="expand"
              key="descriptions"
              class="col-12"
            >
              <b-form-checkbox
                v-for="category in cookieCategories"
                :key="category.category"
                v-model="category.usage"
                :name="category.name"
                :class="getClass(category) "
                :aria-describedby="`description_${category.name}`"
                :disabled="category.disabled"

                size="xl"
              >
                <h4 class="first mb-0">
                  {{ $t('cookieLaw:'+category.name+'.label') }}
                </h4>
                <p :id="`description_${category.name}`">
                  {{ $t('cookieLaw:'+category.name+'.description',{clientname:curName}) }}
                </p>
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div
          key="btns"
          class="disclaimer-footer btns my-2"
        >
          <div class=" mt-2 mt-md-0 text-center">
            <!-- eslint-disable-next-line vuejs-accessibility/tabindex-no-positive -->
            <button
              id="acceptAll"
              type="button"
              data-tabindex="1"
              class="btn btn-primary btn-block"
              :aria-label="$t('cookieLaw:acceptAllCookies')"
              data-ident="cookieDisclaimerAccept"
              @click="accept(true)"
            >
              {{ $t('cookieLaw:acceptAll') }}
            </button>
          </div>
          <div class="mt-2 mb-2 mb-sm-0 mt-md-0 text-center">
            <button
              v-show="expand"
              id="acceptChoice"
              type="button"
              class="btn btn-link underline"
              :title="$t('cookieLaw:acceptChoice')"
              :aria-label="$t('cookieLaw:acceptChoice') +' cookieLaw:acceptChoice'"
              data-tabindex="7"
              data-ident="cookieDisclaimerDecline"
              @click="accept(false)"
            >
              {{ $t('cookieLaw:acceptChoice') }}
            </button>
            <!-- eslint-disable-next-line vuejs-accessibility/tabindex-no-positive -->
            <a
              v-show="!expand"
              href="#"
              class="btn btn-link underline"
              :aria-label="$t('cookieLaw:showCategories') +' cookieLaw:acceptAll'"
              data-tabindex="2"
              data-ident="cookieDisclaimerToggle2"
              @click="expand=!expand"
            >
              {{ $t('cookieLaw:settings') }}
            </a>
          </div>
        </div>
      </transition-group>
    </div><!-- disclaimer -->
  </div><!-- wrap-->
</template>

<script>
/* ICONS */
// import IconCheck from 'ICON:IconCheck';
import { BFormCheckbox /* BFormGroup */ } from 'bootstrap-vue';
// import RouterLinkSimple from 'components/RouterLinkSimple.vue';
import IconChevronUpAlternative from 'ICON:IconChevronUpAlternative';
import { mapMutations } from 'vuex';
import i18nHelper from 'utils/i18nHelper';

import {
  getCookieVal, setCookie,
} from 'mixins/composition/CookieApi';
import GtmTools from 'mixins/GtmTools';
import setupCookieLaw from 'mixins/composition/CookieLaw'; // get shared functions/computed props

export default {
  components: {
    // IconCheck,
    BFormCheckbox,
    // BFormGroup,
    IconChevronUpAlternative,
    // RouterLinkSimple,
  },
  mixins: [GtmTools],
  setup(props, { root }) {
    const cookieLawComposition = setupCookieLaw(root.$store);
    const {
      cookieCategories, setStates, hasConfirmed,
    } = cookieLawComposition;
    return {
      cookieCategories,
      setStates,
      hasConfirmed,
      // confirmedState,
    };
  },
  data() {
    return {
      componentReady: false,
      expand: false,
      i18nHelper: i18nHelper(this.$i18n.i18next),
      userConfirmed: false,
      delay: false,
    };
  },
  computed: {
    clientname() {
      return process.env.CLIENTNAME;
    },
    routeName() {
      return this.$route.meta.name;
    },
    showAnyway() { // user clicked "Cookie Einstellungen" footer link
      return this.$store.state.cookieLaw.showDisclaimer;
    },
    hideOverlay() {
      return (this.routeName === 'privacy' || this.routeName === 'disclaimer');
    },
    uid() {
      let uid = this.$store.getters.getUid;
      if (!uid) {
        uid = getCookieVal('uid');
      }
      return uid;
    },
    curName() {
      return this.$store.getters.getPartnerConfigValue('partnerName') || this.clientname;
    },
    visible() {
      return this.showAnyway || (!this.hasConfirmed && !this.hideOverlay);
    }

  },
  i18nOptions: { namespaces: ['common', 'cookieLaw'] },
  watch: {
    expand(expanded) {
      if (expanded) {
        this.$nextTick(() => {
          const acceptChoice = document.querySelector('#acceptChoice');
          acceptChoice.setAttribute('tabindex', acceptChoice.dataset.tabindex);
        });
      }
    }
  },
  mounted() {
    Promise.all([
      this.i18nHelper.checkKey('cookieLaw:required.description'),
    ])
      .catch(() => {
        // eslint-disable-next-line no-console
        console.warn('couldn\'t load translation files');
      })
      .finally(() => {
        this.componentReady = true;
        if (!this.hasConfirmed && this.routeName !== 'privacy' && this.routeName !== 'disclaimer') {
          // document.querySelector('html').classList.add('scroll-off'); // sroll sperre
          const disableParentElements = true;
          this.toggleTabFocus(disableParentElements);
        } else {
          const accepted = this.getAcceptedCookie(); // array for cookieLawValues = setupCookieLaw(root.store); data
          this.setStates(accepted, false); // composition update method
        }
      });
  },
  beforeDestroy() {
    const disableEls = false;
    this.toggleTabFocus(disableEls);
  },
  destroyed() {
    document.querySelector('html').classList.remove('scroll-off');
  },
  methods: {
    ...mapMutations({ setCookiePermissions: 'STORE_COOKIE_PERMISSIONS', setShowDisclaimer: 'SHOW_COOKIE_DISCLAIMER', storeConfirmed: 'SET_COOKIE_DISCLAIMER' }),
    scrollOn(event) {
      const { attributes } = event.target;
      const to = attributes['data-to'].value;
      document.querySelector('html').classList.remove('scroll-off');
      this.$router.push(to);
    },
    toggleTabFocus(disable) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            const allFocusableElements = 'a,button,[tabindex],label,input,select';
            const disClaimerCheckboxes = '.disclaimer .custom-control-input,.disclaimer .custom-control-label';
            const tabableEls = document.querySelectorAll(allFocusableElements);


            const disclaimerElements = document.querySelectorAll('.disclaimer a,.disclaimer button,.disclaimer [tabindex]');
            const checkBoxes = document.querySelectorAll(disClaimerCheckboxes);
            const acceptAll = document.querySelector('#acceptAll');

            if (disable) {
              tabableEls.forEach((el) => {
                el.setAttribute('tabindex', '-5');
              });
              let tabindex = 0;
              disclaimerElements.forEach((el) => {
                tabindex = el.dataset.tabindex || 0; // get value from predefined data
                el.setAttribute('tabindex', tabindex);
              });
              // console.log('checkBoxes', checkBoxes);
              checkBoxes.forEach((el) => {
                el.removeAttribute('tabindex');
              });
              if (acceptAll) {
                acceptAll.focus();
                acceptAll.classList.add('autofocus');
                acceptAll.addEventListener('blur', (event) => {
                  event.target.classList.remove('autofocus');
                });
              }
            } else { // re enable focusable els
              tabableEls.forEach((el) => {
                el.setAttribute('tabindex', '0');
              });
            }
          }, 2000);
        });
      });
    },
    accept(all) {
      // set usage true for all Categories
      if (all) {
        this.cookieCategories.forEach((category) => {
          category.usage = true;
        });
      }
      const accepted = this.getAcceptedCookie(); // array for cookieLawValues = setupCookieLaw(root.store); data

      this.setStates(accepted, this.uid); // composition update method

      /**
       * set cookieLawValues = setupCookieLaw(root.store); foreach category
       */
      this.writeCookie(accepted);

      let timer = 10;
      if (all) {
        timer = 1500;
      }
      this.delay = setTimeout(() => {
        this.hasConfirmed = 'confirmed';
        this.setConfirmed();
        this.setShowDisclaimer(false);
        document.querySelector('html').classList.remove('scroll-off');
        this.toggleTabFocus(false);
        this.delay = false;
      }, timer);
    },
    getClass(category) {
      return category?.btn?.className;
    },
    setConfirmed() {
      // set cookies
      const disclaimerCookieOptions = {
        name: 'disclaimer',
        value: 'confirmed',
        expires: '1y',
      };
      setCookie(disclaimerCookieOptions);
      this.dataLayerPush({
        event: 'disclaimer',
        confirmed: 'confirmed',
      });
      this.storeConfirmed(true);
    },
    writeCookie(accepted) {
      const acceptedCookieOptions = {
        name: 'accepted',
        value: JSON.stringify(accepted), // save as json
        expires: '1y',
      };
      setCookie(acceptedCookieOptions); // set cookieLawValues = setupCookieLaw(root.store); 'accepted'
    },
    getAcceptedCookie() {
      const accepted = [];
      this.cookieCategories.forEach((category) => {
        const { name, usage } = category;
        accepted.push({ name, usage }); // create object
      });
      return accepted;
    },
  },

};

</script>
<style lang="scss" scoped>
    .overlay-block{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.4);
        z-index: 10000;
    }

  .disclaimer{
    font-size:0.8rem;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding:1em;
    background-color: #FFF;
    -ms-overflow-style: none;
    position: fixed;
    bottom:3em;
    overflow: auto;
    box-sizing: border-box;
    z-index: 10001;
    width: 90vw;
    margin-left:-45vw;
    left:50%;
    max-height:75vh;
    height:auto;

    @media #{$media_md_min} {
      font-size:1rem;
      width: 70vw;
      margin-left:-35vw;
      bottom:1em;
      left:50%;
    }
    line-height: 1.5;
    box-shadow: 0 -4px 4px rgba($color_grey_bg, 0.05);
    border: 1px solid $color_grey_200;
    @include drop_shadow;

    /deep/ .custom-control.custom-checkbox{
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none;
       transition:none;
      }
    }

    .b-custom-control-xl .custom-control-label p{
        line-height: 1.5;
    }
    .scrollable{
      height: calc(100% - 80px);
      overflow:hidden;
      overflow-y:auto;
    }

    h3#cookieHeader{
      font-size:1.1em;
      margin-bottom:0.25em;
      @media #{$media_lg_min} {
        font-size:1.3em;
      }
      /*padding: 0.5rem 2rem;*/
    }
    .btns{
      display:flex;
      flex-direction: column;
      flex-basis:100%;
      @media #{$media_md_min} {
        flex-direction: row-reverse;
        flex-basis:auto;
      }
      .btn-info {
        background: #fff;
        border-color: $color_grey_200;
        color: $color_grey;

        #app:not(.isTouchDevice) &:hover {
          background: $color_grey_bg;
          border-color: $color_grey_200;
          color: #fff;
        }
      }
    }
    .link{
      background-color: transparent;
      cursor:pointer;
    }
    .custom-checkbox{
      margin-top:1em;
      width:100%;
      @media #{$media_lg_min} {
        // width:13em;
      }
    }

  }

    // Animations
    // slideFromBottom
    .slideFromBottom-enter, .slideFromBottom-leave-to {
        transform: translate(0px, 10em);
    }
    .slideFromBottom-enter-to, .slideFromBottom-leave {
        transform: translate(0px, 0px);
    }
    .slideFromBottom-enter-active {
        transition: transform .2s ease-out;
    }
    .slideFromBottom-leave-active {
        transition: transform .2s ease-in;
    }
</style>
