import axios from 'apiclient';

const tiptapContentGet = ({
  uid, jwt, language, page, containerKey, previewDraft,
}) => axios.post('/api/cms/content/get', {
  mandant: process.env.CLIENTNAME,
  uid,
  jwt,
  language,
  page,
  containerKey,
  previewDraft,
}, {
  cache: {
    ignoreCache: false,
    maxAge: 15 * 60 * 10000,
  },
}).then((res) => res.data);

export default tiptapContentGet;
