import priceRequest from 'requests/priceRequest';
import loadObject, { loadObjectDescription, loadObjectRatings } from 'requests/loadObject';
import { objectPriceKey, objectPriceDefaults } from 'store/keys/objectPrice';
import getRkiData from 'requests/rkiData';
import { differenceInCalendarDays } from 'date-fns';
import { fromAPIDateString, validateDateStringRange } from 'utils/DateHelper';
import { getCookieVal } from 'mixins/composition/CookieApi';

export default {
  // dispatch price request
  GET_PRICE: ({
    commit, state, getters, rootState,
  }, properties) => {
    const key = objectPriceKey(properties);
    // automatically invalidate prices after 60 minutes
    if (properties.forceRequest || typeof state.pricesData[key] === 'undefined' || state.pricesData[key].priceObject.ts <= (new Date().valueOf() - (1000 * 1 * 60 * 60))) {
      commit('SET_FETCHING_PRICE_ERROR', { fetchingPriceError: false });
      commit('SET_IS_FETCHING_PRICE', true);
      const { activeObjectNumber } = state;
      let adultsMax = 0;
      const hasAdultsMax = Object.prototype.hasOwnProperty.call(properties, 'adultsMax'); // check if adultsmax exists in properties
      if (hasAdultsMax) {
        adultsMax = properties.adultsMax;
      } else { // get data from Active Object (Booking, Object, ...)
        const activeObjectData = state.data[activeObjectNumber];
        adultsMax = activeObjectData.adultsMax;
      }

      const uid = rootState.user.uid ? rootState.user.uid : getCookieVal('uid');
      const jwt = rootState.user.jwt ? rootState.user.jwt : getCookieVal('jwt');
      const priceDefaults = objectPriceDefaults(properties);
      const objectPriceOptions = Object.assign(priceDefaults, {
        adultsMax,
        mandant: process.env.CLIENTNAME,
        partner: getters.getPartnerConfigValue('partnerID'),
        uid,
        jwt,
      });
      return priceRequest(objectPriceOptions)
        .then((priceData) => {
          // remove paypal from payments for AB-Test EDMT-5209
          const experiment = getters.getExperimentById('experiment_EDMT-5209');
          const variant = parseInt(experiment?.variant, 10);
          if (variant > 0 && priceData?.inkasso === '0' && priceData?.payments && priceData.payments.length > 0) {
            priceData.payments = priceData.payments.filter((payment) => payment !== 11);
          }

          commit('SET_PRICE', { priceData, ...properties });
          if (typeof window !== 'undefined') {
            if (!window.dataLayer) { window.dataLayer = []; }
            window.dataLayer.push({
              event: 'objectVacancyPush',
              objectVacancy: (priceData.status === 'free') ? 'yes' : 'no',
            });
          }
        })
        .catch((e) => {
          commit('SET_FETCHING_PRICE_ERROR', { fetchingPriceError: true });
          return Promise.reject(e);
        })
        .finally(() => commit('SET_IS_FETCHING_PRICE', false));
    }

    if (typeof window !== 'undefined') {
      if (!window.dataLayer) { window.dataLayer = []; }
      window.dataLayer.push({
        event: 'objectVacancyPush',
        objectVacancy: (state.pricesData[key].status === 'free') ? 'yes' : 'no',
      });
    }

    return Promise.resolve();
  },
  GET_OBJECT_PRICE_REQUEST_DATA: ({ dispatch, commit, state }, {
    startDate = null, endDate = null, persons = 2, language = 'de', uid = '', partner = '', jwt = '',
  }) => {
    // price request
    if (validateDateStringRange(startDate, endDate)) {
      // duration
      const duration = differenceInCalendarDays(fromAPIDateString(endDate), fromAPIDateString(startDate));

      commit('SET_OBJECT_PRICE_REQUEST_DATA', {
        startDate, endDate, persons, language,
      });

      const { activeObjectNumber } = state;

      return dispatch('GET_PRICE', {
        startDate,
        endDate,
        persons,
        duration,
        language,
        uid,
        partner,
        jwt,
        objectNumber: activeObjectNumber,
      }).finally(() => {
        commit('SET_OBJECT_PRICE_IDENTIFIER_KEY', { partner });
      });
    }
    commit('SET_OBJECT_PRICE_REQUEST_DATA', { persons, language });

    commit('SET_OBJECT_PRICE_IDENTIFIER_KEY', { partner });
    return Promise.resolve();
  },

  // reset object states
  RESET_OBJECTS: ({ state }) => {
    state.list = [];
    state.data = {};
  },

  // gets an object in case it's not already in store
  GET_OBJECT: ({ state, commit, rootState }, { objectNumber }) => {
    if (!state.list.includes(objectNumber)) {
      const jwt = rootState.user.jwt ? rootState.user.jwt : getCookieVal('jwt');
      return loadObject(objectNumber, jwt, rootState.app.currentLanguage)
        .then((object) => {
          if (Object.keys(object).length) {
            commit('SET_OBJECT', { object, language: rootState.app.currentLanguage });
          }
        });
    }
    return Promise.resolve();
  },
  GET_OBJECT_DESCRIPTION: (
    { state, commit },
    { objectNumber, languageCode, translationSource },
  ) => {
    const descriptionsData = state.data[objectNumber].descriptions;
    const textSrc = translationSource ? 'translation' : 'natives';
    const languageContainer = descriptionsData[textSrc];
    if (!languageContainer || !languageContainer[languageCode]) {
      return loadObjectDescription(objectNumber, languageCode, translationSource)
        .then((result) => {
          // console.log('loaded Description');
          commit('SET_OBJECT_DESCRIPTION', { objectNumber, result, lang: languageCode });
        });
    }
    return Promise.resolve();
  },
  // loadObjectRatings
  GET_OBJECT_RATINGS: ({ state, commit }, {
    objectNumber, languageCode, ratingId, pageNr, perPage,
  }) => {
    // no ratings for this object or translation requested
    let getNewFromAPI = (!state.ratingsData[objectNumber]
      || (languageCode && languageCode.length > 0));

    const checkPageIndex = pageNr;

    getNewFromAPI = getNewFromAPI || (!state.ratingsData[objectNumber].ratings[checkPageIndex]);
    if (getNewFromAPI) {
      return loadObjectRatings({
        objectNumber, languageCode, ratingId, pageNr, perPage,
      })
        .then((objectRatings) => { // if loadObjectRatings PROMISE resolved
          // console.log('languageCode && ratingId', languageCode, ratingId);
          if (languageCode && ratingId) { // mutate the state with the translation result object
            commit('SET_OBJECT_RATINGS_TRANSLATION', { ratingId, objectRatings });
          } else {
            // call mutation 'SET_OBJECT_RATINGS' to add objectRatings to store.state
            commit('SET_OBJECT_RATINGS', objectRatings);
          }
        });
    }
    return Promise.resolve();
  },

  // marker to check if price request failed
  SAVE_FETCHING_PRICE_ERROR: ({ commit }, { status }) => {
    commit('SET_FETCHING_PRICE_ERROR', { fetchingPriceError: status });
  },
  GET_RKIDATA: ({ getters, commit }, { landkreisID }) => {
    if (getters.getRkiData(landkreisID)) return Promise.resolve();
    return getRkiData(landkreisID).then((data) => {
      commit('SET_RKIDATA', { landkreisID, data });
    });
  },
};
