import axios from 'apiclient';

const getCovidData = (load) => axios.post('/api/getCovidInfo', { ...load }, {
  timeout: 30000,

}).then((res) => res);

export const fetchCovidStateStatus = () => axios.post('/api/getCovidInfoStateStatus')
  .then((res) => Promise.resolve(res.data))
  .catch((res) => Promise.reject(res.errors));

export default getCovidData;
