/* eslint-disable no-undef */

import {
  computed,
  ref,
} from '@vue/composition-api';
import config from 'config/appConfig';
import defaultsDeep from 'lodash.defaultsdeep';
import {
  getCookieVal,
} from 'mixins/composition/CookieApi';
import { userLogConsentCookie } from 'requests/userSaveTrackingParams';

export default (store) => {
  const states = ref({ required: {}, statistic: {}, marketing: {} });
  const confirmedState = ref('');
  const force = ref('');
  const hasConfirmed = computed({
    get: () => {
      const confirmed = getCookieVal('disclaimer');
      const confirmedStore = store.state.cookieLaw.confirmed;
      confirmedState.value = confirmed;
      return force.value || confirmedStore || confirmedState.value || false;
    },
    // setter
    set: (newValue) => {
      force.value = newValue;
      confirmedState.value = newValue;
      store.state.cookieLaw.confirmed = newValue;
    },
  });
  const userLogConsent = (accepted, uid) => {
    userLogConsentCookie(uid, accepted.value);
  };
  const setStates = (accepted, uid) => {
    accepted.forEach((categorie) => {
      const { name, usage } = categorie;
      states.value[name] = usage; // set current value from cookie and form
      store.state.cookieLaw[name] = usage;
    });
    // console.log('states : ', states);
    if (uid) { userLogConsent(states, uid); }
    return states;
  };

  const cookieCategories = computed({
    get: () => {
      const cookieText = getCookieVal('accepted');
      let userAcceptions = {};
      if (cookieText) { userAcceptions = JSON.parse(cookieText); } // get user data from cookie
      let mergedState = defaultsDeep(userAcceptions, config.cookieLaw.categoriesConfig); // merge config data with user decision cookie data
      mergedState = Object.values(mergedState);
      mergedState.forEach((cat) => {
        const { name, usage } = cat;
        states.value[name] = usage;
      });
      return mergedState;
    },
  });

  return {
    setStates, hasConfirmed, cookieCategories, states,
  };
};
