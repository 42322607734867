import { redirectTo } from 'router/guards/navigationGuards';

export default ({
  to, next, store,
}) => {
  if (to.query && to.query.partner && to.query.partner.toString().match(/[0-9]+/)) {
    next();
    return;
  }

  if (store.state.partnerConfig.partnerID) {
    const query = { ...to.query, partner: store.state.partnerConfig.partnerID };
    next(redirectTo(to, {
      query,
    }));
    return;
  }
  next();
};

