/*

@@author        j0Shi
@@date          2019.07.04
@@desc          Helpers to format dates
@@usedby        ObjectVacancyForm -> Object
                utils/vacancyHelper.js
                Object

*/

import {
  isValid, format, parse, differenceInCalendarDays, parseISO,
} from 'date-fns';

const apiDateFormat = 'yyyy-MM-dd'; // ISO8601

// return a formatted date string using XDate syntax
function toDateString(dateObj, formatString = 'dd.MM.yyyy') {
  if (!isValid(dateObj)) return false;
  const formatedString = format(dateObj, formatString);
  return formatedString;
}
// returns standard api request date format
// api internally uses php's strtotime() so it's able to handle multiple formats
// but can't hurt to have a standard
function toAPIDateString(dateObj) {
  if (!isValid(dateObj)) return false;
  return format(dateObj, apiDateFormat);
}
// returns date object from API date string
function fromAPIDateString(s) {
  const d = parse(s, apiDateFormat, new Date());
  if (!isValid(d)) return false;
  d.setHours(12, 0, 0, 0);
  return d;
}
// returns date object from API date time string
function fromAPIDateTimeString(s, formatString = 'yyyy-MM-dd HH:mm:ss') {
  const d = parse(s, formatString, new Date());

  if (!isValid(d)) {
    return false;
  }
  return d;
}
// returns date object from ISO 8601 date time string
function fromISODateTimeString(s, outputFormat = 'dd.MM.yyyy HH:mm:ss') {
  const d = parseISO(s);
  if (!isValid(d)) return false;
  return format(d, outputFormat);
}
// converts flatPickr format into XDate format
function getDateFormatStringFromFlatpickr(formatString) {
  const mapObj = {
    d: 'dd',
    D: 'EEE',
    l: 'EEEE',
    j: 'd',
    J: 'do',
    w: 'i',
    W: 'w',
    F: 'MMMM',
    m: 'MM',
    n: 'M',
    M: 'MMM',
    U: 't',
    y: 'yy',
    Y: 'yyyy',
    Z: 'yyyy-MM-ddTkk:mm:ss.SSSX',
  };
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  return formatString.replace(re, (matched) => mapObj[matched]);
}

/**
 * returns the duration between 2 dates in days
 * @param {String} dateFrom
 * @param {String} dateTo
 * @return {String} duration
 */
function getDurationBetweenDates(dateStrFrom, dateStrTo) {
  let duration = 0;
  const dateFromObj = fromAPIDateString(dateStrFrom);
  const dateFromTo = fromAPIDateString(dateStrTo);

  duration = (dateFromTo.getTime() - dateFromObj.getTime()) / 1000;
  duration /= (60 * 60 * 24);

  return Math.abs(Math.round(duration));
}

// validate start and end dates
function validateDateStringRange(startDateString, endDateString) {
  if (!startDateString || !endDateString || startDateString === endDateString) return false;

  const startDate = fromAPIDateString(startDateString);
  const endDate = fromAPIDateString(endDateString);

  return isValid(startDate)
    && isValid(endDate)
    && differenceInCalendarDays(endDate, startDate) >= 1
    && differenceInCalendarDays(startDate, new Date()) >= 0
    && differenceInCalendarDays(endDate, new Date()) >= 0;
}

export {
  toDateString,
  toAPIDateString,
  fromAPIDateString,
  fromAPIDateTimeString,
  fromISODateTimeString,
  getDateFormatStringFromFlatpickr,
  getDurationBetweenDates,
  validateDateStringRange,
};
