<template>
  <div>
    <!-- early DL Push-->
  </div>
</template>
<script>
import GtmTools from 'mixins/GtmTools';
import DeviceHelper from 'mixins/DeviceHelper';
import {
  getCookieVal,
} from 'mixins/composition/CookieApi';
import setupCookieLaw from 'mixins/composition/CookieLaw'; // get shared functions/computed props
// import cookieCategories from 'mixins/composition/CookieLaw'; // get shared functions/computed props
import gaPropertyConfig from 'config/gaProperty';
import {
  getCLS, getFID, getLCP, getTTFB, getFCP,
} from 'web-vitals';

export default {
  name: 'DataLayerPush',
  mixins: [GtmTools, DeviceHelper],
  setup(props, { root }) {
    const cookieLawComposition = setupCookieLaw(root.$store);
    const {
      cookieCategories, states,
    } = cookieLawComposition;
    return {
      cookieCategories,
      states,
    };
  },
  data() {
    return {
      loadingStart: 0,
      loadingEnd: 0,
      pageLoadId: null,
      gaPropertySet: false,
      pushWebVitals: false,
    };
  },
  computed: {
    gclidLatest() {
      return this.$store.getters.getGclidLatest || this.$route.query.gclid;
    },
    webVitalsDone() { // get value, default 0
      return this.$store.getters.getTrackingWebVitals;
    },
    uid() {
      let uid = this.$store.getters.getUid;
      if (!uid) {
        uid = getCookieVal('uid');
      }
      return uid;
    },
    usertype() {
      return this.$store.state.usertype;
    },
    statistic() {
      return this.$store.state.cookieLaw.statistic;
    },
    marketing() {
      return this.$store.state.cookieLaw.marketing;
    },
    currentLanguage() {
      return this.$store.getters.getCurrentLanguage;
    },
    gaPropertyData() {
      if (gaPropertyConfig.gaProperties[this.currentLanguage]) {
        return gaPropertyConfig.gaProperties[this.currentLanguage];
      }
      return gaPropertyConfig.gaProperties.fallback;
    },
    gaProperty() {
      return this.$store.getters.getGaProperty; // i.e. UA-34169905-13
    },
    gaPropertyMandant() {
      return this.gaPropertyData.shortcut;// i.e. EDCHDE
    },
  },
  watch: {
    gaProperty(propertyId) {
      const obj = { event: 'propertyChange', gaProperty: propertyId, mandant: this.gaPropertyMandant };
      this.dataLayerPush(obj);
    },
    statistic: {
      immediate: true,
      handler(allowed) { // allowed if User accpeted statistics cookies
        if (!this.clientSide) { return; }
        this.dataLayerPush({
          event: 'consent_statistic',
          consent_statistic: allowed,
        });
        // const status = allowed ? 'granted' : 'denied';
        // this.gtag('consent', 'update', {
        //   analytics_storage: status,
        // });
      },
    },
    marketing: {
      immediate: true,
      handler(allowed) { // allowed if User accpeted marketing cookies
        if (!this.clientSide) { return; }
        // if (allowed) { this.$gtm.enable(true); } // enable GTM in App.vue to avoid ssr problem

        this.dataLayerPush({
          event: 'consent_marketing',
          consent_marketing: allowed,
        });
        // const status = allowed ? 'granted' : 'denied';
        // this.gtag('consent', 'update', {
        //   ad_storage: status,
        // });
      },
    },
    currentLanguage: { // EDVUE-2284, get currentLanguage for different property ids
      immediate: true, // must set immediately
      handler() {
        this.$nextTick(() => {
          this.$store.commit('SET_GA_PROPERTY', this.gaPropertyData[this.usertype]); // write propertyid to store "gaProperties.de.service:UA-34169905-13"
        });
      },
    },
  },
  mounted() {
    this.dataLayerPush({
      event: 'client',
      Breakpoint: this.dh_breakpoint || undefined,
      Revision: process.env.REVISION || 'local',
      AWINID: this.$route.query?.zanpid || undefined,
      GCLID: this.gclidLatest || undefined,
    });

    // get current experiments and user variants to push them in dataLayer at 'propertyPush'
    const experimentsConf = this.$store.getters.getExperiments;
    const experimentsDataLayer = [];
    Object.values(experimentsConf).forEach((experimentData) => {
      experimentsDataLayer.push(`${experimentData.experimentKey}:${experimentData.variant}`);
    });
    if (this.gaProperty) {
    // send initial property to GTM (to be dynamic with config)
      this.setProperty(
        {
          event: 'propertyPush',
          gaProperty: this.gaProperty,
          mandant: this.gaPropertyMandant,
          originalLocation: `${document.location.protocol}//${
            document.location.hostname
          }${document.location.pathname
          }${document.location.search}`,
          experiments: experimentsDataLayer.join('!'),
        },
      ).then(() => {
        this.gaPropertySet = true;
      })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  },
  methods: {
    trackTime({
      name, delta, id, custom,
    }) {
      if (!this.pageLoadId) {
        const idParts = id.split('-');
        [this.pageLoadId] = idParts;
      }
      // console.log(this.$route?.matched[0]?.components?.default?.name, this.$router.currentRoute);
      const value = Math.round(name === 'CLS' ? delta * 1000 : delta);
      if (!custom && this.pushWebVitals) {
        // Assumes the global `dataLayer` array exists, see:
        // https://developers.google.com/tag-manager/devguide
        this.dataLayerPush({
          event: 'web-vitals',
          event_category: 'Web Vitals',
          event_action: name,
          // Google Analytics metrics must be integers, so the value is rounded.
          // For CLS the value is first multiplied by 1000 for greater precision
          // (note: increase the multiplier for greater precision if needed).
          event_value: value,
          // The `id` value will be unique to the current page load. When sending
          // multiple values from the same page (e.g. for CLS), Google Analytics can
          // compute a total by grouping on this ID (note: requires `eventLabel` to
          // be a dimension in your report).
          event_label: id,
        });
      }
      let view = 'na';
      if (this.$route?.matched[0]?.components?.default?.name) {
        view = this.$route?.matched[0]?.components?.default?.name;
      }
      let fullPath = '';
      if (this.$route?.fullPath) {
        fullPath = this.$route?.fullPath;
      }

      this.$store.dispatch('SET_WEBVITALS', {
        uid: this.uid, name, value, id, pageid: this.pageLoadId, view, fullPath,
      });
    },
    logDelta({ name, id, delta }) {
      // eslint-disable-next-line no-console
      console.log(`${name} matching ID ${id} changed by ${delta}`);
    },
    logPerformance() {
      // only once per pageload
      if (!this.webVitalsDone) {
        getTTFB((metric) => {
          try {
            if (!this.pageLoadId) {
              const idParts = metric.id.split('-');
              [this.pageLoadId] = idParts;
            }
            const contentLoaded = metric.entries[0].domComplete;
            const dataObject = {
              name: 'contentLoaded', delta: contentLoaded, id: this.pageLoadId, custom: true,
            };
            if (contentLoaded) {
              this.trackTime(dataObject);
            } else {
              // eslint-disable-next-line no-console
              console.log('no time available');
            }
          } catch (err) {
            // prevent errors
          }
        });
        /**
       * Calculates the TTFB value for the current page and calls the onReport function once the page has loaded, along with the relevant navigation performance entry used to determine the value.
       * The reported value is a DOMHighResTimeStamp.
       */
        getTTFB(this.trackTime);

        /**
        * Largest Contentful Paint (LCP): measures loading performance. To provide a good user experience, LCP should occur within 2.5 seconds of when the page first starts loading.
        * Calculates the LCP value for the current page and calls the onReport function once the value is ready (along with the relevant largest-contentful-paint performance entries used to determine the value).
        * The reported value is a DOMHighResTimeStamp.

        * First Input Delay (FID): measures interactivity. To provide a good user experience, pages should have a FID of less than 100 milliseconds.
        * Cumulative Layout Shift (CLS): measures visual stability. To provide a good user experience, pages should maintain a CLS of less than 0.1.

        * The DOMHighResTimeStamp type is a double and is used to store a time value in milliseconds.
        */
        getCLS(this.trackTime);
        // First Input Delay
        getFID(this.trackTime);
        // Largest Contentful Paint
        getLCP(this.trackTime);
        getFCP(this.trackTime);
      }
    },
  },
};
</script>
