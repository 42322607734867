/**
 * WEBPACK_TARGET AND NODE_ENV ARE ONLY RELIABLY SET DURING WEBPACK BUILD PROCESS
 * in nodejs scripts please make sure to `env-cmd` the .env or `cross-env` the vars you need!!
 */
const clientName = process.env.CLIENTNAME || 'e-domizil.de';
const TARGET_NODE = process.env.WEBPACK_TARGET === 'node';
const isTest = process.env.NODE_ENV === 'test';
const isProduction = process.env.NODE_ENV === 'production';
const isNode = process.env.WEBPACK_TARGET === 'node' || process.env.NODE_ENV === 'test';
const isHMR = process.env.WEBPACK_TARGET === 'serve';
const target = TARGET_NODE || isTest
  ? 'server'
  : 'client';

module.exports = {
  clientName,
  TARGET_NODE,
  target,
  isProduction,
  isNode,
  isTest,
  isHMR,
};
