import {
  getCookieVal,
  removeCookie,
} from 'mixins/composition/CookieApi';

import userGet from 'requests/userGet';
import userSaveContact from 'requests/userSaveContact';
import watchedObjectsGet from 'requests/watchedObjectsGet';
import bookingListGet from 'requests/bookingListGet';
import bookingDetailsGet from 'requests/bookingDetailsGet';
import watchedObjectSet from 'requests/watchedObjectSet';
import lastSeenObjectsGet from 'requests/lastSeenObjectsGet';
import lastSeenObjectsSet from 'requests/lastSeenObjectsSet';
import loadSearchObjects from 'requests/searchObjects';
import userLogin from 'requests/userLogin';
import partnerLogin from 'requests/partnerLogin';
import userRegistration from 'requests/userRegistration';
import userRegistrationConfirm from 'requests/userRegistrationConfirm';
import userPasswordRequest from 'requests/userPasswordRequest';
import userPasswordReset from 'requests/userPasswordReset';
import userUpdate from 'requests/userUpdate';
import userWatchlistSendCustomer from 'requests/userWatchlistSendCustomer';
import userSaveTrackingParams, { userLogTimings, userLogHistory } from '../../requests/userSaveTrackingParams';

export default {

  /**
   * if no uid in cookie get a unique uid from API and save to store and cookie
   * if uid in cookie, send uid to API
   */
  GET_USER: ({ commit }, {
    uid, uidOrigin, jwt, trackingParams, revision, experiments, breakPoint, screenWidth, screenHeight, localeInit,
  }) => userGet(uid, uidOrigin, jwt, trackingParams, revision, experiments, breakPoint, screenWidth, screenHeight, localeInit)
    .then((response) => {
      commit('SET_USER_UID', { uid: response.uid });
      commit('SET_JWT', { jwt: response.jwt });
      commit('SET_ACCOUNT_DATA', { accountData: response.accountData || {} });
      if (response.ebk) {
        commit('SET_USER_EBK', { ebk: response.ebk });
      }
      if (response.pid) {
        commit('SET_PID_LATEST', response.pid);
      }
      if (response.pidHistory) {
        commit('SET_PID_HISTORY', response.pidHistory);
      }
      if (response.ptc) {
        commit('SET_PTC_LATEST', response.ptc);
      }
      if (response.gclid) {
        commit('SET_GCLID_LATEST', response.gclid);
      }
      if (response.msclkid) {
        commit('SET_MSCLKID_LATEST', response.msclkid);
      }
      if (response.dclid) {
        commit('SET_DCLID_LATEST', response.dclid);
      }
      if (response.experiments) {
        commit('SET_EXPERIMENT_DATA', response.experiments);
      }
    }),

  /**
   * get last seen objects from API and save to store
   */
  GET_LAST_SEEN_OBJECTS: ({ state, commit }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');
    return lastSeenObjectsGet(
      uid,
    ).then((response) => {
      commit('SET_USER_UID', { uid: response.uid });
      if (response.lastSeenObjects) {
        commit('SAVE_LASTSEENOBJECTS', response.lastSeenObjects);
      }
    });
  },

  /**
   * add object number to last seen object list
   */
  ADD_TO_LASTSEENOBJECTS({ state, commit }, { objectData }) {
    const uid = state.uid ? state.uid : getCookieVal('uid');
    return lastSeenObjectsSet(uid, objectData)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });
        commit('SAVE_LASTSEENOBJECTS', response.lastSeenObjects);
      });
  },
  GET_BOOKINGDETAILS: ({ state, commit }, { bookingId }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');
    const jwt = state.jwt ? state.jwt : getCookieVal('jwt');
    return bookingDetailsGet(uid, jwt, bookingId)
      .then((response) => {
        if (response.bookingDetails) {
          commit('SET_BOOKINGDETAILS', response.bookingDetails);
        }
      });
  },
  GET_BOOKINGLIST: ({ state, commit }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');
    const jwt = state.jwt ? state.jwt : getCookieVal('jwt');
    return bookingListGet(uid, jwt)
      .then((response) => {
        if (response.bookingList) {
          commit('SET_BOOKINGLIST', response.bookingList);
        }
      });
  },
  GET_WATCHED_OBJECTS: ({ state, commit }, { watchListUID, watchList }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');
    const jwt = state.jwt ? state.jwt : getCookieVal('jwt');
    return watchedObjectsGet(uid, jwt, watchListUID, watchList)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });

        // EDVUE-3252 merge current uid with watchlist origin uid if user accepted statistics
        // only has impact on shared watchlist
        if (response.sharedWatchListUserUID) {
          commit('SET_SHARED_WATCHLIST_USER_UID', { uid: response.sharedWatchListUserUID });
        }

        // clear vuex watchlist first
        state.watchedObjects = [];
        state.watchedObjectsShared = [];

        // users own watchlist
        if (response.userWatchList) {
          response.userWatchList.forEach((watchedObject) => {
            commit('SET_WATCHED_OBJECT', {
              objectNumber: parseInt(watchedObject.objectNumber, 10),
              price: watchedObject.watchPrice,
              priceOriginal: watchedObject.watchPriceOriginal,
              pricePercentReduction: watchedObject.watchPricePercentReduction,
              currency: watchedObject.watchCurrency,
              startDate: watchedObject.watchStartDate,
              endDate: watchedObject.watchEndDate,
              persons: watchedObject.watchPersons,
              addDate: watchedObject.watchAddDate,
              deleteDate: watchedObject.watchDeleteDate,
            });
          });
          commit('SET_USER_WATCHLISTUID', response.watchListUID);
        }

        // user got shared watchlist
        if (response.sharedWatchList) {
          response.sharedWatchList.forEach((watchedObject) => {
            commit('SET_WATCHED_OBJECT_SHARED', {
              objectNumber: parseInt(watchedObject.objectNumber, 10),
              price: watchedObject.watchPrice,
              priceOriginal: watchedObject.watchPriceOriginal,
              pricePercentReduction: watchedObject.watchPricePercentReduction,
              currency: watchedObject.watchCurrency,
              startDate: watchedObject.watchStartDate,
              endDate: watchedObject.watchEndDate,
              persons: watchedObject.watchPersons,
              addDate: watchedObject.watchAddDate,
              deleteDate: watchedObject.watchDeleteDate,
            });
          });
        }
      });
  },

  UPDATE_WATCHED_OBJECTS({ state, commit }, { objectData, action }) {
    // make (broken) heart animation faster, before request finishes
    // this is only for single objects, not for "addAll" or "deleteAll"
    if (!Array.isArray(objectData.objectNumber)) {
      objectData.deleteDate = null;
      if (action === 'delete') {
        objectData.deleteDate = Date.now();
      }
      commit('SET_WATCHED_OBJECT', objectData);
    }

    const uid = state.uid ? state.uid : getCookieVal('uid');
    return watchedObjectSet(uid, objectData, action)
      .then((response) => {
        if (response) {
          commit('SET_USER_UID', { uid: response.uid });

          if (response.userWatchList) {
            Object.values(response.userWatchList).forEach((watchedObject) => {
              commit('SET_WATCHED_OBJECT', {
                objectNumber: parseInt(watchedObject.objectNumber, 10),
                price: watchedObject.watchPrice,
                priceOriginal: watchedObject.watchPriceOriginal,
                pricePercentReduction: watchedObject.watchPricePercentReduction,
                currency: watchedObject.watchCurrency,
                startDate: watchedObject.watchStartDate,
                endDate: watchedObject.watchEndDate,
                persons: watchedObject.watchPersons,
                addDate: watchedObject.watchAddDate,
                deleteDate: watchedObject.watchDeleteDate,
              });
            });
            commit('SET_USER_WATCHLISTUID', response.watchListUID);
          }
        }
      })
      .catch((errData) => {
        switch (action) {
          case 'add':
            // remove watched object if request fails
            objectData.deleteDate = Date.now();
            break;
          case 'delete':
            // re-add object if request fails
            objectData.deleteDate = null;
            break;
          default:
            break;
        }
        // only for single objects, not for "addAll" or "deleteAll"
        if (!Array.isArray(objectData.objectNumber)) {
          commit('SET_WATCHED_OBJECT', {
            objectData,
          });
        }
        return Promise.reject(errData);
      });
  },

  /**
   * fires API search for displaying object data in /watchlist
   */
  // eslint-disable-next-line no-unused-vars
  async SEARCH_WATCHED_OBJECTS({ state, commit }, { page }) {
    // must be multiple search requests because api only returns 40 objects max

    // check which watched objects are active or deleted
    const watchedObjectsActive = [];
    const watchedObjectsDeleted = [];
    state.watchedObjects.forEach((watchedObject) => {
      if (!watchedObject.deleteDate) {
        watchedObjectsActive.push(watchedObject.objectNumber);
      } else {
        watchedObjectsDeleted.push(watchedObject.objectNumber);
      }
    });

    const watchedObjectsES = {};

    // get active watchlist objects data from ES
    let searchQuery = {};
    searchQuery.objects = watchedObjectsActive.join();
    searchQuery.page = page;
    if (searchQuery.objects) {
      searchQuery = { ...searchQuery, ...this.getters.getSearchFilters };
      await loadSearchObjects(searchQuery).then((searchObjects) => {
        if (searchObjects?.hits?.length) {
          searchObjects.hits.forEach((searchObject) => {
            watchedObjectsES[searchObject.objectNumber] = searchObject;
          });
        }
        // only save aggregations from active objects
        // aggregation data like searchBounds, averageRating, searchFilterCounts
        if (searchObjects?.aggs) {
          commit('SET_AGGREGATIONS_WATCHLIST', searchObjects.aggs);
        }
      });
    }

    // get deleted watchlist objects data from ES
    searchQuery = {};
    searchQuery.objects = watchedObjectsDeleted.join();
    searchQuery.page = 1;
    if (searchQuery.objects) {
      searchQuery = { ...searchQuery, ...this.getters.getSearchFilters };
      await loadSearchObjects(searchQuery).then((searchObjects) => {
        if (searchObjects?.hits?.length) {
          searchObjects.hits.forEach((searchObject) => {
            watchedObjectsES[searchObject.objectNumber] = searchObject;
          });
        }
      });
    }

    // get shared watchlist objects data from ES
    const watchedObjectsShared = [];
    state.watchedObjectsShared.forEach((watchedObjectShared) => {
      watchedObjectsShared.push(watchedObjectShared.objectNumber);
    });
    searchQuery = {};
    searchQuery.objects = watchedObjectsShared.join();
    searchQuery.page = 1;
    if (searchQuery.objects) {
      searchQuery = { ...searchQuery, ...this.getters.getSearchFilters };
      await loadSearchObjects(searchQuery).then((searchObjects) => {
        if (searchObjects?.hits?.length) {
          searchObjects.hits.forEach((searchObject) => {
            watchedObjectsES[searchObject.objectNumber] = searchObject;
          });
        }
      });
    }

    const watchedObjectsArray = Object.values(watchedObjectsES);
    commit('SET_WATCHED_OBJECTS_SEARCH', { watchedObjectsArray });
  },

  // async action that only trigger a sync commit => could be rewritten as mutation => TODO
  ADD_STORE_SUBSCRIPTION: ({ commit }, { subscriptionTarget }) => {
    commit('SET_STORE_SUBSCRIPTION', subscriptionTarget);
    return Promise.resolve();
  },

  // async action that only trigger a sync commit => could be rewritten as mutation => TODO
  SAVE_PID_LATEST: ({ commit }, { pid }) => {
    commit('SET_PID_LATEST', pid);
    return Promise.resolve();
  },

  SAVE_PTC_LATEST: ({ commit }, { ptc }) => {
    commit('SET_PTC_LATEST', ptc);
    return Promise.resolve();
  },

  SAVE_GCLID_LATEST: ({ commit }, { gclid }) => {
    commit('SET_GCLID_LATEST', gclid);
    return Promise.resolve();
  },

  SAVE_MSCLKID_LATEST: ({ commit }, { msclkid }) => {
    commit('SET_MSCLKID_LATEST', msclkid);
    return Promise.resolve();
  },

  SAVE_DCLID_LATEST: ({ commit }, { dclid }) => {
    commit('SET_DCLID_LATEST', dclid);
    return Promise.resolve();
  },

  USER_SAVE_CONTACT: ({ state, commit }, { email }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userSaveContact(uid, email)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });
        commit('SET_USER_EBK', { ebk: response.ebk });
      }).catch(() => {});
  },

  // async action that only trigger a sync commit => could be rewritten as mutation => TODO
  SAVE_ENCOURAGEMENT_FLAG: ({ commit }, { objectNumber }) => {
    commit('SET_ENCOURAGEMENT_FLAG', { objectNumber });
    return Promise.resolve();
  },

  // eslint-disable-next-line no-unused-vars
  SET_WEBVITALS: ({ commit }, {
    uid, name, value, pageid, view, fullPath,
  }) => {
    commit('SET_WEBVITALS_DONE', 1);
    // const encodedQuery = encodeURIComponent(JSON.stringify(query));
    userLogTimings(uid, {
      name, value, pageid, view, fullPath,
    });
  },

  USER_LOG_HISTORY: ({ commit }, {
    uid, jwt, historyParams,
  }) => userLogHistory(uid, jwt, historyParams)
    .then((response) => {
      commit('SET_JWT', { jwt: response.jwt });
    }),

  // eslint-disable-next-line no-unused-vars
  SAVE_TRACKING_PARAMS: ({ state, commit }, { trackingParams }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userSaveTrackingParams(uid, trackingParams);
  },

  // eslint-disable-next-line no-unused-vars
  USER_REGISTRATION: ({ state, commit }, { user }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userRegistration(uid, user)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });

        if (response.accountData) {
          commit('SET_ACCOUNT_DATA', { accountData: response.accountData });
        }
        return Promise.resolve(response);
      });
  },

  // eslint-disable-next-line no-unused-vars
  USER_REGISTRATION_CONFIRM: ({ commit }, { confirmID }) => userRegistrationConfirm(confirmID)
    .then((response) => Promise.resolve(response)),

  PARTNER_LOGIN: ({ commit }, { loginData }) => partnerLogin(loginData.uid, loginData.partner, loginData.c)
    .then((response) => {
      commit('SET_USER_UID', { uid: response.uid });
      commit('SET_JWT', { jwt: response.jwt });

      if (response.accountData) {
        commit('SET_ACCOUNT_DATA', { accountData: response.accountData });
      }
      return Promise.resolve(response);
    }),

  USER_LOGIN: ({ state, commit }, { user, loginPersistent }) => {
    const jwt = state.jwt ? state.jwt : getCookieVal('jwt');
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userLogin(uid, jwt, user, loginPersistent)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });
        commit('SET_JWT', { jwt: response.jwt });

        if (response.accountData) {
          commit('SET_ACCOUNT_DATA', { accountData: response.accountData });
        }
        return Promise.resolve(response);
      });
  },

  USER_LOGOUT: ({ commit }) => {
    commit('SET_ACCOUNT_DATA', { accountData: {} });
    commit('SET_JWT', { jwt: '' });
    removeCookie('jwt');
    return Promise.resolve();
  },

  // eslint-disable-next-line no-unused-vars
  USER_PASSWORD_REQUEST: ({ state, commit }, { user }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userPasswordRequest(uid, user)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });

        return Promise.resolve(response);
      });
  },

  // eslint-disable-next-line no-unused-vars
  USER_PASSWORD_RESET: ({ state, commit }, { resetID, user }) => {
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userPasswordReset(uid, resetID, user)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });

        return Promise.resolve(response);
      });
  },

  // eslint-disable-next-line no-unused-vars
  USER_UPDATE: ({ state, commit }, { userAccountData }) => {
    const jwt = state.jwt ? state.jwt : getCookieVal('jwt');
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userUpdate(uid, jwt, userAccountData)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });
        commit('SET_JWT', { jwt: response.jwt });

        if (response.data?.accountData) {
          commit('SET_ACCOUNT_DATA', { accountData: response.data.accountData });
        }
        return Promise.resolve(response);
      });
  },

  // eslint-disable-next-line no-unused-vars
  USER_WATCHLIST_SEND_CUSTOMER: ({ state, commit }, { inputs }) => {
    const jwt = state.jwt ? state.jwt : getCookieVal('jwt');
    const uid = state.uid ? state.uid : getCookieVal('uid');

    return userWatchlistSendCustomer(uid, jwt, inputs)
      .then((response) => {
        commit('SET_USER_UID', { uid: response.uid });
        commit('SET_JWT', { jwt: response.jwt });
        return response;
      });
  },
};
