<template>
  <header
    id="headerMain"
    v-observe-visibility="{
      callback: editAppIntersectClass,
    }"
  >
    <b-navbar
      v-if="showHeader"
    >
      <a
        v-if="showHeaderLogo"
        class="navbar-brand"
        :href="arp_linkBuilder(mainLink)"
        :aria-label="$t('common:share.homepage')"
      >
        <img
          :src="mxn_logoPath"
          height="100%"
          alt=""
        >
      </a>
      <div class="ml-auto">
        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <ul class="navbar-nav ml-auto d-flex align-items-center">
            <li
              v-if="UserApi_loggedIn"
              class="nav-item d-none d-lg-inline-block"
            >
              <i class="icon">
                <icon-user-circle-alternative />
              </i>
              <em
                class="mr-2"
              >
                {{ UserApi_accountData.accountFirstName }}
              </em>
            </li>

            <!-- Burger Menu -->
            <li class="nav-item d-sm-none">
              <button
                class="nav-link"
                type="button"
                data-ident="headerNavMetaActiveToggle"
                @click="headerNavMetaActiveToggle"
              >
                <i class="icon"><icon-bars /></i>
                <em>
                  {{ $t('common:header.headerBulletpoints.menu') }}
                </em>
              </button>
            </li>

            <!-- Suche -->
            <li class="nav-item d-none d-sm-inline-block">
              <a
                class="nav-link"
                :href="arp_linkBuilder({ name: 'Search'})"
                data-ident="headerBulletpointsSearch"
                @click.prevent="arp_linkClicker({ name: 'Search' })"
              >
                <i class="icon">
                  <icon-search-alternative />
                </i>
                <em>
                  {{ $t('common:header.headerBulletpoints.search') }}
                </em>
              </a>
            </li>

            <!-- Merkliste -->
            <li class="nav-item">
              <a
                class="nav-link"
                :href="arp_linkBuilder({ name: 'WatchList', query: watchListQuery })"
                data-ident="headerShowWatchlist"
                @click.prevent="arp_linkClicker({ name: 'WatchList', query: watchListQuery })"
              >
                <i class="icon">
                  <icon-heart />
                </i>
                <em class="text-nowrap">
                  <span class="d-none d-sm-inline">
                    {{ $t('common:menu.watchlist') }}
                  </span>
                  <span class="d-inline d-sm-none">
                    {{ $t('common:header.headerBulletpoints.mark') }}
                  </span>
                  <span class="badge badge-pill badge-secondary">{{ watchedObjectsCount }}</span>
                </em>
              </a>
            </li>

            <!-- Office Info -->
            <li
              class="nav-item d-none d-sm-inline-block"
            >
              <office-contact
                :template="'OfficeContactHeader'"
              />
            </li>
          </ul>
        </b-collapse>
      </div>
    </b-navbar>
    <div
      id="header-nav-meta"
      :class="{active: headerNavMetaActive}"
    >
      <ul>
        <li class="d-sm-none">
          <a
            :href="arp_linkBuilder({ name: 'Search'})"
            data-ident="headerBulletpointsSearch2"
            @click.prevent="headerNavMetaActiveToggle(); arp_linkClicker({ name: 'Search'});"
          >
            {{ $t('common:header.headerBulletpoints.search') }}
          </a>
        </li>

        <li class="d-lg-none">
          <office-contact
            :template="'OfficeContactHeader'"
          />
        </li>
      </ul>
    </div>
  </header>
</template>
<script>

import UserApi from 'mixins/UserApi';
import Partner from 'mixins/Partner';
import IntersectionObserverHelper from 'mixins/IntersectionObserverHelper';
import { BNavbar, BCollapse } from 'bootstrap-vue';
import OfficeContact from 'components/OfficeContact.vue';
import AnchorRouterPush from 'mixins/AnchorRouterPush';

/* ICONS */
import IconSearchAlternative from 'ICON:IconSearchAlternative';
import IconHeart from 'ICON:IconHeart';
import IconUserCircleAlternative from 'ICON:IconUserCircleAlternative';
import IconBars from 'ICON:IconBars';

export default {
  name: 'Header',
  components: {
    IconSearchAlternative,
    IconHeart,
    IconUserCircleAlternative,
    BNavbar,
    BCollapse,
    OfficeContact,
    IconBars,
  },
  mixins: [IntersectionObserverHelper, AnchorRouterPush, UserApi, Partner],
  data() {
    return {
      headerNavMetaActive: false,
    };
  },
  computed: {
    partnerUrl() {
      return this.$store.getters.getPartnerConfigValue('partnerUrl') || '';
    },
    mainLink() {
      if (this.UserApi_loggedIn || this.partnerUrl === '' || this.$store.getters.getPartnerConfigValue('ignorePartnerUrl') === 'Y') {
        return { name: 'Home' };
      }
      return this.partnerUrl;
    },
    watchedObjects() {
      return this.$store.getters.getWatchedObjects;
    },
    watchedObjectsCount() {
      let count = 0;
      this.watchedObjects.forEach((watchedObject) => {
        if (!watchedObject.deleteDate) {
          count += 1;
        }
      });
      return count;
    },
    watchListQuery() {
      const returnQuery = {};
      if (this.$store.getters.getWatchListUID) {
        returnQuery.watchListUID = this.$store.getters.getWatchListUID;
      }
      return returnQuery;
    },
  },
  methods: {
    headerNavMetaActiveToggle() {
      this.headerNavMetaActive = !this.headerNavMetaActive;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 0;
  border-bottom: 1px solid $color_grey_100;
  margin-bottom: -1px;
}
/deep/ #header-nav-meta {
  overflow: hidden;
  background: #fff;

  @include transition_slow;

  max-height: 0;
  opacity: 0;

  &.active {
    display: block;
    max-height: 30em;
    opacity: 1;
    padding-left:0em;
    @media #{$media_xl_min} {
      padding-top: 1.5em;
    }

    @media #{$media_lg} {
      padding-top: 1em;
    }

    @media #{$media_md} {
      padding-top: 2em;
    }

    @media #{$media_sm} {
      padding-top: 1em;
    }

    @media #{$media_xs} {
      padding-top: 0;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0.5em $margin_content;

      @media #{$media_xs} {
        padding: 0.4em $margin_content_xs;
      }

      border-top: 1px solid $color_grey_100;
      margin: 0;
      list-style: none;
      display: block;

      &:first-child {
        border-top: none;
      }

      a {
        padding: 0.5em 0;

        @media #{$media_xs} {
          padding: 0.4em 0;
        }
      }

      .icon,
      .icon-flag {
        margin: 0 0.4em 0 0;
      }

      em {
        @include font_regular;
      }
    }
  }
}

.navbar-brand {
  height: 64px;
}
.badge{
  &.badge-pill{
    background-color: #fff;
    color:var(--color_ci);
    padding: 4px 0;
    width:2em;
    font-size: 0.7em;
    font-weight: 700;
    line-height: 1.1em;
    text-align: center;
    white-space: nowrap;
    vertical-align: unset;
  }
}

#app:not(.isTouchDevice) .nav-link:hover {
  .badge-pill{
      color: #fff;
      background-color:var(--color_ci);
  }
}
</style>
