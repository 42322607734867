<template>
  <div class="empty-loading" />
</template>

<script>

export default {
  name: 'EmptyLoading',
};
</script>
