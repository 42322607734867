/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  // https://projects.e-domizil.de/jira/browse/EDVUE-1572
  // workaround for firefox throwing serviceWorker errors for strict security settings
  // this basically tries to access the serviceWorker once at startup and only registers the serviceWorker if the call doesn't throw an error
  navigator.serviceWorker.getRegistration().then(() => {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready(registration) {
        console.log('App is being served from cache by a service worker.\n'
          + 'For more details, visit https://goo.gl/AFskqB', registration);
      },
      registered(registration) {
        console.log('Service worker has been registered.', registration);
      },
      cached(registration) {
        console.log('Content has been cached for offline use.', registration);
      },
      updatefound(registration) {
        console.log('New content is downloading.', registration);
      },
      updated(registration) {
        console.log('New content is available; please refresh.', registration);
      },
      offline() {
        console.log('No internet connection found. App is running in offline mode.');
      },
      error(error) {
        console.error('Error during service worker registration:', error);
      },
    });
  })
    .catch(() => {});
}
