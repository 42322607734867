// user module
import userState from './state';
import userActions from './actions';
import userGetters from './getters';
import userMutations from './mutations';

export default {
  state: userState,
  actions: userActions,
  getters: userGetters,
  mutations: userMutations,
};
